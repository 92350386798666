/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import {
	AcquiringInfo,
	AdminAccount,
	AdminOrder,
	AdminOrderCalculation,
	GetActiveOrderResponse,
	GetAdminAccountsResponse,
	GetAdminOrdersResponse,
	GetBankCardsResponse,
	GetParkingSpacesResponse,
	GetParkingsResponse,
	GetParkingStatResponse,
	GetSystemStatusesResponse,
	MyAccountInfo,
	Order,
	OrderStatusType,
	Parking,
	ParkingSpace,
	Statistics,
	SystemStatus,
	Tariff,
	ChequeInfo,
	AppVersion,
	GetAppVersionsResponse,
	Transaction,
	OrderStatusDetails,
	GetAdjustmentStatResponse,
} from 'store/dto/dtos';
import types from 'store/types';
import { actionCreator, IPageOpt } from 'store/utils';

export default {
	setParkings: actionCreator<GetParkingsResponse>(types.data.SET_PARKINGS),
	setCurrentParking: actionCreator<Parking>(types.data.SET_CURRENT_PARKING),
	setParkingSpaces: actionCreator<GetParkingSpacesResponse>(types.data.SET_PARKING_SPACES),
	setCurrentParkingSpace: actionCreator<ParkingSpace>(types.data.SET_CURRENT_PARKING_SPACE),
	setAccountInfo: actionCreator<MyAccountInfo>(types.data.SET_ACCOUNT_INFO),
	setOrders: actionCreator<Order[]>(types.data.SET_ORDERS),
	setPaidLink: actionCreator<string>(types.data.SET_PAID_LINK),
	setActiveOrder: actionCreator<GetActiveOrderResponse>(types.data.SET_ACTIVE_ORDER),
	setParkingStats: actionCreator<GetParkingStatResponse>(types.data.SET_PARKING_STATS),
	setAdminOrders: actionCreator<GetAdminOrdersResponse>(types.data.SET_ADMIN_ORDERS),
	setAdminOrder: actionCreator<AdminOrder>(types.data.SET_ADMIN_ORDER),
	setTariff: actionCreator<Tariff>(types.data.SET_TARIFF),
	setBankCards: actionCreator<GetBankCardsResponse>(types.data.SET_BANK_CARDS),
	setStatistics: actionCreator<Statistics>(types.data.SET_STATISTICS),
	setSystemStatuses: actionCreator<GetSystemStatusesResponse>(types.data.SET_SYSTEM_STATUSES),
	setAdminCurrentSystemStatus: actionCreator<SystemStatus>(types.data.SET_ADMIN_CURRENT_SYSTEM_STATUS),
	setCurrentSystemStatus: actionCreator<SystemStatus>(types.data.SET_CURRENT_SYSTEM_STATUS),
	saga: {
		asyncGetParkings: actionCreator<IPageOpt>(types.data.saga.ASYNC_GET_PARKINGS),
		asyncGetParkingSpaces: actionCreator<string>(types.data.saga.ASYNC_GET_PARKING_SPACES),
		asyncGetAcountInfo: actionCreator<string>(types.data.saga.ASYNC_GET_ACOUNT_INFO),
		asyncSetAccountInfo: actionCreator<{
			email:string; displayName:string; licenseNumber:string;}>(types.data.saga.ASYNC_SET_ACCOUNT_INFO),
		asyncGetBankCards: actionCreator<string>(types.data.saga.ASYNC_GET_BANK_CARDS),
		asyncDeleteBankCard: actionCreator<{id:string}>(types.data.saga.ASYNC_DELETE_BANK_CARD),
		asyncGetOrders: actionCreator<IPageOpt>(types.data.saga.ASYNC_GET_ORDERS),
		asyncSetPaidOrder: actionCreator<{orderId:string, returlUrl:string}>(types.data.saga.ASYNC_SET_PAID_ORDER),
		asyncGetActiveOrder: actionCreator<any>(types.data.saga.ASYNC_GET_ACTIVE_ORDER),
		asyncGetParkingStats: actionCreator<any>(types.data.saga.ASYNC_GET_PARKING_STATS),
		asyncGetAdminOrders: actionCreator<{
			page: number;
			items: number;
			sort: string;
			filter: string;
			from:string;
			to:string;
			status:any;
			transactionSource:any;
		}>(types.data.saga.ASYNC_GET_ADMIN_ORDERS),
		asyncUpdateAdminOrders: actionCreator<{id:string, refund:boolean, newStatusType?: OrderStatusType, newDurationTill?: string, newAmount?: number, description: string, callback?:() => void}>(types.data.saga.ASYNC_UPDATE_ADMIN_ORDER),
		asyncCalculateAdminOrder: actionCreator<{id:string, durationTill:string, callbackCalculateAdminOrder?:(item:AdminOrderCalculation) => void}>(types.data.saga.ASYNC_CALCULATE_ADMIN_ORDER),

		asyncGetSchedule: actionCreator<{callbackGetSchedule:(item:Tariff[])=> void}>(types.data.saga.ASYNC_GET_SCHEDULE),
		// asyncDeleteTariff: actionCreator<{id:string}>(types.data.saga.ASYNC_DELETE_TARIFFS),
		asyncUpdateSchedule: actionCreator<{schedule:Tariff[], callbackUpdateSchedule:(item:boolean)=> void}>(types.data.saga.ASYNC_UPDATE_SCHEDULE),
		// asyncCreateTariff: actionCreator<Tariff>(types.data.saga.ASYNC_CREATE_TARIFFS),

		asyncGetStatistics: actionCreator<{to:string, from:string}>(types.data.saga.ASYNC_GET_STATISTICS),

		asyncGetAdminAccounts: actionCreator<{
			page: number;
			items: number;
			sort: string;
			filter: string;
			callbackAdminAccounts?:(items:GetAdminAccountsResponse) => void;
				}>(types.data.saga.ASYNC_GET_ADMIN_ACCOUNTS),
		asyncGetAdminAccount: actionCreator<{id:string, callbackAdminAccount?:(item:AdminAccount) => void}>(types.data.saga.ASYNC_GET_ADMIN_ACCOUNT),
		asyncUpdateAdminAccounts: actionCreator<{id:string, fake:boolean, callbackUpdateAdminAccount?:() => void}>(types.data.saga.ASYNC_UPDATE_ADMIN_ACCOUNTS),
		asyncGetTransactions: actionCreator<{orderId:string, callbackGetTransactions?:(item:Transaction[]) => void}>(types.data.saga.ASYNC_GET_TRANSACTIONS),

		asyncGetSystemStatuses: actionCreator<{
			page: number;
			items: number;
			sort: string;
			filter: string;
			from:string;
			to:string;
		}>(types.data.saga.ASYNC_GET_SYSTEM_STATUSES),
		asyncCreateSystemStatus: actionCreator<{item:SystemStatus, callback?:() => void}>(types.data.saga.ASYNC_CREATE_SYSTEM_STATUS),
		asyncGetAdminCurrentSystemStatus: actionCreator<any>(types.data.saga.ASYNC_GET_ADMIN_CURRENT_SYSTEM_STATUS),
		asyncGetCurrentSystemStatus: actionCreator<{callback?:(item:SystemStatus) => void}>(types.data.saga.ASYNC_GET_CURRENT_SYSTEM_STATUS),
		asyncGetAcquiring: actionCreator<{callbackAcquiring?:(item:AcquiringInfo) => void}>(types.data.saga.ASYNC_GET_ACQUIRING),
		asyncGetCheques: actionCreator<{orderId:string, callbackCheques?:(item:ChequeInfo[]) => void}>(types.data.saga.ASYNC_GET_CHEQUES),

		asyncGetOrderStatuses: actionCreator<{orderId:string, callbackOrderStatuses?:(item:OrderStatusDetails[]) => void}>(types.data.saga.ASYNC_GET_ORDER_STATUSES),

		asyncFindParkingSpaces: actionCreator<{filter:string, page:number, items:number, sort:string, callbackFindParkingSpaces?:(item:GetParkingSpacesResponse) => void}>(types.data.saga.ASYNC_FIND_PARKING_SPACES),
		asyncCreateAppVersion: actionCreator<{item:AppVersion, callbackCreateAppVersion?:(item:AppVersion) => void}>(types.data.saga.ASYNC_CREATE_APP_VERSION),
		asyncGetCurrentAppVersions: actionCreator<{callbackCurrentAppVersions?:(item:AppVersion) => void}>(types.data.saga.ASYNC_GET_CURRENT_APP_VERSIONS),
		asyncGetAppVersion: actionCreator<{id:string, callbackAppVersion?:(item:AppVersion) => void}>(types.data.saga.ASYNC_GET_APP_VERSION),
		asyncGetAppVersions: actionCreator<{
			page: number;
			items: number;
			sort: string;
			filter: string;
			from:string;
			to:string;
			callbackAppVersions?:(item:GetAppVersionsResponse) => void;
				}>(types.data.saga.ASYNC_GET_APP_VERSIONS),
		asyncGetAdjustmentStat: actionCreator<{
			page: number;
			items: number;
			sort: string;
			filter: string;
			from:string;
			to:string;
			parkingSpaceId:string;
			parkingId:string;
			callbackAdjustmentStat?:(item:GetAdjustmentStatResponse) => void;}>(types.data.saga.ASYNC_GET_ADJUSTMENT_STAT),
		asyncGetExportAdjustmentStat: actionCreator<{
			from:string;
			to:string;
			parkingSpaceId:string;
			parkingId:string;
			callbackExportAdjustmentStat:(item:Uint8Array) => void;}>(types.data.saga.ASYNC_GET_EXPORT_ADJUSTMENT_STAT),

	},
};
