import styled from 'styled-components';
import ShowPasswordSVG from 'assets/icons/forms/show_password.svg';

export const ShowPasswordIcon = styled(ShowPasswordSVG)``;

export const AShowPassword = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0.875rem;
  cursor: pointer;
`;

export const Form = styled.div`
  &.active {
    svg {
      fill: #ff8a00;
    }
  }
  svg {
    fill: #92929d;
  }
  width: 100%;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1px;

    /* App/Text gray */

    color: red;
  }
`;
export const Label = styled.label`
  display: block;
  margin-bottom: 0.75rem;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #92929d;
`;
export const Input = styled.input`
  display: block;
  width: 100%;
  height: 48px;
  padding: 0.375rem 0.75rem;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #92929d;
  background: #1d2335;
  border: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-right: 2.5rem;
  color:#fff;
  &.error {
    /* border-color: #198754; */
    border: 1px solid red;
  }
  .text-field__input_invalid {
    /* border-color: #198754; */
    border: 1px solid #198754;
  }
  .text-field__input_invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  &:focus {
    color: none;
    border: none;
    outline: 0;
  }
`;
