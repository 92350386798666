/* eslint-disable prefer-regex-literals */
/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import actions from 'store/actions';

import {
	AppVersion, GetAppVersionsResponse,
} from 'store/dto/dtos';
import selectors from 'store/selectors';
import {
	PlusOutlined,
	HistoryOutlined,
} from '@ant-design/icons';
import {
	Tooltip, Button, Modal, Input, Form, Table, DatePicker,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
	ContentWidget,
	ControlElement,
	FooterWidget,
	TableTest,
	TitleWidget,
	WidgetBox,
} from './styledComponent';

const { TextArea } = Input;

interface IDataType {
	id: React.Key;
	date: string;
	iosVersion: number;
	androidVersion: number;
  }

const { RangePicker } = DatePicker;

const columns: ColumnsType<IDataType> = [
	{
		title: 'Время',
		dataIndex: 'date',

	},
	{
		title: 'iosVersion',
		dataIndex: 'iosVersion',

	},
	{
		title: 'androidVersion',
		dataIndex: 'androidVersion',
	},
];
export type AppDataWidgetHandle = {
  updateHandle: () => void;
};

const AppVersionWidget = React.forwardRef<AppDataWidgetHandle>((props, ref) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	// const statisticsData: GetSystemStatusesResponse = useSelector(
	// selectors.data.getSystemStatuses,
	// );

	const [currentData, setCurrentData] = React.useState<AppVersion>(new AppVersion());
	const [statisticsData, setStatisticsData] = React.useState<GetAppVersionsResponse>(new GetAppVersionsResponse());
	const currentLoading : string[] = useSelector(selectors.app.getCurrentLoading);

	const [paramsControlTable, setParamsControlTable] = React.useState<{ from:any, to:any}>({ from: null, to: null });
	const [dataHistoryTable, setDataHistoryTable] = React.useState<IDataType[]>([]);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [isHistoryModalOpen, setIsHistoryModalOpen] = React.useState(false);

	const updateData = () => {
		const callbackCurrentAppVersions = (item:AppVersion) => {
			setCurrentData(item);
		};
		dispatch(actions.data.saga.asyncGetCurrentAppVersions({ callbackCurrentAppVersions }));
	};

	const showModal = () => {
		currentData.androidVersion ? form.setFieldValue('androidVersion', currentData.androidVersion)
			: form.setFieldValue('androidVersion', '');
		currentData.iosVersion ? form.setFieldValue('iosVersion', currentData.iosVersion)
			: form.setFieldValue('iosVersion', '');
		setIsModalOpen(true);
	};

	const sussesCloseModalEdit = () => {
		setIsModalOpen(false);
		dispatch(actions.data.saga.asyncGetAdminCurrentSystemStatus(''));
	};

	const handleEditOk = () => {
		const callbackCreateAppVersion = (item:AppVersion) => { setCurrentData(item); sussesCloseModalEdit(); };

		dispatch(actions.data.saga.asyncCreateAppVersion({
			item: {
				androidVersion: form.getFieldValue('androidVersion'), iosVersion: form.getFieldValue('iosVersion'),
			} as AppVersion,
			callbackCreateAppVersion,
		}));
	};
	const loadDataHistiry = () => {
		const callbackAppVersions = (item:GetAppVersionsResponse) => {
			setStatisticsData(item);
		};
		dispatch(actions.data.saga.asyncGetAppVersions({
			items: 0,
			page: 0,
			filter: '',
			sort: 'date desc',
			to: paramsControlTable.to && dayjs(paramsControlTable.to).add(24, 'hour').toISOString(),
			from: paramsControlTable.from && dayjs(paramsControlTable.from).toISOString(),
			callbackAppVersions,
		}));
	};
	const openHistoryModal = () => {
		const callbackAppVersions = (item:GetAppVersionsResponse) => {
			setStatisticsData(item);
			setIsHistoryModalOpen(true);
		};
		dispatch(actions.data.saga.asyncGetAppVersions({
			items: 0,
			page: 0,
			filter: '',
			sort: 'date desc',
			to: paramsControlTable.to && dayjs(paramsControlTable.to).add(24, 'hour').toISOString(),
			from: paramsControlTable.from && dayjs(paramsControlTable.from).toISOString(),
			callbackAppVersions,
		}));
	};

	React.useEffect(() => {
		updateData();
	}, []);

	React.useImperativeHandle(ref, () => ({
		updateHandle() {
			updateData();
		},
	}));
	React.useEffect(() => {
		// setRequestParams({
		// ...requestParams,
		// orderDisabled: currentData.orderDisabled,
		// paymentDisabled: currentData.paymentDisabled,
		// });
	}, [currentData]);

	React.useEffect(() => {
		const tempData: IDataType [] = [];
		statisticsData.items?.forEach((item) => {
			tempData.push({
				date: dayjs(item.date).format('DD.MM.YYYY HH:mm'),
				id: item.id,
				androidVersion: item.androidVersion,
				iosVersion: item.iosVersion,
			});
		});
		setDataHistoryTable(tempData);
	}, [statisticsData]);

	React.useEffect(() => {
		loadDataHistiry();
	}, [paramsControlTable]);
	return (
		<WidgetBox>
			<TitleWidget>
				<span>Версии приложений</span>

				<ControlElement>
					<Tooltip title="История">
						<Button onClick={openHistoryModal} className="button-antd" ghost icon={<HistoryOutlined />}>
							{/* История */}
						</Button>
					</Tooltip>
				</ControlElement>
			</TitleWidget>
			<ContentWidget>
				<div className="div1">IOS version:</div>
				<div
					className="div2"
				>
					{currentData?.iosVersion}
				</div>
				<div className="div3">Android version: </div>
				<div className="div4">
					{currentData?.androidVersion}
				</div>
			</ContentWidget>
			<FooterWidget>
				<Button
					style={{ fontSize: '12px' }}
					size="small"
					type="primary"
					onClick={showModal}
				>
					Изменить

				</Button>
			</FooterWidget>

			<Modal
				title="Изменить версии приложений"
				open={isModalOpen}
				footer={[

					<Button
						onClick={() => setIsModalOpen(false)}
						loading={!currentLoading.findIndex((item) => item === 'createSystemStatus')}
					>
						Отмена
					</Button>,
					<Button
						form="myForm"
						type="primary"
						key="submit"
						htmlType="submit"
						loading={!currentLoading.findIndex((item) => item === 'createSystemStatus')}
					>
						Подтвердить
					</Button>,

				]}
			>
				<Form
					id="myForm"
					form={form}
					onFinish={handleEditOk}
					// labelCol={{ span: 8 }}
					// wrapperCol={{ span: 12 }}
					layout="vertical"
					style={{ minWidth: 400 }}
					initialValues={{ remember: true }}
				>
					<Form.Item>
						<Input.Group compact>
							<Form.Item
								label="IOS version"
								name="iosVersion"
								style={{ width: 'calc(100% - 32px)' }}
								rules={[
									{ required: true, message: 'Обязательное поле!' },
									{
										pattern: new RegExp('^([0-9]{1}[.][0-9]{2})*$'),
										message: 'Версия должна быть типа X.YY',
									},
									{
										validator: async (rule, value) => {
											if (value < currentData.iosVersion) {
												throw new Error('Новая версия должна быть больше предыдущей');
											}
										},
									},
								]}
							>
								<Input autoComplete="off" />

							</Form.Item>
							<Tooltip title="Увеличить версию ">
								<Button
									style={{ marginTop: '30px' }}
									icon={<PlusOutlined />}
									onClick={() => {
										form.setFieldValue('iosVersion', (parseFloat(form.getFieldValue('iosVersion')) + 0.01).toFixed(2));
									}}
								/>
							</Tooltip>
						</Input.Group>
					</Form.Item>

					<Form.Item>
						<Input.Group compact>
							<Form.Item
								label="Android version"
								name="androidVersion"
								style={{ width: 'calc(100% - 32px)' }}
								rules={[
									{ required: true, message: 'Обязательное поле!' },
									{
										pattern: new RegExp('^([0-9]{1}[.][0-9]{2})*$'),
										message: 'Версия должна быть типа X.YY',
									},
									{
										validator: async (rule, value) => {
											if (value < currentData.androidVersion) {
												throw new Error('Новая версия должна быть больше предыдущей');
											}
										},
									},
								]}
							>
								<Input autoComplete="off" />

							</Form.Item>
							<Tooltip title="Увеличить версию">
								<Button
									style={{ marginTop: '30px' }}
									icon={<PlusOutlined />}
									onClick={() => {
										form.setFieldValue('androidVersion', (parseFloat(form.getFieldValue('androidVersion')) + 0.01).toFixed(2));
									}}
								/>
							</Tooltip>
						</Input.Group>
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				title={[
					<div style={{
						display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px',
					}}
					>
						<span>история</span>
						<RangePicker
							format="DD.MM.YYYY"
							onChange={(value) => {
								value ? setParamsControlTable({
									from: dayjs(value?.[0]).set('hour', 0).set('minute', 0).set('second', 0),
									to: dayjs(value?.[1]).set('hour', 0).set('minute', 0).set('second', 0),
								}) : setParamsControlTable({ from: null, to: null });
							}}
							style={{ minWidth: '300px' }}
						/>
					</div>,
				]}
				open={isHistoryModalOpen}
				width="70vw"
				footer={[
					<Button type="primary" onClick={() => setIsHistoryModalOpen(false)}>
						Закрыть
					</Button>,
				]}
			>
				<TableTest>
					<Table
						bordered
						columns={columns}
						dataSource={dataHistoryTable}
						pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50'] }}
						scroll={{ y: 'calc(50vh - 4em)' }}
					/>
				</TableTest>
			</Modal>
		</WidgetBox>
	);
});

export default AppVersionWidget;
