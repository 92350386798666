/* eslint-disable no-unused-expressions */
/* eslint-disable no-tabs */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-mixed-operators */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button as CustomButton } from 'components/forms/Button';
import {
	Form, Input, TimePicker, Modal,
} from 'antd';
import { DeleteOutlined, WarningOutlined  } from '@ant-design/icons';
import adminInstructionPdf from 'assets/files/administrator_instructoin.pdf';
import actions from 'store/actions';
import { Tariff } from 'store/dto/dtos';
import dayjs from 'common/utils/dayjs';
import 'dayjs/locale/ru';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { v4 as uuidv4 } from 'uuid';
import {
	Body,
	Title,
	MainSection,
	NameTitle,
	ControlElement,
	ListElement,
} from './styledComponent';

interface ErrorMessage {
	id:string;
	message:string;
}

interface TempTariff {
	id:string;
	start:any;
	end:any;
}

const moment = extendMoment(Moment);

const TestTariffAdminPage: React.FC = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [scheduleData, setScheduleData] = React.useState<Tariff[]>([]);
	const [errorData, setErrorData] = React.useState<ErrorMessage[]>([]);
	const [isEdit, setIsEdit] = React.useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = React.useState(false);

	const loadData = () => {
		const callbackGetSchedule = (value: Tariff[]) => {
			setIsEdit(false);
			setScheduleData(value);

			value.forEach(((item, index) => {
				form.setFields([
					{ name: `from_${item.id}`, value: dayjs(item.from, 'HH:mm') },
					{ name: `to_${item.id}`, value: dayjs(item.to, 'HH:mm') },
					{ name: `amount_${item.id}`, value: item.hourlyRate },
				]);
			}));
		};
		dispatch(actions.data.saga.asyncGetSchedule({
			callbackGetSchedule,
		}));
	};

	const updateData = () => {
		const callbackUpdateSchedule = (item: boolean) => {
			if (item) {
				loadData();
			}
		};
		dispatch(actions.data.saga.asyncUpdateSchedule({
			schedule: scheduleData,
			callbackUpdateSchedule,
		}));
	};

	const cancel = () => {
		setIsEdit(false);
		loadData();
		setErrorData([]);
		form.resetFields();
	};

	const add = () => {
		console.log(scheduleData, scheduleData.at(-1));
		console.log(form.isFieldValidating(`to_${scheduleData.at(-1)?.id}`), form.getFieldError(`to_${scheduleData.at(-1)?.id}`).length === 0);
		const temp = {
			hourlyRate: 25,
			id: uuidv4(),
			from: scheduleData.length !== 0 ? scheduleData[scheduleData.length - 1].to : '00:00',
			to: scheduleData.length !== 0 ? scheduleData[scheduleData.length - 1].to : '00:00',
		} as Tariff;
		setScheduleData([...scheduleData, temp]);

		form.setFields([
			{ name: `from_${temp.id}`, value: scheduleData.length !== 0 ? dayjs(scheduleData[scheduleData.length - 1].to, 'HH:mm') : dayjs('00:00', 'HH:mm') },
			{ name: `to_${temp.id}`, value: scheduleData.length !== 0 ? dayjs(scheduleData[scheduleData.length - 1].to, 'HH:mm') : dayjs('00:00', 'HH:mm') },
			{ name: `amount_${temp.id}`, value: 25 },
		]);
	};

	const deleteItem = (id:string) => {
		setScheduleData(scheduleData.filter((el) => el.id !== id));
	};

	const isValidRange = () => {
		const temp: TempTariff[] = [];
		scheduleData.forEach((item) => {
			const tempFrom = item.from.split(':');
			const tempTo = item.to.split(':');
			temp.push({
				id: item.id,
				start: moment(`${tempFrom[0]}:${tempFrom[1]}`, 'HH:mm'),
				end: moment(`${tempTo[0]}:${tempTo[1]}`, 'HH:mm'),
			});
		});

		temp.sort((a, b) => {
			if (a.start > b.start) return 1;
			if (a.start < b.start) return -1;
			return 0;
		});
		const overlapsTariff:TempTariff[] = [];
		temp.forEach((item) => {
			const intervalA = moment.range(item.start, item.end);

			temp.forEach((items) => {
				if (items.id !== item.id) {
					const intervalB = moment.range(items.start, items.end);
					if (intervalA.overlaps(intervalB)) {
						// console.log(moment(intervalA.intersect(intervalB)?.start).format('HH:mm'), moment(intervalA.intersect(intervalB)?.end).format('HH:mm'));

						overlapsTariff.push(item);
					}
				}
			});
		});
		const tempErrorMessage: ErrorMessage[] = [];
		overlapsTariff.forEach((item) => {
			tempErrorMessage.push({ id: item.id, message: 'Интервалы пересекаются' });
		});
		setErrorData(tempErrorMessage);
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const save = () => {
		isValidRange();
		if (scheduleData.at(-1)?.to !== '00:00') { showModal(); } else if (errorData.length === 0)	updateData();
	};
	React.useEffect(() => {
		loadData();
	}, []);

	return (
		<Body>
			<Title>
				<NameTitle>Тарифы</NameTitle>
				<ControlElement>
					<CustomButton style={{ marginLeft: 12 }} onClick={() => window.open(adminInstructionPdf, '_blank')}>
						Инструкция администратора
					</CustomButton>
				</ControlElement>
			</Title>
			<MainSection>
				<ListElement>

					<div className="header">
						<h2>Интервалы</h2>
					</div>
					<div className="list">
						<Form
							id="myForm"
							form={form}
							onFinish={save}
						>
							{scheduleData.map((item, index) => (
								<div className="item" key={item.id}>
									<span style={{ width: '30px', marginTop: '5px'  }}>{index + 1}</span>
									<span style={{ marginTop: '5px' }}>C: </span>
									<Form.Item
										name={`from_${item.id}`}
										rules={[
											{ required: true, message: 'Укажите время!' },
										]}
									>
										<TimePicker
											inputReadOnly
											disabled
											style={{
												border: 'none',
												borderBottomWidth: '1px',
												borderBottomColor: !isEdit ? 'gray' : '#fff',
												borderBottomStyle: 'solid',
												borderBottomLeftRadius: '0px',
												borderBottomRightRadius: '0px',
												width: '150px',
											}}
											clearIcon={null}
											value={dayjs(item.from, 'HH:mm')}
											minuteStep={30}
											showNow={false}
											format="HH:mm"
											onChange={(e) => {
												const tempArray = [...scheduleData];
												const tempItem = { ...tempArray[index] };
												tempItem.from = dayjs(e).format('HH:mm');
												tempArray[index] = tempItem;
												setScheduleData(tempArray);
												// if()
												// isValidRange();
											}}
										/>

									</Form.Item>
									<span style={{ marginTop: '5px' }}>До: </span>
									<Form.Item
										name={`to_${item.id}`}
										rules={[
											{ required: true, message: 'Укажите время!' },
											{
												validator: async (rule, value) => {
													if (
														(item.to !== '00:00' ? dayjs(item.to, 'HH:mm') : dayjs('23:59', 'HH:mm')) <= dayjs(item.from, 'HH:mm')) {
														throw new Error('"До" раньше чем "c"');
													}
												},
											},
										]}
									>

										<TimePicker
											disabled={!isEdit || (index < scheduleData.length - 1)}
											style={{
												border: 'none',
												borderBottomWidth: '1px',
												borderBottomColor: !isEdit ? 'gray' : '#fff',
												borderBottomStyle: 'solid',
												borderBottomLeftRadius: '0px',
												borderBottomRightRadius: '0px',
												width: '150px',
											}}
											value={dayjs(item.to, 'HH:mm')}
											clearIcon={null}
											minuteStep={30}
											showNow={false}
											format="HH:mm"
											onChange={(e) => {
												const tempArray = [...scheduleData];
												const tempItem = { ...tempArray[index] };
												tempItem.to = dayjs(e).format('HH:mm');
												tempArray[index] = tempItem;
												setScheduleData(tempArray);
												isValidRange();
											}}
										/>

									</Form.Item>
									<span style={{ marginTop: '5px' }}>Стоимость: </span>
									<Form.Item
										name={`amount_${item.id}`}
										rules={[
											{ required: true, message: 'Укажите стоимость!' },
										]}
									>
										<Input
											disabled={!isEdit}
											type="number"
											style={{
												width: '100px', borderColor: !isEdit ? 'gray' : '#fff',
											}}
											suffix="RUB"
											onChange={(e) => {
												const tempArray = [...scheduleData];
												const tempItem = { ...tempArray[index] };
												tempItem.hourlyRate = parseInt(e.target.value, 10);
												tempArray[index] = tempItem;
												setScheduleData(tempArray);
											}}
										/>
									</Form.Item>
									{isEdit && (index === scheduleData.length - 1)  && <DeleteOutlined style={{ marginTop: '8px' }} onClick={() => deleteItem(item.id)}  />}
									{errorData.map((message) => (message.id === item.id) && (
										<div className="error-block">
											<WarningOutlined style={{ fontSize: '25px', color: 'red' }} />
											<span>{message.message}</span>
										</div>
									))}

								</div>

							))}
						</Form>
					</div>

					<div className="footer">
						{!isEdit
							? (
								<CustomButton style={{ fontSize: '14px', padding: '7px 17px', height: 'auto' }} onClick={() => setIsEdit(true)}>
									Изменить
								</CustomButton>
							)

							: (
								<>
									<CustomButton
										disabled={
											(scheduleData.at(-1)!.to !== '00:00' ? dayjs(scheduleData.at(-1)!.to, 'HH:mm') : dayjs('23:59', 'HH:mm')) <= dayjs(scheduleData.at(-1)!.from, 'HH:mm') || scheduleData.at(-1)!.to === '00:00'
										}
										style={{ fontSize: '14px', padding: '7px 17px', height: 'auto' }}
										onClick={add}
									>
										Добавить
									</CustomButton>
									<CustomButton key="submit" form="myForm" style={{ fontSize: '14px', padding: '7px 17px', height: 'auto' }}>
										Сохранить
									</CustomButton>
									<CustomButton style={{ fontSize: '14px', padding: '7px 17px', height: 'auto' }} onClick={cancel}>
										Отменить
									</CustomButton>

								</>
							) }

					</div>

					{/* </form> */}
				</ListElement>

			</MainSection>

			<Modal title="Внимание" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
				<p>Расписание должно начинаться в 00:00 и заканчиваться в 00:00, что обозначает полные сутки.</p>
				<p>Измените время окончания последнего интервала на 00:00</p>
			</Modal>
		</Body>
	);
};

export default TestTariffAdminPage;
