/* eslint-disable max-len */
import { GetParkingSpacesResponse, GetParkingsResponse, ParkingSpaceStatus } from 'store/dto/parking.dtos';
import types from 'store/types';
import { actionCreator } from 'store/utils';

export default {

	saga: {
		asyncGetParkingSpaces: actionCreator<{page: number, items: number, sort: string, filter: string, customerId: string, callbackParkingSpaces?:(item:GetParkingSpacesResponse) => void}>(types.parkingSpaceManipulation.saga.ASYNC_GET_PARKING_SPACES_MANIPULATION),
		asyncGetParkingsIts: actionCreator<{page: number, items: number, sort: string, filter: string, customerId: string, callbackParkings?:(item:GetParkingsResponse) => void}>(types.parkingSpaceManipulation.saga.ASYNC_GET_PARKINGS_ITS),
		asyncSetParkingSpaceEngineerMode: actionCreator<{ customerId: string, id:string, callbackEngineer?:(item:ParkingSpaceStatus) => void }>(types.parkingSpaceManipulation.saga.ASYNC_SET_PARKING_SPACE_ENGINEER_MODE),
		asyncGetParkingSpaceStatus: actionCreator<{ customerId: string, id: string, callbackParkingSpaceStatus?:(value:ParkingSpaceStatus) => void }>(types.parkingSpaceManipulation.saga.ASYNC_GET_PARKING_SPACE_STATUS),

		asyncOpenParkingSpace: actionCreator<{ customerId: string, id:string, callbackOpen?:(item:ParkingSpaceStatus) => void}>(types.parkingSpaceManipulation.saga.ASYNC_OPEN_PARKING_SPACE),
		asyncMoveStepParkingSpace: actionCreator<{ customerId: string, id:string, open:boolean, description:string, callbackMoveStep?:(item:ParkingSpaceStatus) => void}>(types.parkingSpaceManipulation.saga.ASYNC_MOVE_STEP_PARKING_SPACE),

	},
};
