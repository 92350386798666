import styled from 'styled-components';

export const Form = styled.div`
  /* margin-bottom: 1rem; */
  width: 100%;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1px;

    /* App/Text gray */

    color: #962c2c;
  }
`;
export const Label = styled.label`
  display: block;
  margin-bottom: 0.75rem;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #92929d;
`;

export const Prefix = styled.div`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2.5rem;
    background-color: #1d233500;
    border-right: 2px solid #171725;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }

  &::after {
    content: "+7";
    color: #fff;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    width: 2.5rem;
    top: 50%;
    transform: translateY(-50%);
    justify-content: center;
  }
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  height: 48px;
  padding: 0.375rem 0.75rem;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #92929d;
  background: #1d2335;
  border: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  padding-left: 3rem;
  color:#fff;
  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  &:focus {
    color: none;
    border: none;
    outline: 0;
  }
  &:required {
    border-color: black;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
    ${Prefix}::after {
      color: #92929d;
    }
  }
`;
