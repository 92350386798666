import IAppState from 'store/interfaces/App.Interface';
import { IAction } from 'store/utils';

export const initialState: IAppState = {
	globalLoading: undefined,
	currentLoadings: [],
	notification: [],
	successfulApi: [],
	showBooking: false,
	showTariffEditForm: false,
	showAdminOrderEditForm: false,
	showAdminOrderHistoryForm: false,
	showWarningPopup: false,

};
const reducer = (state = initialState, action: IAction<any>): IAppState => {
	const { payload } = action;

	switch (action.type) {
	case 'GLOBAL_LOADING':
		return { ...state, globalLoading: payload };
	case 'SET_CURRENT_LOADINGS':
		return { ...state, currentLoadings: payload };
	case 'SET_NOTIFICATION':
		return { ...state, notification: payload };
	case 'SET_SUCCESSFUL_API':
		return { ...state, successfulApi: payload };
	case 'SET_SHOW_BOOKING':
		return { ...state, showBooking: payload };
	case 'SET_SHOW_TARIFF_EDIT_FORM':
		return { ...state, showTariffEditForm: payload };
	case 'SET_SHOW_ADMIN_ORDER_EDIT_FORM':
		return { ...state, showAdminOrderEditForm: payload };
	case 'SET_SHOW_ADMIN_ORDER_HISTORY_FORM':
		return { ...state, showAdminOrderHistoryForm: payload };
	case 'SET_SHOW_WARNING_POPUP':
		return { ...state, showWarningPopup: payload };

	default:
		return state;
	}
};

export default reducer;
