/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import baseApiService from 'service/base-api.service';
import { call, put } from 'redux-saga/effects';
import {
	CreateSystemStatusRequest, GetAdminCurrentSystemStatusRequest,
	GetCurrentSystemStatusRequest,
	GetSystemStatusesRequest, GetSystemStatusesResponse, SystemStatus,
} from 'store/dto/dtos';
import { IAction } from 'store/utils';
import actions from 'store/actions';

const getSystemStatusesCall = (opts: {
    page: number;
    items: number;
    sort: string;
    filter: string;
	from:string;
	to:string;
}): Promise<GetSystemStatusesResponse> => {
	const request = new GetSystemStatusesRequest();
	request.filter = opts.filter;
	request.items = opts.items;
	request.page = opts.page;
	request.sort = opts.sort;
	request.from = opts.from;
	request.to = opts.to;
	return baseApiService.get(request);
};

const getAdminCurrentSystemStatusCall = (): Promise<SystemStatus> => {
	const request = new GetAdminCurrentSystemStatusRequest();
	return baseApiService.get(request);
};
const getCurrentSystemStatusCall = (): Promise<SystemStatus> => {
	const request = new GetCurrentSystemStatusRequest();
	return baseApiService.get(request);
};

const createSystemStatusCall = (opts: {item:SystemStatus}): Promise<SystemStatus> => {
	const request = new CreateSystemStatusRequest();
	request.entity = opts.item;
	return baseApiService.post(request);
};

export function* getSystemStatusesRequest(action: IAction<{ page: number;
    items: number;
    sort: string;
    filter: string;
	from:string;
	to:string;}>) {
	try {
		const {
			payload: {
				page, items, sort, filter, from, to,
			},
		} = action;
		yield put(actions.app.saga.asyncAddLoading('loadingSystemStatuses'));
		const result: GetSystemStatusesResponse = yield call(getSystemStatusesCall, {
			page, items, sort, filter, from, to,
		});
		yield put(actions.data.setSystemStatuses(result));
		yield put(actions.app.saga.asyncRemoveLoading('loadingSystemStatuses'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingSystemStatuses'));
		yield put(actions.data.setSystemStatuses({} as GetSystemStatusesResponse));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
export function* getAdminCurrentSystemStatusRequest(action: IAction<any>) {
	try {
		const { payload } = action;
		yield put(actions.app.saga.asyncAddLoading('loadingAdminCurrentSystemStatus'));
		const result: SystemStatus = yield call(getAdminCurrentSystemStatusCall);
		yield put(actions.data.setAdminCurrentSystemStatus(result));
		yield put(actions.app.saga.asyncRemoveLoading('loadingAdminCurrentSystemStatus'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingAdminCurrentSystemStatus'));
		yield put(actions.data.setAdminCurrentSystemStatus({} as SystemStatus));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
export function* getCurrentSystemStatusRequest(action: IAction<{callback?: (item:SystemStatus) => void}>) {
	try {
		const { payload } = action;
		yield put(actions.app.saga.asyncAddLoading('loadingCurrentSystemStatus'));
		const result: SystemStatus = yield call(getCurrentSystemStatusCall);
		yield put(actions.data.setCurrentSystemStatus(result));
		yield payload.callback?.((result));
		yield put(actions.app.saga.asyncRemoveLoading('loadingCurrentSystemStatus'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingCurrentSystemStatus'));
		yield put(actions.data.setCurrentSystemStatus({} as SystemStatus));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}

export function* createSystemStatusRequest(action: IAction<{item: SystemStatus, callback?: () => void}>) {
	try {
		const {
			payload: { item, callback },
		} = action;
		yield put(actions.app.saga.asyncAddLoading('createSystemStatus'));
		const result: SystemStatus = yield call(createSystemStatusCall, {
			item,
		});
		// yield put(actions.data.saga.asyncGetAdminCurrentSystemStatus(''));
		yield callback?.();
		yield put(actions.app.saga.asyncRemoveLoading('createSystemStatus'));
		yield put(actions.app.saga.asyncInitNotification({
			messages: 'Состояние системы изменено!',
			type: 'warm',
		}));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('createSystemStatus'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
