/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import { TDevice } from 'common/devices';
import useWindowDimensions from 'common/useWindowDimensions';
import LoadingComponent from 'components/layout/Loading';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import {
	Order,
	OrderStatusType,
	SystemStatus,
} from 'store/dto/dtos';
import selectors from 'store/selectors';
import { BackArrowIcon } from '../SelectMapElement/styledComponent';
// import { MainBlock } from '../SelectMapElement/styledComponent';
import {
	SectionContent, TitleContent, BodyContent, HistoryElement,
	TimeElement, DateElement, TimerElement, DescriptionElement,
	FirstNameElement, NameElement, Subtitle, SecondElement, PriceElement,
	PaidElement, LinkElement, MainBlock, ActiveHistoryElement, ContentMobile, TitleMobile,
} from './styledComponent';

interface IHistoryElement {
    setOrderData:(item:Order[]) => void;
    selectedMarker: any;
    selectedOrder: (item:Order) => void;

}

const HistoryLayoutElement: React.FC<IHistoryElement> = (props) => {
	const {
		selectedOrder, selectedMarker, setOrderData,
	} = props;
	const dispatch = useDispatch();
	const [activeOrder, setActiveOrder] = React.useState<any>(null);
	const [selectedHistory, setShowSelectedHistory] = React.useState<boolean>(false);
	const [activeHistoryElement, setActiveHistoryElement] = React.useState<Order>(new Order());
	const orderData : Order[] = useSelector(selectors.data.getOrders) || [];
	const paidLink : string = useSelector(selectors.data.getPaindLink);
	const systemStatus: SystemStatus = useSelector(selectors.data.getCurrentSystemStatus);

	const { width } = useWindowDimensions();

	const getDeviceType = (): TDevice => {
		if (width <= 768) return 'mobile';
		return 'laptop';
	};

	React.useEffect(() => {
		setOrderData(orderData);
	}, [orderData]);
	React.useEffect(() => {
		dispatch(actions.data.saga.asyncGetOrders({
			items: 0,
			filter: '',
			page: 0,
			sort: 'created desc',
		}));
	}, []);
	React.useEffect(() => {
		setActiveOrder(selectedMarker);
		setActiveHistoryElement(orderData.find((m) => m.id === selectedMarker?.id) || new Order());
	}, [selectedMarker]);
	React.useEffect(() => {
		if (paidLink) {
			// eslint-disable-next-line no-unused-expressions
			const w = window.open(paidLink, '_parent');
			w?.addEventListener('close', () => {
				console.log('closed');
			});
		}
	}, [paidLink]);
	const paidOrder = (item: Order) => {
		const callback = (items:SystemStatus) => {
			if (items.paymentDisabled) {
				dispatch(actions.app.setShowWarningPopup(true));
			} else {
				dispatch(actions.data.saga.asyncSetPaidOrder({ orderId: item.id, returlUrl: window.location.href }));
			}
		};

		dispatch(actions.data.saga.asyncGetCurrentSystemStatus({ callback }));
	};
	const refresh = () => {
		dispatch(actions.data.saga.asyncGetOrders({
			items: 0,
			filter: '',
			page: 0,
			sort: 'created desc',
		}));
	};

	const selectedOrderMobile = (item:Order) => {
		selectedOrder(item);
		setActiveHistoryElement(item);
		setShowSelectedHistory(true);
	};

	const getStatus = (status: OrderStatusType) => {
		switch (status) {
		case OrderStatusType.New:
			return 'Новая';
		case OrderStatusType.Booked:
			return 'Бронь';
		case OrderStatusType.UserCancelled:
			return 'Отменена пользователем';
		case OrderStatusType.ReadyToDriveIn:
			return 'Готова к въезду';
		case OrderStatusType.Active:
			return 'Активна';
		case OrderStatusType.NotPaid:
			return 'Завершено, не оплачено';
		case OrderStatusType.PaymentProcessing:
			return 'В процессе оплаты';
		case OrderStatusType.Paid:
			return 'Оплачено';
		case OrderStatusType.SystemCancelled:
			return 'Отменена системой';
		case OrderStatusType.OperatorCancelled:
			return 'Отменена оператором';
		default:
			return ('Неизвестно');
		}
	};

	return (
		<>

			<MainBlock className={!selectedHistory ? 'show' : 'out'}>
				{getDeviceType() === 'mobile' && <LoadingComponent loadingElement="paidLoading" />}
				<TitleContent>
					<span>История</span>
					<span onClick={() => refresh()}>Обновить</span>
				</TitleContent>
				<SectionContent>

					<BodyContent>
						{orderData?.map((item:Order) => (
							<HistoryElement key={item.id} className={activeOrder?.id === item.id ? 'active' : 'none'}>

								<TimeElement>
									<DateElement>{moment(item.created).format('DD.MM.YY HH:mm')}</DateElement>
									<TimerElement>

										{(item.status === OrderStatusType.SystemCancelled || item.status === OrderStatusType.OperatorCancelled) ? '---' : `${moment.duration(item.duration).days() * 24 + moment.duration(item.duration).hours()} ч  ${moment.duration(item.duration).minutes()} мин` }
									</TimerElement>
								</TimeElement>

								<DescriptionElement>
									<FirstNameElement>
										<NameElement>{item.address}</NameElement>
										<Subtitle>
											<span>
												{item.number}
											</span>
										</Subtitle>
									</FirstNameElement>

									<SecondElement>
										<PriceElement>
											{getStatus(item.status)}
											<br />
											<span>
												{(item.status === OrderStatusType.SystemCancelled || item.status === OrderStatusType.OperatorCancelled) ? '---' : `${item.amount} РУБ` }
											</span>
										</PriceElement>
									</SecondElement>
								</DescriptionElement>

								{(item.status === (OrderStatusType.NotPaid || OrderStatusType.Active || OrderStatusType.Booked)) && (
									<PaidElement
										className={(systemStatus.paymentDisabled) ? 'disabled-button' : ''}
										onClick={() => paidOrder(item)}
									>
										<span>Оплатить парковку</span>
									</PaidElement>
								)}
								<LinkElement onClick={() => (getDeviceType() === 'laptop'
									? selectedOrder(item)
									: selectedOrderMobile(item))}
								>
									<span>Посмотреть на карте</span>
								</LinkElement>
							</HistoryElement>
						))}
					</BodyContent>
				</SectionContent>
			</MainBlock>
			<ActiveHistoryElement className={selectedHistory ? 'show' : 'out'}>
				<ContentMobile>
					<TitleMobile>
						<div style={{ cursor: 'pointer' }} onClick={() => setShowSelectedHistory(false)}>
							<BackArrowIcon />
							<span>Назад</span>

						</div>
					</TitleMobile>
					<HistoryElement key={activeHistoryElement?.id} className="active">

						<TimeElement>
							<DateElement>{moment(activeHistoryElement?.created).format('DD.MM.YY HH:mm')}</DateElement>
							<TimerElement>
								{/* {`${moment.duration(activeHistoryElement?.duration).hours()} ч`}
								{' '}
								{`${moment.duration(activeHistoryElement?.duration).minutes()} мин`} */}
								{(activeHistoryElement.status === OrderStatusType.SystemCancelled || activeHistoryElement.status === OrderStatusType.OperatorCancelled) ? '---' : `${moment.duration(activeHistoryElement.duration).hours()} ч  ${moment.duration(activeHistoryElement.duration).minutes()} мин` }

							</TimerElement>
						</TimeElement>

						<DescriptionElement>
							<FirstNameElement>
								<NameElement>{activeHistoryElement?.address}</NameElement>
								<Subtitle>
									<span>
										{activeHistoryElement?.number}
									</span>
								</Subtitle>
							</FirstNameElement>

							<SecondElement>
								<PriceElement>
									{getStatus(activeHistoryElement?.status)}
									<br />
									<span>
										{(activeHistoryElement.status === OrderStatusType.SystemCancelled || activeHistoryElement.status === OrderStatusType.OperatorCancelled) ? '---' : `${activeHistoryElement.amount} РУБ`}
									</span>
								</PriceElement>
							</SecondElement>
						</DescriptionElement>

						{(activeHistoryElement?.status === (OrderStatusType.NotPaid || OrderStatusType.Active || OrderStatusType.Booked)) && (
							<PaidElement
								className={(systemStatus.paymentDisabled) ? 'disabled-button' : ''}
								onClick={() => paidOrder(activeHistoryElement)}
							>
								<span>Оплатить парковку</span>
							</PaidElement>
						)}
					</HistoryElement>
				</ContentMobile>

			</ActiveHistoryElement>
		</>
	);
};

export default HistoryLayoutElement;
