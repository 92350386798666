import IState from 'store/interfaces/Store.Interface';

const getRoot = (state: IState) => state.authorization;

export default {
	get: (state: IState) => getRoot(state),
	getToken: (state: IState) => getRoot(state).token,
	getAuth: (state: IState) => getRoot(state).auth,
	getRegistrationData: (state:IState) => getRoot(state).registrationData,
	getAdminData: (state:IState) => getRoot(state).adminData,
	getCustomer: (state: IState) => getRoot(state).customer,
	getPermissions: (state: IState) => getRoot(state).permissions,
	getUser: (state: IState) => getRoot(state).user,
};
