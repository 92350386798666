/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import FooterCabinetMobile from 'components/layout/FooterCabinetMobile';
import React from 'react';
import HeaderBar from 'components/layout/HeaderBar';
import { Modal, Button as ButtonAntd } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/forms/Button';
import actions from 'store/actions';
import {
	GetActiveOrderResponse,
	GetParkingSpacesResponse, Order, ParkingSpace, ParkingSpaceStatus, ParkingSpaceStatusType, SystemStatus,
} from 'store/dto/dtos';
import selectors from 'store/selectors';
import { useNavigate } from 'react-router-dom';
import LoadingComponent from 'components/layout/Loading';
import BookingParkingElement from 'components/elements/BookingParking';
import { BackArrowIcon } from 'components/elements/BookingParking/styledComponent';
import { ClearOutlined } from '@ant-design/icons';
import {
	BackArrow,
	BodyContent,
	Content, EmptySearch, FooterButton, FooterContent, HeaderSearch, InputBox, InputElement, MainSection, ParkingPlace, TitleInput,
} from './styledComponent';

const SearchNewPage: React.FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const activeOrder: GetActiveOrderResponse = useSelector(selectors.data.getActiveOrder) || null;
	const showBooking: boolean = useSelector(selectors.app.getShowBooking);

	// const [zamokNumber, setZamokNumber] = React.useState<{first: any, second: any, third: any }>({ first: null, second: null, third: null });
	const [zamokNumberString, setZamokNumberString] = React.useState<any>(null);
	const [parkingSpaces, setParkingSpaces] = React.useState<GetParkingSpacesResponse>(new GetParkingSpacesResponse());
	const [checkEmptySearch, setCheckEmptySearch] = React.useState<boolean>(false);
	const [activeParking, setActiveParking] = React.useState<ParkingSpace>(new ParkingSpace());
	const [isModalPaidOpen, setIsModalPaidOpen] = React.useState(false);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [showEmptySearch, setShowEmptySearch] = React.useState(false);
	React.useEffect(() => {
		document.getElementById('inputBox1')?.focus();
	}, []);

	const search = () => {
		if (zamokNumberString) {
			setCheckEmptySearch(false);
			const callbackFindParkingSpaces = (items:GetParkingSpacesResponse) => {
				if (items.itemsPerPage === 0) {
					setParkingSpaces(items);
					setShowEmptySearch(true);
				} else {
					setShowEmptySearch(false);
					setParkingSpaces(items);
				}
			};
			dispatch(actions.data.saga.asyncFindParkingSpaces({
				filter: `${zamokNumberString}`, items: 0, page: 0, sort: '', callbackFindParkingSpaces,
			}));
		} else {
			setCheckEmptySearch(true);
		}
	};

	const getColor = (status:ParkingSpaceStatus) => {
		switch (status.status) {
		case (ParkingSpaceStatusType.Free):
			return '#33CC33';
		case (ParkingSpaceStatusType.Busy):
			return '#E74A4F';
		case (ParkingSpaceStatusType.Booked):
			return '#E74A4F';
		case (ParkingSpaceStatusType.OfflineClosed):
			return '#E74A4F';
		case (ParkingSpaceStatusType.OfflineOpen):
			return '#E74A4F';
		default:
			return '#E74A4F';
		}
	};

	React.useEffect(() => {
		if (!zamokNumberString) {
			setCheckEmptySearch(false);
			setShowEmptySearch(false);
		}
	}, [zamokNumberString]);

	const showModalPaid = () => {
		setIsModalPaidOpen(true);
	};

	const handleOkPaid = () => {
		dispatch(actions.app.setShowBooking(true));
		// navigate('/map/history');
		setIsModalPaidOpen(false);
	};

	const handleCancelPaid = () => {
		setIsModalPaidOpen(false);
	};

	const activatedParking = (item:ParkingSpace) => {
		if (item.status.status === ParkingSpaceStatusType.Free) {
			if (activeOrder.hasActiveOrder) {
				showModalPaid();
			} else {
				setActiveParking(item);
			}
		} else {
			setActiveParking(new ParkingSpace());
		}
	};

	const showModal = () => {
		const callback = (items:SystemStatus) => {
			if (items.orderDisabled) {
				dispatch(actions.app.setShowWarningPopup(true));
			} else {
				setIsModalOpen(true);
			}
		};

		dispatch(actions.data.saga.asyncGetCurrentSystemStatus({ callback }));
	};

	const setBookingParking = () => {
		dispatch(actions.data.setCurrentParkingSpace(activeParking));
		const callback = (item:Order) => {
			console.log(item);
			// item.id && navigate('/map/search');
			setActiveParking(new ParkingSpace());
			dispatch(actions.app.setShowBooking(true));
		};
		dispatch(actions.parkingManipulation.saga.asyncActiveAndBookingParkingSpace({ id: activeParking.id, callback }));
	};

	const handleOk = () => {
		setBookingParking();
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const clickBack = () => {
		navigate('/map/search');
	};

	const changeSearch = (e:any) => {
		const re = /^[0-9\b]+$/;
		if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length <= 3) {
			setZamokNumberString(e.target.value);
		}
		if (e.target.value !== '') {
			(document.getElementById('inputZamokNumberBox') as HTMLInputElement).classList.add('not-empty');
		} else {
			(document.getElementById('inputZamokNumberBox') as HTMLInputElement).classList.remove('not-empty');
		}
	};

	return (
		<MainSection>
			<LoadingComponent loadingElement="activateAndBookingParking" />
			<LoadingComponent loadingElement="GetParkingSpacesResponse" />
			{(showBooking && activeOrder.hasActiveOrder) && <BookingParkingElement />}
			<HeaderBar />
			<Content>
				<HeaderSearch>
					<BackArrow onClick={() => clickBack()}>
						<BackArrowIcon />
						<span>Назад</span>

					</BackArrow>
					<TitleInput>
						<span>Поиск по номеру замка</span>
					</TitleInput>
					<InputElement>

						<InputBox
							id="inputZamokNumberBox"
							type="number"
							placeholder="Введите номер замка"
							onChange={(e:any) => changeSearch(e)}
							value={zamokNumberString}
						/>

					</InputElement>
					<div className="button-controll">
						<Button style={{ flex: 1 }} onClick={() => search()}>Найти</Button>
					</div>

				</HeaderSearch>
				<BodyContent className={activeParking?.id ? 'showButton' : 'outButton'}>
					{ parkingSpaces.items?.map((item, index) => (

						<ParkingPlace
							key={item.id}
							borderTop={index === 0 ? '2px dashed #333C56' : 'none'}
							colorBackground={getColor(item.status)}
							onClick={() => activatedParking(item)}
							style={{ color: activeParking?.id === item.id ? '#fff' : 'black' }}
						>
							<span>{item.number}</span>
							<span>{item.status.status === ParkingSpaceStatusType.Free ? 'Свободно' : ' Парковка занята'}</span>
						</ParkingPlace>
					))}

					{(showEmptySearch)
					&& <EmptySearch><div>К сожалению ничего не найдено, проверьте номер парковки и повторите поиск</div></EmptySearch>}
				</BodyContent>
				<FooterContent className={activeParking?.id ? 'showButton' : 'outButton'}>

					<Button style={{ width: '100%' }} onClick={() => showModal()}>Открыть замок</Button>

				</FooterContent>
			</Content>

			<FooterCabinetMobile />

			<Modal
				title="Внимание"
				open={isModalPaidOpen}
				onOk={handleOkPaid}
				onCancel={handleCancelPaid}
				centered
				footer={[
					<FooterButton>
						<ButtonAntd key="submit" type="primary" onClick={handleOkPaid}>
							Показать неоплаченный заказ
						</ButtonAntd>
						<ButtonAntd key="back" onClick={handleCancelPaid}>
							Закрыть
						</ButtonAntd>
					</FooterButton>,

				]}
			>
				<p>Оплатите активную парковку, прежде чем начинать новую.</p>
			</Modal>

			<Modal
				okText="Подтвердить"
				title="Внимание"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				centered
			>
				<p>После нажатия на кнопку &quot;Подтвердить&quot; замок сразу откроется и начнется оплата.</p>

			</Modal>

		</MainSection>
	);
};

export default SearchNewPage;
