import styled from 'styled-components';
import HeaderSVG from 'assets/icons/HomePage/ParkomatBox.svg';
import { device } from 'common/devices';

export const ParkomatIcon = styled(HeaderSVG)`
  right: 0;
  top: 0;
  position: absolute;
  width: 100%;
  @media ${device.tablet600} {
    display: none;
  }
`;

export const TitleSpan = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 110%;
  letter-spacing: 0.1px;
  color: #ffffff;
  @media ${device.tablet600} {
    font-size: 32px;
  }
`;
export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.tablet600} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    /* padding: 0px var(--padding); */
    box-sizing: border-box;
  }
`;
// First section
export const SectionTitle = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  
  @media ${device.tablet600} {
    box-sizing: border-box;
    height: auto;
    min-height: auto;
    margin-top: 100px;
    margin-bottom: 69px;
    padding: 0px var(--padding);
  }
`;
export const TitleText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 10%;
  top: 30%;
  z-index: 1;
  gap: 32px;
  width: 20%;

  .footer-from {
    width: 100%;
    /* margin-top: 24px; */
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media ${device.tablet600} {
    gap: 0px;
    position: relative;
    width: 100%;
    left: auto;
    top: auto;
  }
`;
export const TitleMainSpan = styled(TitleSpan)`
  @media ${device.tablet600} {
    margin-bottom: 32px;
  }
`;
export const TitleSubSpan = styled.span`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #0679ff;
  @media ${device.tablet600} {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 24px;
  }
`;

export const FormLogin = styled.div`
  .phone-login {
    margin-bottom: 32px;
  }
  .password-login {
    margin-bottom: 24px;
  }
  .button-login {
    font-size: 16px;
  }

  &.loading{
    .phone-login{
      
    }
  }
`;
export const FooterLogin = styled.div`
  z-index: 10;
  position: relative;
  box-sizing: border-box;
  @media ${device.tablet600} {
    padding: 0px var(--padding);
  }
`;
