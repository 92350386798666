/* eslint-disable import/named */
import { call, put } from 'redux-saga/effects';
import {
	RegisterAccountRequest,
	ResetAccountPasswordRequest,
	VerifyPhoneRequest, VerifyPhoneResponse,
} from 'store/dto/dtos';
// import baseApiService from 'service/base-api.service';
import baseApiService from 'service/base-api.service';
import { IAction } from 'store/utils';
import actions from 'store/actions';
import { IResetPassword } from 'store/interfaces/Authorization.Interface';
import { history } from 'common/history';
import { logIn } from './tokenWorkers';

export interface IRegistrationCall {
phone:string;
password:string;
verifyCode:number;
displayName:string;
}

const verificationCall = (opts: string): Promise<VerifyPhoneResponse> => {
	const request = new VerifyPhoneRequest();
	request.phone = opts;
	return baseApiService.post(request);
};
const registrationCall = (opts: IRegistrationCall): Promise<boolean> => {
	const request = new RegisterAccountRequest();
	request.phone = opts.phone;
	request.displayName = opts.displayName;
	request.verifyCode = opts.verifyCode;
	request.password = opts.password;
	return baseApiService.post(request);
};

const resetPasswordCall = (opts: IResetPassword): Promise<boolean> => {
	const request = new ResetAccountPasswordRequest();
	request.verifyCode = opts.verifyCode;
	request.phone = opts.phone;
	request.newPassword = opts.newPassword;
	return baseApiService.post(request);
};

export function* resetAccountPasswordRequest(action:IAction<IResetPassword>) {
	try {
		yield put(actions.app.saga.asyncAddLoading('resetPasswordLoading'));
		const { payload } = action;
		const result: boolean = yield call(resetPasswordCall, payload);
		console.log(result);
		yield put(actions.app.saga.asyncRemoveLoading('resetPasswordLoading'));
		yield call(history.push, '/reset-password/success');
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('resetPasswordLoading'));
		yield call(history.push, '/reset-password/error');
		yield put(actions.app.saga.asyncInitNotification({
			messages:
			`Ошибка! ${error.responseStatus.message}`,
			type: 'warm',
		}));
	}
}

export function* verificationPhoneRequest(action: IAction<{number:string, reset:boolean}>) {
	try {
		const {
			payload: {
				number, reset,
			},
		} = action;
		const result: VerifyPhoneResponse = yield call(verificationCall, number);
		console.log('verificationPhoneRequest', result);
		if (!reset) {
			yield call(history.push, '/verification');
		}
	} catch (error:any) {
		// yield call(history.push, '/registration');
		yield put(actions.app.saga.asyncInitNotification({
			messages:
			`${error.responseStatus.message}`,
			type: 'warm',
		}));
	}
}

export function* registrationRequest(action: IAction<IRegistrationCall>) {
	try {
		const {
			payload: {
				phone, password, verifyCode, displayName,
			},
		} = action;
		yield put(actions.app.saga.asyncAddLoading('registrationProcess'));
		const result: boolean = yield call(registrationCall, {
			phone, password, verifyCode, displayName,
		});
		console.log(`registrationRequest${result}`);
		yield put(actions.app.saga.asyncInitNotification({
			messages:
			'Регистрация завершена!',
			type: 'warm',
		}));
		yield call(
			logIn,
			actions.authorization.saga.asyncLogIn({ login: phone.slice(1), password }),
		);
		yield put(actions.app.saga.asyncRemoveLoading('registrationProcess'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('registrationProcess'));
		yield put(actions.app.saga.asyncInitNotification({
			messages:
			`Ошибка! ${error.responseStatus.message}`,
			type: 'warm',
		}));
	}
}
