/* eslint-disable no-unused-expressions */
import { Button } from 'components/forms/Button';
// import PasswordInput from 'components/forms/PasswordInput';
// import CheckboxInput from 'components/forms/CheckboxInput';
// import PhoneInput from 'components/forms/PhoneInput';
// import TextInput from 'components/forms/TextInput';
import {
	AShowPassword, Form as FormPassword, Input as InputPassword, Label as LabelPassword, ShowPasswordIcon,
} from 'components/forms/PasswordInput/styledComponent';
import {
	Form as FormPhone, Input as InputPhone, Label as LabelPhone, Prefix as PrefixPhone,
} from 'components/forms/PhoneInput/styledComponent';
import FooterBar from 'components/layout/FooterBar';
import LoadingComponent from 'components/layout/Loading';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import actions from 'store/actions';
import { TitleLogin } from '../Registration/styledComponent';
// import selectors from 'store/selectors';
import {
	ParkomatIcon,
	SectionTitle,
	TitleMainSpan,
	TitleSubSpan,
	TitleText,
	MainSection,
	FormLogin,
	FooterLogin,
} from './styledComponent';

type FormData = {
	phone: string;
	password:string;
  };

const LoginPage: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const [nameInput, setNameInput] = React.useState('');
	// const [phoneInput, setPhoneInput] = React.useState('');
	const [passwordShown, setPasswordShown] = React.useState(false);
	const {
		register, handleSubmit, formState: { errors },
	} = useForm<FormData>();

	const onSubmit = handleSubmit((data) => {
		dispatch(actions.authorization.saga.asyncLogIn({ login: data.phone, password: data.password }));
		// navigate('/map/search');
	});

	const togglePassword = () => {
		// When the handler is invoked
		// inverse the boolean state of passwordShown
		setPasswordShown(!passwordShown);
	};

	const setNavigate = (url = '', hash = '') => {
		navigate(`/${url}/${hash}`);
	};

	return (
		<MainSection>
			<LoadingComponent loadingElement="buttonLogIn" />
			<SectionTitle>
				<TitleText>
					<TitleMainSpan>
						Приветствуем
					</TitleMainSpan>
					<TitleSubSpan onClick={() => navigate('/registration')}>
						Зарегистрироваться
					</TitleSubSpan>
					<FormLogin style={{ width: '100%' }} onSubmit={onSubmit}>
						<FormPhone className="phone-login">
							<LabelPhone htmlFor="input-field">Номер телефона</LabelPhone>
							<PrefixPhone>
								<InputPhone
									type="tel"
									placeholder="Ваш номер телефона"
									autoComplete="off"
									maxLength={10}
									{...register('phone', {
										required: true,
										maxLength: 10,
										pattern: /\(?([0-9]{3})\)?([-]?)([0-9]{3})\2([0-9]{4})/,
									})}
								/>

							</PrefixPhone>
							{errors.phone && <p>Некорректный номер телефона</p>}

						</FormPhone>

						<FormPassword className="password-login">
							<LabelPassword htmlFor="input-field">Укажите пароль</LabelPassword>
							<div style={{ position: 'relative', display: 'flex' }}>
								<InputPassword
									// className={invalid ? '.text-field__input_invalid' : ' '}
									type={passwordShown ? 'text' : 'password'}
									autoComplete="off"
									placeholder="Укажите пароль"
									{...register('password', {
										required: true,
										maxLength: 50,
										minLength: 3,
									})}
								/>
								<AShowPassword>
									<ShowPasswordIcon onClick={togglePassword} />
								</AShowPassword>
							</div>
							{errors.password && <p>Некорректный пароль</p>}
						</FormPassword>

						{/* <PhoneInput
							className="phone-login"
							onChange={onChangePhone}
							label="Номер телефона"
							name="phone"
							placeholder="Ваш номер телефона"
							value={phoneInput}
						/> */}
						{/* <PasswordInput
							className="password-login"
							onChange={onChangeName}
							label="Пароль"
							name="name"
							placeholder="Ваш пароль"
							invalid={invalidCheck}
							value={nameInput}
						/> */}
					</FormLogin>
					{/* <Button className="button-login" type="submit">
						Войти
					</Button> */}

					<div className="footer-from">
						<TitleLogin onClick={() => setNavigate('reset-password', 'first-step')}>
							Забыли пароль?
						</TitleLogin>

						<Button onClick={() => onSubmit()} className="button-login" type="submit">
							Войти
						</Button>
					</div>
				</TitleText>
				<ParkomatIcon />
			</SectionTitle>
			<FooterLogin>
				<FooterBar />
			</FooterLogin>
		</MainSection>
	);
};

export default LoginPage;
