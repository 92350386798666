import { device } from 'common/devices';
import styled from 'styled-components';

const ButtonTemplate = styled.button`
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 8px;
  /* min-width: 127px; */
  height: 46px;
  
  border: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 0px 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #000000;

  @media ${device.tablet600} {
    width: 100%;
    font-size: 20px;
  }

  &:hover {
    background: #FF8A0098;
  }

  &:disabled{
  background-color: #cccccc;
  color: #666666;
}
`;

export const Button = styled(ButtonTemplate)`
  background: #FF8A00;
`;
