/* eslint-disable no-tabs */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

export default function useCheckPermission(perm:string) {
	const [isPermission, setIsPermission] = React.useState<boolean>(false);
	const permissionData: string[] = useSelector(
		selectors.authorization.getPermissions,
	);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (permissionData.length === 0) {
			const callbackPermissions = (value:string[]) => {
				value.find((item) => item === 'system.manage');
				setIsPermission(value.find((item) => item === 'system.manage') ? true : !!value.find((m) => m === perm));
			};
			dispatch(actions.authorization.saga.asyncGetPermissionsCallBack({ callbackPermissions }));
		} else {
			setIsPermission(permissionData.find((item) => item === 'system.manage') ? true : !!permissionData.find((m) => m === perm));
		}
	}, []);
	return isPermission;
}

// export default function useCheckPermission(perm:string) {
// 	// const [isPermission, setIsPermission] = React.useState<boolean>(false);
// 	const permissionData: string[] = useSelector(
// 		selectors.authorization.getPermissions,
// 	);
// 	const dispatch = useDispatch();

// 	if (permissionData.length === 0) {
// 		const callbackPermissions = (value:string[]) => {
// 			value.find((item) => item === 'system.manage');
// 			return (value.find((item) => item === 'system.manage') ? true : !!value.find((m) => m === perm));
// 		};
// 		dispatch(actions.authorization.saga.asyncGetPermissionsCallBack({ callbackPermissions }));
// 	} else {
// 		return (permissionData.find((item) => item === 'system.manage') ? true : !!permissionData.find((m) => m === perm));
// 	}
// }
