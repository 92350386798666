/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { put, call } from 'redux-saga/effects';
import baseApiService from 'service/base-api.service';
import errorApiService from 'service/error.service';
import actions from 'store/actions';
import {
	Statistics, GetStatisticsRequest, GetAcquiringRequest, AcquiringInfo,
} from 'store/dto/dtos';
import { IAction } from 'store/utils';

const getStatisticsCall = (opts: {from:string, to:string}): Promise<Statistics> => {
	const request = new GetStatisticsRequest();
	request.from = opts.from;
	request.to = opts.to;
	return baseApiService.get(request);
};
const getAcquiringinfo = (opts: { id?: string }): Promise<AcquiringInfo> => {
	const request = new GetAcquiringRequest();
	return baseApiService.get(request);
};

export function* getAcquiringRequest(
	action: IAction<{
		callbackAcquiring?: (item: AcquiringInfo) => void;
	}>,
) {
	try {
		yield put(actions.app.saga.asyncAddLoading('getAcquiringRequest'));
		const {
			payload: { callbackAcquiring },
		} = action;
		const result: AcquiringInfo = yield call(getAcquiringinfo, {});
		yield callbackAcquiring?.(result);
		yield put(
			actions.app.saga.asyncRemoveLoading('getAcquiringRequest'),
		);
	} catch (error) {
		yield put(actions.app.saga.asyncRemoveLoading('getAcquiringRequest'));
		yield put(
			actions.app.saga.asyncInitNotification({
				messages: errorApiService.catchRequestError(error),
				type: 'warm',
			}),
		);
	}
}

export function* getStatisticsRequest(action: IAction< {from:string, to:string}>) {
	try {
		const {
			payload: {
				from, to,
			},
		} = action;
		yield put(actions.app.saga.asyncAddLoading('loadingStatistics'));
		const result: Statistics = yield call(getStatisticsCall, {
			from, to,
		});
		yield put(actions.data.setStatistics(result));
		yield put(actions.app.saga.asyncRemoveLoading('loadingStatistics'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingStatistics'));
		yield put(actions.data.setStatistics({} as Statistics));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
