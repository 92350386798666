import { call, put } from 'redux-saga/effects';
import {
	DeleteBankCardRequest,
	GetBankCardsRequest,
	GetBankCardsResponse,
	GetMyAccountInfoRequest,
	MyAccountInfo,
	UpdateMyAccountInfoRequest,

} from 'store/dto/dtos';
import baseApiService from 'service/base-api.service';
import errorApiService from 'service/error.service';
import { IAction } from 'store/utils';
import actions from 'store/actions';

const getBankCardsCall = (opts: string): Promise<GetBankCardsResponse> => {
	const request = new GetBankCardsRequest();
	console.log(opts);
	return baseApiService.get(request);
};
const deleteBankCardCall = (opts: {id:string}): Promise<number> => {
	const request = new DeleteBankCardRequest();
	request.ids = [opts.id];
	return baseApiService.delete(request);
};
const myAccountInfoCall = (opts: string): Promise<MyAccountInfo> => {
	const request = new GetMyAccountInfoRequest();
	console.log(opts);
	return baseApiService.get(request);
};
const updateMyAccountInfo = (opts: {email:string, displayName:string, licenseNumber:string}): Promise<MyAccountInfo> => {
	const request = new UpdateMyAccountInfoRequest();
	request.displayName = opts.displayName;
	request.email = opts.email;
	request.licenseNumber = opts.licenseNumber;
	console.log(opts);
	return baseApiService.putCall(request);
};

export function* getBankCardsRequest(action: IAction<string>) {
	try {
		yield put(actions.app.saga.asyncAddLoading('loadingCards'));
		const { payload } = action;
		const result: GetBankCardsResponse = yield call(getBankCardsCall, payload);
		yield put(actions.data.setBankCards(result));
		yield put(actions.app.saga.asyncRemoveLoading('loadingCards'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingCards'));
		yield put(actions.app.saga.asyncInitNotification({
			messages:
			errorApiService.catchRequestError(error),
			type: 'warm',
		}));
	}
}

export function* deleteBankCardRequest(action: IAction<{id:string}>) {
	try {
		const {
			payload: { id },
		} = action;
		yield put(actions.app.saga.asyncAddLoading('deleteBankCard'));
		const result: string = yield call(deleteBankCardCall, { id });
		console.log(result);
		yield put(actions.app.saga.asyncRemoveLoading('deleteBankCard'));
		yield put(actions.data.saga.asyncGetBankCards(''));
		yield put(actions.app.saga.asyncInitNotification({
			messages: 'Карта удалена!',
			type: 'warm',
		}));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('deleteBankCard'));
		// yield put(actions.data.setTariff({} as Tariff));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
export function* getMyAccountInfoRequest(action: IAction<string>) {
	try {
		yield put(actions.app.saga.asyncAddLoading('loadingProfile'));
		const { payload } = action;
		const result: MyAccountInfo = yield call(myAccountInfoCall, payload);
		yield put(actions.data.setAccountInfo(result));
		yield put(actions.app.saga.asyncRemoveLoading('loadingProfile'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingProfile'));
		yield put(actions.app.saga.asyncInitNotification({
			messages:
			errorApiService.catchRequestError(error),
			type: 'warm',
		}));
	}
}
export function* updateMyAccountInfoRequest(action: IAction<{displayName:string, email:string, licenseNumber:string}>) {
	try {
		yield put(actions.app.saga.asyncAddLoading('loadingProfile'));
		const { payload } = action;
		const result: MyAccountInfo = yield call(updateMyAccountInfo, payload);
		yield put(actions.data.setAccountInfo(result));
		yield put(actions.app.saga.asyncRemoveLoading('loadingProfile'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingProfile'));
		yield put(actions.app.saga.asyncInitNotification({
			messages:
			errorApiService.catchRequestError(error),
			type: 'warm',
		}));
	}
}
