/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { dataFaq } from 'common/data/dataFAQ';
import { TDevice } from 'common/devices';
import { IDataFAQ } from 'common/Interface';
import useWindowDimensions from 'common/useWindowDimensions';
import { Button } from 'components/forms/Button';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FooterBar from 'components/layout/FooterBar';
import React from 'react';
import L, { Icon } from 'leaflet';
import { alpha, styled } from '@mui/material/styles';
import {
	MapContainer, Marker, TileLayer, Popup, ZoomControl,
} from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import ElementFAQMain from 'components/layout/FAQListMain';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';
import { SystemStatus } from 'store/dto/dtos';
import WarningPopupHome from 'components/elements/PopupWarningHomeElement';
import {
	IconPrinciple,
	MapMainSpan,
	ParkomatIcon,
	PrincipleMainSpan,

	SectionMap,
	SectionPrinciple,
	SectionTitle,

	TitleMainSpan,
	TitlePrinciple,
	TitleSubSpan,
	TitleText,
	MapElement,
	BlurElement,
	MainSection,
	PriceTitle,
	SectionBottom,
	SectionFAQ,
	SectionPrice,
	Tariff,
	TariffBlock,
	TitleFAQ,
	FAQLists,
	LeftBottomIcon,
	// BottomPastomatIcon,
	RightBottomIcon,
	PrincipleSubElement,
	PrincipleSubSpanPoint,
	PrincipleSubSpanPointName,
	PrincipleSubSpanPointStage,
	PrincipleSubSpanPointStageName,
	TopIconMobile,
	CarZamokIcon,
	NewZamokIcon,
	BlurEffect,
} from './styledComponent';

interface IParkingsMarkerData {
	lat:number;
	lon:number;
	name:string;
	countParkings:number;
	id:string;
}

const dataMarker : IParkingsMarkerData[] = [
	{
		lat: 56.013209,
		lon: 92.847199,
		name: 'Парковка 1',
		countParkings: 5,
		id: '687664c5-9eb9-4969-bd64-2ac1f1b67b38',
	},
	{
		lat: 56.013308,
		lon: 92.848482,
		name: 'Парковка 2',
		countParkings: 7,
		id: '02166923-2082-45fd-b4a2-08ebf4cd9938',
	},
	{
		lat: 56.013364,
		lon: 92.849552,
		name: 'Парковка 3',
		countParkings: 11,
		id: 'c5fc8892-0f94-4750-8014-8185b02da585',
	},
	{
		lat: 56.013397,
		lon: 92.850115,
		name: 'Парковка 4',
		countParkings: 12,
		id: '9bf0be17-b6fe-4530-b486-4b0cdf406b2c',
	},
	{
		lat: 56.012958,
		lon: 92.850893,
		name: 'Парковка 5',
		countParkings: 19,
		id: 'bafaab06-e8aa-4c04-a402-4ca2cb9315a4',

	},
	{
		lat: 56.01347,
		lon: 92.851402,
		name: 'Парковка 6',
		countParkings: 20,
		id: '3ac13c76-92c1-404e-bb12-699e2849c000',
	},
	{
		lat: 56.013528,
		lon: 92.852471,
		name: 'Парковка 7',
		countParkings: 15,
		id: '3e20a71a-28cc-4be1-9637-df0430c98aeb',
	},
	{
		lat: 56.013009,
		lon: 92.853329,
		name: 'Парковка 8',
		countParkings: 9,
		id: 'b48a8fb6-3c1c-4ed9-84b4-87785f893c7d',
	},
	{
		lat: 56.013295,
		lon: 92.853368,
		name: 'Парковка 9',
		countParkings: 8,
		id: '874e029b-d777-4931-981c-5d7a0c30c1c3',
	},
	{
		lat: 56.01359,
		lon: 92.854112,
		name: 'Парковка 10',
		countParkings: 20,
		id: 'a4685946-226f-4c1b-84bd-a929815b03aa',
	},
	{
		lat: 56.013652,
		lon: 92.855638,
		name: 'Парковка 11',
		countParkings: 10,
		id: '30699491-8e14-4ae0-9156-e3e388e5698b',
	},
	{
		lat: 56.013748,
		lon: 92.856682,
		name: 'Парковка 12',
		countParkings: 12,
		id: 'fa657cd0-7cf2-40ec-ae22-257d564aebcc',
	},
	{
		lat: 56.004708,
		lon: 92.849535,
		name: 'Парковка 13',
		countParkings: 21,
		id: '1374cfd4-1ef7-4bc9-8bde-c564c2d7d03a',
	},
	{
		lat: 56.008348,
		lon: 92.856871,
		name: 'Парковка 14',
		countParkings: 16,
		id: '489787c1-c583-4c0c-87e1-a833189deb19',
	},
	{
		lat: 56.007639,
		lon: 92.856944,
		name: 'Парковка 15',
		countParkings: 22,
		id: '489787c1-c583-4c0c-87e1-a833189deb19',
	},
];

const parkingSvg = `
<svg viewBox="0 0 80 80" width="24" height="24" version="1.1" xmlns="http://www.w3.org/2000/svg" >
<path d="M0,25.6c0-9,0-13.4,1.7-16.9c1.5-3,4-5.5,7-7C12.2,0,16.6,0,25.6,0h28.8c9,0,13.4,0,16.9,1.7c3,1.5,5.5,4,7,7C80,12.2,80,16.6,80,25.6v28.8c0,9,0,13.4-1.7,16.9c-1.5,3-4,5.5-7,7C67.8,80,63.4,80,54.4,80H25.6c-9,0-13.4,0-16.9-1.7c-3-1.5-5.5-4-7-7C0,67.8,0,63.4,0,54.4V25.6z" fill="#095BA7"/>
<path class="st1" d="M27.9,15.3v44.3c0,2.8-2.4,5-5.2,5s-5.2-2.3-5.2-5V21.3c0-3.3,2.7-6,6-6C23.5,15.3,27.9,15.3,27.9,15.3z" fill="#FFFFFF"/>
<path class="st1" d="M37.8,25.3H26.2v-10h11.6c9.8,0,17.8,8,17.8,17.8s-8,17.8-17.8,17.8H26.2v-10h11.6c4.3,0,7.8-3.5,7.8-7.8C45.6,28.8,42.1,25.3,37.8,25.3z" fill="#FFFFFF" />
<circle class="st2" cx="68.7" cy="61.1" r="7.5" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.5"/>
<text transform="matrix(1 0 0 1 63.5104 64.0604)" font-size="10px">20</text>
<circle class="st2" cx="57.4" cy="61.1" r="5.9" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.5"/>
<text transform="matrix(1 0 0 1 53.5768 63.3631)" font-size="7px">15</text>
<circle class="st2" cx="48.1" cy="61.1" r="5.1" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.5"/>
<text transform="matrix(1 0 0 1 44.5033 63.3633)" font-size="7px">10</text>
</svg>
`;
const parkingSvgActive = `
<svg viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg">
<path  d="M3.2,26.8c0-8.3,0-12.3,1.6-15.6c1.4-2.8,3.7-5.1,6.4-6.4c3.2-1.6,7.3-1.6,15.6-1.6h26.5c8.3,0,12.3,0,15.6,1.6c2.8,1.4,5.1,3.7,6.4,6.4c1.6,3.2,1.6,7.3,1.6,15.6v26.5c0,8.3,0,12.3-1.6,15.6c-1.4,2.8-3.7,5.1-6.4,6.4c-3.2,1.6-7.3,1.6-15.6,1.6H26.8c-8.3,0-12.3,0-15.6-1.6c-2.8-1.4-5.1-3.7-6.4-6.4c-1.6-3.2-1.6-7.3-1.6-15.6V26.8z" stroke="white" stroke-width="6" fill="#095BA7" box-sizing="border-box" />
<path  d="M28.9,17.3v40.8c0,2.6-2.2,4.6-4.8,4.6s-4.8-2.1-4.8-4.6V22.8c0-3,2.5-5.5,5.5-5.5C24.8,17.3,28.9,17.3,28.9,17.3z" fill="#FFFFFF"/>
<path d="M38,26.5H27.3v-9.2H38c9,0,16.4,7.4,16.4,16.4S47,50.1,38,50.1H27.3v-9.2H38c4,0,7.2-3.2,7.2-7.2S42,26.5,38,26.5z" fill="#FFFFFF" />
<circle  cx="66.5" cy="59.5" r="6.9" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.5"/>
<text transform="matrix(1 0 0 1 61.6572 62.1621)"  font-size="10px">20</text>
<circle cx="56" cy="59.5" r="5.4" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.5"/>
<text transform="matrix(1 0 0 1 52.5156 61.5215)"  font-size="7px">15</text>
<circle  cx="47.4" cy="59.5" r="4.7" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.5"/>
<text transform="matrix(1 0 0 1 44.166 61.5215)"  font-size="7px">10</text>
</svg>
`;

const getImgSrc = (src: string, color?: string, borderColor?: string) => {
	const result = src;
	// if (!src.startsWith('<')) return result;
	// if (color) result = result.replace(/\{color\}/g, color);
	// if (borderColor) result = result.replace(/\{borderColor\}/g, borderColor);
	return `data:image/svg+xml;base64,${btoa(result)}`;
};

const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		border: 'none',
	},
	'& .MuiInput-underline:after': {
		border: 'none',
	},
	'& .MuiOutlinedInput-root': {
		backgroundColor: 'rgb(23, 23, 37)',
		borderRadius: '0.5rem',
		height: '64px',
		'& fieldset': {
			border: 'none',
		},
		'&:hover fieldset': {
			border: 'none',
		},
		'&.Mui-focused fieldset': {
			border: 'none',
		},
	},
});

const HomePage: React.FC = () => {
	const mapRef = React.useRef<L.Map>(null);
	const [value, setValue] = React.useState<IParkingsMarkerData | null>(null);
	const [countParkings, setCountParkings] = React.useState<number>(0);
	const dispatch = useDispatch();
	const showWarning: boolean = useSelector(selectors.app.getShowWarning);

	const { width } = useWindowDimensions();
	const getDeviceType = (): TDevice => {
		if (width <= 768) return 'mobile';
		return 'laptop';
	};

	const duckIcon = new Icon({
		iconUrl: getImgSrc(parkingSvg, 'yellow', 'black'),
		iconAnchor: new L.Point(8, 0),
		popupAnchor: new L.Point(0, 0),
		iconSize: new L.Point(16, 16),
	});
	const duckIconActive = new Icon({
		iconUrl: getImgSrc(parkingSvgActive, 'yellow', 'black'),
		iconAnchor: new L.Point(13, 0),
		popupAnchor: new L.Point(0, 0),
		iconSize: new L.Point(26, 26),
	});

	const navigate = useNavigate();

	React.useEffect(() => {
		// sessionStorage.getItem('showWarningPopupMain') !== '1'
		// && dispatch(actions.app.setShowWarningPopup(true));

		// const callback = (item:SystemStatus) => console.log('MapPage', item);
		// dispatch(actions.data.saga.asyncGetCurrentSystemStatus({ callback }));
	}, []);
	React.useEffect(() => {
		let temp = 0;
		// eslint-disable-next-line no-return-assign
		dataMarker.forEach((item) => temp += item.countParkings);
		setCountParkings(temp);
		if (value?.id) {
			// mapRef.current?.closePopup();
			mapRef.current?.setView({ lat: value?.lat, lng: value?.lon }, 16);
		}
	}, [value]);

	return (
		<MainSection style={{ overflow: showWarning ? 'hidden' : 'auto' }}>
			{/* <BlurEffect className={showWarning ? 'show' : 'out'} /> */}
			{/* <WarningPopupHome /> */}
			<SectionTitle>
				<TitleText>
					<TitleMainSpan>
						Удобный паркинг
						<br />
						в Красноярске
					</TitleMainSpan>
					<TitleSubSpan>
						Простой и понятный паркинг нового поколения в вашем городе
					</TitleSubSpan>
					<Button onClick={() => navigate('/registration')}>Зарегистрироваться</Button>
				</TitleText>
				{getDeviceType() === 'laptop' ? <ParkomatIcon /> : <div><TopIconMobile /></div>}
			</SectionTitle>
			<SectionPrinciple id="principle">
				<IconPrinciple>
					<CarZamokIcon />

				</IconPrinciple>

				<TitlePrinciple>
					<PrincipleMainSpan>Простой понятный механизм</PrincipleMainSpan>
					<PrincipleSubElement>
						<PrincipleSubSpanPoint>
							<PrincipleSubSpanPointStage>
								<PrincipleSubSpanPointStageName><div>1</div></PrincipleSubSpanPointStageName>
							</PrincipleSubSpanPointStage>
							<PrincipleSubSpanPointName>
								Скачайте приложение «КрасПаркинг»
								в «AppStore» или «GooglePlay» и зарегистрируйтесь.
							</PrincipleSubSpanPointName>
						</PrincipleSubSpanPoint>
						<PrincipleSubSpanPoint>
							<PrincipleSubSpanPointStage>
								<PrincipleSubSpanPointStageName><div>2</div></PrincipleSubSpanPointStageName>
							</PrincipleSubSpanPointStage>
							<PrincipleSubSpanPointName>
								Выберите парковку и номер парковочного места, подъедьте к замку с выбранным номером и
								нажмите в приложении «Открыть замок».
							</PrincipleSubSpanPointName>
						</PrincipleSubSpanPoint>
						<PrincipleSubSpanPoint>
							<PrincipleSubSpanPointStage>
								<PrincipleSubSpanPointStageName><div>3</div></PrincipleSubSpanPointStageName>
							</PrincipleSubSpanPointStage>
							<PrincipleSubSpanPointName>
								Пользуйтесь услугой парковки.
							</PrincipleSubSpanPointName>
						</PrincipleSubSpanPoint>
					</PrincipleSubElement>
				</TitlePrinciple>
				<NewZamokIcon />
			</SectionPrinciple>

			<SectionMap id="map">
				<MapMainSpan>Весь исторический центр Красноярска</MapMainSpan>

				<MapElement>
					<BlurElement>
						<div style={{ width: getDeviceType() === 'laptop' ? '35%' : '95%' }}>
							{/* <SearchInput
								onChange={onChangeSearch}
								name="field"
								placeholder="Поиск парковок"
								value={searchInput}
								size="big"
							/> */}
							<div style={{ width: '100%', zIndex: '2000', pointerEvents: 'all' }}>
								<Autocomplete
									value={value}
									onChange={(event: any, values) => {
										setValue(values);
										console.log('onChange', values);
									}}
									id="controllable-states-demo"
									getOptionLabel={(option) => option.name}
									options={dataMarker}
									renderInput={(params) => (

										<CssTextField
											{...params}
											color="warning"
											placeholder="Поиск парковок по улице"
											InputProps={{
												...params.InputProps,
												startAdornment: (
													<InputAdornment position="start">
														<SearchIcon sx={{ fontSize: 30, color: 'rgb(255, 138, 0)' }} />
													</InputAdornment>
												),
											}}
										/>
									)}
								/>
							</div>
							<div className="sub-title-search">
								<div>
									<span>15</span>
									<span>парковок</span>
								</div>
								<div>
									<span>{countParkings}</span>
									<span>мест</span>
								</div>
							</div>
						</div>
					</BlurElement>
					<MapContainer
						ref={mapRef}
						center={[56.010351, 92.852601]}
						zoom={15}
						scrollWheelZoom={false}
						zoomControl={false}
					>
						<ZoomControl position="bottomright" />
						<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright ">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
						{dataMarker.map((item) => (
							<Marker
								icon={item.id === value?.id ? duckIconActive : duckIcon}
								position={[item.lat, item.lon]}
								eventHandlers={{
									click: () => {
										console.log('marketClick', item);
										setValue(item);
									},
								}}
							>
								<Popup className="marker-popup">
									<span>{item.name}</span>
									<span>
										Количество мест:
										{' '}
										{item.countParkings}
										{' '}
									</span>
								</Popup>
							</Marker>
						))}

					</MapContainer>

				</MapElement>
			</SectionMap>
			<SectionBottom>
				<SectionPrice id="price">
					<PriceTitle>Стоимость парковки</PriceTitle>
					<TariffBlock>
						<Tariff>
							<span>Тариф 1 час</span>
							<span>
								Тарификация производится с момента начала пользования услугой и округляется до 1 часа в большую стороную.
							</span>
							<div>
								<span>Стоимость</span>
								<span>25 ₽</span>
							</div>
						</Tariff>
						{/* <Tariff>
							<span>Тариф сутки</span>
							<span>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua.
							</span>
							<span>Стоимость</span>
							<span>150 ₽</span>
						</Tariff> */}
					</TariffBlock>
				</SectionPrice>
				<SectionFAQ id="FAQ">
					<TitleFAQ>Вопросы</TitleFAQ>
					<FAQLists>
						{ dataFaq.map((obj:IDataFAQ) => (
							<ElementFAQMain key={obj.id} inner title={obj.title} description={obj.description} />
						))}
					</FAQLists>
				</SectionFAQ>
				<FooterBar />
				{/* <BottomPastomatIcon /> */}

				<RightBottomIcon />
				<LeftBottomIcon />
			</SectionBottom>

		</MainSection>
	);
};

export default HomePage;
