import * as React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { v4 as uuidv4 } from 'uuid';
import useCheckPermission from 'common/usePermission';
import { ITypeMenuAction } from '../interface';

interface IIsolatedMenu {
	item:any;
	rowActionArray: ITypeMenuAction[];
	onActionMenu:(itemAction:ITypeMenuAction, id:string) => void;
}

const IsolatedMenu:React.FC<IIsolatedMenu> = (props) => {
	const {
		item, rowActionArray, onActionMenu,
	} = props;
	const [anchorMenuEl, setAnchorMenuEl] = React.useState<null | HTMLElement>(null);

	const openMenu = Boolean(anchorMenuEl);

	const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorMenuEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorMenuEl(null);
	};

	const eventMenuAction = (itemAction:ITypeMenuAction, id:string) => {
		setAnchorMenuEl(null);
		onActionMenu(itemAction, id);
	};

	return (
		<>
			<IconButton
				aria-label="expand row"
				size="small"
				onClick={(e:any) => handleClickMenu(e)}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="demo-positioned-menu"
				aria-labelledby="demo-positioned-button"
				anchorEl={anchorMenuEl}
				open={openMenu}
				onClose={handleCloseMenu}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				{ rowActionArray.map((m) => (
					useCheckPermission(m.permission) && (
						<MenuItem
							key={uuidv4()}
							onClick={() => { eventMenuAction(m, item['id' as keyof typeof item]); }}
						>
							{m.name}
						</MenuItem>
					)
				))}
			</Menu>

		</>
	);
};
export default IsolatedMenu;
