import IState from 'store/interfaces/Store.Interface';

const getRoot = (state: IState) => state.data;

export default {
	get: (state: IState) => getRoot(state),
	getParking: (state: IState) => getRoot(state).parking,
	getCurrentParking: (state: IState) => getRoot(state).currentParking,
	getParkingSpace: (state: IState) => getRoot(state).parkingSpace,
	getCurrentParkingSpace: (state: IState) => getRoot(state).currentParkingSpace,
	getMyAccountInfo: (state: IState) => getRoot(state).acountInfo,
	getOrders: (state:IState) => getRoot(state).orders,
	getPaindLink: (state:IState) => getRoot(state).paidLink,
	getActiveOrder: (state:IState) => getRoot(state).activeOrder,
	getParkingStats: (state:IState) => getRoot(state).parkingStats,
	getAdminOrders: (state:IState) => getRoot(state).adminOrders,
	getAdminOrder: (state:IState) => getRoot(state).adminOrder,
	getTariff: (state:IState) => getRoot(state).tariff,
	getTariffs: (state:IState) => getRoot(state).tariffs,
	getBankCards: (state:IState) => getRoot(state).bankCards,
	getStatistics: (state:IState) => getRoot(state).statistics,
	getSystemStatuses: (state:IState) => getRoot(state).systemStatuses,
	getAdminCurrentSystemStatus: (state:IState) => getRoot(state).adminCurrentSystemStatus,
	getCurrentSystemStatus: (state:IState) => getRoot(state).currentSystemStatus,

};
