import { takeEvery } from 'redux-saga/effects';
import actions from 'store/actions';
import * as loadingWorkers from './loadingWorkers';
import * as appWorkers from './appWorkers';
import * as notificationWorkers from './notificationWorkers';

export const {
	addLoadingWorker, removeLoadingWorker, sendMessages, addNotificationWorker, removeNotificationWorker, initNotificationWorker,
} = { ...loadingWorkers, ...appWorkers, ...notificationWorkers };

export default function* appWatcher() {
	const { saga } = actions.app;
	yield takeEvery(saga.asyncAddLoading.type, addLoadingWorker);
	yield takeEvery(saga.asyncRemoveLoading.type, removeLoadingWorker);
	yield takeEvery(saga.asyncSendSupport.type, sendMessages);
	yield takeEvery(saga.asyncAddNotification.type, addNotificationWorker);
	yield takeEvery(saga.asyncInitNotification.type, initNotificationWorker);
	yield takeEvery(saga.asyncRemoveNotification.type, removeNotificationWorker);
}
