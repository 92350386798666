import { takeLatest } from 'redux-saga/effects';
import actions from 'store/actions';
import * as parkingSpaceManipulationWorkers from './parkingSpaceManipulationWorkers';

export const {
	// bookingPArkingSpaceRequest, completePArkingSpaceRequest, activatePArkingSpaceRequest,
	getParkingSpacesRequest, getParkingSpaceStatusRequest, setParkingSpaceEngineerModeRequest,
	openParkingSpaceRequest, moveStepParkingSpaceRequest, getParkingsRequest,
} = {
	...parkingSpaceManipulationWorkers,
};

export default function* parkingSpaceWatcher() {
	const { saga } = actions.parkingSpaceManipulation;
	// yield takeLatest(saga.asyncBookingParkingSpace.type, bookingPArkingSpaceRequest);
	// yield takeLatest(saga.asyncActivateParkingSpace.type, activatePArkingSpaceRequest);
	// yield takeLatest(saga.asyncCompleteParkingSpace.type, completePArkingSpaceRequest);
	yield takeLatest(saga.asyncGetParkingSpaces.type, getParkingSpacesRequest);
	yield takeLatest(saga.asyncGetParkingSpaceStatus.type, getParkingSpaceStatusRequest);
	yield takeLatest(saga.asyncSetParkingSpaceEngineerMode.type, setParkingSpaceEngineerModeRequest);
	yield takeLatest(saga.asyncMoveStepParkingSpace.type, moveStepParkingSpaceRequest);
	yield takeLatest(saga.asyncOpenParkingSpace.type, openParkingSpaceRequest);
	yield takeLatest(saga.asyncGetParkingsIts.type, getParkingsRequest);
}
