import styled from 'styled-components';

export const WidgetBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  border: 2px solid #c5c5c582;
  background-color: #5d5d7012;
  border-radius: 10px;
  width: 500px;
  position: relative;
`;
export const TitleWidget = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
`;
export const ContentWidget = styled.div`
  /* color: #ff37
  display: grid;
  grid-template-columns: 200px 100px;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .div1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .div2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .div3 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .div4 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .div5 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .div6 {
    grid-area: 3 / 2 / 4 / 3;
  } */

  color: #fff;
  display: flex;
  flex-direction: column;
  & .blockAcquiring {
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
  }
  & .nameAcquiring {
    width: 60%;
  }
  & .valueAcquiring {
  }
`;
