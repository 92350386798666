/* eslint-disable max-len */
import React from 'react';
import {
	DescriptionElement, ElementList, JSBSVG, MasterCardSVG, MirSVG, PlusSVG, TitleElement, VisaSVG,
} from './styledComponent';

// interface IElementFAQ {
// title:string;
// description:string;
// inner?: boolean;
// }
// const ElementFAQ: React.FC<IElementFAQ> = (props) => {
const ElementFAQ: React.FC = () => {
	// const { title, description, inner } = props;
	const [clickEvent1, setClickEvent1] = React.useState(true);
	const [clickEvent2, setClickEvent2] = React.useState(true);

	return (
		<>
			<ElementList className={`${clickEvent1 ? '' : 'show'}`} onClick={() => setClickEvent1(!clickEvent1)}>
				<TitleElement>
					<span>Правила пользования муниципальными парковками</span>
					<PlusSVG />
				</TitleElement>
				<DescriptionElement>
					<span className="DescriptionTitle"> ПРАВИЛА  </span>
					<span className="DescriptionTitle"> ПОЛЬЗОВАНИЯ МУНИЦИПАЛЬНЫМИ ПАРКОВКАМИ (ПАРКОВОЧНЫМИ МЕСТАМИ), РАСПОЛОЖЕННЫМИ НА АВТОМОБИЛЬНЫХ ДОРОГАХ ОБЩЕГО ПОЛЬЗОВАНИЯ МЕСТНОГО ЗНАЧЕНИЯ Г. КРАСНОЯРСКА, И РАЗМЕЩЕНИЯ НА НИХ ТРАНСПОРТНЫХ СРЕДСТВ</span>
					<br />
					<span>Настоящие Правила определяют порядок пользования муниципальными парковками (парковочными местами), расположенными на автомобильных дорогах общего пользования местного значения г. Красноярска, и обязательны к выполнению всеми лицами, находящимися на территории муниципальной парковки. Несоблюдение требований настоящих Правил влечет административную ответственность в соответствии с действующим законодательством.</span>
					<br />
					<span className="DescriptionTitle"> I. ОБЩИЕ ПОЛОЖЕНИЯ  </span>
					<br />
					<span>1.1. К территории муниципальной парковки относится участок автомобильной дороги общего пользования местного значения г. Красноярска, обозначенный соответствующими дорожными знаками и разметкой, предусмотренными приложением N 1 к постановлению Совета Министров - Правительства Российской Федерации от 23 октября 1993 года N 1090 &quot;О Правилах дорожного движения&quot;.</span>
					<span>1.2. Парковка - элемент благоустройства территории (объект благоустройства), представляющий собой специально обозначенное и при необходимости обустроенное и оборудованное место, являющееся частью автомобильной дороги и (или) примыкающее к проезжей части и (или) тротуару, обочине, эстакаде или мосту либо являющееся частью подэстакадных или подмостовых пространств, площадей и иных объектов улично-дорожной сети, находящихся в собственности города Красноярска, и предназначенное для организованной стоянки транспортных средств на платной основе или без взимания платы.</span>
					<span>1.3. Парковочное место - специально обозначенное и при необходимости обустроенное и оборудованное место на муниципальной парковке, предназначенное для размещения одного транспортного средства.</span>
					<span>1.4. Парковочная сессия - период времени размещения транспортного средства на платной муниципальной парковке (пользования парковочным местом), началом которого считается момент бранирования или открытие парковочного замка на платной муниципальной парковке и окончанием которого считается момент закрытия парковочного замка платной муниципальной парковки.</span>
					<span>1.5. Все технические средства организации дорожного движения, установленные на муниципальной парковке, все стационарные и мобильные объекты, предназначенные для функционирования парковки, в том числе объекты взимания платы (паркоматы), объекты видеонаблюдения, являются частью парковки.</span>
					<span>1.6. Муниципальные парковки не предназначены для хранения транспортных средств. Риски угона и ущерба, причиненного транспортным средствам третьими лицами на муниципальной парковке, владельцы транспортных средств несут самостоятельно.</span>
					<br />
					<span className="DescriptionTitle">II. ПОРЯДОК ОПЛАТЫ   </span>
					<br />
					<span>2.1. На платной муниципальной парковке бесплатно размещаются:</span>
					<span>2.1.1. транспортные средства, управляемые инвалидами, и транспортные средства, перевозящие таких инвалидов и (или) детей-инвалидов, если на указанных транспортных средствах установлен опознавательный знак «Инвалид», – на местах для парковки автотранспортных средств инвалидов, обозначенных соответствующими дорожными знаками и разметкой»;</span>
					<span>2.2. Оплата за размещение транспортного средства на платной муниципальной парковке осуществляется с помощью банковской карты:</span>
					<span>2.2.1. оплата через паркомат муниципальных парковок с помощью банковских карт международных платежных систем.</span>
					<span>Оплата производится при наличии достаточного количества денежных средств на банковской карте.</span>
					<span>Документом, подтверждающим оплату пользования парковкой через паркомат, является чек.</span>

					<span>2.3 Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку Оплата. Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платёжных систем:</span>

					<span> - МИР</span>
					<span> - VISA International</span>
					<span> - Mastercard Worldwide</span>
					<span> - JCB.</span>
					<div className="iconGroup">

						<MirSVG />
						<VisaSVG />
						<MasterCardSVG />
						<JSBSVG />

					</div>
					<span>2.3.1 Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure, для проведения платежа также может потребоваться ввод специального пароля.</span>

					<span> 2.3.2 Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.</span>

					<br />
					<span className="DescriptionTitle">
						III. ПРАВИЛА РАЗМЕЩЕНИЯ ТРАНСПОРТНЫХ СРЕДСТВ
						НА ТЕРРИТОРИИ ПАРКОВКИ

					</span>
					<br />
					<span>3.1. на муниципальных парковках запрещается:</span>
					<span>3.1.1. загораживать парковочные места и создавать иные препятствия к пользованию парковочными местами;</span>
					<span>3.1.2. размещать на парковочном месте, предназначенном для транспортных средств определенного вида, если это предусмотрено соответствующими дорожными знаками и разметкой, транспортное средство иного вида;</span>
					<span>3.1.3. размещать транспортное средство с нарушением границ парковочных мест;</span>
					<span>3.1.4. оставлять транспортное средство с нечитаемыми, нестандартными или установленными с нарушением требований государственного стандарта государственными регистрационными знаками, без государственных регистрационных знаков, а равно без установленных на предусмотренных для этого местах транспортного средства государственных регистрационных знаков, а также с государственными регистрационными знаками, оборудованными с применением материалов, препятствующих или затрудняющих их идентификацию;</span>
					<span>3.1.5. выезжать с парковочного места, после постановки автомобиля на парковку, для изменения положения автомобиля или иных нужд более чем на 10 секунд (замок закроется автоматически) услуга парковки будет завершена;</span>
					<span>3.1.6. подъезжать транспортным средством к парковочному замку во время бронирования парковочного места, на расстояние меньше видимости «дуги» парковочного замка;</span>
					<span>3.1.7.  наезжать колесом транспортного средства на парковочный замок;</span>
					<span>3.1.8. габариты транспортного средства не должны выходить за площадь парковочного замка (замок закроется автоматически);</span>
					<span>3.1.9 запрещено завершать сессию парковки, находясь транспортным средством над поверхностью замка и «в слепой зоне» дуги.</span>
					<br />
					<span className="DescriptionTitle"> IV. ПРАВИЛА ПОВЕДЕНИЯ НА ТЕРРИТОРИИ ПАРКОВКИ  </span>
					<br />
					<span>4.1 на муниципальных парковках запрещается:</span>
					<span>4.1.1. загрязнять территорию платной муниципальной парковки;</span>
					<span>4.1.2. препятствовать уборке территории улиц и парковочного пространства;                </span>
					<span>4.1.3. повреждать оборудование, установленное на территории платной муниципальной парковки;</span>
				</DescriptionElement>
			</ElementList>
			<ElementList className={`${clickEvent2 ? '' : 'show'}`} onClick={() => setClickEvent2(!clickEvent2)}>
				<TitleElement>
					<span>Пользовательское соглашение</span>
					<PlusSVG />
				</TitleElement>
				<DescriptionElement>
					<span className="DescriptionTitle">1.    ОБЩИЕ ПОЛОЖЕНИЯ</span>
					<br />
					<span>1.1. Настоящее Пользовательское соглашение (далее – Соглашение) относится к сайту «КРАСПАРКИНГ», расположенному по адресу www.         .ru, и ко всем соответствующим сайтам, связанным с сайтом www.        .ru.</span>
					<span>1.2. Сайт «КРАСПАРКИНГ» (далее – Сайт) является собственностью МКУ г. Красноярска  «Управление дорог, инфраструктуры и благоустройства»  ИНН      , ОГРН        (далее – МКУ «УДИБ»)</span>
					<span>1.3. Настоящее Соглашение регулирует отношения между Администрацией сайта «КРАСПАРКИНГ» (далее – Администрация сайта) и Пользователем данного Сайта.</span>
					<span>1.4. Администрация сайта оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.</span>
					<span>1.5. Продолжение использования Сайта Пользователем означает принятие Соглашения и изменений, внесенных в настоящее Соглашение.</span>
					<span>1.6. Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.</span>
					<br />
					<span className="DescriptionTitle">2. ОПРЕДЕЛЕНИЯ ТЕРМИНОВ</span>
					<br />
					<span>2.1. Перечисленные ниже термины имеют для целей настоящего Соглашения следующее значение:</span>
					<span>2.1.1 «КРАСПАРКИНГ» – сайт, расположенный на доменном имени www.      .ru, осуществляющий свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов.</span>
					<span>2.1.2. Сайт, содержащий информацию о платных муниципальных парковках (парковочных местах) на автомобильных дорогах общего пользования местного значения г. Красноярска, позволяющий осуществить пользование парковками (парковочными местами) в том числе и оплачивать парковочную сессию.</span>
					<span>2.1.3. Администрация сайта – уполномоченные сотрудники на управления Сайтом, действующие от имени МКУ «УДИБ».</span>
					<span>2.1.4. Пользователь сайта (далее - Пользователь) – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.</span>
					<span>2.1.5. Содержание Сайта (далее – Содержание) - охраняемые результаты интеллектуальной деятельности, включая тексты литературных произведений, их названия, предисловия, аннотации, статьи, иллюстрации, обложки, музыкальные произведения с текстом или без текста, графические, текстовые, фотографические, производные, составные и иные произведения, пользовательские интерфейсы, визуальные интерфейсы, названия товарных знаков, логотипы, программы для ЭВМ, базы данных, а также дизайн, структура, выбор, координация, внешний вид, общий стиль и расположение данного Содержания, входящего в состав Сайта и другие объекты интеллектуальной собственности все вместе и/или по отдельности, содержащиеся на Сайте.</span>
					<br />
					<span className="DescriptionTitle">3. ПРЕДМЕТ СОГЛАШЕНИЯ</span>
					<br />
					<span>3.1. Предметом настоящего Соглашения является предоставление Пользователю доступа к содержащимся на Сайте информации.</span>
					<span>
						3.1.1. Сайт предоставляет Пользователю доступ ресурс, позволяющий пользоваться муниципальными паковками, а именно:
						- узнать расположение муниципальных парковок;
						- узнать количество свободных парковочных мест;
						- узнать правила парковки;
						- способы оплаты парковочной сессии;
						- открыть/закрыть замок;
						- оплатить услугу.

					</span>
					<span>3.2. Доступ к Сайту предоставляется на бесплатной основе.</span>
					<span>
						3.3 При регистрации на Сайте необходимо ввести данные:
						- имя;
						- телефонный номер с которого будет производится парковочная сессия;
						- электронная почта;
						- государственный регистрационный знак автомобиля.
					</span>
					<span>3.4. Настоящее Соглашение является публичной офертой. Получая доступ к Сайту Пользователь считается присоединившимся к настоящему Соглашению.</span>
					<span>3.5. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации</span>
					<br />
					<span className="DescriptionTitle">4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</span>
					<br />
					<span>4.1. Администрация сайта вправе:</span>
					<span>4.1.1. Изменять правила пользования Сайтом, а также изменять содержание данного Сайта. Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте.</span>
					<span>4.1.2. Ограничить доступ к Сайту в случае нарушения Пользователем условий настоящего Соглашения.</span>
					<span>4.2. Пользователь вправе:</span>
					<span>4.2.1. Получить доступ к использованию Сайта после соблюдения требований о регистрации.</span>
					<span>4.2.2. Пользоваться всеми имеющимися на Сайте услугами.</span>
					<span>4.2.3. Задавать любые вопросы, относящиеся к услугам Сайта.</span>
					<span>4.2.4. Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Соглашением и не запрещенных законодательством Российской Федерации.</span>
					<span>4.3. Пользователь Сайта обязуется:</span>
					<span>4.3.1. Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет непосредственное отношение к предоставляемым услугам данного Сайта.</span>
					<span>4.3.2. Соблюдать имущественные и неимущественные права авторов и иных правообладателей при использовании Сайта.</span>
					<span>4.3.3. Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сайта.</span>
					<span>4.3.4. Не распространять с использованием Сайта любую конфиденциальную и охраняемую законодательством Российской Федерации информацию о физических либо юридических лицах.</span>
					<span>4.3.5. Избегать любых действий, в результате которых может быть нарушена конфиденциальность охраняемой законодательством Российской Федерации информации.</span>
					<span>4.3.6. Не использовать Сайт для распространения информации рекламного характера.</span>
					<span>4.4. Пользователю запрещается:</span>
					<span>4.4.1. Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания Сайта;</span>
					<span>4.4.2. Нарушать надлежащее функционирование Сайта;</span>
					<span>4.4.3. Любым способом обходить навигационную структуру Сайта для получения или попытки получения любой информации, документов или материалов любыми средствами, которые специально не представлены сервисами данного Сайта;</span>
					<span>4.4.4. Несанкционированный доступ к функциям Сайта, любым другим системам или сетям, относящимся к данному Сайту, а также к любым услугам, предлагаемым на Сайте;</span>
					<span>4.4.4. Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся к Сайту.</span>
					<span>4.4.5. Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о любом другом Пользователе Сайта.</span>
					<span>4.4.6. Использовать Сайт и его Содержание в любых целях, запрещенных законодательством Российской Федерации, а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права других лиц.</span>
					<br />
					<span className="DescriptionTitle">5. ИСПОЛЬЗОВАНИЕ САЙТА</span>
					<br />
					<span>5.1. Сайт и Содержание, входящее в состав Сайта, принадлежит и управляется Администрацией сайта.</span>
					<span>5.2. Содержание Сайта не может быть скопировано, опубликовано, воспроизведено, передано или распространено любым способом, а также размещено в глобальной сети «Интернет» без предварительного письменного согласия Администрации сайта.</span>
					<span>5.3. Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также другими правами, связанными с интеллектуальной собственностью, и законодательством.</span>
					<span>5.4. Полное использование Сайта, может потребовать создания учётной записи Пользователя.</span>
					<span>5.5. Пользователь несет персональную ответственность за сохранение конфиденциальности информации учётной записи, включая пароль, а также за всю без исключения деятельность, которая ведётся от имени Пользователя учётной записи.</span>
					<span>5.7. Администрация сайта не обладает правом в одностороннем порядке аннулировать учетную запись Пользователя, без уведомления Пользователя.</span>
					<span>5.8. Информация, размещаемая на Сайте не должна истолковываться как изменение настоящего Соглашения.</span>
					<br />
					<span className="DescriptionTitle">6. ОТВЕТСТВЕННОСТЬ</span>
					<br />
					<span>6.1. Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного нарушения любого положения настоящего Соглашения, а также вследствие несанкционированного доступа к коммуникациям другого Пользователя, Администрацией сайта не возмещаются.</span>
					<span>6.2. Администрация сайта не несет ответственности за:</span>
					<span>6.2.1. Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой силы, а также любого случая неполадок в телекоммуникационных, компьютерных, электрических и иных смежных системах.</span>
					<span>6.2.2. Действия систем переводов, банков, платежных систем и за задержки, связанные с их работой.</span>
					<span>6.2.3. Надлежащее функционирование Сайта, в случае, если Пользователь не имеет необходимых технических средств для его использования, а также не несет никаких обязательств по обеспечению пользователей такими средствами.</span>
					<br />
					<span className="DescriptionTitle">7. НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</span>
					<br />
					<span>7.1. Администрация сайта вправе раскрыть любую собранную о Пользователе данного Сайта информацию, если раскрытие необходимо в связи с расследованием или жалобой в отношении неправомерного использования Сайта либо для установления (идентификации) Пользователя, который может нарушать или вмешиваться в права Администрации сайта или в права других Пользователей Сайта.</span>
					<span>7.2. Администрация сайта имеет право раскрыть любую информацию о Пользователе, которую посчитает необходимой для выполнения положений действующего законодательства или судебных решений, обеспечения выполнения условий настоящего Соглашения, защиты прав или безопасности МКУ «УДИБ», Пользователей.</span>
					<span>7.3. Администрация сайта имеет право раскрыть информацию о Пользователе, если действующее законодательство Российской Федерации требует или разрешает такое раскрытие.</span>
					<span>7.4. Администрация сайта вправе без предварительного уведомления Пользователя прекратить и (или) заблокировать доступ к Сайту, если Пользователь нарушил настоящее Соглашение или содержащиеся в иных документах условия пользования Сайтом, а также в случае прекращения действия Сайта либо по причине технической неполадки или проблемы.</span>
					<span>7.5. Администрация сайта не несет ответственности перед Пользователем или третьими лицами за прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения или иного документа, содержащего условия пользования Сайтом.</span>
					<br />
					<span className="DescriptionTitle">8. РАЗРЕШЕНИЕ СПОРОВ</span>
					<br />
					<span>8.1. В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предъявление претензии (письменного предложения о добровольном урегулировании спора).</span>
					<span>8.2. Получатель претензии в течение 30 календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</span>
					<span>8.3. При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться в суд за защитой своих прав, которые предоставлены им действующим законодательством Российской Федерации.</span>
					<br />
					<span className="DescriptionTitle">9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ </span>
					<br />
					<span>9.1. Администрация сайта не принимает встречные предложения от Пользователя относительно изменений настоящего Пользовательского соглашения.</span>
					<span>9.2. Отзывы Пользователя, размещенные на Сайте, не являются конфиденциальной информацией и могут быть использованы Администрацией сайта без ограничений.</span>
					<br />
					<span className="DescriptionTitle">10. РЕКВИЗИТЫ </span>
					<br />
					<span>Муниципальное казенное учреждение города Красноярска «Управление дорог, инфраструктуры и благоустройства» (МКУ «УДИБ»)</span>
					<span>
						ИНН
						{' '}
						<span className="whiteText">2466215012</span>
						{' '}
						/ КПП
						{' '}
						<span className="whiteText">246301001</span>
						{' '}
						/ ОГРН
						{' '}
						<span className="whiteText">1082468051995</span>
					</span>
					<span>Адрес (местонахождение): 660041, г. Красноярск, ул. Можайского, 11.</span>
					<span>
						тел.:8 (391)
						{' '}
						<span className="whiteText">217-98-20</span>
						; факс:
						<span className="whiteText">
							{' '}
							217-98-23
						</span>
					</span>
				</DescriptionElement>
			</ElementList>
			{/* <ElementList className={`${clickEvent1 ? '' : 'show'}`} onClick={() => setClickEvent1(!clickEvent2)}>
				<TitleElement>
					<span>{title}</span>
					<PlusSVG />
				</TitleElement>
				{inner ? <DescriptionElement dangerouslySetInnerHTML={{ __html: description || '' }} />
					: <DescriptionElement>{description}</DescriptionElement>}
			</ElementList>
			<ElementList className={`${clickEvent2 ? '' : 'show'}`} onClick={() => setClickEvent2(!clickEvent2)}>
				<TitleElement>
					<span>{title}</span>
					<PlusSVG />
				</TitleElement>
				{inner ? <DescriptionElement dangerouslySetInnerHTML={{ __html: description || '' }} />
					: <DescriptionElement>{description}</DescriptionElement>}
			</ElementList> */}
		</>

	);
};
export default ElementFAQ;
