import styled from 'styled-components';

export const WidgetBox = styled.div`

  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  border: 2px solid #c5c5c582;
  background-color: #5d5d7012;
  border-radius: 10px;
  box-sizing: border-box;
  width: 475px;
`;
export const TitleWidget = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
`;
export const ContentWidget = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  & .blockAcquiring {
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
  }
  & .nameAcquiring {
    width: 60%;
  }
  & .valueAcquiring {
  }
`;
