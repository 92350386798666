import styled from 'styled-components';

export const Body = styled.div`
  /* position: absolute; */
  height: 100%;
  /* width: 100%; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  &.show {
    display: flex;
  }
  &.out {
    display: none;
  }
`;

export const EditForm = styled.div`
  position: absolute;
  transition: all 100ms;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  z-index: 51;
  transform: translate(-50%, -50%);
  background-color: #121212;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.16),
    rgba(255, 255, 255, 0.16)
  );
  padding: 26px;
  &.show {
    top: 50%;
  }
  &.out {
    top: -50%;
  }
`;
export const TitleForm = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #ffffff;
`;
export const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const TimeElements = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
export const ButtonElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
`;
