/* eslint-disable import/no-named-as-default-member */
import { call, put } from 'redux-saga/effects';
import {
	GetParkingsRequest,
	GetParkingsResponse,
	GetParkingStatRequest,
	GetParkingStatResponse,
} from 'store/dto/dtos';
import actions from 'store/actions';
import { IAction, IPageOpt } from 'store/utils';
import baseApiService from 'service/base-api.service';
import errorApiService from 'service/error.service';

const parkingCall = (opts: IPageOpt): Promise<GetParkingsResponse> => {
	const request = new GetParkingsRequest();
	request.filter = opts.filter;
	request.items = opts.items;
	request.page = opts.page;
	request.sort = opts.sort;
	return baseApiService.get(request);
};
const parkingStatsCall = (opts: IPageOpt): Promise<GetParkingStatResponse> => {
	console.log(opts);
	const request = new GetParkingStatRequest();
	return baseApiService.get(request);
};

export function* getParkingsRequest(action: IAction<IPageOpt>) {
	try {
		yield put(actions.app.saga.asyncAddLoading('loadingParkings'));
		const {
			payload: {
				page, items, sort, filter,
			},
		} = action;
		const result: GetParkingsResponse = yield call(parkingCall, {
			page, items, sort, filter,
		});
		yield put(actions.data.setParkings(result));
		yield put(actions.app.saga.asyncRemoveLoading('loadingParkings'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingParkings'));
		yield put(actions.data.setParkings(new GetParkingsResponse()));
		yield put(actions.app.saga.asyncInitNotification({
			messages:
			errorApiService.catchRequestError(error),
			type: 'warm',
		}));
	}
}
export function* getParkingStatRequest(action: IAction<IPageOpt>) {
	try {
		const {
			payload,
		} = action;
		const result: GetParkingStatResponse = yield call(parkingStatsCall, payload);
		console.log(result);
		yield put(actions.data.setParkingStats(result));
	} catch (error:any) {
		yield put(actions.data.setParkingStats(new GetParkingStatResponse()));
		yield put(actions.app.saga.asyncInitNotification({
			messages:
			errorApiService.catchRequestError(error),
			type: 'warm',
		}));
	}
}
