/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-tabs */
/* eslint-disable react/prop-types */
import SearchMapElement from 'components/elements/SearchMapElement';
import SelectMapElement from 'components/elements/SelectMapElement';
import React from 'react';
import L, { Icon } from 'leaflet';
import {
	MapContainer,
	Marker,
	Popup,
	TileLayer,
	// useMap,
	// useMapEvents,
} from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import actions from 'store/actions';
import {
	GetActiveOrderResponse, Order, OrderStatusType, Parking, ParkingStatus, ParkingStatusType,
} from 'store/dto/dtos';
import selectors from 'store/selectors';
import BookingParkingElement from 'components/elements/BookingParking';
import ActiveParkingElement from 'components/layout/ActiveParking';
// import LoadingComponent from 'components/layout/Loading';
import HistoryLayoutElement from 'components/elements/HistoryElement';
// import FooterCabinetMobile from 'components/layout/FooterCabinetMobile';
import HeaderBar from 'components/layout/HeaderBar';
import FooterCabinetMobile from 'components/layout/FooterCabinetMobile';
import LoadingComponent from 'components/layout/Loading';
import {
	SectionMap,
	MapElement,
	MainSection,
	ActiveParkingElementList,
	NotificationMobile,
} from './styledComponent';
import SearchPage from '../SearchPage';

interface IMarkerRef {
  element: any;
  id: string;
}

const newParkingSvgActive = `
<svg viewBox="0 0 80 80"  version="1.1" xmlns="http://www.w3.org/2000/svg">
<path  d="M3.2,26.8c0-8.3,0-12.3,1.6-15.6c1.4-2.8,3.7-5.1,6.4-6.4c3.2-1.6,7.3-1.6,15.6-1.6h26.5c8.3,0,12.3,0,15.6,1.6c2.8,1.4,5.1,3.7,6.4,6.4c1.6,3.2,1.6,7.3,1.6,15.6v26.5c0,8.3,0,12.3-1.6,15.6c-1.4,2.8-3.7,5.1-6.4,6.4c-3.2,1.6-7.3,1.6-15.6,1.6H26.8c-8.3,0-12.3,0-15.6-1.6c-2.8-1.4-5.1-3.7-6.4-6.4c-1.6-3.2-1.6-7.3-1.6-15.6V26.8z" stroke="{borderColor}" stroke-width="6" fill="{mainColor}" box-sizing="border-box" />
<path  d="M28.9,17.3v40.8c0,2.6-2.2,4.6-4.8,4.6s-4.8-2.1-4.8-4.6V22.8c0-3,2.5-5.5,5.5-5.5C24.8,17.3,28.9,17.3,28.9,17.3z" fill="#FFFFFF"/>
<path d="M38,26.5H27.3v-9.2H38c9,0,16.4,7.4,16.4,16.4S47,50.1,38,50.1H27.3v-9.2H38c4,0,7.2-3.2,7.2-7.2S42,26.5,38,26.5z" fill="#FFFFFF" />
<circle  cx="66.5" cy="59.5" r="6.9" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.5"/>
<text transform="matrix(1 0 0 1 61.6572 62.1621)"  font-size="10px">20</text>
<circle cx="56" cy="59.5" r="5.4" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.5"/>
<text transform="matrix(1 0 0 1 52.5156 61.5215)"  font-size="7px">15</text>
<circle  cx="47.4" cy="59.5" r="4.7" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.5"/>
<text transform="matrix(1 0 0 1 44.166 61.5215)"  font-size="7px">10</text>
</svg>
`;

const getIconStatus = (src: string, active: boolean, status?: ParkingStatusType) => {
	const result = src;
	let mainColor = '#095BA7';
	if (status) {
		switch (status) {
		case ParkingStatusType.OfflineClosed || ParkingStatusType.OfflineOpen:
			mainColor = '#92929D';
			break;
		case ParkingStatusType.Working:
			mainColor = '#095BA7';
			break;
		case ParkingStatusType.AllBusy:
			mainColor = 'red';
			break;
		default:
			mainColor = '#095BA7';
			break;
		}
	}
	return `data:image/svg+xml;base64,${btoa(result.replace(/\{borderColor\}/g, active ? 'black' : mainColor).replace(/\{mainColor\}/g, mainColor))}`;
};

const MapMobilePage: React.FC = () => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [ordersDataArray, setOrdersDataArray] = React.useState<Order[]>([]);
	const [parkingsDataArray, setParkingsDataArray] = React.useState<Parking[]>(
		[],
	);
	const mapRef = React.useRef<L.Map>(null);
	const [activeMarker, setActiveMarker] = React.useState<any>(null);
	const [activeSearchMobileFlag, setActiveSearchMobileFlag] = React.useState<
    boolean
  >(false);
	const showBooking: boolean = useSelector(selectors.app.getShowBooking);

	const [centerMap, setCenterMap] = React.useState<{
    lat: number;
    lng: number;
  }>({ lat: 56.01839, lng: 92.86717 });
	const activeOrder: GetActiveOrderResponse = useSelector(selectors.data.getActiveOrder) || null;

	let markerRefs: IMarkerRef[] = [];

	const getIconParking = (active:boolean, iconStatus?: ParkingStatus) => {
		const parkingActiveIcon = new Icon({
			iconUrl: getIconStatus(newParkingSvgActive, active, iconStatus?.status),
			iconAnchor: active ? new L.Point(13, 13) : new L.Point(9, 9),
			popupAnchor: active ? new L.Point(0, -13) : new L.Point(0, -9),
			iconSize: active ? new L.Point(26, 26) : new L.Point(18, 18),
		});

		return parkingActiveIcon;
	};

	const activeSearchMobile = (flag: boolean) => {
		setActiveSearchMobileFlag(flag);
	};
	const setSelectedOrder = (item: Order) => {
		setActiveMarker(markerRefs.find((m) => m.id === item.id));
		setCenterMap({ lat: item.lat, lng: item.lon });
	};

	const setActiveOrder = (item: Order) => {
		dispatch(actions.app.setShowBooking(true));

		setActiveMarker(
			pathname.split('/')[2] === 'history'
				? markerRefs.find((m) => m.id === item.id)
				: markerRefs.find((m) => m.id === item.parkingId),
		);
		setCenterMap({ lat: item.lat, lng: item.lon });
	};
	const setSelectedParking = (item: Parking) => {
		const temp = parkingsDataArray.find((m) => m.id === item.id) || null;
		if (temp) {
			setActiveMarker(markerRefs.find((m) => m.id === temp.id));
			setCenterMap({ lat: temp.lat, lng: temp.lon });
		}
	};

	const handleOrderMarker = (item: Order) => {
		setActiveMarker(markerRefs.find((m) => m.id === item.id) || null);
		dispatch(actions.app.setShowBooking(false));
		setCenterMap({ lat: item.lat, lng: item.lon });
	};
	const handleParkingMarker = (item: Parking) => {
		dispatch(actions.data.saga.asyncGetParkingSpaces(item.id));
		dispatch(actions.data.setCurrentParking(item));
		navigate('/map/select');
		dispatch(actions.app.setShowBooking(false));
		setActiveMarker(markerRefs.find((m) => m.id === item.id) || null);
		setCenterMap({ lat: item.lat, lng: item.lon });
	};

	const renderSectionElement = (item: string) => {
		switch (item) {
		case 'search':
			return (
				<SearchMapElement
					selectedParking={setSelectedParking}
					setParkingData={(m) => setParkingsDataArray(m)}
					activeSearch={(m) => activeSearchMobile(m)}
				/>
			);
		case 'select':
			return <SelectMapElement />;
		case 'history':
			return (
				<HistoryLayoutElement
					setOrderData={(m) => setOrdersDataArray(m)}
					selectedMarker={activeMarker}
					selectedOrder={setSelectedOrder}
				/>
			);
		default:
			return null;
		}
	};

	const renderMarkerElement = (routing: string) => {
		markerRefs = [];
		if (routing === 'search' || routing === 'select') {
			return parkingsDataArray?.map((item) => (
				<Marker
					key={item.id}
					ref={(ref) => markerRefs.push({ id: item.id, element: ref })}
					icon={getIconParking(activeMarker?.id === item.id, item.status)}
					position={[item.lat, item.lon]}
					eventHandlers={{
						click: () => {
							handleParkingMarker(item);
						},
						mouseover: (e: any) => {
							e.target.openPopup();
						},
						mouseout: (e: any) => {
							e.target.closePopup();
						},
					}}
				>
					<Popup className="marker-popup">
						<span>{item.number}</span>
						<span>
							{item.count}
							{' '}
							мест
						</span>
					</Popup>
				</Marker>
			));
		}
		return ordersDataArray?.map((item) => (
			<Marker
				key={item.id}
				ref={(ref) => markerRefs.push({ id: item.id, element: ref })}
				icon={getIconParking(activeMarker?.id === item.id)}
				position={[item.lat, item.lon]}
				eventHandlers={{
					click: () => {
						handleOrderMarker(item);
					},
					mouseover: (e: any) => {
						e.target.openPopup();
					},
					mouseout: (e: any) => {
						e.target.closePopup();
					},
				}}
			/>
		));
	};
	const setSectionMapClass = () => {
		if (activeSearchMobileFlag) {
			return 'activeMobile';
		}
		if (pathname.split('/')[2] === 'select') {
			return 'activeSelectMobile';
		}
		if (pathname.split('/')[2] === 'history') {
			return 'activeHistoryMobile';
		}
		return 'outActiveMobile';
	};

	React.useEffect(() => {
		mapRef.current?.setView(centerMap, 16);
		// mapRef.current?.flyTo(centerMap, 16);
	}, [centerMap]);
	React.useEffect(() => {
		mapRef.current?.invalidateSize();
	}, [activeSearchMobileFlag]);
	React.useEffect(() => {
		mapRef.current?.invalidateSize();
	}, [pathname]);
	// React.useEffect(() => {
	// 	dispatch(actions.app.saga.asyncAddLoading('activateAndBookingParking'));
	// }, []);

	return (
		<MainSection>
			<LoadingComponent loadingElement="activateAndBookingParking" />

			<HeaderBar />
			{renderSectionElement(pathname.split('/')[2])}
			{(showBooking && activeOrder.hasActiveOrder) && <BookingParkingElement />}

			<ActiveParkingElementList>
				{(activeOrder.hasActiveOrder && activeOrder?.order?.status !== OrderStatusType.Booked) && (
					<ActiveParkingElement
						onChangeСenter={setActiveOrder}
						activeParking={activeOrder.order}
					/>
				)}
			</ActiveParkingElementList>

			<SectionMap id="map" className={setSectionMapClass()}>
				<MapElement className={setSectionMapClass()}>
					<MapContainer
						ref={mapRef}
						center={centerMap}
						zoom={13}
						scrollWheelZoom
						zoomControl={false}
					>
						<TileLayer
							attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
						{renderMarkerElement(pathname.split('/')[2])}
					</MapContainer>
				</MapElement>
			</SectionMap>
			<FooterCabinetMobile />
		</MainSection>
	);
};

export default MapMobilePage;
