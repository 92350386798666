/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-tabs */
import { call, put } from 'redux-saga/effects';
import baseApiService from 'service/base-api.service';
import errorApiService from 'service/error.service';
import actions from 'store/actions';
import {
	BookAndActivateRequest, CompleteOrderRequest, Order,
} from 'store/dto/dtos';
import { IAction } from 'store/utils';

// const bookingParkings = (opts:string): Promise<Order> => {
// const request = new BookRequest();
// request.parkingSpaceId = opts;
// return baseApiService.post(request);
// };
// const activateParkings = (opts:string): Promise<Order> => {
// const request = new ActivateRequest();
// console.log(opts);
// return baseApiService.post(request);
// };
const activateAndBookingParkings = (opts:string): Promise<Order> => {
	const request = new BookAndActivateRequest();
	request.parkingSpaceId = opts;
	return baseApiService.post(request);
};

// const completeParkings = (opts:string): Promise<Order> => {
// const request = new CompleteOrderRequest();
// console.log(opts);
// return baseApiService.post(request);
// };

// export function* bookingPArkingSpaceRequest(action: IAction<string>) {
// 	try {
// 		const {
// 			payload,
// 		} = action;
// 		yield put(actions.app.saga.asyncAddLoading('bookingLoading'));
// 		const result: Order = yield call(
// 			bookingParkings,
// 			payload,
// 		);
// 		yield put(actions.data.setActiveOrder({ hasActiveOrder: true, order: result }));
// 		yield put(actions.app.saga.asyncRemoveLoading('bookingLoading'));
// 	} catch (error:any) {
// 		yield put(actions.app.saga.asyncRemoveLoading('bookingLoading'));
// 		if (error?.responseStatus) {
// 			yield put(actions.app.saga.asyncInitNotification({
// 				messages:
//                 errorApiService.catchRequestError(error),
// 				type: 'warm',
// 			}));
// 		}
// 	}
// }
export function* activateAndBookingParkingSpaceRequest(action: IAction<{
	id:string;
	callback?:(item:Order) => void;}>) {
	try {
		const {
			payload: {	id, callback },
		} = action;
		yield put(actions.app.saga.asyncAddLoading('activateAndBookingParking'));
		const result: Order = yield call(
			activateAndBookingParkings,
			id,
		);
		yield put(actions.app.saga.asyncInitNotification({
			messages:
            `${result ? 'Замок открыт!' : 'Что-то пошло не так, попробуйте еще раз.'}`,
			type: 'warm',
		}));
		yield callback?.(result);
		yield put(actions.app.saga.asyncRemoveLoading('activateAndBookingParking'));
		yield put(actions.app.setShowBooking(true));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('activateAndBookingParking'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
                errorApiService.catchRequestError(error),
				type: 'warm',
			}));
		}
	}
}
// export function* activatePArkingSpaceRequest(action: IAction<string>) {
// 	try {
// 		const {
// 			payload,
// 		} = action;

// 		yield put(actions.app.saga.asyncAddLoading('activateLoading'));
// 		const result: Order = yield call(
// 			activateParkings,
// 			payload,
// 		);

// 		yield put(actions.app.saga.asyncInitNotification({
// 			messages:
//             `${result ? 'Замок открыт!' : 'Что-то пошло не так, попробуйте еще раз.'}`,
// 			type: 'warm',
// 		}));

// 		yield put(actions.app.saga.asyncRemoveLoading('activateLoading'));
// 	} catch (error:any) {
// 		yield put(actions.app.saga.asyncRemoveLoading('activateLoading'));
// 		if (error?.responseStatus) {
// 			yield put(actions.app.saga.asyncInitNotification({
// 				messages:
//                 errorApiService.catchRequestError(error),
// 				type: 'warm',
// 			}));
// 		}
// 	}
// }
// export function* completePArkingSpaceRequest(action: IAction<string>) {
// 	try {
// 		const {
// 			payload,
// 		} = action;
// 		yield put(actions.app.saga.asyncAddLoading('completeLoading'));
// 		const result: Order = yield call(
// 			completeParkings,
// 			payload,
// 		);
// 		if (result) {
// 			yield put(actions.data.saga.asyncSetPaidOrder({ orderId: payload, returlUrl: `${window.location.origin}/map/history` }));
// 		}
// 		yield put(actions.app.saga.asyncRemoveLoading('completeLoading'));
// 	} catch (error:any) {
// 		yield put(actions.app.saga.asyncRemoveLoading('completeLoading'));
// 		if (error?.responseStatus) {
// 			yield put(actions.app.saga.asyncInitNotification({
// 				messages:
//                 errorApiService.catchRequestError(error),
// 				type: 'warm',
// 			}));
// 		}
// 	}
// }
