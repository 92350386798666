/* eslint-disable no-undef */
/* eslint-disable no-tabs */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import {
	ParkingSpaceMode,
	ParkingSpaceStatus as ParkingSpaceStatusBase,
} from 'store/dto/dtos';
import TableElement from 'components/elements/TableElement';
import { ITypeMenuAction, Order, TypeMenuAction } from 'components/elements/TableElement/interface';
import { ReloadOutlined } from '@ant-design/icons';
import InputMask, { Props } from 'react-input-mask';
import 'dayjs/locale/ru';
import {
	Input, DatePicker, Button as ButtonAntd, Tooltip, Modal, Form, InputProps, InputRef,
} from 'antd';
import { GetParkingSpacesResponse, ParkingSpaceState, ParkingSpaceStatus } from 'store/dto/parking.dtos';

import { Customer } from 'store/dto/hub.dtos';
import tokenService from 'service/token.service';
import {
	Body,
	Title,
	MainSection,
	NameTitle,
	ControlElement,

	ModalEditContent,
	EditInputForModal,
	EditInputForModalTitle,
} from './styledComponent';

const { RangePicker } = DatePicker;

const ParkingSpacesPage: React.FC = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [modal, contextHolder] = Modal.useModal();
	const [query, setQuery] = React.useState('');
	const [parkingSpaceData, setParkingSpaceData] = React.useState<GetParkingSpacesResponse>(new GetParkingSpacesResponse());
	const [parkingSpaceStatus, setParkingSpaceStatus] = React.useState<ParkingSpaceStatus>(new ParkingSpaceStatus());
	const customer: Customer = useSelector(selectors.authorization.getCustomer) || [];
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [isModalWarningOpen, setIsModalWarningOpen] = React.useState(false);
	const [valueMask, setValueMask] = React.useState<string>('');
	const loading : string[] = useSelector(selectors.app.getCurrentLoading) || [];

	const [paramsControlTable, setParamsControlTable] = React.useState<{filter:string}>(
		{ filter: '' },
	);
	const [paramsPage, setParamsPage] = React.useState<{order: Order;
		orderBy: string;
		page:number;
		rowsPerPage:number; }>(
			{
				order: 'asc', orderBy: '', page: 0, rowsPerPage: 25,
			},
		);

	React.useEffect(() => {
		const timeOutId = setTimeout(() => setParamsControlTable({ ...paramsControlTable, filter: query }), 5);
		return () => clearTimeout(timeOutId);
	}, [query]);

	React.useEffect(() => {
		updateData();
	}, [paramsPage, paramsControlTable]);

	const updateData = () => {
		const callbackParkingSpaces = (value: GetParkingSpacesResponse) => {
			setParkingSpaceData(value);
		};

		dispatch(actions.parkingSpaceManipulation.saga.asyncGetParkingSpaces(
			{
				items: paramsPage.rowsPerPage,
				filter: paramsControlTable.filter,
				page: paramsPage.page,
				sort: `${paramsPage.orderBy} ${paramsPage.order}`,
				customerId: customer.id
					? customer.id
					: (tokenService.customerId as string),
				callbackParkingSpaces,

			},
		));
	};

	const showControlForm = (id: string) => {
		const temp = parkingSpaceData.items.find((item) => item.id === id);
		if (temp) {
			const callbackParkingSpaceStatus = (value: ParkingSpaceStatus) => { setParkingSpaceStatus(value); setIsModalOpen(true); };
			dispatch(
				actions.parkingSpaceManipulation.saga.asyncGetParkingSpaceStatus({
					customerId: '',
					id: temp.id,
					callbackParkingSpaceStatus,
				}),
			);
		}
	};

	const onDoubleClickRow = (id:string) => {
		const temp = parkingSpaceData.items.find((item) => item.id === id);
		if (temp) {
			const callbackParkingSpaceStatus = (value: ParkingSpaceStatus) => { setParkingSpaceStatus(value); setIsModalOpen(true); };
			dispatch(
				actions.parkingSpaceManipulation.saga.asyncGetParkingSpaceStatus({
					customerId: '',
					id: temp.id,
					callbackParkingSpaceStatus,
				}),
			);
		}
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const rowMenuEvent = (itemAction:ITypeMenuAction, id:string) => {
		switch (itemAction.type) {
		case 'control':
			showControlForm(id);
			break;
		default:
			console.log('alert');
		}
	};

	const openParking = () => {
		const callbackOpen = (value: ParkingSpaceStatus) => {
			setIsModalWarningOpen(false);
			setIsModalOpen(false);
			setParkingSpaceStatus(new ParkingSpaceStatus());
		};
		dispatch(
			actions.parkingSpaceManipulation.saga.asyncOpenParkingSpace({
				customerId: '',
				id: parkingSpaceStatus.id,
				callbackOpen,
			}),
		);
	};
	const moveStepParking = (open: boolean) => {
		const callbackMoveStep = (value: ParkingSpaceStatus) => {
			setIsModalWarningOpen(false);
			setIsModalOpen(false);
			setParkingSpaceStatus(new ParkingSpaceStatus());
		};
		dispatch(
			actions.parkingSpaceManipulation.saga.asyncMoveStepParkingSpace({
				customerId: customer.id
					? customer.id
					: (tokenService.customerId as string),
				id: parkingSpaceStatus.id,
				open,
				description: '',
				callbackMoveStep,
			}),
		);
	};

	const preparingForClosing = () => {
		if (parkingSpaceStatus?.mode !== ParkingSpaceMode.Engineer) {
			const callbackEngineer = (value: ParkingSpaceStatus) => {
				if (parkingSpaceStatus?.positionDescription === 'Закрыт частично') {
					openParking();
				} else if (parkingSpaceStatus?.positionDescription === 'Открыт в обратную сторону') {
					moveStepParking(false);
				}
			};
			dispatch(
				actions.parkingSpaceManipulation.saga.asyncSetParkingSpaceEngineerMode({
					customerId: '',
					id: parkingSpaceStatus?.id,
					callbackEngineer,
				}),
			);
		} else if (parkingSpaceStatus?.mode === ParkingSpaceMode.Engineer) {
			if (parkingSpaceStatus?.positionDescription === 'Закрыт частично') {
				openParking();
			} else if (parkingSpaceStatus?.positionDescription === 'Открыт в обратную сторону') {
				moveStepParking(false);
			}
		}
	};

	const handleWarningOk = () => {
		preparingForClosing();
	};

	const handleWarningCancel = () => {
		setIsModalWarningOpen(false);
	};

	const onChangeMask = (event:any) => {
		const { value } = event.target;
		// const newState = {
		// 	mask: '9 9 9',
		// 	value,
		// };
		// if (/^3[47]/.test(value)) {
		// 	newState.mask = '9 9 9';
		// }
		// this.setState(newState);

		if (value.split('_').length - 1 === 0) {
			if (valueMask !== value) 			{
				console.log(value);
				console.log(value.replace(/ /g, ''));
				setQuery(value.replace(/ /g, ''));
			}
		}
		setValueMask(value);
	};

	return (
		<Body>
			{contextHolder}
			<Title>
				<NameTitle>Замки</NameTitle>
				<ControlElement>

					{/* <Input
						value={query}
						onChange={(event) => setQuery(event.target.value)}
						id="outlined-search"
						placeholder="поиск..."
					/> */}
					<InputMask
						className="customInputMask"
						placeholder="Трехзначный номер замка"
						mask="9 9 9"
						value={valueMask}
						onChange={(e) => onChangeMask(e)}
					/>

					<Tooltip title="search">
						<ButtonAntd type="primary" onClick={updateData} shape="circle" icon={<ReloadOutlined />} />
					</Tooltip>
				</ControlElement>
			</Title>
			<MainSection>
				<TableElement
					onDoubleClickRow={onDoubleClickRow}
					changeParamsPage={setParamsPage}
					pagination={{ itemsPerPage: parkingSpaceData.itemsPerPage, page: parkingSpaceData.page, total: parkingSpaceData.total }}
					tableProps={{ defaultSorted: 'name', defaultOrder: 'asc', rowMenuAction: [{ type: 'control', name: 'Управление', permission: 'parkingSpace.control' }] }}
					rowMenuEvent={rowMenuEvent}
					tableData={{
						items: parkingSpaceData.items,
						header: [
							{
								key: 'parkingName',
								translate: 'Имя парковочного пространства',
								format: 'string',
								propsStyle: { align: 'left' },

							},
							{
								key: 'name',
								translate: 'Номер замка',
								format: 'string',
								propsStyle: { align: 'left' },

							},
						],
					}}
				/>

			</MainSection>
			<Modal
				title="Управление"
				centered
				width={600}
				open={isModalOpen}
				onCancel={handleCancel}
				footer={[
					<ButtonAntd key="back" onClick={handleCancel}>
						Закрыть
					</ButtonAntd>,
				]}
			>
				<ModalEditContent>
					<div className="ModalEditContentRow">
						<span>Имя парковочного пространства </span>
						<span>{parkingSpaceStatus.fullName}</span>
					</div>

					<div className="ModalEditContentRow">
						<span>Состояние замка</span>
						<span>
							{parkingSpaceStatus?.state === ParkingSpaceState.Closed
								? 'Закрыт'
								: parkingSpaceStatus?.state === ParkingSpaceState.Opened
									? 'Открыт'
									: 'Полож. неизвестно'}

						</span>
					</div>
					<div className="ModalEditContentRow">
						<span>Статус замка</span>
						<span>
							{parkingSpaceStatus?.statusDescription === 'Готов'
								? 'Ошибок нет'
								: parkingSpaceStatus?.statusDescription}

						</span>
					</div>

				</ModalEditContent>

				<EditInputForModal>
					<EditInputForModalTitle>Команды управления замком</EditInputForModalTitle>

					{/* <ButtonAntd
						onClick={() => setIsModalWarningOpen(true)}
						disabled={parkingSpaceStatus?.positionDescription !== 'Закрыт частично'}
						type={parkingSpaceStatus?.positionDescription === 'Закрыт частично' ? 'primary' : 'default'}
					>
						Открыть замок принудительно

					</ButtonAntd>
					<ButtonAntd
						onClick={() => setIsModalWarningOpen(true)}
						disabled={parkingSpaceStatus?.positionDescription !== 'Открыт в обратную сторону'}
						type={parkingSpaceStatus?.positionDescription === 'Открыт в обратную сторону' ? 'primary' : 'default'}
					>
						Сделать шаг замком в сторону закрытия

					</ButtonAntd> */}
					<ButtonAntd
						onClick={() => setIsModalWarningOpen(true)}
						disabled={parkingSpaceStatus?.positionDescription !== 'Открыт в обратную сторону' && parkingSpaceStatus?.positionDescription !== 'Закрыт частично'}
						type={(parkingSpaceStatus?.positionDescription === 'Открыт в обратную сторону' || parkingSpaceStatus?.positionDescription === 'Закрыт частично') ? 'primary' : 'default'}
					>
						Освободить машину ( прижать замок к земле )

					</ButtonAntd>
				</EditInputForModal>
			</Modal>

			<Modal centered title="Внимание" open={isModalWarningOpen} onOk={handleWarningOk} onCancel={handleWarningCancel}>
				<p>
					После того, как вы нажмете
					{' '}
					<span style={{ color: 'rgb(255,138,0)' }}>ОК</span>
					{' '}
					заказ завершится автоматически, а замок перейдет в инженерный режим и больше не будет доступен пользователям.
				</p>
				<p>
					<span style={{ color: 'rgb(255,138,0)' }}>Уведомите</span>
					{' '}
					технический отдел об этом замке, что бы они вывели замок в рабочий режим.
				</p>
			</Modal>
		</Body>
	);
};

export default ParkingSpacesPage;
