import { Button } from 'components/forms/Button';
import { HashLink } from 'react-router-hash-link';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useWindowDimensions from 'common/useWindowDimensions';
import { TDevice } from 'common/devices';
import { useScrollBlock } from 'common/useScrollBlock';
import tokenService from 'service/token.service';
import {
	BodyList,
	Header, HeaderIcon, MobileMenuIcon, Phone,
} from './styledComponent';
import FooterBar from '../FooterBar';

const HeaderBar: React.FC = () => {
	const navigate = useNavigate();
	const [clickEvent, setClickEvent] = React.useState(false);
	const { pathname } = useLocation();
	const { width } = useWindowDimensions();
	const [blockScroll, allowScroll] = useScrollBlock();

	const getDeviceType = (): TDevice => {
		if (width <= 768) return 'mobile';
		return 'laptop';
	};

	const closeMenuMobile = () => {
		setClickEvent(false);
		allowScroll();
	};
	const openMenuMobile = () => {
		setClickEvent(!clickEvent);
		// eslint-disable-next-line no-unused-expressions
		clickEvent ? allowScroll() : blockScroll();
	};
	const buttonNavigate = (url:string) => {
		navigate(url);
		closeMenuMobile();
	};
	const logout = () => {
		navigate('/');
		closeMenuMobile();
		tokenService.logout();
	};
	return (
		<Header>
			<HeaderIcon onClick={() => buttonNavigate('/')} />
			{getDeviceType() === 'laptop' && (
				<>
					<div className="headerElement">

						<HashLink smooth to="/#principle">
							Принцип работы
						</HashLink>

						<HashLink smooth to="/#map">Карта парковок</HashLink>
						<HashLink smooth to="/#price">Стоимость</HashLink>
						<HashLink smooth to="/#FAQ">Вопросы</HashLink>
						<HashLink className={pathname.split('/')[1] === 'about' ? 'active' : ''} smooth to="/about">О проекте</HashLink>
						<HashLink className={pathname === '/contacts' ? 'active' : ''} smooth to="/contacts">Контакты</HashLink>

					</div>
					<Phone>
						<span>Единый справочный центр</span>
						<span>
							+7 (391)
							<span>
								{' '}
								206 14 14
							</span>
						</span>
					</Phone>
					<Button onClick={() => navigate('/login')}>Войти</Button>

				</>
			)}
			{getDeviceType() === 'mobile' && (
				<>
					<MobileMenuIcon className={`${clickEvent ? 'show' : ''}`} onClick={() => openMenuMobile()} />
					<BodyList className={`${clickEvent ? 'show' : ''}`}>
						<div className="headerElement">
							<HashLink onClick={() => closeMenuMobile()} smooth to="/#principle">
								Принцип работы
							</HashLink>

							<HashLink onClick={() => closeMenuMobile()} smooth to="/#map">Карта парковок</HashLink>
							<HashLink onClick={() => closeMenuMobile()} smooth to="/#price">Стоимость</HashLink>
							<HashLink onClick={() => closeMenuMobile()} smooth to="/#FAQ">Вопросы</HashLink>
							<HashLink
								onClick={() => closeMenuMobile()}
								className={pathname.split('/')[1] === 'about' ? 'active' : ''}
								smooth
								to="/about"
							>
								О проекте

							</HashLink>
							<HashLink
								onClick={() => closeMenuMobile()}
								className={pathname === '/contacts' ? 'active' : ''}
								smooth
								to="/contacts"
							>
								Контакты

							</HashLink>
							{(tokenService.isLoggedIn && pathname.split('/')[1] !== 'map' && pathname.split('/')[1] !== 'profile'
							&& pathname.split('/')[1] !== 'search-active')
										&& (
											<HashLink
												onClick={() => { closeMenuMobile(); navigate('/map/search'); }}
												smooth
												to="/map/search"
												style={{ color: '#FF8A00' }}
											>
												Вернуться в личный кабинет

											</HashLink>
										)}

							<Button onClick={() => (tokenService.isLoggedIn ? logout() : buttonNavigate('/login'))}>
								{tokenService.isLoggedIn ? 'Выйти' : 'Войти'}

							</Button>
						</div>
						<div className="footerElement">
							<FooterBar />

						</div>
					</BodyList>

				</>
			)}

		</Header>
	);
};

export default HeaderBar;
