import styled from 'styled-components';

import HistorySVG from 'assets/icons/Header/History.svg';
import MapSVG from 'assets/icons/Header/Map.svg';
import SettingSVG from 'assets/icons/Header/Setting.svg';
import HeaderSVG from 'assets/icons/header_icon.svg';

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
export const MainSection = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 81px);
  display: flex;
`;
export const BlurEffect = styled.div`
  position: fixed;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: 50;
  &.show {
    display: flex;
  }
  &.out {
    display: none;
  }
`;

export const MenuSection = styled.div`
  width: 400px;
  background-color: #232323;
  transition: all 100ms;
  overflow-x: hidden;
  .insid-element {
    margin: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &.show {
    width: 400px;
  }
  &.hidden {
    width: 0px;
  }
`;

export const MenuElement = styled.div`
  cursor: pointer;
  span {
    transition: all 100ms;
    white-space: nowrap;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #ffffff;
  }
  &.active {
    span {
      color: #ff8a00;
    }
  }
`;
export const ContentSection = styled.div`
  width: 100%;
  /* background-color: blue; */
`;

export const HeaderIcon = styled(HeaderSVG)`
  position: relative;
  /* width: 60%; */
`;
export const SettingIcon = styled(SettingSVG)`
  position: relative;
  /* width: 20%; */
`;
export const MapIcon = styled(MapSVG)`
  position: relative;
  /* width: 20%; */
`;
export const HistoryIcon = styled(HistorySVG)`
  position: relative;
  /* width: 20%; */
`;

export const HeaderElement = styled.div`
  position: relative;
  width: 100%;
  height: 100%; 
  top: 0;
  display: flex;
  padding: 0px 40px;
  flex-direction: row;
    align-items: center;
    justify-content: space-between;
  z-index: 1200;
  background: #171725;
  box-shadow: 0px 4px 10px rgba(16, 16, 32, 0.5);
 box-sizing:border-box;
  a {
    text-decoration: none;
    /* font-family: "Roboto"; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.1px;
    color: #8f9bbc;
    cursor: pointer;
    &:hover {
      color: #a2afd4;
    }
  }
`;

export const IconBlock = styled.div`
    display: flex;

`;
export const PlaceBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
  div {
    display: flex;
    flex-direction: column;
    span:nth-child(1) {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      text-align: center;
      letter-spacing: 0.1px;
      color: #8f9bbc;
    }
    span:nth-child(2) {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */
      letter-spacing: 0.1px;
      color: #ffffff;
    }
  }
`;
export const MenuBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap:43px;
  .active {
    span {
      color: #ff8a00;
    }
    svg {
      fill: #ff8a00;
    }
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    svg {
      transition: all 500ms;
    }
    span {
      transition: all 500ms;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      text-align: center;
      letter-spacing: 0.1px;
      color: #8f9bbc;
    }

    &:hover {
      span {
        color: #ff8a00;
      }
      svg {
        fill: #ff8a00;
      }
    }
  }
`;
export const SystemBlock = styled.div`
  justify-content: center;
  display: flex;
  cursor: pointer;
  &:hover {
    span {
      color: #ff8a00;
    }
  }
  span {
    transition: all 500ms;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.1px;

    color: #8f9bbc;
  }
`;
export const LogoutBlock = styled.div`
  justify-content: center;
  display: flex;
  cursor: pointer;
  &:hover {
    span {
      color: #ff8a00;
    }
  }
  span {
    transition: all 500ms;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.1px;

    /* Orange */

    color: #ff8a00;
  }
`;
