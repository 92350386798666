/* eslint-disable max-len */

import React, { useEffect } from 'react';
import {
	Form as FormPhone, Input as InputPhone, Label as LabelPhone, Prefix as PrefixPhone,
} from 'components/forms/PhoneInput/styledComponent';
import { Form as FormText, Input as InputText, Label as LabelText } from 'components/forms/TextInput/styledComponent';
import { useForm } from 'react-hook-form';
import FooterBar from 'components/layout/FooterBar';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { GetBankCardsResponse, MyAccountInfo } from 'store/dto/dtos';
import { useNavigate } from 'react-router-dom';
import LoadingComponent from 'components/layout/Loading';
import tokenService from 'service/token.service';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {
	Body,
	BodyProfile,
	ButtonLists,
	Card,
	CardBlock,
	CardBlockElement,
	CardIcon,
	CloseSVG,
	Content,
	DeleteCard,
	ElementButtonList,
	FeedBackTitle,
	IconCard,
	Locked,
	LockIcon,
	Main, NumberCard, ProfileInput, Title, TitleCard, TitleCardBlock,
} from './styledComponent';

type FormData = {
	phone: string;
	displayName: string;
	email:string;
	licenseNumber:string;
  };
const ProfilePage: React.FC = () => {
	const {
		register, handleSubmit, formState: { errors }, setValue,
	} = useForm<FormData>();
	const [openModal, setOpenModal] = React.useState(false);
	const [deletedId, setDeletedId] = React.useState<string>('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [editProfile, setEditProfile] = React.useState(false);

	const accountData : MyAccountInfo = useSelector(selectors.data.getMyAccountInfo) || [];
	const cardData : GetBankCardsResponse = useSelector(selectors.data.getBankCards) || [];

	const onSubmit = handleSubmit((data) => {
		const { displayName, email, licenseNumber } = data;
		dispatch(actions.data.saga.asyncSetAccountInfo({ displayName, email, licenseNumber }));
		setEditProfile(false);
	});

	const setNavigate = (url = '', hash = '') => {
		navigate(`/${url}/${hash}`);
	};

	const cancelEdit = () => {
		setValue('phone', accountData.phone?.slice(2, accountData.phone.length));
		setValue('displayName', accountData.displayName);
		setValue('email', accountData.email);
		setValue('licenseNumber', accountData.licenseNumber);
		setEditProfile(false);
	};

	const logout = () => {
		navigate('/');
		tokenService.logout();
	};

	useEffect(() => {
		dispatch(actions.data.saga.asyncGetAcountInfo(''));
		dispatch(actions.data.saga.asyncGetBankCards(''));
	}, []);
	useEffect(() => {
		setValue('phone', accountData.phone?.slice(2, accountData.phone.length));
		setValue('displayName', accountData.displayName);
		setValue('email', accountData.email);
		setValue('licenseNumber', accountData.licenseNumber);
	}, [accountData]);

	const handleCloseModal = () => {
		setDeletedId('');
		setOpenModal(false);
	};

	const deleteTariffDialog = () => {
		if (deletedId) {
			dispatch(actions.data.saga.asyncDeleteBankCard({ id: deletedId as string }));
			setOpenModal(false);
		}
	};
	const handleOpenModal = (id:string) => {
		setDeletedId(id);
		setOpenModal(true);
	};

	return (
		<BodyProfile>
			<LoadingComponent loadingElement="loadingProfile" />
			<LoadingComponent loadingElement="loadingCards" />
			<LoadingComponent loadingElement="deleteBankCard" />
			<Main>

				<Content>
					<Title><span>Профиль</span></Title>
					<Body>
						<ProfileInput onSubmit={onSubmit}>

							<FormPhone>
								<LabelPhone htmlFor="input-field">Номер телефона</LabelPhone>
								<div className="div1" style={{ position: 'relative', display: 'flex' }}>
									<PrefixPhone style={{ width: '100%' }}>
										<InputPhone
											placeholder="Ваш номер телефона"
											autoComplete="off"
											readOnly
											{...register('phone')}
										/>

									</PrefixPhone>
									<Locked className="show">
										<LockIcon />
									</Locked>
								</div>
								{errors.phone && <p>Некорректный номер телефона</p>}

							</FormPhone>

							<FormText>
								<LabelText htmlFor="input-field">Ваше ФИО</LabelText>
								<div className="div1" style={{ position: 'relative', display: 'flex' }}>
									<InputText
										type="text"
										autoComplete="off"
										readOnly={!editProfile}
										placeholder="Полное имя"
										{...register('displayName', {
											required: true,
											maxLength: 50,
											minLength: 3,
										})}
									/>
									<Locked className={!editProfile ? 'show' : 'out'}>
										<LockIcon />
									</Locked>
								</div>
								{errors.displayName && <p>Некорректное имя</p>}
							</FormText>

							<FormText>
								<LabelText>E-mail</LabelText>
								<div className="div1" style={{ position: 'relative', display: 'flex' }}>
									<InputText
										className="locked"
										type="text"
										readOnly={!editProfile}
										autoComplete="off"
										placeholder="Ваш email"
										{...register('email', {
											required: true,
											// eslint-disable-next-line max-len
											pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])/,
										})}
									/>
									<Locked className={!editProfile ? 'show' : 'out'}>
										<LockIcon />
									</Locked>
								</div>
								{errors.email && <p>Некорректный email</p>}
							</FormText>

							<FormText>
								<LabelText htmlFor="input-field">Гос Номер</LabelText>
								<div className="div1" style={{ position: 'relative', display: 'flex' }}>
									<InputText
										type="text"
										autoComplete="off"
										readOnly={!editProfile}
										placeholder="X777XX777"
										{...register('licenseNumber', {
											required: true,
											maxLength: 9,
											minLength: 8,
											pattern: /^[\u0410,\u0412,\u0415,\u041A,\u041C,\u041D,\u041E,\u0420,\u0421,\u0422,\u0423,\u0425]{1}[0-9]{3}[\u0410,\u0412,\u0415,\u041A,\u041C,\u041D,\u041E,\u0420,\u0421,\u0422,\u0423,\u0425]{2}[0-9]{2,3}$/i,
											// pattern: /^[A,\u0410,B,\u0412,E,\u0415,K,\u041A,M,\u041C,H,\u041D,O,\u041E,P,\u0420,C,\u0421,T,\u0422,У,\u0423,X,\u0425]{1}[0-9]{3}[A,\u0410,B,\u0412,E,\u0415,K,\u041A,M,\u041C,H,\u041D,O,\u041E,P,\u0420,C,\u0421,T,\u0422,У,\u0423,X,\u0425]{2}[0-9]{2,3}$/i,
										})}
									/>
									<Locked className={!editProfile ? 'show' : 'out'}>
										<LockIcon />
									</Locked>
								</div>
								{errors.licenseNumber && (
									<p>
										Формат номера должен быть X777XX777. Используйте русские буквы
										A B E K M H O P C T У X
									</p>
								)}
							</FormText>

							<ButtonLists>
								{editProfile
									? (
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<ElementButtonList onClick={() => onSubmit()} color="#03b700">Сохранить изменения</ElementButtonList>
											<ElementButtonList color="#FF234A" onClick={() => cancelEdit()}>Отменить</ElementButtonList>
										</div>
									)
									: <ElementButtonList color="#fff" onClick={() => setEditProfile(true)}>Редактировать профиль</ElementButtonList>}
								<ElementButtonList onClick={() => setNavigate('reset-password', 'first-step')} color="#fff">Изменить пароль</ElementButtonList>
								<ElementButtonList color="#fff" onClick={() => logout()}>Выйти из аккаунта</ElementButtonList>
								<ElementButtonList color="#FF234A">Удалить аккаунт</ElementButtonList>
							</ButtonLists>

						</ProfileInput>
						{cardData.cards?.length && (
							<CardBlock>
								<TitleCardBlock>
									Оплата
								</TitleCardBlock>
								<CardBlockElement>

									{cardData.cards?.map((item) => (
										<Card>
											<TitleCard><span>Банковская карта</span></TitleCard>
											<IconCard><CardIcon /></IconCard>
											<NumberCard><span>{item.maskedPan ? item.maskedPan : '0000 0000 0000 0000'}</span></NumberCard>
											<DeleteCard onClick={() => handleOpenModal(item.id)}>
												<CloseSVG />
											</DeleteCard>

										</Card>
									))}
									{/*
								<Card>
									<TitleCard><span>Банковская карта</span></TitleCard>
									<IconCard><CardIcon /></IconCard>
									<NumberCard><span>4455 6789 1234 4567</span></NumberCard>
									<DeleteCard>
										<CloseSVG />
									</DeleteCard>

								</Card> */}
								</CardBlockElement>
								<FeedBackTitle style={{ display: 'none' }}>
									Добавить карту
								</FeedBackTitle>
							</CardBlock>
						)}
					</Body>
				</Content>

			</Main>
			<div style={{ position: 'relative', width: '100%', bottom: '0' }}>
				<FooterBar />
			</div>

			<Dialog
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Вы действительно хотите удалить карту?
				</DialogTitle>
				<DialogActions>
					<Button onClick={handleCloseModal}>Нет</Button>
					<Button onClick={deleteTariffDialog} autoFocus>
						Да
					</Button>
				</DialogActions>
			</Dialog>
		</BodyProfile>
	);
};

export default ProfilePage;
