/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import baseApiService from 'service/base-api.service';
import { put, call } from 'redux-saga/effects';
import {
	AppVersion, CreateAppVersionRequest, GetAdminCurrentAppVersionRequest,
	GetAppVersionRequest, GetAppVersionsRequest, GetAppVersionsResponse,
} from 'store/dto/dtos';
import { IAction } from 'store/utils';
import actions from 'store/actions';

const getAppVersionsCall = (opts: {
    page: number;
    items: number;
    sort: string;
    filter: string;
	from:string;
	to:string;
}): Promise<GetAppVersionsResponse> => {
	const request = new GetAppVersionsRequest();
	request.filter = opts.filter;
	request.items = opts.items;
	request.page = opts.page;
	request.sort = opts.sort;
	request.from = opts.from;
	request.to = opts.to;
	return baseApiService.get(request);
};

const getAppVersionCall = (opts: {id:string}): Promise<AppVersion> => {
	const request = new GetAppVersionRequest();
	request.id = opts.id;
	return baseApiService.get(request);
};
const getCurrentAppVersionCall = (opts: {id?:string}): Promise<AppVersion> => {
	const request = new GetAdminCurrentAppVersionRequest();
	return baseApiService.get(request);
};

const createAppVersionCall = (opts: {item:AppVersion}): Promise<AppVersion> => {
	const request = new CreateAppVersionRequest();
	request.entity = opts.item;
	return baseApiService.post(request);
};

export function* getAppVersionsRequest(action: IAction<{ page: number;
    items: number;
    sort: string;
    filter: string;
	from:string;
	to:string;
    callbackAppVersions?:(item:GetAppVersionsResponse) => void;}>) {
	try {
		const {
			payload: {
				page, items, sort, filter, from, to, callbackAppVersions,
			},
		} = action;
		yield put(actions.app.saga.asyncAddLoading('loadingSystemStatuses'));
		const result: GetAppVersionsResponse = yield call(getAppVersionsCall, {
			page, items, sort, filter, from, to,
		});
		yield callbackAppVersions?.(result);
		yield put(actions.app.saga.asyncRemoveLoading('loadingSystemStatuses'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingSystemStatuses'));

		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}

export function* getAdminCurrentAppVersionRequest(action: IAction<{
    callbackCurrentAppVersions?:(item:AppVersion) => void;}>) {
	try {
		const {
			payload: { callbackCurrentAppVersions },
		} = action;
		yield put(actions.app.saga.asyncAddLoading('GetAdminCurrentAppVersionRequest'));
		const result: AppVersion = yield call(getCurrentAppVersionCall, {

		});
		yield callbackCurrentAppVersions?.(result);
		yield put(actions.app.saga.asyncRemoveLoading('GetAdminCurrentAppVersionRequest'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('GetAdminCurrentAppVersionRequest'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
export function* createAppVersionRequest(action: IAction<{item: AppVersion;
    callbackCreateAppVersion?:(item:AppVersion) => void;}>) {
	try {
		const {
			payload: { item, callbackCreateAppVersion },
		} = action;
		yield put(actions.app.saga.asyncAddLoading('CreateAppVersionRequest'));
		const result: AppVersion = yield call(createAppVersionCall, {
			item,
		});
		yield callbackCreateAppVersion?.(result);
		yield put(actions.app.saga.asyncRemoveLoading('CreateAppVersionRequest'));
		yield put(actions.app.saga.asyncInitNotification({
			messages: 'Добавлена новая версия приложений!',
			type: 'warm',
		}));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('CreateAppVersionRequest'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
