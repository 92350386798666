import { device } from 'common/devices';
import styled from 'styled-components';

export const Main = styled.div.attrs(
	(props: { isLogin: boolean }) => props,
)`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  padding-top: ${(props) => (props.isLogin ? '60px' : '140px')};
  gap: 24px;
  min-height: ${(props) => (props.isLogin ? 'calc(100vh - 81px)' : 'calc(100vh)')};
  /* min-height: calc(100vh - 81px); */
  @media ${device.tablet600} {
    min-height: 100vh;
    padding-top: 0px;
  }
`;
export const Body = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction:column;
  padding:0px 80px;
  @media ${device.tablet600} {
    padding:0px 16px;
    /* justify-content: center;   */
  }
  
`;
export const HeaderElement = styled.div`
  position: relative;
  top: 0;
`;
export const FooterElement = styled.div`
  position: relative;
  bottom: 0;

  @media ${device.tablet600} {
    padding:0px 16px;
  }
`;
export const Title = styled.div`
  margin-bottom: 24px;
  span {
    font-style: normal; 
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #ffffff;
  }

  @media ${device.tablet600} {
    /* display:none; */
    margin-top: 68px;
    span {
      font-size: 32px;
    }
  }
`;
