/* eslint-disable prefer-regex-literals */
/* eslint-disable no-useless-escape */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button as ButtonAntd, Modal } from 'antd';
import { Button } from 'components/forms/Button';
import useWindowDimensions from 'common/useWindowDimensions';
import SearchInput from 'components/forms/SearchInput';
import LoadingComponent from 'components/layout/Loading';
// import LoadingComponent from 'components/layout/Loading';
import React from 'react';
// { useEffect }

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import actions from 'store/actions';
import {
	GetActiveOrderResponse,
	GetParkingSpacesResponse, GetParkingsResponse, OrderStatusType, Parking, ParkingSpace,
	ParkingSpaceStatus, ParkingSpaceStatusType, SystemStatus,
} from 'store/dto/dtos';
import selectors from 'store/selectors';
import { Form, Input, Prefix } from 'components/forms/SearchInput/styledComponent';
import {
	SectionContent,
	ContentSearch,
	HeaderSearch,
	ParkingPlace,
	ControlElement,
	EmptySearch,
	// SectionContentMobile,
} from './styledComponent';

interface IMapSearchElement {
	setParkingData:(item:Parking[]) => void;
    selectedParkingSpace:(item:ParkingSpace) => void;

}

const SearchMapNewElement: React.FC<IMapSearchElement> = (props) => {
	const {
		setParkingData, selectedParkingSpace,
	} = props;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const parkingData: GetParkingsResponse = useSelector(selectors.data.getParking) || [];
	const activeOrder: GetActiveOrderResponse = useSelector(selectors.data.getActiveOrder) || null;
	const systemStatus: SystemStatus = useSelector(selectors.data.getCurrentSystemStatus);
	const [parkingSpaces, setParkingSpaces] = React.useState<GetParkingSpacesResponse>(new GetParkingSpacesResponse());
	const [activeParking, setActiveParking] = React.useState<ParkingSpace>(new ParkingSpace());
	const [showBookingControl, setShowBookingControl] = React.useState(false);
	const [query, setQuery] = React.useState('');
	const [displayMessage, setDisplayMessage] = React.useState('');
	const [isModalPaidOpen, setIsModalPaidOpen] = React.useState(false);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [clickInput, setClickInput] = React.useState<boolean>(false);
	const [showEmptySearch, setShowEmptySearch] = React.useState(false);

	const updateData = () => {
		const callbackFindParkingSpaces = (item:GetParkingSpacesResponse) => {
			setParkingSpaces(item);
			if (item.itemsPerPage === 0) {
				setShowEmptySearch(true);
			} else {
				setShowEmptySearch(false);
			}
		};
		dispatch(
			actions.data.saga.asyncFindParkingSpaces({
				items: 0,
				filter: displayMessage,
				page: 0,
				sort: '',
				callbackFindParkingSpaces,
			}),
		);
	};

	const onChangeSearchInput = (input: string) => {
		setQuery(input);
	};

	const activatedParking = (item:ParkingSpace) => {
		if (item.status.status === ParkingSpaceStatusType.Free) {
			if (activeOrder.hasActiveOrder) {
				showModalPaid();
			} else {
				setActiveParking(item);
				selectedParkingSpace(item);
				setShowBookingControl(true);
			}
		} else {
			setActiveParking(new ParkingSpace());
			setShowBookingControl(false);
		}
	};

	const showModalPaid = () => {
		setIsModalPaidOpen(true);
	};

	const handleOkPaid = () => {
		dispatch(actions.app.setShowBooking(true));
		// navigate('/map/history');
		setIsModalPaidOpen(false);
	};

	const handleCancelPaid = () => {
		setIsModalPaidOpen(false);
	};

	const showModal = () => {
		const callback = (items:SystemStatus) => {
			if (items.orderDisabled) {
				dispatch(actions.app.setShowWarningPopup(true));
			} else {
				setIsModalOpen(true);
			}
		};

		dispatch(actions.data.saga.asyncGetCurrentSystemStatus({ callback }));
	};

	const setBookingParking = () => {
		dispatch(actions.data.setCurrentParkingSpace(activeParking));
		dispatch(actions.parkingManipulation.saga.asyncActiveAndBookingParkingSpace({ id: activeParking.id }));
		setShowBookingControl(false);
		// dispatch(actions.app.setShowBooking(true));
	};

	const handleOk = () => {
		setBookingParking();
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	React.useEffect(() => {
		const timeOutId = setTimeout(() => {
			setDisplayMessage(query);
		}, 500);
		return () => clearTimeout(timeOutId);
	}, [query]);

	React.useEffect(() => {
		setShowBookingControl(false);
		setActiveParking(new ParkingSpace());
		updateData();
	}, [displayMessage]);

	React.useEffect(() => {
		setParkingData(parkingData.items);
	}, [parkingData]);

	React.useEffect(() => {
		dispatch(actions.data.saga.asyncGetActiveOrder(''));
	}, []);

	const getColor = (status:ParkingSpaceStatus) => {
		switch (status.status) {
		case (ParkingSpaceStatusType.Free):
			return '#33CC33';
		case (ParkingSpaceStatusType.Busy):
			return '#E74A4F';
		case (ParkingSpaceStatusType.Booked):
			return '#E74A4F';
		case (ParkingSpaceStatusType.OfflineClosed):
			return '#E74A4F';
		case (ParkingSpaceStatusType.OfflineOpen):
			return '#E74A4F';
		default:
			return '#E74A4F';
		}
	};

	React.useEffect(() => {
		dispatch(
			actions.data.saga.asyncGetParkings({
				items: 0,
				filter: '',
				page: 0,
				sort: '',
			}),
		);
	}, []);

	React.useEffect(() => {
		setParkingData(parkingData.items);
	}, [parkingData]);

	const changeSearch = (e:any) => {
		const re = /^[0-9\b]+$/;
		if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length <= 3) {
			onChangeSearchInput(e.target.value);
		}
	};

	return (
		<SectionContent>

			<HeaderSearch>
				{/* <SearchInput
					onChange={onChangeSearchInput}
					name="field_search"
					placeholder="Поиск"
					value={query}
				/> */}

				<Form>

					<Prefix>
						<Input
							id="input-search"
							autocomplete="nope"
							type="text"
							onChange={(e:any) => changeSearch(e)}
							onKey
							name="field_search"
							placeholder="Поиск"
							value={query}
							style={{ pointerEvents: 'all' }}
							autofocus
							onBlur={() => {
								setClickInput(false);
							}}
							onClick={() => {
								setClickInput(true);
							}}
						/>
					</Prefix>
				</Form>
			</HeaderSearch>

			<ContentSearch className={showBookingControl ? 'active' : 'out'}>
				{ displayMessage.length !== 0 && parkingSpaces.items?.map((item, index) => (

					<ParkingPlace
						key={item.id}
						borderTop={index === 0 ? '2px dashed #333C56' : 'none'}
						colorBackground={getColor(item.status)}
						onClick={() => activatedParking(item)}
						style={{ color: activeParking?.id === item.id ? '#fff' : 'black' }}
					>
						<span>{item.number}</span>
						<span>{item.status.status === ParkingSpaceStatusType.Free ? 'Свободно' : ' Парковка занята'}</span>
					</ParkingPlace>
				))}
				{displayMessage.length === 0 && <span style={{ color: '#fff' }}>Начните вводить номер замка в поисковую строку.</span>}
				{(showEmptySearch)
					&& <EmptySearch><div>К сожалению ничего не найдено, проверьте номер парковки и повторите поиск</div></EmptySearch>}
			</ContentSearch>

			<ControlElement className={showBookingControl ? 'active' : 'out'}>
				<Button
					className={(systemStatus.orderDisabled) ? 'disabled-button' : ''}
					onClick={() => { showModal(); }}
				>
					Открыть замок

				</Button>
			</ControlElement>

			<Modal
				title="Внимание"
				open={isModalPaidOpen}
				onOk={handleOkPaid}
				onCancel={handleCancelPaid}
				centered
				footer={[
					<ButtonAntd key="submit" type="primary" onClick={handleOkPaid}>
						Показать
						{' '}
						{activeOrder?.order?.status === OrderStatusType.Active ? ' активную парковку ' : 'не оплаченный заказ '}
					</ButtonAntd>,
					<ButtonAntd key="back" onClick={handleCancelPaid}>
						Закрыть
					</ButtonAntd>,

				]}
			>
				<p>
					{activeOrder?.order?.status === OrderStatusType.Active ? 'Завершите активную парковку' : 'Оплатите предыдущую парковку'}
					, прежде чем начинать новую.
				</p>
			</Modal>

			<Modal
				okText="Подтвердить"
				title="Внимание"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<p>После нажатия на кнопку &quot;Подтвердить&quot; замок сразу откроется и начнется оплата.</p>

			</Modal>
		</SectionContent>
	);
};

export default SearchMapNewElement;
