import React from 'react';
import {
	DescriptionElement, ElementList, PlusSVG, TitleElement,
} from './styledComponent';

interface IElementFAQ {
	title:string;
	description:string;
	inner?: boolean;
}
const ElementFAQMain: React.FC<IElementFAQ> = (props) => {
	const { title, description, inner } = props;
	const [clickEvent, setClickEvent] = React.useState(true);

	return (
		<ElementList className={`${clickEvent ? '' : 'show'}`} onClick={() => setClickEvent(!clickEvent)}>
			<TitleElement>
				<span>{title}</span>
				<PlusSVG onClick={() => setClickEvent(!clickEvent)} />
			</TitleElement>
			{inner ? <DescriptionElement dangerouslySetInnerHTML={{ __html: description || '' }} />
				: <DescriptionElement>{description}</DescriptionElement>}
		</ElementList>

	);
};
export default ElementFAQMain;
