import { device } from 'common/devices';
import styled from 'styled-components';

export const SectionContent = styled.div`
  /* direction: ltr; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 1.5rem; */
  overflow: hidden;
  box-sizing: border-box;
  @media ${device.tablet600} {
    height:45vh;
    padding-top: 64px;
    bottom: 64px;
    transition: all 250ms;
    transform: translateY(1000%);
    &.show {
      transform: translateY(0%);
    }
    &.out {
      transform: translateY(300%);
      /* transform: translateY(0%); */
    }
    z-index: 1100;
    position: absolute;
    background-color: #1d2335;
  }
`;

export const HeaderSearch = styled.div`
  width: 100%;
  /* height: 90px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #1d2335;
  box-sizing: border-box;
  padding: 56px 40px 24px 40px;
  @media ${device.tablet600} {
    padding: 0px 19px;
    z-index: 1101;
    position: fixed;
    transition: all 250ms;
    background: none;
    &.show {
      bottom: calc(45vh - 12px);
    }
    &.out {
      bottom: 88px;
    }
  }
`;
export const ContentSearch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 20px 40px;
  box-sizing: border-box;
  overflow: auto;

  &.active {
    height: calc(100vh - 81px - 128px - 94px)

  }
  &.out {
    height: calc(100vh - 81px - 128px )
  }

  @media ${device.tablet600} {
    padding: 40px 19px 5px 19px;
  }
`;

export const ParkingPlace = styled.div.attrs(
	(props: { colorBackground: string, borderTop: string }) => props,
)`
  cursor: pointer;
  flex: 0 0 auto;
  padding: 8px 12px;
  box-sizing: border-box;
  width: 100%;
  /* height: 77px; */
  background: ${(props) => props.colorBackground};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: black;
  /* position:relative; */
  span {
    text-transform: lowercase;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.1px;
  }
  span:nth-child(2) {
    font-size: 16px;
  }
  &.actived {
    background: #33cc33;
    span {
      color: #fff;
    }
  }
  /* 
  &.actived:after{
    z-index:3;
    border-top: 2px dashed #ff8a00 !important;
    border-right: 2px dashed #ff8a00 !important;
    border-bottom: 2px dashed #ff8a00 !important;
  } */

  &:hover {
    &:after {
      z-index: 2;
      border-top: 2px dashed #a4a4a4;
      border-right: 2px dashed #a4a4a4;
      border-bottom: 2px dashed #a4a4a4;
    }
  }
`;
export const ControlElement = styled.div`
  transform: translateY(1000%);

  transition: all 250ms;
  width: 402px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: #1d2335;
  box-shadow: 0px -4px 10px rgba(16, 16, 32, 0.5);
  position: fixed;
  padding: 24px 40px;
  box-sizing: border-box;
  left: 0;
  bottom: 0;

  button {
    border-radius: 100px;
  }
  &.active {
    transform: translateY(0%);
  }

  .disabled-button {
    background: #595959;
  }
  @media ${device.tablet600} {
    height: 100px;
    bottom: 64px;
    /* margin-top:64px; */
  }
`;

export const EmptySearch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
    justify-content: flex-start;
  width: 100%;
  height: 100%;
  color:#fff;
  div {
    /* padding: 24px 12px; */
    /* background-color: #171725; */
  }
`;
