/* eslint-disable max-len */
import { INotification } from 'store/interfaces/App.Interface';
import types from 'store/types';
import { actionCreator } from 'store/utils';

export default {
	setGlobalLoading: actionCreator<{isLoading: boolean} | undefined>(types.app.GLOBAL_LOADING),
	setCurrentLoadings: actionCreator<string[]>(types.app.SET_CURRENT_LOADINGS),
	setNotification: actionCreator<INotification[]>(types.app.SET_NOTIFICATION),
	setSuccessfulApi: actionCreator<string[]>(types.app.SET_SUCCESSFUL_API),
	setShowBooking: actionCreator<boolean>(types.app.SET_SHOW_BOOKING),
	setShowTariffEditForm: actionCreator<boolean>(types.app.SET_SHOW_TARIFF_EDIT_FORM),
	setShowAdminOrderEditForm: actionCreator<boolean>(types.app.SET_SHOW_ADMIN_ORDER_EDIT_FORM),
	setShowAdminOrderHistoryForm: actionCreator<boolean>(types.app.SET_SHOW_ADMIN_ORDER_HISTORY_FORM),
	setShowWarningPopup: actionCreator<boolean>(types.app.SET_SHOW_WARNING_POPUP),
	saga: {
		asyncAddLoading: actionCreator<string>(types.app.saga.ASYNC_ADD_LOADING),
		asyncRemoveLoading: actionCreator<string>(types.app.saga.ASYNC_REMOVE_LOADING),
		asyncSendSupport: actionCreator<{phone: string, message: string, name: string, email:string }>(types.app.saga.ASYNC_SEND_SUPPORT),
		asyncAddNotification: actionCreator<INotification>(types.app.saga.ASYNC_ADD_NOTIFICATION),
		asyncRemoveNotification: actionCreator<string>(types.app.saga.ASYNC_REMOVE_NOTIFICATION),
		asyncInitNotification: actionCreator<{ messages: string, type: string}>(types.app.saga.ASYNC_INIT_NOTIFICATION),
	},
};
