import IState from 'store/interfaces/Store.Interface';
import appSelector from './app';
import authorizationSelector from './authorization';
import dataSelector from './data';
import userSelector from './user';

export default {
	getRoot: (state: IState) => state,
	app: appSelector,
	authorization: authorizationSelector,
	data: dataSelector,
	user: userSelector,
};
