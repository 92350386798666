import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { Button } from 'components/forms/Button';
import {
	CloseIcon,
	Content,
	EditForm, Footer, Title,
} from './styledComponent';

const WarningPopup: React.FC = () => {
	const dispatch = useDispatch();
	const show: boolean = useSelector(selectors.app.getShowWarning);

	const close = () => {
		sessionStorage.setItem('showWarningPopup', '1');
		dispatch(actions.app.setShowWarningPopup(false));
	};

	return (

		<EditForm className={show ? 'show' : 'out'}>
			<CloseIcon onClick={() => close()} />
			<Title><span>Уважаемые пользователи!</span></Title>
			<Content>
				<span>В настоящее время Система находится на техническом обслуживании.</span>
				<br />
				<span>Парковка осуществляется в общем порядке. Оплата не требуется.</span>
				<br />
				<span>Приносим извинения за доставленные неудобства.</span>
			</Content>
			<Footer>
				<Button onClick={() => close()}>Закрыть</Button>
			</Footer>
		</EditForm>

	);
};

export default WarningPopup;
