/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { Button } from 'components/forms/Button';
import LoadingComponent from 'components/layout/Loading';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import actions from 'store/actions';
import { OrderStatusType, SystemStatus } from 'store/dto/dtos';

import selectors from 'store/selectors';
import {
	SectionContent, BackArrow,
	BackArrowIcon, TitleBooking,
	SubTitleBooking, ControlElement, Main, TimerSection, TimerIcon, TextTimer, RefreshIcon, TopElement,
} from './styledComponent';

const BookingParkingElement: React.FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const parkingSpaceData = useSelector(selectors.data.getActiveOrder).order || [];
	const paidLink : string = useSelector(selectors.data.getPaindLink);
	const showBooking: boolean = useSelector(selectors.app.getShowBooking);
	const systemStatus: SystemStatus = useSelector(selectors.data.getCurrentSystemStatus);
	const [timer, setTimer] = React.useState<{minutes:number, hours:number}>({
		minutes: moment.duration(moment.utc().diff(moment.utc(parkingSpaceData.created))).minutes(),
		hours: moment.duration(moment.utc().diff(moment.utc(parkingSpaceData.created))).hours(),
	});

	const clickBack = () => {
		dispatch(actions.app.setShowBooking(false));
		// navigate('/map/search');
	};
	const paidOrder = () => {
		const callback = (items:SystemStatus) => {
			if (items.paymentDisabled) {
				dispatch(actions.app.setShowWarningPopup(true));
			} else {
				dispatch(actions.data.saga.asyncSetPaidOrder({
					orderId: parkingSpaceData.id,
					returlUrl: `${window.location.href}`,
				}));
			}
		};

		dispatch(actions.data.saga.asyncGetCurrentSystemStatus({ callback }));
	};
	const refresh = () => {
		dispatch(actions.data.saga.asyncGetActiveOrder(''));
	};
	useEffect(() => {
		if (parkingSpaceData.status === OrderStatusType.Active || parkingSpaceData.status === OrderStatusType.Booked) {
			const intervalTimer = setInterval(() => {
				const duration = moment.duration(moment.utc().diff(moment.utc(parkingSpaceData.created)));
				setTimer({ hours: duration.hours(), minutes: duration.minutes() });
			}, 10000);
			return () => clearInterval(intervalTimer);
		}
		return () => {
			console.log('Cleanup');
		};
	}, [timer]);

	useEffect(() => {
		setTimer({
			minutes: moment.duration(moment.utc().diff(moment.utc(parkingSpaceData?.created))).minutes(),
			hours: moment.duration(moment.utc().diff(moment.utc(parkingSpaceData?.created))).hours(),
		});
	}, []);

	useEffect(() => {
		if (paidLink) {
			// eslint-disable-next-line no-unused-expressions
			const w = window.open(paidLink, '_parent');
			w?.addEventListener('close', () => {
				console.log('closed');
			});
		}
	}, [paidLink]);

	return (
		// <Main className="show">
		<Main className={showBooking ? 'show' : 'hidden'}>
			<LoadingComponent loadingElement="activateAndBookingParking" />
			<SectionContent>
				<TopElement>
					<BackArrow onClick={() => clickBack()}>
						<BackArrowIcon />
						<span>Назад</span>

					</BackArrow>
					<RefreshIcon onClick={() => refresh()} />
				</TopElement>
				<TitleBooking>
					<span>{parkingSpaceData.number}</span>
					<span>{parkingSpaceData.address}</span>
				</TitleBooking>
				<SubTitleBooking>
					{/* <span>{parkingSpaceData.number}</span> */}
					{/* <span>Тарификация начинается с момента бронирования</span> */}
					<div className="block-tariff">
						<div>
							<span>Начало</span>
							<span>{moment(parkingSpaceData.created).format('HH:mm')}</span>
						</div>
						<div>
							<span>Тариф</span>
							<span>почасовой</span>
						</div>
						<div>
							<span>К оплате</span>
							<span>
								{parkingSpaceData.amount}
								₽
							</span>

						</div>
					</div>
				</SubTitleBooking>
				<TimerSection>
					<TextTimer>
						<span>Стоянка</span>
						{parkingSpaceData.status === OrderStatusType.Active ? <span>{`${timer?.hours}ч ${timer?.minutes}мин`}</span>
							: (
								<span>
									{` ${moment.duration(parkingSpaceData?.duration).hours()}ч ${moment.duration(parkingSpaceData?.duration).minutes()}мин`}
								</span>
							)}
					</TextTimer>
					<TimerIcon className={parkingSpaceData.status === OrderStatusType.Active ? 'active' : ''} />
				</TimerSection>
			</SectionContent>
			<ControlElement>
				{/* <Button
					disabled
					style={{
						width: '100%', backgroundColor: '#999999', color: '#fff', fontSize: '14px',
					}}
				>
					Продлить парковку
				</Button>
				<Button style={{
					width: '100%', backgroundColor: '#0779fd', color: '#fff', fontSize: '14px',
				}}
				>
					Завершить парковку
				</Button> */}

				{parkingSpaceData.status === OrderStatusType.NotPaid
					? (
						<>
							<span>
								Парковка закрыта, необходима оплата
							</span>
							<Button className={(systemStatus.paymentDisabled) ? 'disabled-button' : ''} style={{ width: '100%' }} onClick={() => paidOrder()}>Оплатить</Button>
						</>
					)
					: (parkingSpaceData.status === OrderStatusType.PaymentProcessing
						? (
							<span>
								Парковка находится в процессе оплаты. Завершите оплату
							</span>
						) : (
							<span>
								Парковка завершится автоматически
								сразу после того как вы уедете
							</span>
						))}
			</ControlElement>
		</Main>

	);
};

export default BookingParkingElement;
