import { User } from 'store/dto/hub.dtos';

const tokenService = {

	get bearerToken() {
		const value = sessionStorage.getItem('bearer_token');
		return value;
	},
	get refreshToken() {
		const value = sessionStorage.getItem('refresh_token');
		return value;
	},
	get customerId() {
		const value = sessionStorage.getItem('customerId');
		return value;
	},
	get permissions() {
		const value = sessionStorage.getItem('permissions');
		return value;
	},
	get user() {
		const value = sessionStorage.getItem('user');
		return value;
	},

	get isLoggedIn() {
		return sessionStorage.getItem('bearer_token') !== null;
	},

	setToken(bearerToken:string, refreshToken:string) {
		localStorage.removeItem('bearer_token');
		localStorage.removeItem('refresh_token');

		sessionStorage.removeItem('bearer_token');
		sessionStorage.removeItem('refresh_token');

		sessionStorage.setItem('bearer_token', bearerToken);
		sessionStorage.setItem('refresh_token', refreshToken);
	},

	setCustomer(id:string) {
		sessionStorage.removeItem('customerId');
		sessionStorage.setItem('customerId', id);
	},
	setPermissions(permissions:string[]) {
		sessionStorage.removeItem('permissions');
		sessionStorage.setItem('permissions', JSON.stringify(permissions));
	},

	setUser(user:User) {
		sessionStorage.removeItem('user');
		sessionStorage.setItem('user', JSON.stringify(user));
	},

	logout() {
		localStorage.removeItem('bearer_token');
		localStorage.removeItem('refresh_token');

		sessionStorage.removeItem('bearer_token');
		sessionStorage.removeItem('refresh_token');

		sessionStorage.removeItem('permissions');
		localStorage.removeItem('permissions');

		sessionStorage.removeItem('customerId');
		localStorage.removeItem('customerId');

		sessionStorage.removeItem('user');
		localStorage.removeItem('user');
	},
};
export default tokenService;
