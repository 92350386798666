import { combineReducers } from 'redux';
import IAppState from 'store/interfaces/App.Interface';
import { IAuthorizationState } from 'store/interfaces/Authorization.Interface';

import IUserState from 'store/interfaces/User.Interface';
import IDataState from 'store/interfaces/Data.Interface';
import authorizationReducer from './authorization';
import appReducer from './app';
import userReducer from './user';
import dataReducer from './data';

export interface IState {
    authorization: IAuthorizationState;
    user: IUserState;
    app:IAppState;
    data:IDataState;
}

const rootReducer = combineReducers<IState>({
	authorization: authorizationReducer,
	user: userReducer,
	app: appReducer,
	data: dataReducer,

});

export default rootReducer;
