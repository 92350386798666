/* eslint-disable no-unused-expressions */
import { Button } from 'components/forms/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	ButtonSection, Main, ResultElement, SuccessIcon, TitleResult, ErrorIcon,
} from './styledComponent';

interface IResultResetPasswordPage {
	success:boolean;
}

const ResultResetPasswordPage: React.FC<IResultResetPasswordPage> = (props) => {
	const { success } = props;
	const navigate = useNavigate();
	return (
		<Main>
			<ResultElement>
				{success ? <SuccessIcon /> : <ErrorIcon />}
				<TitleResult>
					{success ? <span>Пароль изменен</span> : <span>Ошибка</span>}
				</TitleResult>
				<ButtonSection>

					<Button onClick={() => navigate('/profile')}> Вернуться в профиль</Button>
					{!success && <Button onClick={() => navigate('/reset-password/first-step')}> Попробовать сначала</Button>}
				</ButtonSection>
			</ResultElement>
		</Main>
	);
};

export default ResultResetPasswordPage;
