/* eslint-disable no-undef */
/* eslint-disable no-tabs */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import {
	GetAdjustmentStatResponse,
} from 'store/dto/dtos';
import TableElement from 'components/elements/TableElement';
import { ITypeMenuAction, Order, TypeMenuAction } from 'components/elements/TableElement/interface';
import dayjs from 'common/utils/dayjs';
import 'dayjs/locale/ru';
import {
	Select, DatePicker, Button as ButtonAntd, Tooltip,
} from 'antd';
import {
	ReloadOutlined, DownloadOutlined,
} from '@ant-design/icons';
import {
	GetParkingSpacesResponse, GetParkingsResponse,
} from 'store/dto/parking.dtos';
import { Customer } from 'store/dto/hub.dtos';
import tokenService from 'service/token.service';
// eslint-disable-next-line import/no-unresolved
import errorApiService from 'service/error.service';
import {
	Body,
	Title,
	MainSection,
	NameTitle,
	ControlElement,

} from './styledComponent';

const { RangePicker } = DatePicker;
const { Option } = Select;

const LockReportPage: React.FC = () => {
	const dispatch = useDispatch();
	// const [valueParkingSpaces, setValueParkingSpaces] = React.useState<string>();
	// const [valueParkings, setValueParkings] = React.useState<string>();

	const [parkingSpaceData, setParkingSpaceData] = React.useState<GetParkingSpacesResponse>(new GetParkingSpacesResponse());
	const [parkingsData, setParkingsData] = React.useState<GetParkingsResponse>(new GetParkingsResponse());
	const [adjustmentStatData, setAdjustmentStatData] = React.useState<GetAdjustmentStatResponse>(new GetAdjustmentStatResponse());
	const customer: Customer = useSelector(selectors.authorization.getCustomer) || [];

	const [paramsControlTable, setParamsControlTable] = React.useState<{filter:string, parkingId:any, parkingSpaceId:any, to:any, from:any}>(
		{
			filter: '', parkingId: null, parkingSpaceId: null, to: '', from: '',
		},
	);
	const [paramsPage, setParamsPage] = React.useState<{order: Order;
		orderBy: string;
		page:number;
		rowsPerPage:number; }>(
			{
				order: 'asc', orderBy: '', page: 0, rowsPerPage: 25,
			},
		);

	React.useEffect(() => {
		updateData();
	}, [paramsPage, paramsControlTable]);
	React.useEffect(() => {
		updateParkingSpaces('');
		updateParkings('');
	}, []);

	const updateData = () => {
		const callbackAdjustmentStat = (valueReq: GetAdjustmentStatResponse) => {
			setAdjustmentStatData(valueReq);
		};

		dispatch(actions.data.saga.asyncGetAdjustmentStat(
			{
				items: paramsPage.rowsPerPage,
				filter: paramsControlTable.filter,
				page: paramsPage.page,
				sort: `${paramsPage.orderBy} ${paramsPage.order}`,
				from: paramsControlTable.from,
				to: paramsControlTable.to,
				parkingId: paramsControlTable.parkingId,
				parkingSpaceId: paramsControlTable.parkingSpaceId,
				callbackAdjustmentStat,

			},
		));
	};

	const updateApiUrl = () => {
		if (localStorage.getItem('apiUrl') === null) {
			if (window.location.hostname === 'krasparking.admkrsk.ru') {
				console.log('данные взяты cuber');
				return 'https://api.krasparking.admkrsk.ru';
			}
			if (window.location.hostname === 'stage.parking.msu24.ru') {
				console.log('данные взяты stage');
				return 'https://api.stage.parking.msu24.ru/';
			}
			console.log('данные взяты default');
			// return 'https://api.krasparking.admkrsk.ru';
			return 'https://api.dev.parking.msu24.ru';
		}

		return localStorage.getItem('apiUrl');
	};

	const downloadExcell = async () => {
		dispatch(actions.app.saga.asyncAddLoading('getExportAdjustmentStatRequest'));
		const url = `${updateApiUrl()}/json/reply/GetExportAdjustmentStatRequest
		?from=${paramsControlTable.from}
		&to=${paramsControlTable.to}
		&parkingSpaceId=${(paramsControlTable.parkingSpaceId || paramsControlTable.parkingSpaceId !== null) ? paramsControlTable.parkingSpaceId : ''}
		&parkingId=${(paramsControlTable.parkingId || paramsControlTable.parkingId !== null) ? paramsControlTable.parkingId : ''}`;
		const authHeader = `Bearer ${tokenService.bearerToken}`;

		const options = {
			headers: {
				Authorization: authHeader,
			},
		};

		fetch(url, options)
			.then(async (res) => {
				if (res.ok) {
					return 	res.blob();
				}
				const text = await res.text();
				return Promise.reject(JSON.parse(text));
			})
			.then((blob) => {
				const fileURL = window.URL.createObjectURL(blob);
				const alink = document.createElement('a');
				alink.href = fileURL;
				alink.download = 'Отчет по корректировкам заказов.xlsx';
				alink.click();
				dispatch(actions.app.saga.asyncRemoveLoading('getExportAdjustmentStatRequest'));
			})
			.catch((error) => {
				if (error?.responseStatus) {
					dispatch(actions.app.saga.asyncInitNotification({
						messages: errorApiService.catchRequestError(error),
						type: 'warm',
					}));
				}
				dispatch(actions.app.saga.asyncRemoveLoading('getExportAdjustmentStatRequest'));
			});
	};

	const onDoubleClickRow = (id:string) => {
		console.log(id);
	};

	const rowMenuEvent = (type:ITypeMenuAction, id:string) => {
		switch (type) {
		default:
			console.log('alert');
		}
	};

	const handleSearchParkingSpaces = (newValue: string) => {
		updateParkingSpaces(newValue);
	};

	const handleChangeParkingSpaces = (newValue: string) => {
		setParamsControlTable({ ...paramsControlTable, parkingSpaceId: newValue, parkingId: null });
	};

	const handleSearchParkings = (newValue: string) => {
		updateParkings(newValue);
	};

	const handleChangeParkings = (newValue: string) => {
		setParamsControlTable({ ...paramsControlTable, parkingId: newValue, parkingSpaceId: null });
	};

	const updateParkingSpaces = (filter:string) => {
		const callbackParkingSpaces = (valueReq: GetParkingSpacesResponse) => {
			setParkingSpaceData(valueReq);
		};

		dispatch(actions.parkingSpaceManipulation.saga.asyncGetParkingSpaces(
			{
				items: 50,
				filter,
				page: 0,
				sort: 'name desc',
				customerId: customer.id
					? customer.id
					: (tokenService.customerId as string),
				callbackParkingSpaces,

			},
		));
	};
	const updateParkings = (filter:string) => {
		const callbackParkings = (valueReq: GetParkingsResponse) => {
			setParkingsData(valueReq);
		};

		dispatch(actions.parkingSpaceManipulation.saga.asyncGetParkingsIts(
			{
				items: 50,
				filter,
				page: 0,
				sort: 'name desc',
				customerId: customer.id
					? customer.id
					: (tokenService.customerId as string),
				callbackParkings,

			},
		));
	};

	return (
		<Body>
			<Title>
				<NameTitle>Отчет по замка</NameTitle>
				<ControlElement>
					<RangePicker onChange={(value) => setParamsControlTable({
						...paramsControlTable,
						from: value ? dayjs(dayjs(value?.[0]).set('hour', 0).set('minute', 0).set('second', 0)).toISOString() : '',
						to: value ? dayjs(dayjs(value?.[1]).set('hour', 0).set('minute', 0).set('second', 0)).add(24, 'hour').add(-1, 'second').toISOString() : '',
					})}
					/>
					<Select
						showSearch
						value={paramsControlTable.parkingSpaceId}
						placeholder="Поиск по замку"
						style={{ width: 200 }}
						defaultActiveFirstOption={false}
						showArrow={false}
						filterOption={false}
						onSearch={handleSearchParkingSpaces}
						onChange={handleChangeParkingSpaces}
						notFoundContent={null}
						allowClear
						options={(parkingSpaceData.items || []).map((d) => ({
							value: d.id,
							label: d.name,
						}))}
					/>
					<Select
						showSearch
						value={paramsControlTable.parkingId}
						placeholder="Поиск по парковке"
						style={{ width: 200, color: '#fff' }}
						defaultActiveFirstOption={false}
						showArrow={false}
						filterOption={false}
						onSearch={handleSearchParkings}
						onChange={handleChangeParkings}
						notFoundContent={null}
						allowClear
						options={(parkingsData.items || []).map((d) => ({
							value: d.id,
							label: d.name,
						}))}
					/>
					<Tooltip title="search">
						<ButtonAntd type="primary" onClick={updateData} shape="circle" icon={<ReloadOutlined />} />
					</Tooltip>
					<ButtonAntd type="primary" onClick={downloadExcell} shape="round" icon={<DownloadOutlined />}>
						Excel
					</ButtonAntd>
				</ControlElement>
			</Title>
			<MainSection>
				<TableElement
					onDoubleClickRow={onDoubleClickRow}
					changeParamsPage={setParamsPage}
					pagination={{ itemsPerPage: adjustmentStatData.itemsPerPage, page: adjustmentStatData.page, total: adjustmentStatData.total }}
					tableProps={{ defaultSorted: 'parkingName', defaultOrder: 'asc', rowMenuAction: [{ type: 'none', name: 'Изменить', permission: 'parkingSpaceStatistic.read' }] }}
					rowMenuEvent={rowMenuEvent}
					tableData={{
						items: adjustmentStatData.items,
						header: [
							{
								key: 'parkingName',
								translate: 'Парковка',
								format: 'string',
								propsStyle: { align: 'left' },

							},
							{
								key: 'parkingSpaceName',
								translate: 'Замок',
								format: 'string',
								propsStyle: { align: 'left' },

							},
							{
								key: 'totalOrders',
								translate: 'Всего заказов',
								format: 'numberNew',
								propsStyle: { align: 'left' },

							},
							{
								key: 'adjustedOrders',
								translate: 'Отредактированные заказы',
								format: 'numberNew',
								propsStyle: { align: 'left' },

							},
						],
					}}
				/>

			</MainSection>
		</Body>
	);
};

export default LockReportPage;
