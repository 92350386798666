/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put } from 'redux-saga/effects';
import { IAction } from 'store/utils';
import actions from 'store/actions';
import { history } from 'common/history';
import tokenService from 'service/token.service';
import errorApiService from 'service/error.service';
import adminApiService, { updateApiUrl } from 'service/admin-api.services';
import {
	Authenticate,
	AuthenticateResponse,
	Customer,
	GetCurrentCustomerRequest,
	GetCurrentUserPermissionsRequest,
	GetCurrentUserRequest,
	Permission,
	User,
} from 'store/dto/hub.dtos';

export interface IAsyncCall {
  login: string;
  password: string;
}

const loginCall = (opts: IAsyncCall): Promise<AuthenticateResponse> => {
	const { login, password } = opts;
	const request = new Authenticate();
	request.provider = 'credentials';
	request.userName = login;
	request.password = password;
	return adminApiService.post(request);
};

const getCustomerCall = (opts: { id?: string }): Promise<Customer> => {
	const { id } = opts;
	const request = new GetCurrentCustomerRequest();
	request.customerId = id || '';
	return adminApiService.get(request);
};
const getPermissionsCall = (opts: { id?: string }): Promise<string[]> => {
	const { id } = opts;
	const request = new GetCurrentUserPermissionsRequest();
	return adminApiService.get(request);
};
const getCurrentUserCall = (opts: { id?: string }): Promise<User> => {
	const { id } = opts;
	const request = new GetCurrentUserRequest();
	return adminApiService.get(request);
};

export function* getPermissionsRequest(
	action: IAction<{
    callbackPermissions?: (item: string[]) => void;
  }>,
) {
	try {
		yield put(actions.app.saga.asyncAddLoading('getPermissionsRequest'));
		const {
			payload: { callbackPermissions },
		} = action;
		const result: string[] = yield call(getPermissionsCall, {});
		yield put(actions.authorization.setPermissions(result));
		yield callbackPermissions?.(result);
		yield put(actions.app.saga.asyncRemoveLoading('getPermissionsRequest'));
	} catch (error) {
		yield put(actions.app.saga.asyncRemoveLoading('getPermissionsRequest'));
		yield put(
			actions.app.saga.asyncInitNotification({
				messages: errorApiService.catchRequestError(error),
				type: 'warm',
			}),
		);
	}
}
export function* getCurrentUserRequest(
	action: IAction<{
		callbackCurrentUser?: (item: User) => void;
  }>,
) {
	try {
		yield put(actions.app.saga.asyncAddLoading('getCurrentUserRequest'));
		const {
			payload: { callbackCurrentUser },
		} = action;
		const result: User = yield call(getCurrentUserCall, {});
		yield callbackCurrentUser?.(result);
		yield put(actions.authorization.setUser(result));
		yield tokenService.setUser(result);
		yield put(actions.app.saga.asyncRemoveLoading('getCurrentUserRequest'));
	} catch (error) {
		yield put(actions.app.saga.asyncRemoveLoading('getCurrentUserRequest'));
		yield put(
			actions.app.saga.asyncInitNotification({
				messages: errorApiService.catchRequestError(error),
				type: 'warm',
			}),
		);
	}
}

export function* loginAdmin(
	action: IAction<{ login: string, password: string }>,
) {
	try {
		yield put(actions.app.saga.asyncAddLoading('loadingAdminLogin'));
		const {
			payload: { login, password },
		} = action;
		console.log(updateApiUrl(), login);
		if (updateApiUrl() === 'https://hub.krsk.its.msu24.ru') {
			if (
				login === 'admin'
			|| login === 'i.perevalov'
			|| login === 'a.yarovenko'
			|| login === 'a.dubinin'
			|| login === 'p.mituhin'
			|| login === 'a.volkov'
			|| login === 'n.ahremenko'
			|| login === 'tech.support'
			|| login === 'k.novik'
			|| login === 'm.ufimtsev'
			|| login === 'i.popov'
			|| login === 'r.vasiliev'
			) {
				const result: AuthenticateResponse = yield call(loginCall, {
					login,
					password,
				});
				yield put(actions.authorization.setAdminData(result));
				yield tokenService.setToken(result.bearerToken, result.refreshToken);
				const resultCustomer: Customer = yield call(getCustomerCall, {});
				yield put(actions.authorization.setCustomer(resultCustomer));
				yield tokenService.setCustomer(resultCustomer.id);
				const resultPermissions: string[] = yield call(getPermissionsCall, {});
				yield put(actions.authorization.setPermissions(resultPermissions));
				yield tokenService.setPermissions(resultPermissions);

				const resultUser:User = yield call(getCurrentUserCall, {});
				yield put(actions.authorization.setUser(resultUser));
				yield tokenService.setUser(resultUser);

				yield put(actions.app.saga.asyncRemoveLoading('loadingAdminLogin'));
				yield call(history.push, 'admin/orders');
			} else {
				yield put(actions.app.saga.asyncRemoveLoading('loadingAdminLogin'));
				yield put(actions.authorization.saga.asyncLoginError(''));
				yield put(
					actions.app.saga.asyncInitNotification({
						messages: 'У вас нет прав для просмотра этой страницы',
						type: 'warm',
					}),
				);
			}
		} else {
			const result: AuthenticateResponse = yield call(loginCall, {
				login,
				password,
			});
			yield put(actions.authorization.setAdminData(result));
			yield tokenService.setToken(result.bearerToken, result.refreshToken);
			const resultCustomer: Customer = yield call(getCustomerCall, {});
			yield put(actions.authorization.setCustomer(resultCustomer));
			yield tokenService.setCustomer(resultCustomer.id);

			const resultPermissions: string[] = yield call(getPermissionsCall, {});
			yield put(actions.authorization.setPermissions(resultPermissions));
			yield tokenService.setPermissions(resultPermissions);

			const resultUser:User = yield call(getCurrentUserCall, {});
			yield put(actions.authorization.setUser(resultUser));
			yield tokenService.setUser(resultUser);

			yield put(actions.app.saga.asyncRemoveLoading('loadingAdminLogin'));
			yield call(history.push, 'admin/orders');
		}
	} catch (error) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingAdminLogin'));
		yield put(actions.authorization.saga.asyncLoginError(''));
		yield put(
			actions.app.saga.asyncInitNotification({
				messages: errorApiService.catchRequestError(error),
				type: 'warm',
			}),
		);
	}
}
