/* eslint-disable no-unused-expressions */
import React from 'react';
import {
	AShowPassword,
	Form as FormPassword, Input as InputPassword, Label as LabelPassword, ShowPasswordIcon,
} from 'components/forms/PasswordInput/styledComponent';
import { Form as FormText, Input as InputText, Label as LabelText } from 'components/forms/TextInput/styledComponent';
import { useForm } from 'react-hook-form';
import { Button } from 'components/forms/Button';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { useNavigate } from 'react-router-dom';
import {
	ButtonSection, Main, ProfileInput, Title, VerifyPhoneReturn,
} from './styledComponent';

interface IResetPasswordSecondPage {
	phone:string;
}

type FormData = {
	field1: string;
	field2: string;
	field3:string;
  };

const ResetPasswordSecondPage: React.FC<IResetPasswordSecondPage> = (props) => {
	const { phone } = props;
	const {
		register, handleSubmit, formState: { errors }, getValues,
	} = useForm<FormData>();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [passwordShown, setPasswordShown] = React.useState(false);
	const [timer, setTimer] = React.useState(30);
	const id = React.useRef<number>();

	const togglePassword = () => {
		// When the handler is invoked
		// inverse the boolean state of passwordShown
		setPasswordShown(!passwordShown);
	};
	const onSubmit = handleSubmit((data) => {
		dispatch(actions.authorization.saga.asyncResetPassword({
			newPassword: data.field1,
			phone: `+7${phone}`,
			verifyCode: Number(data.field3),
		}));
	});

	const clear = () => {
		window.clearInterval(id.current || undefined);
	};
	const initTimer = () => {
		id.current = window.setInterval(() => {
			setTimer((time) => time - 1);
		}, 1000);
		return () => clear();
	};

	React.useEffect(() => {
		initTimer();
	}, []);

	React.useEffect(() => {
		if (timer === 0) {
			clear();
		}
	}, [timer]);

	const startTimer = () => {
		setTimer(30);
		initTimer();
	};

	const resetVerificationCode = () => {
		startTimer();
		dispatch(actions.authorization.saga.asyncVerification({ number: `7${phone}`, reset: true }));
	};

	return (
		<Main>

			<Title><span>Укажите ваш новый пароль и код из SMS.</span></Title>

			<ProfileInput onSubmit={onSubmit}>
				<FormPassword className={passwordShown ? 'active' : ''}>
					<LabelPassword htmlFor="input-field">Новый пароль</LabelPassword>
					<div style={{ position: 'relative', display: 'flex' }}>
						<InputPassword
							id="field1"
							type={passwordShown ? 'text' : 'password'}
							className={errors.field1 && 'error'}
							autoComplete="do-not-autofill"
							// placeholder="placeholder"
							{...register('field1', {
								required: true,
							})}
						/>
						<AShowPassword>
							<ShowPasswordIcon onClick={togglePassword} />
						</AShowPassword>
					</div>
					{errors.field1 && <p>Укажите пароль</p>}
				</FormPassword>
				<FormPassword className={passwordShown ? 'active' : ''}>
					<LabelPassword htmlFor="input-field">Повторите новый пароль</LabelPassword>
					<div style={{ position: 'relative', display: 'flex' }}>
						<InputPassword
							type={passwordShown ? 'text' : 'password'}
							autoComplete="do-not-autofill"
							className={((errors.field2 || getValues('field1') !== getValues('field2')) && 'error') || ''}
							// placeholder="placeholder"
							{...register('field2', {
								required: true,
							})}
						/>
						<AShowPassword>
							<ShowPasswordIcon className={passwordShown ? '.active' : ''} onClick={togglePassword} />
						</AShowPassword>
					</div>
					{getValues('field1') !== getValues('field2') && <p>Пароли не совпадают</p>}
					{errors.field2 && <p>Введите пароль повторно</p>}
				</FormPassword>

				<FormText>
					<LabelText>Проверочный код</LabelText>
					<InputText
						type="number"
						autoComplete="do-not-autofill"
						className={errors.field3 ? 'error' : ''}
						{...register('field3', {
							required: true,
							valueAsNumber: true,
							maxLength: 4,
							minLength: 4,

						})}
					/>
					{errors.field3?.types && <p>Укажите </p>}
				</FormText>
				<VerifyPhoneReturn
					style={{
						color: timer === 0 ? '#0679ff' : '#4d4d4d',
						cursor: timer === 0 ? 'pointer' : 'default',
					}}
					onClick={() => (timer === 0 ? resetVerificationCode() : null)}
				>
					Выслать повторно через
					{' '}
					{timer}
					c

				</VerifyPhoneReturn>
				<ButtonSection>
					<Button onClick={() => navigate('/reset-password/first-step')}> Назад</Button>
					<Button type="submit"> Изменить</Button>
				</ButtonSection>
			</ProfileInput>
		</Main>
	);
};

export default ResetPasswordSecondPage;
