import React from 'react';
import {
	Form, Label, Input, Prefix,
} from './styledComponent';

interface ISearchInput {
    onChange: (str: string) => void;
    onclick?: (str: boolean) => void;
    placeholder: string;
    name?: string;
    size?: string;
    label?:string;
    value?: string;
}
const SearchInput: React.FC <ISearchInput> = (props) => {
	const {
		value, name, placeholder, onChange, label, size, onclick,
	} = props;
	const [clickInput, setClickInput] = React.useState<boolean>(false);

	React.useEffect(() => {
		onclick?.(clickInput);
	}, [clickInput]);
	return (
		<Form>
			{label && <Label>{label}</Label>}
			<Prefix>
				<Input
					id="input-search"
					autocomplete="nope"
					size={size}
					type="text"
					onChange={(event: { target: { value: string } }) => onChange(event.target.value)}
					name={name}
					placeholder={placeholder}
					value={value}
					style={{ pointerEvents: 'all' }}
					autofocus
					onBlur={() => {
						setClickInput(false);
					}}
					onClick={() => {
						setClickInput(true);
					}}
				/>
			</Prefix>
		</Form>
	);
};

export default SearchInput;
