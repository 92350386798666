import { device } from 'common/devices';
import styled from 'styled-components';
import SuccessSVG from 'assets/icons/Profile/success.svg';
import ErrorSVG from 'assets/icons/Profile/error.svg';

export const SuccessIcon = styled(SuccessSVG)``;
export const ErrorIcon = styled(ErrorSVG)``;

export const Main = styled.div`

  display: flex;
  justify-content: center;
`;
export const ResultElement = styled.div`
width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const TitleResult = styled.div`
  margin-top: 28px;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
  @media ${device.tablet600} {
    span {
      font-size: 32px;
      line-height: 44px;
    }
  }
`;

export const ButtonSection = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 400px;
  align-items: center;
  flex-direction: column;
  gap:24px;
  button {
    width: 95%;
  }
  button:nth-child(2) {
    background-color: #fff0;
    border: 2px solid #ff8a00;
    border-radius: 8px;
    color: #ff8a00;
  }
  @media ${device.tablet600} {
    width: 100%;
    button {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
    }
    flex-direction: column;
    gap: 24px;
  }
`;
