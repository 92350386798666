import { device } from 'common/devices';
import styled from 'styled-components';

export const SectionContent = styled.div`
  /* direction: ltr; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 1.5rem; */
  overflow: hidden;
  box-sizing: border-box;
  @media ${device.tablet600} {
    height:45vh;
    padding-top: 64px;
    bottom: 64px;
    transition: all 250ms;
    transform: translateY(1000%);
    &.show {
      transform: translateY(0%);
    }
    &.out {
      transform: translateY(300%);
      /* transform: translateY(0%); */
    }
    z-index: 1100;
    position: absolute;
    background-color: #1d2335;
  }
`;

export const HeaderSearch = styled.div`
  width: 100%;
  /* height: 90px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #1d2335;
  box-sizing: border-box;
  padding: 56px 40px 24px 40px;
  @media ${device.tablet600} {
    padding: 0px 19px;
    z-index: 1101;
    position: fixed;
    transition: all 250ms;
    background: none;
    &.show {
      bottom: calc(45vh - 12px);
    }
    &.out {
      bottom: 88px;
    }
  }
`;
export const ContentSearch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 40px;
  box-sizing: border-box;
  overflow: auto;
  @media ${device.tablet600} {
    padding: 40px 19px 5px 19px;
  }
`;

export const ParkingStreetPlace = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: flex-start;

  cursor: pointer;
  border-bottom: 1px solid #242436;
  padding-bottom: 12px;
  transition: all 250ms;
  &:hover {
    border-bottom: 1.5px solid #6f6f88;
  }
`;

export const NamePlace = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.1px;

  color: #ffffff;
`;
export const DescriptionPlace = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #92929d;
  }

  span:nth-child(3) {
    color: #79bf57;
  }
`;
