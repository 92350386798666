import styled from 'styled-components';
import MarkerSVG from 'assets/icons/Map/iconMap.svg';
import MarkerActiveSVG from 'assets/icons/Map/iconMapActive.svg';
// import { device } from 'common/devices';

export const MarkerIcon = styled(MarkerSVG)``;
export const MarkerActiveIcon = styled(MarkerActiveSVG)``;
export const BackgroundHeaderMobile = styled.div`
  display: none;
`;
export const MainSection = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 81px);
  display: flex;

`;

export const SectionContent = styled.div`
  overflow: auto;
  /* direction: rtl; */
  position: relative;
  width: 402px;

  box-sizing: border-box;
  background: #1d2335;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 1%;
  &.hiddenScroll {
    overflow: hidden;
  }

`;

// Map section
export const SectionMap = styled.div`
  &.outActiveMobile {
    width: calc(100% - 402px);
    height: calc(100vh - 81px);
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 50px;
    .leaflet-container {
      width: 100%;
      height: calc(100vh - 81px);
      .leaflet-control-attribution {
        display: none;
      }
      .leaflet-popup-close-button {
        display: none;
      }
    }
  }

`;

export const ActiveParkingElementList = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 1000;

`;
export const MapElement = styled.div`
  &.outActiveMobile {
    height: calc(100vh - 81px);
    .leaflet-top {
      top: 50%;
    }
    .leaflet-left {
      left: 95%;
      /* right: 0 !important; */
    }
    .marker-popup .leaflet-popup-tip {
      background: #171725;
    }

    .marker-popup .leaflet-popup-content-wrapper {
      background: #171725;
      border-radius: 8px;
      display: flex;
      span:nth-child(1) {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.1px;

        color: #ffffff;
      }
      span:nth-child(2) {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.1px;

        /* App/Gray */

        color: #92929d;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .leaflet-popup-content {
        margin: 8px 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }


`;
