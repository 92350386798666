// import { dataAbout } from 'common/data/dataAbout';
// import { IDataAbout } from 'common/Interface';
import ElementFAQ from 'components/layout/FAQList';

import FooterBar from 'components/layout/FooterBar';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
	Main,
	BodyPage,
	Content,
	DescriptionContent,
	FAQSection,
	GoalsProject,
	StepsGoals,
	TitleGoals,
	TitleMain,
	DocumentSVG,
	RoutingCompany,
	RouteCompany,
} from './styledComponent';

const AboutPage: React.FC = () => {
	const navigate = useNavigate();
	return (
		<Main>
			<TitleMain>
				<span>О проекте</span>
			</TitleMain>
			<BodyPage>
				<Content>
					<RoutingCompany>
						<RouteCompany onClick={() => navigate('/about/document')}>
							<DocumentSVG />
							<span>Документы</span>
						</RouteCompany>
					</RoutingCompany>
					<DescriptionContent>
						<span>
							Проект «КрасПаркинг» работает для решения проблемы хаотичной парковки на улицах Красноярска.
							<br />
							Главная цель - создать возможность для парковки в условиях загруженности улично-дорожной сети.

						</span>

					</DescriptionContent>
					<GoalsProject>
						<TitleGoals>
							<span>Основными задачами проекта являются:</span>
						</TitleGoals>
						<StepsGoals>
							<ul>
								<li>Снижение нагрузки на улично-дорожную сеть;</li>
								<li>Снижение количества нарушений правил парковки;</li>
								<li>Стимулирование использования общественного транспорта;</li>
								<li>Улучшение экологической обстановки.</li>
							</ul>
						</StepsGoals>
					</GoalsProject>
					<FAQSection>
						{/* { dataAbout.map((obj:IDataAbout) => (
							<ElementFAQ inner title={obj.title} description={obj.description} />
						))} */}
						<ElementFAQ />
					</FAQSection>
				</Content>
			</BodyPage>
			<div>
				<FooterBar />
			</div>
		</Main>
	);
};

export default AboutPage;
