import styled from 'styled-components';

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;
export const MainSection = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  box-sizing: border-box;
`;

export const Title = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
export const NameTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 110%;
  letter-spacing: 0.1px;

  color: #ff8a00;
`;
export const ControlElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  & .anticon {
    color: white;
  }

  & .customInputMask{
    font-size:18px;
  padding:10px 10px 10px 5px;
  display:block;
  width:300px;
  border:none;
  border-bottom:1px solid #757575;
  background-color: #ff000000;
  color:#fff;
  }
  & .customInputMask:focus{
    /* border-color: #ffa229; */
    border:none;
  border-bottom:1px solid #ffa229;
   /* box-shadow: 0 0 0 2px rgb(255 175 5 / 10%); */
   border-inline-end-width: 1px; 
    outline: 0; 
    color:#fff;
  }
`;

export const CustomInput = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ContentSection = styled.div`
  width: 100%;
  background-color: blue;
`;

export const DateTimePickerClass = styled.input`
  display: inline-flex;
  align-items: center;
  background-color: #fff0;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  height: 31px;
  max-width:300px;
  &:focus-within {
    border-color: #ff8a00;
  }

  /* input { */
  font: inherit;
  color: inherit;
  appearance: none;
  outline: none;
  /* border: 0; */
  /* background-color: transparent; */
  /* height: 30px; */
  &[type="datetime-local"] {
    padding: 0 0.5rem 0 0.5rem;
    /* border-right-width: 0; */
  }

  width: 100%;
  box-sizing: border-box;

  span {
    height: 1rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    border-right: 1px solid #fff;
  }
`;

export const EditInputForModalTitle = styled.div`
  font-size: 16px;
`;

export const EditInputForModal = styled.div`
  padding: 8px;
  box-sizing: border-box;
  background-color: #313851b8;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
`;
export const StatusAndDateTimePicker = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 12px;
`;

export const ModalEditContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column !important;
  box-sizing: border-box;
  gap: 8px;
  .ModalEditContentRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ModalEditContentSpin = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #17172442;
`;

export const ContentModalHistory = styled.div`
  display: flex;
  height: 100%;

  table {
    width: 100%;
    border-spacing: 0;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
      0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  }

  td,
  th {
    /* border: 1px solid #ddd; */
    padding: 8px;
  }
  tr {
    /* border-bottom: 1px solid rgba(81, 81, 81, 1); */
    background-color: #313851b8;
    color: white;
  }
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #121212;
    color: white;
  }
  tr:nth-child(even) {
    background-color: #1e212f;
  }
  tr:hover {
    background-color: #33353d;
  }
`;
