import styled from 'styled-components';
import HistorySVG from 'assets/icons/Header/History.svg';
import MapSVG from 'assets/icons/Header/Map.svg';
import SettingSVG from 'assets/icons/Header/Setting.svg';
import { device } from 'common/devices';

export const SettingIcon = styled(SettingSVG)`
  position: relative;
  /* width: 20%; */
`;
export const MapIcon = styled(MapSVG)`
  position: relative;
  /* width: 20%; */
`;
export const HistoryIcon = styled(HistorySVG)`
  position: relative;
  /* width: 20%; */
`;

export const Header = styled.div`
  z-index: 1104;
  position: absolute;
  width: 100%;
  height: 64px;
  bottom: 0px;

  padding: 0px 40px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #171725;
  box-shadow: 0px 4px 10px rgba(16, 16, 32, 0.5);
  box-sizing: border-box;
  display: none;
  a {
    text-decoration: none;
    /* font-family: "Roboto"; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.1px;
    color: #8f9bbc;
    cursor: pointer;
    &:hover {
      color: #a2afd4;
    }
  }
  @media ${device.tablet600} {
    display: flex;
  }
`;

export const MenuBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
  .active {
    span {
      color: #ff8a00;
    }
    svg {
      fill: #ff8a00;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    svg {
      transition: all 500ms;
    }
    span {
      transition: all 500ms;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      text-align: center;
      letter-spacing: 0.1px;
      color: #8f9bbc;
    }

    &:hover {
      span {
        color: #ff8a00;
      }
      svg {
        fill: #ff8a00;
      }
    }
  }
`;
