import styled from 'styled-components';

export const Form = styled.div`
  /* margin-bottom: 1rem; */
  width: 100%;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
`;
export const Label = styled.label`
  display: block;
  margin-bottom: 0.75rem;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #92929d;
`;
export const Input = styled.input.attrs(
	(props: { size: string }) => props,
)`
  display: block;
  width: 100%;
  height: 48px;
  /* padding: 0.375rem 0.75rem; */
  padding: ${(props) => (props.size === 'big' ? '2rem 0.75rem' : '0.375rem 0.75rem')};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #92929d;
  background: #171725;
  border: none;
  border-radius: 0.5rem;
  /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
  appearance: none;
  padding-left: 3rem;
  color:#fff;
  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  &:focus {
    color: none;
    border: none;
    outline: 0;
    /* color: #212529;
      background-color: #fff;
      border-color: #bdbdbd;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.25); */
  }
`;

export const Prefix = styled.div`
  position: relative;

  &::before {
      content: '';
      color: #bdbdbd;
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23FF8A00' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
    }

`;
