/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
import { IReturn, JsonServiceClient } from '@servicestack/client';
import { GetAccessToken } from 'store/dto/dtos';
import errorApiService from './error.service';
import tokenService from './token.service';

const updateApiUrl = () => {
	if (localStorage.getItem('apiUrl') === null) {
		if (window.location.hostname === 'krasparking.admkrsk.ru') {
			return 'https://parking.krsk.its.msu24.ru';
		}
		if (window.location.hostname === 'stage.parking.msu24.ru') {
			return 'https://parking.stage.its.msu24.ru';
		}
		// return 'https://parking.krsk.its.msu24.ru';
		return 'https://parking.dev.its.msu24.ru';
	}
	return localStorage.getItem('apiParkingUrl');
};

const apiUrl = updateApiUrl();
const client: JsonServiceClient = new JsonServiceClient(apiUrl as string);

const baseApiService = {

	prepareBeforeRequest() {
		client.bearerToken = tokenService.bearerToken as string;
	},

	async refresh() {
		const request = new GetAccessToken();
		request.refreshToken = tokenService.refreshToken as string;
		const clientTemp = new JsonServiceClient(apiUrl as string);
		client.bearerToken = tokenService.bearerToken as string;
		const response = await clientTemp.post(request);
		tokenService.setToken(
			response.accessToken,
			response.refreshToken,
		);
		return true;
	},

	async get<T>(request: IReturn<T>): Promise<T> {
		try {
			client.bearerToken = tokenService.bearerToken as string;
			const response = await client.get<T>(request);
			return Promise.resolve<T>(response);
		} catch (e) {
			const { responseStatus: { errorCode } } = e as any;
			if (errorCode === 'TokenException') {
				console.log('getTokenException');
				await this.refresh();
				return this.get(request);
			}
			console.log('get', errorApiService.catchRequestErrorTest(errorCode));
			return Promise.reject(e);
		}
	},

	// post<T>(request: IReturn<T>) {
	// client.bearerToken = tokenService.bearerToken as string;
	// return client.post(request);
	// },

	async post<T>(request: IReturn<T>): Promise<T> {
		try {
			client.bearerToken = tokenService.bearerToken as string;
			const response = await client.post<T>(request);
			return Promise.resolve<T>(response);
		} catch (e) {
			const { responseStatus: { errorCode, message } } = e as any;
			if (errorCode === 'TokenException') {
				await this.refresh();
				return this.post(request);
			}
			console.log('post', message);
			return Promise.reject(e);
		}
	},
	putCall<T>(request: IReturn<T>) {
		client.bearerToken = tokenService.bearerToken as string;
		return client.put(request);
	},

	async delete<T>(request: IReturn<T>): Promise<T> {
		try {
			client.bearerToken = tokenService.bearerToken as string;
			const response = await client.delete<T>(request);
			return Promise.resolve<T>(response);
		} catch (e) {
			const { responseStatus: { errorCode, message } } = e as any;
			if (errorCode === 'TokenException') {
				await this.refresh();
				return this.delete(request);
			}
			console.log('post', message);
			return Promise.reject(e);
		}
	},
	async put<T>(request: IReturn<T>): Promise<T> {
		try {
			client.bearerToken = tokenService.bearerToken as string;
			const response = await client.put<T>(request);
			return Promise.resolve<T>(response);
		} catch (e) {
			const { responseStatus: { errorCode, message } } = e as any;
			if (errorCode === 'TokenException') {
				await this.refresh();
				return this.put(request);
			}
			console.log('post', message);
			return Promise.reject(e);
		}
	},

};
export default baseApiService;
