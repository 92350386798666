const size = {
	laptop4k: 3820,
	laptop2k: 2560,
	laptopFullHD: 1920,
	laptop1600: 1700,
	laptopHD: 1280,
	laptop1024: 1024,
	tablet768: 768,
	tablet600: 600,
	mobile480: 480,
	mobile320: 320,
};

export const device = {
	laptop4k: `(max-width: ${size.laptop4k}px)`,
	laptop2k: `(max-width: ${size.laptop2k}px)`,
	laptopFullHD: `(max-width: ${size.laptopFullHD}px)`,
	laptop1600: `(max-width: ${size.laptop1600}px)`,
	laptopHD: ` (max-width: ${size.laptopHD}px)`,
	laptop1024: ` (max-width: ${size.laptop1024}px)`,
	tablet768: ` (max-width: ${size.tablet768}px)`,
	tablet600: ` (max-width: ${size.tablet600}px)`,
	mobile480: ` (max-width: ${size.mobile480}px)`,
	mobile320: ` (max-width: ${size.mobile320}px)`,
};

export type TDevice = 'laptop' | 'tablet' | 'mobile';
