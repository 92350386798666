import styled from 'styled-components';
import activeParkingSVG from 'assets/icons/Map/activeElement.svg';
import activeParkingArrowSVG from 'assets/icons/Map/activeElementArrow.svg';
import { device } from 'common/devices';

export const ActiveParkingIcon = styled(activeParkingSVG)`
  .activeElement_svg__border {
    transform-origin: center center;
    transform: rotate(45deg);
    /* animation: spinActiveElement 10s linear infinite; */
  }
  &.active {
    .activeElement_svg__border {
      animation: spinActiveElement 10s linear infinite;
    }
  }
  @keyframes spinActiveElement {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
export const ActiveParkingArrowIcon = styled(activeParkingArrowSVG)``;

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  background: #0a0a15;
  border-radius: 16px;
  padding: 16px 20px;
  gap: 16px;
  cursor: pointer;
  transition: all 250ms;
  &:hover {
    background: #1d2335;
  }
  @media ${device.tablet600} {
    padding: 14px 20px;
    width: calc(100vw - 32px);
    justify-content: space-between;
  }
`;
export const IconElement = styled.div``;
export const TextElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  span:nth-child(1) {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
  span:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #92929d;
  }
`;
export const ArrowElement = styled.div`
  margin-left: 20px;
`;
