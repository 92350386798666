/* eslint-disable jsx-a11y/no-static-element-interactions */
import { BackArrowIcon } from 'components/elements/SelectMapElement/styledComponent';
import FooterBar from 'components/layout/FooterBar';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import myPdf from 'assets/files/terms_of_use.pdf';
import pravilaPolzPdf from 'assets/files/pravila_polz.pdf';
import prikaz703Pdf from 'assets/files/11.08.2022_№703.pdf';
import prikaz618Pdf from 'assets/files/15.07.2022_№618.pdf';
import prikaz721Pdf from 'assets/files/23.08.2022_№721.pdf';
import IzmeneniyaPdf from 'assets/files/Izmeneniya_21.07.2022.pdf';
import PostanovleniePdf from 'assets/files/Postanovlenie.pdf';
import {
	Main,
	BodyPage,
	Content,
	DescriptionContent,
	TitleMain,
	DocumentSection,
	ContentElementDocument,
	DocumentElement,
	DocumentSVG,
	IconElementDocument,
	SizeDocument,
	TitleDocument,
	DownloadDocumentLink,
	BodyDocumentPage,
	FooterDocument,
} from './styledComponent';

interface IDocumentProps {
  name: string;
  size: string;
  hrefLink:string;
}
const Document: React.FC<IDocumentProps> = (props) => {
	const { name, size, hrefLink } = props;

	return (
		<DocumentElement>
			<IconElementDocument>
				<DocumentSVG />
			</IconElementDocument>
			<ContentElementDocument>
				<TitleDocument>
					<span>{name}</span>
				</TitleDocument>
				<SizeDocument>
					<span>
						PDF
						{' '}
						{size}
					</span>
				</SizeDocument>
				<DownloadDocumentLink target="_blank" href={hrefLink}>Скачать</DownloadDocumentLink>
			</ContentElementDocument>
		</DocumentElement>
	);
};

const DocumentPage: React.FC = () => {
	const navigate = useNavigate();

	return (
		<BodyDocumentPage>
			<Main>
				<TitleMain>
					<div style={{ cursor: 'pointer' }} onClick={() => navigate('/about')}>
						<BackArrowIcon />
						<span>Назад</span>

					</div>
					<span>Документы</span>
				</TitleMain>
				<BodyPage>
					<Content>
						<DescriptionContent>
							<span>
								Все интересующие Вас документы вы можете найти на этой странице.
							</span>
						</DescriptionContent>
						<DocumentSection>
							<Document name="Пользовательское соглашение" hrefLink={myPdf} size="44kb" />
							<Document name="Правила пользования муниципальными парковками" hrefLink={pravilaPolzPdf} size="30kb" />
							<Document name="11.08.2022 № 703 Постановление администрации г. Красноярска" hrefLink={prikaz703Pdf} size="172kb" />
							<Document
								name="15.07.2022 № 618-п Постановление Правительства Красноярского края"
								hrefLink={prikaz618Pdf}
								size="179kb"
							/>
							<Document
								name="23.08.2022 № 721-п Постановление Правительства Красноярского края"
								hrefLink={prikaz721Pdf}
								size="226kb"
							/>
							<Document name="Изменение в устав от 21.07.2022" hrefLink={IzmeneniyaPdf} size="1.2Mb" />
							<Document
								name="Постановление о создании для использования на платной основе парковок"
								hrefLink={PostanovleniePdf}
								size="974kb"
							/>

						</DocumentSection>
					</Content>
				</BodyPage>

			</Main>
			<FooterDocument>
				<FooterBar />
			</FooterDocument>
		</BodyDocumentPage>
	);
};

export default DocumentPage;
