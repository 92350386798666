import styled from 'styled-components';
import backArrowSVG from 'assets/icons/Map/backArrow.svg';
import { device } from 'common/devices';

export const BackArrowIcon = styled(backArrowSVG)``;

export const MainBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media ${device.tablet600} {
    height: 50vh;
    bottom: 64px;
    z-index: 1100;
    position: absolute;
    background-color: #1d2335;
    overflow: auto;
  }
`;

export const TopElement = styled.div`
  padding: 17px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
  z-index: 1102;
  .header-select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .refresh-select {
    cursor: pointer;
    display: flex;
    align-self: flex-start;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: start;
      letter-spacing: 0.1px;
      color: #ff8a00;
    }
  }
  @media ${device.tablet600} {
    padding: 25px 16px 16px 16px;
    gap: 25px;
  }
`;

export const SectionContent = styled.div`
  padding: 17px 16px 40px 16px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  background: #1d2335;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 1rem;
  overflow-y: auto;
  &.active {
    margin-bottom: 94px;
  }
  @media ${device.tablet600} {
    padding: 17px 40px 40px 40px;
    &.active {
      margin-bottom: 100px;
    }
  }
`;
export const BackArrow = styled.div`
  cursor: pointer;
  display: flex;
  align-self: flex-start;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: start;
    letter-spacing: 0.1px;
    color: #ff8a00;
  }
`;

export const TitleSection = styled.div`
  align-self: flex-start;
  /* margin-bottom: 14px; */
  span {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ffffff;
    text-transform: lowercase;
  }
`;
export const ParkingPlace = styled.div.attrs(
	(props: { colorBackground: string, borderTop: string }) => props,
)`
cursor:pointer;
flex: 0 0 auto;
  width: 210px;
  height: 77px;
  background: ${(props) => props.colorBackground};
  border-radius: 8px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  /* position:relative; */
   span {
    text-transform: lowercase;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.1px;
  }
&.actived{
    background: #33CC33;
    span{
      color:#fff;
    }
  }
    /* 
  &.actived:after{
    z-index:3;
    border-top: 2px dashed #ff8a00 !important;
    border-right: 2px dashed #ff8a00 !important;
    border-bottom: 2px dashed #ff8a00 !important;
  } */


  &:after {
    transition: all 100ms;
    width: 50%;
    height: 77px;
    position: absolute;
    content: "";
    border-top: 2px dashed #333c56;
    /* border-top: ${(props) => props.borderTop}; */
    border-right: 2px dashed #333c56;
    border-bottom: 2px dashed #333c56;
    transform: scale(1.17);
  }

  &:hover{
    &:after {
    z-index:2;
    border-top: 2px dashed #a4a4a4;
    border-right: 2px dashed #a4a4a4;
    border-bottom: 2px dashed #a4a4a4;

  }
  }

  @media ${device.tablet600} {
    &:after {

    width: 60%;

  }
  }
`;

export const ControlElement = styled.div`
  transform: translateY(1000%);

  transition: all 250ms;
  width: 402px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: #1d2335;
  box-shadow: 0px -4px 10px rgba(16, 16, 32, 0.5);
  position: fixed;
  padding: 24px 40px;
  box-sizing: border-box;
  left: 0;
  bottom: 0;

  button {
    border-radius: 100px;
  }
  &.active {
    transform: translateY(0%);
  }

  .ControlElementText {
    color: #fff;
  }

  .disabled-button {
    background: #595959;
  }
  @media ${device.tablet600} {
    height: 100px;
    bottom: 64px;
    width: 100vw;
    padding: 24px 30px;
    /* margin-top:64px; */
  }
`;
