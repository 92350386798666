import { device } from 'common/devices';
import styled from 'styled-components';
import documentSVG from 'assets/icons/Document/document_small.svg';
import jsbSVG from 'assets/icons/bank/jcb_logo.svg';

export const DocumentSVG = styled(documentSVG)``;
export const JsbIcon = styled(jsbSVG)``;

export const Main = styled.div`
    /* box-sizing: border-box; */
padding-bottom:1px;
  @media ${device.tablet600} {
    padding-bottom:0px;
    padding: 0px var(--padding);
    box-sizing: border-box;
  }
`;

export const BodyPage = styled.div`
  position: relative;

  padding-top: 265px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 69px; */
  @media ${device.tablet600} {
    padding-top: 32px;
    padding-bottom: 69px;
  }
`;

export const TitleMain = styled.div`
  position: absolute;
  left: 120px;
  top: 141px;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
  @media ${device.tablet600} {
    padding-top: 25%;
    align-self: start;
    left: auto;
    top: auto;
    position: relative;
    span {
      font-size: 32px;
    }
  }
`;

export const RoutingCompany = styled.div`
  display: flex;
  flex-direction: column;


`;
export const RouteCompany = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  gap:16px;
  &:hover {
    span {
      color: #ff8a00;
    }
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    /* Gray 20% */

    color: #8f9bbc;
  }
`;
export const Content = styled.div`
  /* position: absolute; */
  width: 45%;
  display: flex;
  flex-direction: column;

  @media ${device.tablet600} {
    width: 100%;
  }
`;
export const DescriptionContent = styled.div`
    margin-top:64px;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #ffffff;
  }

  @media ${device.tablet600} {
    margin-top:40px;
  }
`;
export const GoalsProject = styled.div`
  margin-top:64px;
  
  @media ${device.tablet600} {
    margin-top:40px;
  }`;
export const TitleGoals = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #ffffff;
  @media ${device.tablet600} {
    font-size: 20px;
  }
`;
export const StepsGoals = styled.div`
  margin-top:24px;
  ul {
    /* gap:120px */
  }
  li {
    padding-inline-start: 2ch;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #ffffff;
  }
  li::marker {
    content: url("data:image/svg+xml;charset=UTF-8,<svg width='16' height='12' viewBox='0 0 14 2' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='14' height='2' rx='1' fill='%23FF8A00'/></svg>");
  }
  @media ${device.tablet600} {
    ul {
      margin-left: 20px; /* To remove default bottom margin */
      padding: 0;
    }
    li {
      padding-inline-start: 0.5ch;
      /* list-style: disc inside; */
      margin: 15px 0;
    }
    li::marker {
      content: normal;
    }
  }
`;

export const FAQSection = styled.div`
    margin-top:64px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media ${device.tablet600} {
    gap: 16px;
    margin-top:40px;
  }
`;
