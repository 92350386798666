/* eslint-disable no-use-before-define */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { AdminAccount, GetAdminAccountsResponse } from 'store/dto/dtos';
import TableElement from 'components/elements/TableElement';
import { ITypeMenuAction, Order, TypeMenuAction } from 'components/elements/TableElement/interface';
import { ReloadOutlined } from '@ant-design/icons';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import 'dayjs/locale/ru';
// import Input from 'antd/es/input/Input';
import {
	Input, Button as ButtonAntd, Tooltip, Modal, Checkbox,
} from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
	Body,
	Title,
	MainSection,
	NameTitle,
	ControlElement,
} from './styledComponent';

const UsersPage: React.FC = () => {
	const dispatch = useDispatch();
	const [query, setQuery] = React.useState('');
	const [dataAccounts, setDataAccounts] = React.useState<GetAdminAccountsResponse>(new GetAdminAccountsResponse());
	const [selectedAdminAccount, setSelectedAdminAccount] = React.useState<AdminAccount>(new AdminAccount());
	const [fakeCheckedModal, setFakeCheckedModal] = React.useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [paramsControlTable, setParamsControlTable] = React.useState<{filter:string, statusType:any, from:any, to:any}>(
		{
			filter: '', statusType: '', from: null, to: null,
		},
	);
	const [paramsPage, setParamsPage] = React.useState<{order: Order;
		orderBy: string;
		page:number;
		rowsPerPage:number; }>(
			{
				order: 'desc', orderBy: '', page: 0, rowsPerPage: 25,
			},
		);

	const updateData = () => {
		const callbackAdminAccounts = (items:GetAdminAccountsResponse) => setDataAccounts(items);
		dispatch(actions.data.saga.asyncGetAdminAccounts({
			items: paramsPage.rowsPerPage,
			filter: paramsControlTable.filter,
			page: paramsPage.page,
			sort: `${paramsPage.orderBy} ${paramsPage.order}`,
			callbackAdminAccounts,
		}));
	};
	React.useEffect(() => {
		updateData();
	}, [paramsPage, paramsControlTable]);

	const showEditForm = (id: string) => {
		console.log(dataAccounts.items.find((item) => item.id === id) as AdminAccount);
		setSelectedAdminAccount(dataAccounts.items.find((item) => item.id === id) as AdminAccount);
		setFakeCheckedModal(dataAccounts.items.find((item) => item.id === id)?.fake as boolean);
		showModal();
		// if (temp) {
		// dispatch(actions.data.setAdminOrder(temp));
		// dispatch(actions.app.setShowAdminOrderEditForm(true));
		// }
	};

	const onDoubleClickRow = (id:string) => {
		// deleteTariff(id);
		console.log(id);
	};

	const rowMenuEvent = (itemAction:ITypeMenuAction, id:string) => {
		switch (itemAction.type) {
		case 'edit':
			showEditForm(id);
			break;
		default:
			console.log('alert');
		}
	};

	React.useEffect(() => {
		const timeOutId = setTimeout(() => setParamsControlTable({ ...paramsControlTable, filter: query }), 500);
		return () => clearTimeout(timeOutId);
	}, [query]);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		const callbackUpdateAdminAccount = () => {
			setSelectedAdminAccount(new AdminAccount());
			setIsModalOpen(false);
			updateData();
		};
		dispatch(actions.data.saga.asyncUpdateAdminAccounts({
			id: selectedAdminAccount.id,
			fake: fakeCheckedModal,
			callbackUpdateAdminAccount,
		}));
	};

	const handleCancel = () => {
		setSelectedAdminAccount(new AdminAccount());
		setIsModalOpen(false);
	};

	return (
		<Body>
			<Title>
				<NameTitle>Пользователи</NameTitle>
				<ControlElement>
					<Input
						value={query}
						onChange={(event) => setQuery(event.target.value)}
						id="outlined-search"
						placeholder="поиск..."
					/>

					<Tooltip title="search">
						<ButtonAntd type="primary" onClick={updateData} shape="circle" icon={<ReloadOutlined />} />
					</Tooltip>
				</ControlElement>
			</Title>
			<MainSection>
				<TableElement
					onDoubleClickRow={onDoubleClickRow}
					changeParamsPage={setParamsPage}
					pagination={{ itemsPerPage: dataAccounts.itemsPerPage, page: dataAccounts.page, total: dataAccounts.total }}
					tableProps={{ defaultSorted: 'phone', rowMenuAction: [{ type: 'edit', name: 'Изменить', permission: 'account.manage' }] }}
					rowMenuEvent={rowMenuEvent}
					tableData={{
						items: dataAccounts.items,
						header: [
							{
								key: 'phone',
								translate: 'Номер телефона',
								format: 'string',
								propsStyle: { align: 'left' },

							},
							{
								key: 'email',
								translate: 'Email',
								format: 'string',
								propsStyle: { align: 'left' },

							},
							{
								key: 'licenseNumber',
								translate: 'Гос.Знак',
								format: 'string',
								propsStyle: { align: 'left' },
							},
							{
								key: 'fake',
								translate: 'Тестовый',
								format: 'boolean',
								propsStyle: { align: 'left' },
							},
						],
					}}
				/>

			</MainSection>

			<Modal okText="Изменить" centered title="Редактирование" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
				<Checkbox checked={fakeCheckedModal} onChange={() => setFakeCheckedModal(!fakeCheckedModal)}>Тестовый</Checkbox>
			</Modal>

		</Body>
	);
};

export default UsersPage;
