import { Button } from 'components/forms/Button';
import {
	Form as FormPhone, Input as InputPhone, Label as LabelPhone, Prefix as PrefixPhone,
} from 'components/forms/PhoneInput/styledComponent';
import { Form as FormText, Input as InputText, Label as LabelText } from 'components/forms/TextInput/styledComponent';
import FooterBar from 'components/layout/FooterBar';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	AShowPassword, Form as FormPassword, Input as InputPassword, Label as LabelPassword, ShowPasswordIcon,
} from 'components/forms/PasswordInput/styledComponent';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import {
	ParkomatIcon,
	SectionTitle,
	TitleMainSpan,
	TitleSubSpan,
	TitleText,
	MainSection,
	TitleLogin,
	FormRegestration,
	FooterRegistration,
} from './styledComponent';

type FormData = {
	phone: string;
	name: string;
	password:string;
  };
const RegistrationPage: React.FC = () => {
	const {
		register, handleSubmit, formState: { errors },
	} = useForm<FormData>();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [passwordShown, setPasswordShown] = useState(false);

	const onSubmit = handleSubmit((data) => {
		dispatch(actions.authorization.saga.asyncVerification({ number: `7${data.phone}`, reset: false }));
		dispatch(actions.authorization.setRegistrationData({ name: data.name, password: data.password, phone: data.phone }));
		// navigate('/verification');
	});

	const togglePassword = () => {
		// When the handler is invoked
		// inverse the boolean state of passwordShown
		setPasswordShown(!passwordShown);
	};

	return (
		<MainSection>
			<SectionTitle>
				<TitleText>
					<TitleMainSpan>
						Регистрация
					</TitleMainSpan>
					<TitleSubSpan>
						Добро пожаловать в мобильные приложения Парковки Красноярска.
						Пройдите регистрацию чтобы бронировать и оплачивать парковочные места.
					</TitleSubSpan>
					<FormRegestration onSubmit={onSubmit}>
						<FormText className="name-registration">
							<LabelText htmlFor="input-field">Ваше ФИО</LabelText>
							<InputText
								type="text"
								autoComplete="off"
								placeholder="Полное имя"
								{...register('name', {
									required: true,
									maxLength: 50,
									minLength: 3,
								})}
							/>
							{errors.name && <p>Некорректное имя</p>}
						</FormText>
						<FormPhone className="phone-registration">
							<LabelPhone htmlFor="input-field">Номер телефона</LabelPhone>
							<PrefixPhone>
								<InputPhone
									type="tel"
									placeholder="Ваш номер телефона"
									autoComplete="off"
									maxLength={10}
									{...register('phone', {
										required: true,
										maxLength: 10,
										pattern: /\(?([0-9]{3})\)?([-]?)([0-9]{3})\2([0-9]{4})/,
									})}
								/>

							</PrefixPhone>
							{errors.phone && <p>Некорректный номер телефона</p>}

						</FormPhone>

						<FormPassword className="password-registration">
							<LabelPassword htmlFor="input-field">Придумайте пароль</LabelPassword>
							<div style={{ position: 'relative', display: 'flex' }}>
								<InputPassword
									// className={invalid ? '.text-field__input_invalid' : ' '}
									type={passwordShown ? 'text' : 'password'}
									autoComplete="off"
									placeholder="Придумайте пароль"
									{...register('password', {
										required: true,
										maxLength: 50,
										minLength: 3,
									})}
								/>
								<AShowPassword>
									<ShowPasswordIcon onClick={togglePassword} />
								</AShowPassword>
							</div>
							{errors.password && <p>Некорректный пароль</p>}
						</FormPassword>
						<div className="footer-from">
							<TitleLogin onClick={() => navigate('/login')}>
								У меня есть учётная запись
							</TitleLogin>

							<Button className="button-registration" type="submit">Зарегистрироваться</Button>
						</div>
					</FormRegestration>
				</TitleText>
				<ParkomatIcon />
			</SectionTitle>
			<FooterRegistration>
				<FooterBar />
			</FooterRegistration>
		</MainSection>
	);
};

export default RegistrationPage;
