// import { device } from 'common/devices';
import styled from 'styled-components';

export const MainSection = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
`;

export const Content = styled.div`
  margin-top: 73px;
  /* height:max-content; */
  overflow: hidden;
  width: 100%;
  height: calc(100% - 73px - 64px);
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TitleInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    text-align: center;
  }

  span:nth-child(1) {
    font-size: 18px;
    color: #ffffff7a;
  }
  span:nth-child(2) {
    font-size: 16px;
    color: gray;
  }
`;

export const HeaderSearch = styled.div`
  width: 100%;
  /* height: 90px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: #1d2335;
  box-sizing: border-box;
  padding: 0px 19px;
  z-index: 1101;
  transition: all 250ms;
  background: none;
  gap: 12px;
  & .anticon {
    color: white;
  }

  & .customInputMask {
    font-size: 42px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100px;
    border: none;
    border-bottom: 1px solid #757575;
    background-color: #ff000000;
    color: #fff;
  }
  & .customInputMask:focus {
    /* border-color: #ffa229; */
    border: none;
    /* border-bottom: 1px solid #ffa229; */
    /* box-shadow: 0 0 0 2px rgb(255 175 5 / 10%); */
    /* border-inline-end-width: 1px; */
    outline: 0;
    color: #fff;
    width: 90px;
  }
  & .button-controll {
    display: flex;
    flex-direction: row;
    gap: 6px;
    width: 100%;
    margin-top: 12px;
  }
`;

export const InputElement = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;

`;
export const InputBox = styled.input`
  border: 0;
  border-bottom: 2px solid #fff;
  width: 100%;
  font-size: 30px;
  line-height: 110%;
  height: 50px;
  text-align: center;
  padding: 0px;
  background: transparent;
  color: #FF8A00;
  transition: all 250ms;

  &:focus {
    outline: 0;
    color: #FF8A00;

  }
  &::placeholder {
    outline: 0;
    color: #ffffff7a;
    

  }
  &.not-empty{
    width: 50%;
  }
`;

export const BodyContent = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 19px;
  align-items: center;
  gap: 12px;
  max-height: calc(100vh - 192px - 73px - 64px);
  /* box-sizing:border-box; */
  transition: all 250ms;

  &.showButton {
    max-height: calc(100vh - 192px - 73px - 66px - 64px - 24px);
  }
  &.outButton {
    max-height: calc(100vh - 192px - 73px - 64px);
  }
`;
export const FooterContent = styled.div`
  padding: 10px 19px;
  display: flex;
  position: absolute;
  bottom: 66px;
  width: calc(100vw - 19px - 19px);
  transition: all 250ms;
  &.showButton {
    opacity: 1;
  }
  &.outButton {
    opacity: 0;
  }
`;
export const ParkingPlace = styled.div.attrs(
	(props: { colorBackground: string, borderTop: string }) => props,
)`
  cursor: pointer;
  flex: 0 0 auto;
  padding: 8px 12px;
  box-sizing: border-box;
  width: 100%;
  /* height: 77px; */
  background: ${(props) => props.colorBackground};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: black;
  /* position:relative; */
  span {
    text-transform: lowercase;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.1px;
  }
  span:nth-child(2) {
    font-size: 16px;
  }
  &.actived {
    background: #33cc33;
    span {
      color: #fff;
    }
  }
  /* 
  &.actived:after{
    z-index:3;
    border-top: 2px dashed #ff8a00 !important;
    border-right: 2px dashed #ff8a00 !important;
    border-bottom: 2px dashed #ff8a00 !important;
  } */

  &:hover {
    &:after {
      z-index: 2;
      border-top: 2px dashed #a4a4a4;
      border-right: 2px dashed #a4a4a4;
      border-bottom: 2px dashed #a4a4a4;
    }
  }
`;

export const BackArrow = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ff8a00;
  }
`;

export const EmptySearch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  div {
    padding: 24px 12px;
    background-color: #171725;
  }
`;

export const FooterButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;
