import styled from 'styled-components';
import backArrowSVG from 'assets/icons/Map/backArrow.svg';
import timerSVG from 'assets/icons/Map/timer.svg';
import refreshSVG from 'assets/icons/Refresh_icon.svg';
import { device } from 'common/devices';

export const BackArrowIcon = styled(backArrowSVG)``;
export const RefreshIcon = styled(refreshSVG)`
  cursor: pointer;
  width: 24px;
`;
export const TimerIcon = styled(timerSVG)`
  position: absolute;
  height: 90%;
  width: 90%;

  &.active {
    animation: spin 10s ease-in-out infinite;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media ${device.mobile480} {
    display: none;
  }
`;

export const Main = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #1d2335;
  transition: all 250ms;
  z-index: 2001;
  &.show {
    display: block;
    opacity: 1;
  }
  &.hidden {
    display: none;
    opacity: 0;
  }

  @media ${device.tablet600} {
    top: 73px;
    height: calc(100% - 137px);
    z-index: 1300;
  }
`;
export const SectionContent = styled.div`
  width: 100%;
  height: calc(100% - 164px);
  padding: 0px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media ${device.tablet600} {
    padding: 0px 16px;
  }
`;

export const TopElement = styled.div`
  margin-top: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media ${device.tablet600} {
    margin-top: 25px;
  }
`;

export const BackArrow = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ff8a00;
  }
`;
export const TitleBooking = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  margin-top: 17px;
  span:nth-child(1) {
    text-transform: lowercase;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
  span:nth-child(2) {
    text-transform: lowercase;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  @media ${device.tablet600} {
    margin-top: 25px;
  }
`;
export const SubTitleBooking = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  margin-top: 24px;
  text-align: end;
  /* span:nth-child(1) {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ffffff;
  } */
  span {
    text-align: start;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  .block-tariff {
    width: 100%;
    padding: 24px;
    background: #283046;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    box-sizing: border-box;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      span:nth-child(1) {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.1px;

        /* App/Text gray */

        color: #92929d;
      }
      span:nth-child(2) {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */

        letter-spacing: 0.1px;

        color: #ffffff;
      }
    }
  }
`;

export const TimerSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-grow: 3;
`;

export const CircleElement = styled.div`
  width: 72px;
  height: 72px;
  border-top: 5px solid #444;
  border-bottom: 5px solid #444;
  border-left: 5px solid yellow;
  border-right: 5px solid yellow;
  border-image: linear-gradient(50% right top, blue, skyblue, blue);
  border-radius: 50%;
`;
export const ControlElement = styled.div`
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 24px 40px;
  gap: 12px;
  box-shadow: 0px -4px 10px rgba(16, 16, 32, 0.5);
  width: 402px;
  span {
    /* width: 70%; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: start;
    color: #ffffff;
  }

  .disabled-button{
    background: #595959;
  }

  @media ${device.tablet600} {
    width: 100vw;
  }
`;
export const TextTimer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 4px;
  span:nth-child(1) {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  span:nth-child(2) {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 55px;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
`;
