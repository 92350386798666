import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tokenService from 'service/token.service';
import actions from 'store/actions';
import { User } from 'store/dto/hub.dtos';
import selectors from 'store/selectors';

const volkovUserList = ['a.volkov', 'i.popov', 'r.vasiliev'];

export default function useVolkovUser() {
	const [isVolkovUser, setIsVolkovUser] = React.useState<boolean>(true);
	const userData: User = useSelector(
		selectors.authorization.getUser,
	);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (tokenService.user === '' || tokenService.user === null) {
			if (!userData?.id) {
				const callbackCurrentUser = (value:User) => {
					setIsVolkovUser(volkovUserList.includes(value.userName.toLocaleLowerCase()));
				};
				dispatch(actions.authorization.saga.asyncGetCurrentUserCallBack({ callbackCurrentUser }));
			} else {
				setIsVolkovUser(volkovUserList.includes(userData?.userName.toLocaleLowerCase()));
			}
		} else {
			setIsVolkovUser(volkovUserList.includes(JSON.parse(tokenService?.user || '')?.userName.toLocaleLowerCase()));
		}
	});

	return isVolkovUser;
}
