/* Options:
Date: 2023-07-17 08:25:29
Version: 6.00
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://api.dev.parking.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

// @DataContract
export class DeleteItemsRequest implements IDescription
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteItemsRequest>) { (Object as any).assign(this, init); }
}

export interface IDescription
{
    // @DataMember
    description: string;
}

// @DataContract
export class PageRequest
{
    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<PageRequest>) { (Object as any).assign(this, init); }
}

export interface IBaseEntityItemRequest
{
    id: string;
}

export interface IRequestWithEntity
{
}

export enum TransactionSource
{
    Acquiring = 'Acquiring',
    POS = 'POS',
}

export enum OrderStatusType
{
    New = 'New',
    Booked = 'Booked',
    UserCancelled = 'UserCancelled',
    ReadyToDriveIn = 'ReadyToDriveIn',
    Active = 'Active',
    NotPaid = 'NotPaid',
    PaymentProcessing = 'PaymentProcessing',
    Paid = 'Paid',
    SystemCancelled = 'SystemCancelled',
    OperatorCancelled = 'OperatorCancelled',
}

// @DataContract
export class OrderAdjustmentStatistic
{
    // @DataMember
    public parkingSpaceName: string;

    // @DataMember
    public parkingName: string;

    // @DataMember
    public totalOrders: number;

    // @DataMember
    public adjustedOrders: number;

    public constructor(init?: Partial<OrderAdjustmentStatistic>) { (Object as any).assign(this, init); }
}

export interface ICustomerItemRequest extends ICustomerEntity
{
}

export interface ICustomerEntity
{
    // @DataMember
    customerId: string;
}

export enum PaymentPlace
{
    Unknown = 'Unknown',
    Android = 'Android',
    iOS = 'iOS',
    Web = 'Web',
    Pos = 'Pos',
}

export enum ParkingStatusType
{
    OfflineOpen = 'OfflineOpen',
    OfflineClosed = 'OfflineClosed',
    Working = 'Working',
    AllBusy = 'AllBusy',
}

export class ParkingStatus implements IEntity
{
    // @DataMember
    public serverTime: string;

    // @DataMember
    public status: ParkingStatusType;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ParkingStatus>) { (Object as any).assign(this, init); }
}

export class Parking implements IEntity
{
    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public number: string;

    // @DataMember
    public status: ParkingStatus;

    // @DataMember
    public count: number;

    // @DataMember
    public available: number;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<Parking>) { (Object as any).assign(this, init); }
}

export enum ParkingSpaceStatusType
{
    OfflineOpen = 'OfflineOpen',
    OfflineClosed = 'OfflineClosed',
    Free = 'Free',
    Booked = 'Booked',
    Busy = 'Busy',
}

export enum ParkingSpaceLockPosition
{
    Opened = 'Opened',
    Closed = 'Closed',
    Unknown = 'Unknown',
}

// @DataContract
export enum ParkingSpaceMode
{
    Off = 'Off',
    Engineer = 'Engineer',
    Work = 'Work',
}

export class ParkingSpaceStatus implements IEntity
{
    // @DataMember
    public serverTime: string;

    // @DataMember
    public status: ParkingSpaceStatusType;

    // @DataMember
    public lockPosition: ParkingSpaceLockPosition;

    // @DataMember
    public lockPositionError: boolean;

    // @DataMember
    public lockPositionDescription: string;

    // @DataMember
    public mode: ParkingSpaceMode;

    // @DataMember
    public online: boolean;

    // @DataMember
    public onlineTime?: string;

    // @DataMember
    public statusTime: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ParkingSpaceStatus>) { (Object as any).assign(this, init); }
}

export class ParkingSpace implements IEntity
{
    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public number: string;

    // @DataMember
    public status: ParkingSpaceStatus;

    // @DataMember
    public parkingId: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ParkingSpace>) { (Object as any).assign(this, init); }
}

export interface ICustomerIntegrationIdRequest
{
    customerIntegrationId: string;
}

// @DataContract
export class GetEntityGroupsRequestBase implements ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetEntityGroupsRequestBase>) { (Object as any).assign(this, init); }
}

export class BankCard implements IEntity
{
    // @DataMember
    public maskedPan: string;

    // @DataMember
    public paymentSystem: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<BankCard>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EntityViewModelBase implements IEntity
{
    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<EntityViewModelBase>) { (Object as any).assign(this, init); }
}

export interface IEntity
{
}

export interface IEntityTyped
{
    // @DataMember
    entityType: string;
}

export interface IChangeKey extends IReadonlyChangeKey
{
    // @DataMember
    changeKey: string;
}

export interface IReadonlyChangeKey
{
    // @DataMember
    changeKey: string;
}

export class OrderStatusDetails implements IEntity
{
    // @DataMember
    public orderId: string;

    // @DataMember
    public statusTime: string;

    // @DataMember
    public status: OrderStatusType;

    // @DataMember
    public description: string;

    // @DataMember
    public userName: string;

    // @DataMember
    public userDisplayName: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<OrderStatusDetails>) { (Object as any).assign(this, init); }
}

export class ChequeInfo
{
    // @DataMember
    public date: string;

    // @DataMember
    public url: string;

    public constructor(init?: Partial<ChequeInfo>) { (Object as any).assign(this, init); }
}

export enum TransactionStatus
{
    New = 'New',
    Cancelled = 'Cancelled',
    Completed = 'Completed',
}

export enum FiscalizationStatus
{
    WaitFiscalization = 'WaitFiscalization',
    Sent = 'Sent',
    Fiscalized = 'Fiscalized',
}

export class Transaction implements IEntity
{
    // @DataMember
    public orderId: string;

    // @DataMember
    public bankOrderId: string;

    // @DataMember
    public status: TransactionStatus;

    // @DataMember
    public cardholderName: string;

    // @DataMember
    public maskedPan: string;

    // @DataMember
    public paymentInfo: string;

    // @DataMember
    public fiscalizationStatus: FiscalizationStatus;

    // @DataMember
    public source: TransactionSource;

    // @DataMember
    public chequeRequestId: string;

    // @DataMember
    public created: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<Transaction>) { (Object as any).assign(this, init); }
}

export class Tariff implements IEntity
{
    // @DataMember
    public from: string;

    // @DataMember
    public to: string;

    // @DataMember
    public hourlyRate: number;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<Tariff>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BaseEntityViewModelBase implements IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<BaseEntityViewModelBase>) { (Object as any).assign(this, init); }
}

export interface IModified
{
    // @DataMember
    modified: string;

    // @DataMember
    modifiedBy: string;
}

// @DataContract
export class EntityGroupInfo implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public itemsCount: number;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<EntityGroupInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class CustomerNamedBaseEntityViewModelBase implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) { (Object as any).assign(this, init); }
}

export interface INamedEntity extends IEntity, INamed
{
}

export interface INamed
{
    // @DataMember
    name: string;
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity
{
}

export interface ICustomerName
{
    // @DataMember
    customerName: string;
}

// @DataContract
export class NamedBaseEntityViewModelBase implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<NamedBaseEntityViewModelBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PageRequestBase
{
    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<PageRequestBase>) { (Object as any).assign(this, init); }
}

export class MyAccountInfo
{
    // @DataMember
    public phone: string;

    // @DataMember
    public displayName: string;

    // @DataMember
    public email: string;

    // @DataMember
    public licenseNumber: string;

    public constructor(init?: Partial<MyAccountInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetBankCardsResponse
{
    // @DataMember
    public cards: BankCard[];

    public constructor(init?: Partial<GetBankCardsResponse>) { (Object as any).assign(this, init); }
}

export class Order implements IEntity
{
    // @DataMember
    public accountId: string;

    // @DataMember
    public parkingSpaceId: string;

    // @DataMember
    public parkingId: string;

    // @DataMember
    public duration: string;

    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public address: string;

    // @DataMember
    public number: string;

    // @DataMember
    public amount: number;

    // @DataMember
    public completed: boolean;

    // @DataMember
    public status: OrderStatusType;

    // @DataMember
    public statusTime: string;

    // @DataMember
    public statusDescription: string;

    // @DataMember
    public orderNumber: string;

    // @DataMember
    public lockPosition?: ParkingSpaceLockPosition;

    // @DataMember
    public created: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public paymentPlace?: PaymentPlace;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<Order>) { (Object as any).assign(this, init); }
}

// @DataContract
export class VerifyPhoneResponse
{
    // @DataMember
    public nextVerify: string;

    public constructor(init?: Partial<VerifyPhoneResponse>) { (Object as any).assign(this, init); }
}

export class AppVersion implements IEntity
{
    // @DataMember
    public date: string;

    // @DataMember
    public androidVersion: number;

    // @DataMember
    public iosVersion: number;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<AppVersion>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetAppVersionsResponse implements IChangeKey
{
    // @DataMember
    public items: AppVersion[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetAppVersionsResponse>) { (Object as any).assign(this, init); }
}

export class SystemStatus implements IEntity
{
    // @DataMember
    public description: string;

    // @DataMember
    public from: string;

    // @DataMember
    public to?: string;

    // @DataMember
    public paymentDisabled: boolean;

    // @DataMember
    public orderDisabled: boolean;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<SystemStatus>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetSystemStatusesResponse implements IChangeKey
{
    // @DataMember
    public items: SystemStatus[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetSystemStatusesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Statistics
{
    // @DataMember
    public registrationCount: number;

    // @DataMember
    public totalPaymentsAmount: number;

    // @DataMember
    public acquiringPaymentsAmount: number;

    // @DataMember
    public posPaymentsAmount: number;

    // @DataMember
    public totalPaymentsCount: number;

    // @DataMember
    public acquiringPaymentsCount: number;

    // @DataMember
    public posPaymentsCount: number;

    public constructor(init?: Partial<Statistics>) { (Object as any).assign(this, init); }
}

export class AdminOrder implements IEntity
{
    // @DataMember
    public accountId: string;

    // @DataMember
    public accountPhone: string;

    // @DataMember
    public parkingSpaceName: string;

    // @DataMember
    public parkingName: string;

    // @DataMember
    public parkingSpaceId: string;

    // @DataMember
    public parkingId: string;

    // @DataMember
    public duration?: number;

    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public address: string;

    // @DataMember
    public number: string;

    // @DataMember
    public amount: number;

    // @DataMember
    public completed: boolean;

    // @DataMember
    public status: OrderStatusType;

    // @DataMember
    public statusTime: string;

    // @DataMember
    public statusDescription: string;

    // @DataMember
    public orderNumber: string;

    // @DataMember
    public transactionId: string;

    // @DataMember
    public transactionNumber: string;

    // @DataMember
    public transactionSource: TransactionSource;

    // @DataMember
    public transactionDate: string;

    // @DataMember
    public lockPosition?: ParkingSpaceLockPosition;

    // @DataMember
    public created: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public statuses: OrderStatusDetails[];

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<AdminOrder>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetAdminOrdersResponse implements IChangeKey
{
    // @DataMember
    public items: AdminOrder[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetAdminOrdersResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdminOrderCalculation
{
    // @DataMember
    public amount: number;

    // @DataMember
    public duration: number;

    // @DataMember
    public orderStart: string;

    // @DataMember
    public orderEnd: string;

    public constructor(init?: Partial<AdminOrderCalculation>) { (Object as any).assign(this, init); }
}

export class AdminAccount implements IEntity
{
    // @DataMember
    public phone: string;

    // @DataMember
    public email: string;

    // @DataMember
    public licenseNumber: string;

    // @DataMember
    public fake: boolean;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<AdminAccount>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetAdminAccountsResponse implements IChangeKey
{
    // @DataMember
    public items: AdminAccount[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetAdminAccountsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetAdjustmentStatResponse implements IChangeKey
{
    // @DataMember
    public items: OrderAdjustmentStatistic[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetAdjustmentStatResponse>) { (Object as any).assign(this, init); }
}

export class AcquiringInfo
{
    // @DataMember
    public values: { [index: string]: string; };

    public constructor(init?: Partial<AcquiringInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetOrdersResponse implements IChangeKey
{
    // @DataMember
    public items: Order[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetOrdersResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetActiveOrderResponse
{
    // @DataMember
    public hasActiveOrder: boolean;

    // @DataMember
    public order: Order;

    public constructor(init?: Partial<GetActiveOrderResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BookAndActivateResponse
{
    // @DataMember
    public success: boolean;

    // @DataMember
    public errorDescription: string;

    // @DataMember
    public order: Order;

    public constructor(init?: Partial<BookAndActivateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetParkingsResponse implements IChangeKey
{
    // @DataMember
    public items: Parking[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetParkingsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetParkingSpacesResponse implements IChangeKey
{
    // @DataMember
    public items: ParkingSpace[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetParkingSpacesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetParkingStatResponse
{
    // @DataMember
    public totalParkingPlaces: number;

    // @DataMember
    public availableParkingPlaces: number;

    public constructor(init?: Partial<GetParkingStatResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public entityId: string;

    // @DataMember
    public customerIntegrationId: string;

    // @DataMember
    public externalId: string;

    // @DataMember
    public settings: string;

    // @DataMember
    public integrationId: string;

    // @DataMember
    public integrationName: string;

    // @DataMember
    public integrationType: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<EntityIntegration>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntityIntegrationResponse implements IChangeKey
{
    // @DataMember
    public items: EntityIntegration[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetEntityIntegrationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntityGroupsResponse implements IChangeKey
{
    // @DataMember
    public items: EntityGroupInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetEntityGroupsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntitiesResponse implements IChangeKey
{
    // @DataMember
    public items: Object[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetEntitiesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetAccessTokenResponse
{
    // @DataMember(Order=1)
    public accessToken: string;

    // @DataMember(Order=2)
    public refreshToken: string;

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAccessTokenResponse>) { (Object as any).assign(this, init); }
}

// @Route("/sendSupportMessage", "POST")
// @DataContract
export class SendSupportMessageRequest implements IReturn<string>
{
    // @DataMember
    public phone: string;

    // @DataMember
    public email: string;

    // @DataMember
    public name: string;

    // @DataMember
    public message: string;

    public constructor(init?: Partial<SendSupportMessageRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'SendSupportMessageRequest'; }
}

// @Route("/me", "GET")
// @DataContract
export class GetMyAccountInfoRequest implements IReturn<MyAccountInfo>
{

    public constructor(init?: Partial<GetMyAccountInfoRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new MyAccountInfo(); }
    public getTypeName() { return 'GetMyAccountInfoRequest'; }
}

// @Route("/me", "PUT")
// @DataContract
export class UpdateMyAccountInfoRequest implements IReturn<MyAccountInfo>
{
    // @DataMember
    public email: string;

    // @DataMember
    public displayName: string;

    // @DataMember
    public licenseNumber: string;

    public constructor(init?: Partial<UpdateMyAccountInfoRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new MyAccountInfo(); }
    public getTypeName() { return 'UpdateMyAccountInfoRequest'; }
}

// @Route("/account/bankCard", "GET")
// @DataContract
export class GetBankCardsRequest implements IReturn<GetBankCardsResponse>
{

    public constructor(init?: Partial<GetBankCardsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetBankCardsResponse(); }
    public getTypeName() { return 'GetBankCardsRequest'; }
}

// @Route("/me/unregister", "POST")
// @DataContract
export class UnregisterAccountRequest implements IReturn<boolean>
{

    public constructor(init?: Partial<UnregisterAccountRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'UnregisterAccountRequest'; }
}

// @Route("/account/bankCard", "DELETE")
// @DataContract
export class DeleteBankCardRequest implements IReturn<number>, IDescription
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteBankCardRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteBankCardRequest'; }
}

// @Route("/account/currentOrder", "GET")
// @DataContract
export class GetAccountCurrentOrderRequest implements IReturn<Order>
{
    // @DataMember
    public phone: string;

    public constructor(init?: Partial<GetAccountCurrentOrderRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Order(); }
    public getTypeName() { return 'GetAccountCurrentOrderRequest'; }
}

// @Route("/verifyPhone", "POST")
// @DataContract
export class VerifyPhoneRequest implements IReturn<VerifyPhoneResponse>
{
    // @DataMember
    public phone: string;

    public constructor(init?: Partial<VerifyPhoneRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new VerifyPhoneResponse(); }
    public getTypeName() { return 'VerifyPhoneRequest'; }
}

// @Route("/resetPassword", "POST")
// @DataContract
export class ResetAccountPasswordRequest implements IReturn<boolean>
{
    // @DataMember
    public phone: string;

    // @DataMember
    public verifyCode: number;

    // @DataMember
    public newPassword: string;

    public constructor(init?: Partial<ResetAccountPasswordRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'ResetAccountPasswordRequest'; }
}

// @Route("/register", "POST")
// @DataContract
export class RegisterAccountRequest implements IReturn<boolean>
{
    // @DataMember
    public displayName: string;

    // @DataMember
    public phone: string;

    // @DataMember
    public verifyCode: number;

    // @DataMember
    public password: string;

    // @DataMember
    public email: string;

    public constructor(init?: Partial<RegisterAccountRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'RegisterAccountRequest'; }
}

// @Route("/appVersions/adminCurrent", "GET")
// @DataContract
export class GetAdminCurrentAppVersionRequest implements IReturn<AppVersion>
{

    public constructor(init?: Partial<GetAdminCurrentAppVersionRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new AppVersion(); }
    public getTypeName() { return 'GetAdminCurrentAppVersionRequest'; }
}

// @Route("/appVersions", "GET")
// @DataContract
export class GetAppVersionsRequest implements IReturn<GetAppVersionsResponse>
{
    // @DataMember
    public from?: string;

    // @DataMember
    public to?: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetAppVersionsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAppVersionsResponse(); }
    public getTypeName() { return 'GetAppVersionsRequest'; }
}

// @Route("/appVersions/{id}", "GET")
// @DataContract
export class GetAppVersionRequest implements IReturn<AppVersion>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetAppVersionRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new AppVersion(); }
    public getTypeName() { return 'GetAppVersionRequest'; }
}

// @Route("/appVersions", "POST")
// @DataContract
export class CreateAppVersionRequest implements IReturn<AppVersion>, IDescription
{
    // @DataMember(IsRequired=true)
    public entity: AppVersion;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateAppVersionRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new AppVersion(); }
    public getTypeName() { return 'CreateAppVersionRequest'; }
}

// @Route("/systemStatuses/adminCurrent", "GET")
// @DataContract
export class GetAdminCurrentSystemStatusRequest implements IReturn<SystemStatus>
{

    public constructor(init?: Partial<GetAdminCurrentSystemStatusRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new SystemStatus(); }
    public getTypeName() { return 'GetAdminCurrentSystemStatusRequest'; }
}

// @Route("/systemStatuses", "GET")
// @DataContract
export class GetSystemStatusesRequest implements IReturn<GetSystemStatusesResponse>
{
    // @DataMember
    public from?: string;

    // @DataMember
    public to?: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetSystemStatusesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSystemStatusesResponse(); }
    public getTypeName() { return 'GetSystemStatusesRequest'; }
}

// @Route("/systemStatuses/{id}", "GET")
// @DataContract
export class GetSystemStatusRequest implements IReturn<SystemStatus>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetSystemStatusRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new SystemStatus(); }
    public getTypeName() { return 'GetSystemStatusRequest'; }
}

// @Route("/systemStatuses", "POST")
// @DataContract
export class CreateSystemStatusRequest implements IReturn<SystemStatus>, IDescription
{
    // @DataMember(IsRequired=true)
    public entity: SystemStatus;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateSystemStatusRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new SystemStatus(); }
    public getTypeName() { return 'CreateSystemStatusRequest'; }
}

// @Route("/admin/statistics", "GET")
// @DataContract
export class GetStatisticsRequest implements IReturn<Statistics>
{
    // @DataMember
    public from?: string;

    // @DataMember
    public to?: string;

    public constructor(init?: Partial<GetStatisticsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Statistics(); }
    public getTypeName() { return 'GetStatisticsRequest'; }
}

// @Route("/admin/orders", "GET")
// @DataContract
export class GetAdminOrdersRequest implements IReturn<GetAdminOrdersResponse>
{
    // @DataMember
    public transactionSource?: TransactionSource;

    // @DataMember
    public from?: string;

    // @DataMember
    public to?: string;

    // @DataMember
    public status?: OrderStatusType;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetAdminOrdersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAdminOrdersResponse(); }
    public getTypeName() { return 'GetAdminOrdersRequest'; }
}

// @Route("/admin/order/{id}", "GET")
// @DataContract
export class GetAdminOrderRequest implements IReturn<AdminOrder>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetAdminOrderRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdminOrder(); }
    public getTypeName() { return 'GetAdminOrderRequest'; }
}

// @Route("/admin/order/{id}/statuses", "GET")
// @DataContract
export class GetOrderStatusesRequest implements IReturn<OrderStatusDetails[]>
{
    // @DataMember
    public orderId: string;

    public constructor(init?: Partial<GetOrderStatusesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<OrderStatusDetails>(); }
    public getTypeName() { return 'GetOrderStatusesRequest'; }
}

// @Route("/admin/order/{id}/calculate", "GET")
// @DataContract
export class CalculateAdminOrderRequest implements IReturn<AdminOrderCalculation>, IBaseEntityItemRequest
{
    // @DataMember
    public durationTill: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<CalculateAdminOrderRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdminOrderCalculation(); }
    public getTypeName() { return 'CalculateAdminOrderRequest'; }
}

// @Route("/admin/order/{id}", "PUT")
// @DataContract
export class UpdateAdminOrderRequest implements IReturn<AdminOrder>, IDescription, IBaseEntityItemRequest
{
    // @DataMember
    public newStatusType?: OrderStatusType;

    // @DataMember
    public newDurationTill?: string;

    // @DataMember
    public newAmount?: number;

    // @DataMember
    public description: string;

    // @DataMember
    public refund?: boolean;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<UpdateAdminOrderRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdminOrder(); }
    public getTypeName() { return 'UpdateAdminOrderRequest'; }
}

// @Route("/admin/users", "GET")
// @DataContract
export class GetAdminAccountsRequest implements IReturn<GetAdminAccountsResponse>
{
    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetAdminAccountsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAdminAccountsResponse(); }
    public getTypeName() { return 'GetAdminAccountsRequest'; }
}

// @Route("/admin/users/{id}", "GET")
// @DataContract
export class GetAdminAccountRequest implements IReturn<AdminAccount>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetAdminAccountRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdminAccount(); }
    public getTypeName() { return 'GetAdminAccountRequest'; }
}

// @Route("/admin/users/{id}", "PUT")
// @DataContract
export class UpdateAdminAccountRequest implements IReturn<AdminAccount>, IBaseEntityItemRequest
{
    // @DataMember
    public fake: boolean;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<UpdateAdminAccountRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdminAccount(); }
    public getTypeName() { return 'UpdateAdminAccountRequest'; }
}

// @Route("/adjustmentStatistics", "GET")
// @DataContract
export class GetAdjustmentStatRequest implements IReturn<GetAdjustmentStatResponse>
{
    // @DataMember
    public from?: string;

    // @DataMember
    public to?: string;

    // @DataMember
    public parkingId?: string;

    // @DataMember
    public parkingSpaceId?: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetAdjustmentStatRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAdjustmentStatResponse(); }
    public getTypeName() { return 'GetAdjustmentStatRequest'; }
}

// @Route("/exportAdjustmentStatistics", "GET")
// @DataContract
export class GetExportAdjustmentStatRequest implements IReturn<Uint8Array>
{
    // @DataMember
    public from?: string;

    // @DataMember
    public to?: string;

    // @DataMember
    public parkingId?: string;

    // @DataMember
    public parkingSpaceId?: string;

    public constructor(init?: Partial<GetExportAdjustmentStatRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Uint8Array(0); }
    public getTypeName() { return 'GetExportAdjustmentStatRequest'; }
}

// @Route("/parkingSpaces/{Id}/moveStep", "POST")
// @DataContract
export class MoveStepParkingSpaceRequest implements IReturn<ParkingSpaceStatus>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember
    public open: boolean;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<MoveStepParkingSpaceRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatus(); }
    public getTypeName() { return 'MoveStepParkingSpaceRequest'; }
}

// @Route("/parkingSpaces/{Id}/open", "POST")
// @DataContract
export class OpenParkingSpaceRequest implements IReturn<ParkingSpaceStatus>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember
    public force: boolean;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<OpenParkingSpaceRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatus(); }
    public getTypeName() { return 'OpenParkingSpaceRequest'; }
}

// @Route("/admin/order/{orderId}/cheques", "GET")
// @DataContract
export class GetChequesRequest implements IReturn<ChequeInfo[]>
{
    // @DataMember
    public orderId: string;

    public constructor(init?: Partial<GetChequesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<ChequeInfo>(); }
    public getTypeName() { return 'GetChequesRequest'; }
}

// @Route("/order/{orderId}/transactions", "GET")
// @DataContract
export class GetTransactionsRequest implements IReturn<Transaction[]>
{
    // @DataMember
    public orderId: string;

    public constructor(init?: Partial<GetTransactionsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<Transaction>(); }
    public getTypeName() { return 'GetTransactionsRequest'; }
}

// @Route("/acquiring/info", "GET")
// @DataContract
export class GetAcquiringRequest implements IReturn<AcquiringInfo>
{

    public constructor(init?: Partial<GetAcquiringRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new AcquiringInfo(); }
    public getTypeName() { return 'GetAcquiringRequest'; }
}

// @Route("/tariff/schedule", "GET")
// @DataContract
export class GetScheduleRequest implements IReturn<Tariff[]>
{

    public constructor(init?: Partial<GetScheduleRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<Tariff>(); }
    public getTypeName() { return 'GetScheduleRequest'; }
}

// @Route("/tariff/schedule", "PUT")
// @DataContract
export class UpdateScheduleRequest implements IReturn<boolean>
{
    // @DataMember
    public schedule: Tariff[];

    public constructor(init?: Partial<UpdateScheduleRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'UpdateScheduleRequest'; }
}

// @Route("/orders/kioskCompleteOrder", "POST")
// @DataContract
export class CompleteAccountCurrentOrderRequest implements IReturn<Order>
{
    // @DataMember
    public apiKey: string;

    // @DataMember
    public phone: string;

    // @DataMember
    public cardholderName: string;

    // @DataMember
    public maskedPan: string;

    // @DataMember
    public paymentInfo: string;

    public constructor(init?: Partial<CompleteAccountCurrentOrderRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Order(); }
    public getTypeName() { return 'CompleteAccountCurrentOrderRequest'; }
}

// @Route("/orders/kioskSetPaymentProcessing", "POST")
// @DataContract
export class SetPaymentProcessingAccountCurrentOrderRequest implements IReturn<boolean>
{
    // @DataMember
    public apiKey: string;

    // @DataMember
    public phone: string;

    public constructor(init?: Partial<SetPaymentProcessingAccountCurrentOrderRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'SetPaymentProcessingAccountCurrentOrderRequest'; }
}

// @Route("/orders/kioskUnsetPaymentProcessing", "POST")
// @DataContract
export class UnsetPaymentProcessingAccountCurrentOrderRequest implements IReturn<boolean>
{
    // @DataMember
    public apiKey: string;

    // @DataMember
    public phone: string;

    public constructor(init?: Partial<UnsetPaymentProcessingAccountCurrentOrderRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'UnsetPaymentProcessingAccountCurrentOrderRequest'; }
}

// @Route("/orders", "GET")
// @DataContract
export class GetOrdersRequest implements IReturn<GetOrdersResponse>
{
    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetOrdersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetOrdersResponse(); }
    public getTypeName() { return 'GetOrdersRequest'; }
}

// @Route("/orders/current", "GET")
// @DataContract
export class GetActiveOrderRequest implements IReturn<GetActiveOrderResponse>
{

    public constructor(init?: Partial<GetActiveOrderRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetActiveOrderResponse(); }
    public getTypeName() { return 'GetActiveOrderRequest'; }
}

// @Route("/orders/bookAndActivate", "POST")
// @DataContract
export class BookAndActivateRequest implements IReturn<Order>
{
    // @DataMember
    public parkingSpaceId: string;

    public constructor(init?: Partial<BookAndActivateRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Order(); }
    public getTypeName() { return 'BookAndActivateRequest'; }
}

// @Route("/orders/bookAndActivate2", "POST")
// @DataContract
export class BookAndActivate2Request implements IReturn<BookAndActivateResponse>
{
    // @DataMember
    public parkingSpaceId: string;

    public constructor(init?: Partial<BookAndActivate2Request>) { (Object as any).assign(this, init); }
    public createResponse() { return new BookAndActivateResponse(); }
    public getTypeName() { return 'BookAndActivate2Request'; }
}

// @Route("/orders/current/complete", "POST")
// @DataContract
export class CompleteOrderRequest implements IReturn<Order>
{

    public constructor(init?: Partial<CompleteOrderRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Order(); }
    public getTypeName() { return 'CompleteOrderRequest'; }
}

// @Route("/orders/current/payment", "GET")
// @DataContract
export class PaymentRequest implements IReturn<string>
{
    // @DataMember
    public returnUrl: string;

    // @DataMember
    public paymentPlace?: PaymentPlace;

    public constructor(init?: Partial<PaymentRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'PaymentRequest'; }
}

// @Route("/callback", "POST")
// @DataContract
export class CallbackRequest
{
    // @DataMember
    public date: string;

    // @DataMember
    public amount: string;

    // @DataMember
    public orderNumber: string;

    // @DataMember
    public operation: string;

    // @DataMember
    public mdOrder: string;

    // @DataMember
    public status: number;

    // @DataMember
    public cardholderName: string;

    // @DataMember
    public maskedPan: string;

    // @DataMember
    public expiry: string;

    // @DataMember
    public bankName: string;

    // @DataMember
    public ip: string;

    // @DataMember
    public payerEmail: string;

    // @DataMember
    public paymentWay: string;

    // @DataMember
    public paymentDate: string;

    // @DataMember
    public currency: string;

    // @DataMember
    public checksum: string;

    public constructor(init?: Partial<CallbackRequest>) { (Object as any).assign(this, init); }
}

// @Route("/parkings", "GET")
// @DataContract
export class GetParkingsRequest implements IReturn<GetParkingsResponse>
{
    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParkingsResponse(); }
    public getTypeName() { return 'GetParkingsRequest'; }
}

// @Route("/parkings/find", "GET")
// @DataContract
export class GetFindParkingSpacesRequest implements IReturn<GetParkingSpacesResponse>
{
    // @DataMember
    public filter: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    public constructor(init?: Partial<GetFindParkingSpacesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParkingSpacesResponse(); }
    public getTypeName() { return 'GetFindParkingSpacesRequest'; }
}

// @Route("/parkings/{Id}/spaces", "GET")
// @DataContract
export class GetParkingSpacesRequest implements IReturn<GetParkingSpacesResponse>, IBaseEntityItemRequest
{
    // @DataMember
    public id: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingSpacesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParkingSpacesResponse(); }
    public getTypeName() { return 'GetParkingSpacesRequest'; }
}

// @Route("/parkings/stat", "GET")
// @DataContract
export class GetParkingStatRequest implements IReturn<GetParkingStatResponse>
{

    public constructor(init?: Partial<GetParkingStatRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParkingStatResponse(); }
    public getTypeName() { return 'GetParkingStatRequest'; }
}

// @Route("/appVersions/current", "GET")
// @DataContract
export class GetCurrentAppVersionRequest implements IReturn<AppVersion>
{

    public constructor(init?: Partial<GetCurrentAppVersionRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new AppVersion(); }
    public getTypeName() { return 'GetCurrentAppVersionRequest'; }
}

// @Route("/systemStatuses/current", "GET")
// @DataContract
export class GetCurrentSystemStatusRequest implements IReturn<SystemStatus>
{

    public constructor(init?: Partial<GetCurrentSystemStatusRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new SystemStatus(); }
    public getTypeName() { return 'GetCurrentSystemStatusRequest'; }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string>
{

    public constructor(init?: Partial<GetHealthStatusRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'GetHealthStatusRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest implements IReturn<GetEntityIntegrationResponse>, ICustomerIntegrationIdRequest, ICustomerItemRequest
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetEntityIntegrationsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityIntegrationResponse(); }
    public getTypeName() { return 'GetEntityIntegrationsRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest implements IReturn<EntityIntegration>, ICustomerIntegrationIdRequest, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetEntityIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityIntegration(); }
    public getTypeName() { return 'GetEntityIntegrationRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest implements IReturn<EntityIntegration>, ICustomerIntegrationIdRequest, ICustomerItemRequest, IDescription
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: EntityIntegration;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateEntityIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityIntegration(); }
    public getTypeName() { return 'CreateEntityIntegrationRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest implements IReturn<EntityIntegration>, ICustomerIntegrationIdRequest, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: EntityIntegration;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateEntityIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityIntegration(); }
    public getTypeName() { return 'UpdateEntityIntegrationRequest'; }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetEntityPropertiesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return {}; }
    public getTypeName() { return 'GetEntityPropertiesRequest'; }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any>
{
    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember
    public description: string;

    // @DataMember
    public entity: { [index: string]: string; };

    public constructor(init?: Partial<UpdateEntityPropertiesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return {}; }
    public getTypeName() { return 'UpdateEntityPropertiesRequest'; }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string>
{
    // @DataMember
    public property: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetEntityPropertyRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'GetEntityPropertyRequest'; }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string>
{
    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember
    public description: string;

    // @DataMember
    public property: string;

    // @DataMember
    public value: string;

    public constructor(init?: Partial<UpdateEntityPropertyRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'UpdateEntityPropertyRequest'; }
}

// @Route("/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any>
{
    // @DataMember
    public property: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetEntitiesPropertyRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return {}; }
    public getTypeName() { return 'GetEntitiesPropertyRequest'; }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
    // @DataMember
    public id: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetItemEntityGroupsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityGroupsResponse(); }
    public getTypeName() { return 'GetItemEntityGroupsRequest'; }
}

// @Route("/entities/remoteRepository/{repositoryId}/data", "GET")
// @DataContract
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse>
{
    // @DataMember
    public repositoryId: string;

    // @DataMember
    public modified?: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetEntitiesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntitiesResponse(); }
    public getTypeName() { return 'GetEntitiesRequest'; }
}

/**
* Sign In
*/
// @Route("/auth")
// @Route("/auth/{provider}")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    // @ApiMember(Description="AuthProvider, e.g. credentials")
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/access-token")
// @DataContract
export class GetAccessToken implements IReturn<GetAccessTokenResponse>, IPost
{
    // @DataMember(Order=1)
    public refreshToken: string;

    public constructor(init?: Partial<GetAccessToken>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAccessTokenResponse(); }
    public getTypeName() { return 'GetAccessToken'; }
}

