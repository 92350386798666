import AuthorizationTypes from './authorization';
import AppTypes from './app';
import UserTypes from './user';
import DataTypes from './data';
import ParkingManipulationTypes from './parkingManipulation';
import ParkingSpaceManipulationTypes from './parkingSpaceManipulation';

export default {
	authorization: AuthorizationTypes,
	app: AppTypes,
	user: UserTypes,
	data: DataTypes,
	parkingManipulation: ParkingManipulationTypes,
	parkingSpaceManipulation: ParkingSpaceManipulationTypes,
};
