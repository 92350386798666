import * as React from 'react';
import TableHead from '@mui/material/TableHead';
import { visuallyHidden } from '@mui/utils';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import { IHeaderRow, Order } from '../interface';

interface IEnhancedTableProps {
	onRequestSort:(event: React.MouseEvent<unknown>, property: keyof any) => void;
	order: Order;
	orderBy: string;
	headerData:IHeaderRow[];
	showAction:boolean;

  }

const EnhancedTableHead:React.FC<IEnhancedTableProps> = (props) => {
	const {
		order, orderBy, onRequestSort, headerData, showAction,
	} = props;
	const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
		if (property !== 'transactionNumber') { onRequestSort(event, property); }
	};
	return (
		<TableHead>
			<TableRow>
				{headerData.map((headCell) => (
					<TableCell
						key={headCell.key}
						align={headCell.propsStyle.align}
						sortDirection={orderBy === headCell.key ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.key}
							direction={orderBy === headCell.key ? order : 'asc'}
							onClick={createSortHandler(headCell.key)}
						>
							{headCell.translate}
							{orderBy === headCell.key ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				{showAction && <TableCell style={{ width: '40px' }} />}
			</TableRow>
		</TableHead>
	);
};

export default EnhancedTableHead;
