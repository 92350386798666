export default {
	SET_TOKEN: 'SET_TOKEN',
	SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
	SET_AUTH: 'SET_AUTH',
	SET_REGISTRATION_DATA: 'SET_REGISTRATION_DATA',
	SET_ADMIN_DATA: 'SET_ADMIN_DATA',
	SET_CUSTOMER: 'SET_CUSTOMER',
	SET_PERMISSIONS: 'SET_PERMISSIONS',
	SET_USER: 'SET_USER',
	saga: {
		ASYNC_LOG_IN: 'ASYNC_LOG_IN',
		ASYNC_LOGIN_SUSSEC: 'ASYNC_LOGIN_SUSSEC',
		ASYNC_LOGIN_ERROR: 'ASYNC_LOGIN_ERROR',
		ASYNC_VERIFICATION: 'ASYNC_VERIFICATION',
		ASYNC_REGISTRATION: 'ASYNC_REGISTRATION',
		ASYNC_ACCESS_TOKEN: 'ASYNC_ACCESS_TOKEN',
		ASYNC_RESET_PASSWORD: 'ASYNC_RESET_PASSWORD',
		ASYNC_LOGIN_ADMIN: 'ASYNC_LOGIN_ADMIN',
		ASYNC_GET_CUSTOMER: 'ASYNC_GET_CUSTOMER',
		ASYNC_GET_PERMISSIONS_CALLBACK: 'ASYNC_GET_PERMISSIONS_CALLBACK',
		ASYNC_GET_CURRENT_USER_CALLBACK: 'ASYNC_GET_CURRENT_USER_CALLBACK',
	},
};
