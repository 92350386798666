import moment from 'moment';
import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Order, OrderStatusType } from 'store/dto/dtos';

import {
	ActiveParkingArrowIcon, ActiveParkingIcon, ArrowElement, IconElement, Main, TextElement,
} from './styledComponent';

interface IActiveParkingElement {
    activeParking : Order;
	onChangeСenter: (item:Order) => void;
}
const ActiveParkingElement: React.FC<IActiveParkingElement> = (props) => {
	const { activeParking, onChangeСenter } = props;
	// const navigate = useNavigate();

	const toActivePage = () => {
		// navigate('/map/booking');
		onChangeСenter(activeParking);
	};
	const [timer, setTimer] = React.useState<{minutes:number, hours:number}>({
		minutes: moment.duration(moment.utc().diff(moment.utc(activeParking.created))).minutes(),
		hours: moment.duration(moment.utc().diff(moment.utc(activeParking.created))).hours(),
	});

	useEffect(() => {
		if (activeParking.status === OrderStatusType.Active) {
			const intervalTimer = setInterval(() => {
				const duration = moment.duration(moment.utc().diff(moment.utc(activeParking.created)));
				setTimer({ hours: duration.hours(), minutes: duration.minutes() });
			}, 10000);
			return () => clearInterval(intervalTimer);
		}
		return () => console.log('exit');
	}, [timer]);

	return (
		<Main onClick={() => toActivePage()}>
			<IconElement>
				<ActiveParkingIcon className={activeParking.status === OrderStatusType.Active ? 'active' : ''} />
			</IconElement>
			<TextElement>
				<span>{activeParking.address}</span>
				<span>
					{ (activeParking.status === OrderStatusType.Active ? `Время парковки ${timer?.hours}ч ${timer?.minutes}мин`
						: `Время парковки ${moment.duration(activeParking?.duration).hours()}ч 
						${moment.duration(activeParking?.duration).minutes()}мин`)}
				</span>
			</TextElement>
			<ArrowElement>
				<ActiveParkingArrowIcon />
			</ArrowElement>
		</Main>
	);
};
export default ActiveParkingElement;
