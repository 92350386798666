export default {
	SET_PARKING_SPACES: 'SET_PARKING_SPACES',
	SET_CURRENT_PARKING_SPACE: 'SET_CURRENT_PARKING_SPACE',
	SET_PARKINGS: 'SET_PARKINGS',
	SET_CURRENT_PARKING: 'SET_CURRENT_PARKING',
	SET_ACCOUNT_INFO: 'SET_ACCOUNT_INFO',
	SET_BANK_CARDS: 'SET_BANK_CARDS',
	SET_ORDERS: 'SET_ORDERS',
	SET_PAID_LINK: 'SET_PAID_LINK',
	SET_ACTIVE_ORDER: 'SET_ACTIVE_ORDER',
	SET_PARKING_STATS: 'SET_PARKING_STATS',
	SET_ADMIN_ORDER: 'SET_ADMIN_ORDER',
	SET_ADMIN_ORDERS: 'SET_ADMIN_ORDERS',
	SET_TARIFF: 'SET_TARIFF',
	SET_TARIFFS: 'SET_TARIFFS',
	SET_STATISTICS: 'SET_STATISTICS',
	SET_SYSTEM_STATUSES: 'SET_SYSTEM_STATUSES',
	SET_ADMIN_CURRENT_SYSTEM_STATUS: 'SET_ADMIN_CURRENT_SYSTEM_STATUS',
	SET_CURRENT_SYSTEM_STATUS: 'SET_CURRENT_SYSTEM_STATUS',
	saga: {
		ASYNC_GET_PARKING_SPACES: 'ASYNC_GET_PARKING_SPACES',
		ASYNC_GET_PARKINGS: 'ASYNC_GET_PARKINGS',
		ASYNC_FIND_PARKING_SPACES: 'ASYNC_FIND_PARKING_SPACES',
		ASYNC_GET_ACOUNT_INFO: 'ASYNC_GET_ACCOUNT_INFO',
		ASYNC_SET_ACCOUNT_INFO: 'ASYNC_SET_ACCOUNT_INFO',
		ASYNC_GET_BANK_CARDS: 'ASYNC_GET_BANK_CARDS',
		ASYNC_DELETE_BANK_CARD: 'ASYNC_DELETE_BANK_CARD',
		ASYNC_GET_ORDERS: 'ASYNC_GET_ORDERS',
		ASYNC_SET_PAID_ORDER: 'ASYNC_SET_PAID_ORDER',
		ASYNC_GET_ACTIVE_ORDER: 'ASYNC_GET_ACTIVE_ORDER',
		ASYNC_GET_PARKING_STATS: 'ASYNC_GET_PARKING_STATS',
		ASYNC_GET_ADMIN_ORDERS: 'ASYNC_GET_ADMIN_ORDERS',
		ASYNC_GET_ORDER_STATUSES: 'ASYNC_GET_ORDERS_STATUSES',
		ASYNC_GET_TRANSACTIONS: 'ASYNC_GET_TRANSACTIONS',
		ASYNC_UPDATE_ADMIN_ORDER: 'ASYNC_UPDATE_ADMIN_ORDER',
		ASYNC_GET_TARIFF: 'ASYNC_GET_TARIFF',
		ASYNC_GET_SCHEDULE: 'ASYNC_GET_SCHEDULE',
		ASYNC_UPDATE_SCHEDULE: 'ASYNC_UPDATE_SCHEDULE',
		ASYNC_DELETE_TARIFFS: 'ASYNC_DELETE_TARIFFS',
		ASYNC_CREATE_TARIFFS: 'ASYNC_CREATE_TARIFFS',
		ASYNC_GET_STATISTICS: 'ASYNC_GET_STATISTICS',

		ASYNC_GET_CURRENT_APP_VERSIONS: 'ASYNC_GET_CURRENT_APP_VERSIONS',
		ASYNC_GET_APP_VERSION: 'ASYNC_GET_APP_VERSION',
		ASYNC_GET_APP_VERSIONS: 'ASYNC_GET_APP_VERSIONS',
		ASYNC_CREATE_APP_VERSION: 'ASYNC_CREATE_APP_VERSION',

		ASYNC_GET_SYSTEM_STATUSES: 'ASYNC_GET_SYSTEM_STATUSES',
		ASYNC_CREATE_SYSTEM_STATUS: 'ASYNC_GET_SYSTEM_STATUS',
		ASYNC_GET_ADMIN_CURRENT_SYSTEM_STATUS: 'ASYNC_GET_ADMIN_CURRENT_SYSTEM_STATUS',
		ASYNC_GET_CURRENT_SYSTEM_STATUS: 'ASYNC_GET_CURRENT_SYSTEM_STATUS',
		ASYNC_GET_ADMIN_ACCOUNTS: 'ASYNC_GET_ADMIN_ACCOUNTS',
		ASYNC_GET_ADMIN_ACCOUNT: 'ASYNC_GET_ADMIN_ACCOUNT',
		ASYNC_UPDATE_ADMIN_ACCOUNTS: 'ASYNC_UPDATE_ADMIN_ACCOUNTS',
		ASYNC_CALCULATE_ADMIN_ORDER: 'ASYNC_CALCULATE_ADMIN_ORDER',

		ASYNC_GET_ACQUIRING: 'ASYNC_GET_ACQUIRING',

		ASYNC_GET_CHEQUES: 'ASYNC_GET_CHEQUES',

		ASYNC_GET_ADJUSTMENT_STAT: 'ASYNC_GET_ADJUSTMENT_STAT',
		ASYNC_GET_EXPORT_ADJUSTMENT_STAT: 'ASYNC_GET_EXPORT_ADJUSTMENT_STAT',
	},
};
