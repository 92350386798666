import { Order } from 'store/dto/dtos';
import types from 'store/types';
import { actionCreator } from 'store/utils';

export default {

	saga: {
		// asyncBookingParkingSpace: actionCreator<string>(types.parkingManipulation.saga.ASYNC_BOOKING_PARKING_SPACE),
		// asyncActivateParkingSpace: actionCreator<string>(types.parkingManipulation.saga.ASYNC_ACTIVATE_PARKING_SPACE),
		// asyncCompleteParkingSpace: actionCreator<string>(types.parkingManipulation.saga.ASYNC_COMPLETE_PARKING_SPACE),
		asyncActiveAndBookingParkingSpace:
		actionCreator<{id:string, callback?:(item:Order) => void}
			>(types.parkingManipulation.saga.ASYNC_ACTIVE_AND_BOOKING_PARKING_SPACE),
	},
};
