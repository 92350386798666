import { takeLatest } from 'redux-saga/effects';
import actions from 'store/actions';
import * as parkingsWorker from './parkingsWorkers';
import * as accountWorkers from './accountWorkers';
import * as ordersWorkers from './ordersWorkers';
import * as parkingSpacesWorker from './parkingSpacesWorkers';
import * as adminWorkers from './adminWorkers';
import * as tariffWorkers from './tariffWorkers';
import * as statisticWorker from './statisticWorkers';
import * as systemStatusesWorker from './systemStatusesWorkers';
import * as appVersionWorkers from './appVersionWorkers';

export const {
	getParkingsRequest, getParkingSpacesRequest, getMyAccountInfoRequest, getAdminOrdersRequest,
	updateMyAccountInfoRequest, getOrdersRequest, setPaidOrderRequest, getActiveOrderRequest, getParkingStatRequest,
	getTariffsRequest, updateTariffRequest, deleteBankCardRequest,
	getBankCardsRequest, updateAdminOrderRequest, getStatisticsRequest, getSystemStatusesRequest, createSystemStatusRequest,
	getAdminCurrentSystemStatusRequest, getCurrentSystemStatusRequest, updateAdminAccountRequest, getAdminAccountRequest,
	getAdminAccountsRequest, calculateAdminOrderRequest, getAcquiringRequest, getChequesRequest, getTransactionsRequest,
	createAppVersionRequest, getAdminCurrentAppVersionRequest, getAppVersionsRequest, getFindParkingSpacesRequest,
	getOrderStatusesRequest, getAdjustmentStatRequest,
	getExportAdjustmentStatRequest,
} = {
	...parkingsWorker,
	...parkingSpacesWorker,
	...accountWorkers,
	...ordersWorkers,
	...adminWorkers,
	...tariffWorkers,
	...statisticWorker,
	...systemStatusesWorker,
	...appVersionWorkers,
};

export default function* dataWatcher() {
	const { saga } = actions.data;
	yield takeLatest(saga.asyncGetParkings.type, getParkingsRequest);
	yield takeLatest(saga.asyncGetParkingSpaces.type, getParkingSpacesRequest);
	yield takeLatest(saga.asyncGetAcountInfo.type, getMyAccountInfoRequest);
	yield takeLatest(saga.asyncSetAccountInfo.type, updateMyAccountInfoRequest);
	yield takeLatest(saga.asyncGetOrders.type, getOrdersRequest);
	yield takeLatest(saga.asyncSetPaidOrder.type, setPaidOrderRequest);
	yield takeLatest(saga.asyncGetActiveOrder.type, getActiveOrderRequest);
	yield takeLatest(saga.asyncGetParkingStats.type, getParkingStatRequest);
	yield takeLatest(saga.asyncGetAdminOrders.type, getAdminOrdersRequest);
	yield takeLatest(saga.asyncGetSchedule.type, getTariffsRequest);
	yield takeLatest(saga.asyncUpdateSchedule.type, updateTariffRequest);
	yield takeLatest(saga.asyncGetBankCards.type, getBankCardsRequest);
	yield takeLatest(saga.asyncDeleteBankCard.type, deleteBankCardRequest);
	yield takeLatest(saga.asyncUpdateAdminOrders.type, updateAdminOrderRequest);
	yield takeLatest(saga.asyncGetStatistics.type, getStatisticsRequest);
	yield takeLatest(saga.asyncGetSystemStatuses.type, getSystemStatusesRequest);
	yield takeLatest(saga.asyncCreateSystemStatus.type, createSystemStatusRequest);
	yield takeLatest(saga.asyncGetAdminCurrentSystemStatus.type, getAdminCurrentSystemStatusRequest);
	yield takeLatest(saga.asyncGetCurrentSystemStatus.type, getCurrentSystemStatusRequest);
	yield takeLatest(saga.asyncGetAdminAccounts.type, getAdminAccountsRequest);
	yield takeLatest(saga.asyncGetAdminAccount.type, getAdminAccountRequest);
	yield takeLatest(saga.asyncUpdateAdminAccounts.type, updateAdminAccountRequest);
	yield takeLatest(saga.asyncCalculateAdminOrder.type, calculateAdminOrderRequest);
	yield takeLatest(saga.asyncGetAcquiring.type, getAcquiringRequest);
	yield takeLatest(saga.asyncGetCheques.type, getChequesRequest);
	yield takeLatest(saga.asyncGetAppVersions.type, getAppVersionsRequest);
	yield takeLatest(saga.asyncGetCurrentAppVersions.type, getAdminCurrentAppVersionRequest);
	yield takeLatest(saga.asyncCreateAppVersion.type, createAppVersionRequest);
	yield takeLatest(saga.asyncFindParkingSpaces.type, getFindParkingSpacesRequest);
	yield takeLatest(saga.asyncGetTransactions.type, getTransactionsRequest);
	yield takeLatest(saga.asyncGetOrderStatuses.type, getOrderStatusesRequest);
	yield takeLatest(saga.asyncGetAdjustmentStat.type, getAdjustmentStatRequest);
	yield takeLatest(saga.asyncGetExportAdjustmentStat.type, getExportAdjustmentStatRequest);
}
