/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	call, delay, put, race,
} from 'redux-saga/effects';
import actions from 'store/actions';
import parkingApiService from 'service/parking-api.service';
import parkingBaseApiService from 'service/base-api.service';

import { IAction } from 'store/utils';
import {
	GetParkingSpacesRequest, GetParkingSpacesResponse, GetParkingSpaceStatusRequest,
	GetParkingsRequest,
	GetParkingsResponse,
	ParkingSpaceStatus, SetParkingSpaceEngineerModeRequest,
} from 'store/dto/parking.dtos';
import {

	ParkingSpaceStatus as ParkingSpaceStatusBase,
	MoveStepParkingSpaceRequest, OpenParkingSpaceRequest,
} from 'store/dto/dtos';
import errorApiService from 'service/error.service';

const getParkingSpacesRequestCall = (opts: {
	page: number;
    items: number;
    sort: string;
    filter: string;
    customerId:string;
}): Promise<GetParkingSpacesResponse> => {
	const {
		customerId, page, items, sort, filter,
	} = opts;
	const request = new GetParkingSpacesRequest();
	request.filter = filter;
	request.items = items;
	request.page = page;
	request.sort = sort;
	request.customerId = customerId;
	return parkingApiService.get(request);
};
const getParkingsRequestCall = (opts: {
	page: number;
    items: number;
    sort: string;
    filter: string;
    customerId:string;
}): Promise<GetParkingsResponse> => {
	const {
		customerId, page, items, sort, filter,
	} = opts;
	const request = new GetParkingsRequest();
	request.filter = filter;
	request.items = items;
	request.page = page;
	request.sort = sort;
	request.customerId = customerId;
	return parkingApiService.get(request);
};

const parkingStatusSpaceCall = (opts: {
	customerId: string;
	id: string;
  }): Promise<ParkingSpaceStatus> => {
	const { customerId, id } = opts;
	const request = new GetParkingSpaceStatusRequest();
	request.id = id;
	request.customerId = customerId;
	return parkingApiService.get(request);
};

const setEngineerModeCall = (opts: {
	customerId: string;
	id: string;
  }): Promise<ParkingSpaceStatus> => {
	const { customerId, id } = opts;
	const request = new SetParkingSpaceEngineerModeRequest();
	request.customerId = customerId;
	request.id = id;
	return parkingApiService.post(request);
};

const setMoveStepSpaceCall = (opts: {
	customerId: string;
	id: string;
	open:boolean;
	description:string;
  }): Promise<ParkingSpaceStatusBase> => {
	const {
		customerId, id, open, description,
	} = opts;
	const request = new MoveStepParkingSpaceRequest();
	request.customerId = customerId;
	request.id = id;
	request.open = open;
	request.description = description;
	return parkingBaseApiService.post(request);
};

const setOpenSpaceCall = (opts: {
	customerId: string;
	id: string;
  }): Promise<ParkingSpaceStatusBase> => {
	const { customerId, id } = opts;
	const request = new OpenParkingSpaceRequest();
	request.customerId = customerId;
	request.id = id;
	request.force = true;
	return parkingBaseApiService.post(request);
};

export function* moveStepParkingSpaceRequest(
	action: IAction<{
	id: string;
	customerId: string;
	open:boolean;
	description:string;
	callbackMoveStep?: (item: ParkingSpaceStatusBase) => void;
	}>,
) {
	try {
		yield put(actions.app.saga.asyncAddLoading('moveStepParkingSpaceRequest'));
		const {
			payload: {
				customerId, id, open, description, callbackMoveStep,
			},
		} = action;

		const { result, timeOut } = yield race({
			result: call(setMoveStepSpaceCall, {
				customerId,
				id,
				open,
				description,
			}),
			timeOut: delay(3 * 60 * 1000),
		});

		if (result) {
			yield callbackMoveStep?.(result);
			yield put(
				actions.app.saga.asyncInitNotification({
					messages: 'Команда выполнена успешно',
					type: 'susses',
				}),
			);
		} else {
			yield put(
				actions.app.saga.asyncInitNotification({
					messages: 'Превышено время ожидания от сервера',
					type: 'warm',
				}),
			);
		}
		yield put(actions.app.saga.asyncRemoveLoading('moveStepParkingSpaceRequest'));
	} catch (error) {
		yield put(actions.app.saga.asyncRemoveLoading('moveStepParkingSpaceRequest'));
		yield put(
			actions.app.saga.asyncInitNotification({
				messages: errorApiService.catchRequestError(error),
				type: 'warm',
			}),
		);
	}
}

export function* openParkingSpaceRequest(
	action: IAction<{
	id: string;
customerId: string;
callbackOpen?: (item: ParkingSpaceStatusBase) => void;
	}>,
) {
	try {
		yield put(actions.app.saga.asyncAddLoading('openParkingSpaceRequest'));
		const {
			payload: { customerId, id, callbackOpen },
		} = action;

		const { result, timeOut } = yield race({
			result: call(setOpenSpaceCall, {
				customerId,
				id,
			}),
			timeOut: delay(3 * 60 * 1000),
		});

		// const result: ParkingSpaceStatus = yield call(setOpenSpaceCall, {
		//   customerId,
		//   id,
		// });
		if (result) {
			yield callbackOpen?.(result);
			yield put(
				actions.app.saga.asyncInitNotification({
					messages: 'Команда выполнена успешно',
					type: 'susses',
				}),
			);
		} else {
			yield put(
				actions.app.saga.asyncInitNotification({
					messages: 'Превышено время ожидания от сервера',
					type: 'warm',
				}),
			);
		}

		yield put(actions.app.saga.asyncRemoveLoading('openParkingSpaceRequest'));
	} catch (error) {
		yield put(actions.app.saga.asyncRemoveLoading('openParkingSpaceRequest'));
		yield put(
			actions.app.saga.asyncInitNotification({
				messages: errorApiService.catchRequestError(error),
				type: 'warm',
			}),
		);
	}
}

export function* setParkingSpaceEngineerModeRequest(
	action: IAction<{
	id: string;
	customerId: string;
	callbackEngineer?: (item: ParkingSpaceStatus) => void;
	}>,
) {
	try {
		yield put(actions.app.saga.asyncAddLoading('setParkingSpaceEngineerMode'));
		const {
			payload: { customerId, id, callbackEngineer },
		} = action;
		const result: ParkingSpaceStatus = yield call(setEngineerModeCall, {
			customerId,
			id,
		});
		yield callbackEngineer?.(result);
		yield put(
			actions.app.saga.asyncRemoveLoading('setParkingSpaceEngineerMode'),
		);
	} catch (error) {
		yield put(actions.app.saga.asyncRemoveLoading('setParkingSpaceEngineerMode'));
		yield put(
			actions.app.saga.asyncInitNotification({
				messages: errorApiService.catchRequestError(error),
				type: 'warm',
			}),
		);
	}
}
export function* getParkingSpaceStatusRequest(
	action: IAction<{
	customerId: string;
	id: string;
	callbackParkingSpaceStatus?: (item: ParkingSpaceStatus) => void;
	}>,
) {
	try {
		yield put(actions.app.saga.asyncAddLoading('getParkingSpaceStatusRequest'));
		const {
			payload: { customerId, id, callbackParkingSpaceStatus },
		} = action;
		const result: ParkingSpaceStatus = yield call(parkingStatusSpaceCall, {
			customerId,
			id,
		});
		yield callbackParkingSpaceStatus?.(result);
		yield put(actions.app.saga.asyncRemoveLoading('getParkingSpaceStatusRequest'));
	} catch (error) {
		yield put(actions.app.saga.asyncRemoveLoading('getParkingSpaceStatusRequest'));
		yield put(
			actions.app.saga.asyncInitNotification({
				messages: errorApiService.catchRequestError(error),
				type: 'warm',
			}),
		);
	}
}
export function* getParkingSpacesRequest(
	action: IAction<{
		customerId: string;
        page: number;
        items: number;
        sort: string;
        filter: string;
        callbackParkingSpaces?: (item:GetParkingSpacesResponse) => void;}>,
) {
	try {
		yield put(actions.app.saga.asyncAddLoading('getParkingSpacesRequest'));
		const {
			payload: {
				customerId, page, items, sort, filter, callbackParkingSpaces,
			},
		} = action;
		const result: GetParkingSpacesResponse = yield call(
			getParkingSpacesRequestCall,
			{
				page,
				items,
				sort,
				filter,
				customerId,
			},
		);
		yield callbackParkingSpaces?.(result);

		yield put(actions.app.saga.asyncRemoveLoading('getParkingSpacesRequest'));
	} catch (error) {
		yield put(actions.app.saga.asyncRemoveLoading('getParkingSpacesRequest'));
		yield put(
			actions.app.saga.asyncInitNotification({
				messages: errorApiService.catchRequestError(error),
				type: 'warm',
			}),
		);
	}
}
export function* getParkingsRequest(
	action: IAction<{
		customerId: string;
        page: number;
        items: number;
        sort: string;
        filter: string;
        callbackParkings?: (item:GetParkingsResponse) => void;}>,
) {
	try {
		yield put(actions.app.saga.asyncAddLoading('getParkingsRequest'));
		const {
			payload: {
				customerId, page, items, sort, filter, callbackParkings,
			},
		} = action;
		const result: GetParkingsResponse = yield call(
			getParkingsRequestCall,
			{
				page,
				items,
				sort,
				filter,
				customerId,
			},
		);
		yield callbackParkings?.(result);

		yield put(actions.app.saga.asyncRemoveLoading('getParkingsRequest'));
	} catch (error) {
		yield put(actions.app.saga.asyncRemoveLoading('getParkingsRequest'));
		yield put(
			actions.app.saga.asyncInitNotification({
				messages: errorApiService.catchRequestError(error),
				type: 'warm',
			}),
		);
	}
}
