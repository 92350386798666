import styled from 'styled-components';

export const Main = styled.div``;
export const CustomFieldCell = styled.div`
  display: flex;
  flex-direction: column;

  span:nth-child(1) {
    color: #ffffff;
  }
  span:nth-child(2) {
    color: #92929d;
  }
`;

export const ActiveParkingCell = styled.span`
  color: #a3a3a39c;
`;
