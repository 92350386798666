/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable @typescript-eslint/ban-types */
import { call, put, take } from 'redux-saga/effects';
import fileDownload from 'js-file-download';
import actions from 'store/actions';
import { saveAs } from 'file-saver';
import baseApiService from 'service/base-api.service';
import {
	AdminAccount,
	AdminOrder, AdminOrderCalculation, CalculateAdminOrderRequest, GetAdjustmentStatRequest,
	GetAdjustmentStatResponse, GetAdminAccountRequest,
	GetAdminAccountsRequest, GetAdminAccountsResponse,
	GetAdminOrdersRequest, GetAdminOrdersResponse, GetExportAdjustmentStatRequest, GetOrderStatusesRequest, GetTransactionsRequest,
	OrderStatusDetails, OrderStatusType, Transaction, UpdateAdminAccountRequest,
	UpdateAdminOrderRequest,
} from 'store/dto/dtos';
import { IAction } from 'store/utils';

export interface IPageOptAdminOrder{
	page: number;
    items: number;
    sort: string;
    filter: string;
	from:string;
	to:string;
	status:any;
	transactionSource:any;
}
const getAdminAccountsCall = (opts: {
	page: number;
    items: number;
    sort: string;
    filter: string;
}): Promise<GetAdminAccountsResponse> => {
	const request = new GetAdminAccountsRequest();
	request.filter = opts.filter;
	request.items = opts.items;
	request.page = opts.page;
	request.sort = opts.sort;
	return baseApiService.get(request);
};
const getAdminAccountCall = (opts: {
	id:string;
}): Promise<AdminAccount> => {
	const request = new GetAdminAccountRequest();
	request.id = opts.id;
	return baseApiService.get(request);
};
const updateAdminAccountsCall = (opts: {
	id:string;
	fake:boolean;
}): Promise<AdminAccount> => {
	const request = new UpdateAdminAccountRequest();
	request.id = opts.id;
	request.fake = opts.fake;
	return baseApiService.put(request);
};
const getTransactionsRequestCall = (opts: {
	orderId:string;
}): Promise<Transaction[]> => {
	const request = new GetTransactionsRequest();
	request.orderId = opts.orderId;
	return baseApiService.get(request);
};

const getOrderStatusesRequestCall = (opts: {
	orderId:string;
}): Promise<OrderStatusDetails[]> => {
	const request = new GetOrderStatusesRequest();
	request.orderId = opts.orderId;
	return baseApiService.get(request);
};

const getAdminOrdersCall = (opts: IPageOptAdminOrder): Promise<GetAdminOrdersResponse> => {
	const request = new GetAdminOrdersRequest();
	request.filter = opts.filter;
	request.items = opts.items;
	request.page = opts.page;
	request.sort = opts.sort;
	request.from = opts.from;
	request.to = opts.to;
	request.status = opts.status && opts.status;
	request.transactionSource = opts.transactionSource && opts.transactionSource;
	return baseApiService.get(request);
};
const updateAdminOrdersCall = (opts:
	{id:string; newAmount:number; newDurationTill:string;
		newStatusType:OrderStatusType; description:string; refund:boolean; }): Promise<AdminOrder> => {
	const request = new UpdateAdminOrderRequest();
	request.id = opts.id;
	// request.newAmount = opts.newAmount;
	request.newDurationTill = opts.newDurationTill ? opts.newDurationTill : '';
	request.newStatusType = opts.newStatusType ? opts.newStatusType : undefined;
	request.description = opts.description;
	request.refund = opts.refund;
	return baseApiService.putCall(request);
};
const calculateAdminOrdersCall = (opts:
	{id:string, durationTill:string }): Promise<AdminOrderCalculation> => {
	const request = new CalculateAdminOrderRequest();
	request.id = opts.id;
	request.durationTill = opts.durationTill;
	return baseApiService.get(request);
};

const getAdjustmentStatRequestCall = (opts: {
	page: number;
	items: number;
	sort: string;
	filter: string;
	from:string;
	to:string;
	parkingSpaceId:string;
	parkingId:string;

}): Promise<GetAdjustmentStatResponse> => {
	const request = new GetAdjustmentStatRequest();
	request.filter = opts.filter;
	request.items = opts.items;
	request.page = opts.page;
	request.sort = opts.sort;
	request.from = opts.from;
	request.to = opts.to;
	request.parkingSpaceId = opts.parkingSpaceId;
	request.parkingId = opts.parkingId;
	return baseApiService.get(request);
};
const getExportAdjustmentStatRequestCall = (opts: {
	from:string;
	to:string;
	parkingSpaceId:string;
	parkingId:string;
}): Promise<Uint8Array> => {
	const request = new GetExportAdjustmentStatRequest();
	request.from = opts.from;
	request.to = opts.to;
	request.parkingSpaceId = opts.parkingSpaceId;
	request.parkingId = opts.parkingId;
	return baseApiService.get(request);
};

export function* updateAdminOrderRequest(
	action:IAction<{
		id:string;
		newAmount:number;
		newDurationTill:string;
		newStatusType:OrderStatusType;
		description:string;
		refund:boolean;
		callback?: () => void;
	}>,
) {
	try {
		const { payload } = action;
		yield put(actions.app.saga.asyncAddLoading('loadingAdminOrders'));
		yield call(updateAdminOrdersCall, payload);
		yield put(actions.app.saga.asyncRemoveLoading('loadingAdminOrders'));
		yield action.payload.callback?.();
		yield put(actions.app.setShowAdminOrderEditForm(false));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingAdminOrders'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}

export function* getAdminOrdersRequest(action: IAction<IPageOptAdminOrder>) {
	try {
		const {
			payload: {
				page, items, sort, filter, from, to, status, transactionSource,
			},
		} = action;
		yield put(actions.app.saga.asyncAddLoading('loadingAdminOrders'));
		const result: GetAdminOrdersResponse = yield call(getAdminOrdersCall, {
			page, items, sort, filter, from, to, status, transactionSource,
		});
		yield put(actions.data.setAdminOrders(result));
		yield put(actions.app.saga.asyncRemoveLoading('loadingAdminOrders'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingAdminOrders'));
		yield put(actions.data.setAdminOrders({} as GetAdminOrdersResponse));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}

export function* getOrderStatusesRequest(
	action:IAction<{
		orderId:string;
		callbackOrderStatuses: (item:OrderStatusDetails[]) => void;
	}>,
) {
	try {
		const {
			payload: { orderId, callbackOrderStatuses },
		} = action;
		yield put(actions.app.saga.asyncAddLoading('getOrderStatusesRequest'));
		const result: OrderStatusDetails[] = yield call(getOrderStatusesRequestCall, { orderId });
		yield put(actions.app.saga.asyncRemoveLoading('getOrderStatusesRequest'));
		yield callbackOrderStatuses?.(result);
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('getOrderStatusesRequest'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}

export function* updateAdminAccountRequest(
	action:IAction<{
		id:string;
		fake:boolean;
		callbackUpdateAdminAccount?: () => void;
	}>,
) {
	try {
		const { payload } = action;
		yield put(actions.app.saga.asyncAddLoading('loadingUpdateAdminAccountCalls'));
		yield call(updateAdminAccountsCall, payload);
		yield put(actions.app.saga.asyncRemoveLoading('loadingUpdateAdminAccountCalls'));
		yield action.payload.callbackUpdateAdminAccount?.();
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingUpdateAdminAccountCalls'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}

export function* getTransactionsRequest(
	action:IAction<{
		orderId:string;
		callbackGetTransactions: (item:Transaction[]) => void;
	}>,
) {
	try {
		const {
			payload: { orderId, callbackGetTransactions },
		} = action;
		yield put(actions.app.saga.asyncAddLoading('getTransactionsRequest'));
		const result: Transaction[] = yield call(getTransactionsRequestCall, { orderId });
		yield put(actions.app.saga.asyncRemoveLoading('getTransactionsRequest'));
		yield callbackGetTransactions?.(result);
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('getTransactionsRequest'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}

export function* getAdminAccountRequest(
	action: IAction<{
	id: string;
	callbackAdminAccount?: (item: AdminAccount) => void;
	}>,
) {
	try {
		yield put(
			actions.app.saga.asyncAddLoading('getAdminAccountLoading'),
		);
		const {
			payload: { id, callbackAdminAccount },
		} = action;
		const result: AdminAccount = yield call(getAdminAccountCall, { id });
		yield callbackAdminAccount?.(result);
		yield put(
			actions.app.saga.asyncRemoveLoading('getAdminAccountLoading'),
		);
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('getAdminAccountLoading'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
export function* getAdminAccountsRequest(
	action: IAction<{
		page: number;
    items: number;
    sort: string;
    filter: string;
	callbackAdminAccounts?: (item: GetAdminAccountsResponse) => void;
	}>,
) {
	try {
		yield put(
			actions.app.saga.asyncAddLoading('getAdminAccountsLoading'),
		);
		const {
			payload: {
				page, items, sort, filter, callbackAdminAccounts,
			},
		} = action;
		const result: GetAdminAccountsResponse = yield call(getAdminAccountsCall, {
			page, items, sort, filter,
		});
		yield callbackAdminAccounts?.(result);
		yield put(
			actions.app.saga.asyncRemoveLoading('getAdminAccountsLoading'),
		);
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('getAdminAccountsLoading'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
export function* calculateAdminOrderRequest(
	action: IAction<{
		id: string;
		durationTill:string;
		callbackCalculateAdminOrder?: (item: AdminOrderCalculation) => void;
	}>,
) {
	try {
		yield put(
			actions.app.saga.asyncAddLoading('calculateAdminOrder'),
		);
		const {
			payload: {
				id, durationTill, callbackCalculateAdminOrder,
			},
		} = action;
		const result: AdminOrderCalculation = yield call(calculateAdminOrdersCall, {
			id, durationTill,
		});
		yield callbackCalculateAdminOrder?.(result);
		yield put(
			actions.app.saga.asyncRemoveLoading('calculateAdminOrder'),
		);
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('calculateAdminOrder'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}

export function* getAdjustmentStatRequest(
	action: IAction<{
		page: number;
		items: number;
		sort: string;
		filter: string;
		from:string;
		to:string;
		parkingSpaceId:string;
		parkingId:string;
		callbackAdjustmentStat?: (item: GetAdjustmentStatResponse) => void;
	}>,
) {
	try {
		yield put(
			actions.app.saga.asyncAddLoading('getAdjustmentStatRequest'),
		);
		const {
			payload: {
				page, items, sort, filter, from, to, parkingSpaceId, parkingId, callbackAdjustmentStat,
			},
		} = action;
		const result: GetAdjustmentStatResponse = yield call(getAdjustmentStatRequestCall, {
			page, items, sort, filter, from, to, parkingSpaceId, parkingId,
		});
		yield callbackAdjustmentStat?.(result);
		yield put(
			actions.app.saga.asyncRemoveLoading('getAdjustmentStatRequest'),
		);
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('getAdjustmentStatRequest'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
export function* getExportAdjustmentStatRequest(
	action: IAction<{
		from:string;
		to:string;
		parkingSpaceId:string;
		parkingId:string;
		callbackExportAdjustmentStat: (item: Uint8Array) => void;
	}>,
) {
	try {
		yield put(
			actions.app.saga.asyncAddLoading('getExportAdjustmentStatRequest'),
		);
		console.log('fileDownload1');
		const {
			payload: {
				from, to, parkingSpaceId, parkingId, callbackExportAdjustmentStat,
			},
		} = action;
		const result:Uint8Array = yield call(getExportAdjustmentStatRequestCall, {
			from, to, parkingSpaceId, parkingId,
		});
		// не доходит до сюда ?!?
		const blob = new Blob([result], { type: 'application/pdf' });
		saveAs(blob, 'file.pdf');

		yield callbackExportAdjustmentStat(result);
		yield put(
			actions.app.saga.asyncRemoveLoading('getExportAdjustmentStatRequest'),
		);
	} catch (error:any) {
		console.log(error);
		yield put(actions.app.saga.asyncRemoveLoading('getExportAdjustmentStatRequest'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
