import styled from 'styled-components';

export const WidgetBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  border: 2px solid #c5c5c582;
  background-color: #5d5d7012;
  border-radius: 10px;
  &.tables {
    font-size: 9px;
    color: red !important;
  }
`;
export const TitleWidget = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
`;

export const ControlElement = styled.div`
  .button-antd {
    /* padding: 0px 15px !important; */
    span {
      font-weight: 500;
      font-size: 15px !important;
    }
  }
`;
export const ContentWidget = styled.div`
  color: #fff;
  display: grid;
  grid-template-columns: 200px 50px;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 6px;

  .div1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .div2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .div3 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .div4 {
    grid-area: 2 / 2 / 3 / 3;
  }
`;

export const FooterWidget = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TableTest = styled.div`
  .ant-table-wrapper .ant-table-cell-scrollbar {
    box-shadow: none !important;
  }
  .ant-table-tbody .ant-table-cell-row-hover  {
  background: #26293b !important;
}


  & table {
    background-color: #171a26;
    /* min-height:400px !important; */

    & thead > tr > th {
      color: #fff !important;
      background-color: #2f3348 !important;
    }
    /*
    & thead > tr {
      border-width: "2px";
      border-color: "yellow";
      border-style: "solid";
    } */
  }
  .ant-table-thead > tr > th {
    background: #fdfdfd;
  }
`;
