import styled from 'styled-components';
import closeSVG from 'assets/icons/Profile/close.svg';
import iconCardSVG from 'assets/icons/Profile/iconCard.svg';

export const CloseSVG = styled(closeSVG)``;
export const CardIcon = styled(iconCardSVG)``;
export const MainProfileMobile = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction:column;
`;
export const BodyProfile = styled.div`
  position: relative;
  display:flex;
  /* min-height: 90vh; */
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 100px 16px 64px 16px;
  box-sizing: border-box;
  gap:57px;
`;

export const Main = styled.div`
  position: relative;

  margin: 0;
  /* margin-bottom:64px; */
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 100px 16px 57px 16px; */
  gap: 32px;
`;
export const Title = styled.div`
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
`;
export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

`;
export const ProfileInput = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;
export const ButtonLists = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const ElementButtonList = styled.div.attrs(
	(props: { color: string }) => props,
)`
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.1px;
  color: ${(props) => props.color};
`;
export const CardBlock = styled.div`
  margin-top:32px;
  margin-bottom:48px;
display:flex;
flex-direction:column;
gap:12px;
`;
export const TitleCardBlock = styled.div`
  font-style: normal;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #92929d;
`;

export const Card = styled.div`
  display: grid;
  background: #1d2335;
  border-radius: 8px;
  grid-template-columns: 0.2fr 1.2fr 0.2fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 18px;
  box-sizing: border-box;

`;
export const TitleCard = styled.div`
  grid-area: 1 / 1 / 2 / 3;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #92929d;
  }
`;
export const IconCard = styled.div`
cursor: pointer;
  grid-area: 2 / 1 / 3 / 2;
`;
export const NumberCard = styled.div`
  grid-area: 2 / 2 / 3 / 3;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
`;
export const DeleteCard = styled.div`
  align-self: center;
  text-align: end;
  grid-area: 1 / 3 / 3 / 4;
`;

export const FeedBackTitle = styled.div`
  margin-top: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #0679ff;
`;
