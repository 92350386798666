import styled from 'styled-components';

export const Form = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1px;

    /* App/Text gray */

    color: red;
  }
  /* &.locked{

  } */
`;
export const Label = styled.label`
  display: block;
  margin-bottom: 0.75rem;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #92929d;
`;
export const Input = styled.input`
  display: block;
  width: 100%;
  height: 48px;
  padding: 0.375rem 0.75rem;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #92929d;
  background: #1d2335;
  border: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color:#fff;
  &.error {
    /* border-color: #198754; */
    border: 1px solid red;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  &:focus {
    color: none;
    border: none;
    outline: 0;

  }
`;
