import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tokenService from 'service/token.service';
import actions from 'store/actions';
import { User } from 'store/dto/hub.dtos';
import selectors from 'store/selectors';

export default function useCheckSupportUser() {
	const [isTechSupport, setIsTechSupport] = React.useState<boolean>(true);
	const userData: User = useSelector(
		selectors.authorization.getUser,
	);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (tokenService.user === '' || tokenService.user === null) {
			if (!userData?.id) {
				const callbackCurrentUser = (value:User) => {
					setIsTechSupport(value.userName === 'tech.support');
				};
				dispatch(actions.authorization.saga.asyncGetCurrentUserCallBack({ callbackCurrentUser }));
			} else {
				setIsTechSupport(userData?.userName === 'tech.support');
			}
		} else {
			setIsTechSupport(JSON.parse(tokenService.user || '')?.userName === 'tech.support');
		}
	});

	return isTechSupport;
}
