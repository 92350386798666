import { device } from 'common/devices';
import styled from 'styled-components';

const TemplateTitle = styled.div`
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
  @media ${device.tablet600} {
    span {
      font-size: 24px;
    }
  }
`;

export const Main = styled.div`
  padding-bottom: 1px;

  @media ${device.tablet600} {
    padding-bottom: 0px;
    padding: 0px 16px;
    box-sizing: border-box;
  }
`;
export const BodyPage = styled.div`
  position: relative;

  padding-top: 265px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vw;
  margin-bottom: 5%;
  @media ${device.tablet600} {
    padding-top: 75px;
    padding-bottom: 69px;
    gap: 32px;
  }
`;

export const TitleMain = styled.div`
  position: absolute;
  left: 120px;
  top: 141px;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
  @media ${device.tablet600} {
    align-self: start;
    left: auto;
    top: auto;
    position: relative;
    span {
      font-size: 32px;
    }
  }
`;

export const Content = styled.div`
  /* position: absolute; */
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 64px;
  @media ${device.tablet600} {
    width: 100%;
    gap: 40px;
  }
`;
export const DescriptionContent = styled.div`
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #ffffff;
  }
`;

export const CallMeBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${device.tablet600} {
    width: 100%;
    gap: 24px;
  }
`;
export const CallMeBlockTitle = styled(TemplateTitle)`
  /* span {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #ffffff;
  } */
`;
export const CallMeBlockContent = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.tablet600} {
    flex-direction: column;
    gap: 24px;
  }
`;
export const CallMeBlockItem = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  span:nth-child(1) {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  span:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  span:nth-child(3) {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #595a68;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 120%;
      letter-spacing: 0.1px;
      color: #fff;
    }
  }
  @media ${device.tablet600} {
    width: 100%;
    gap: 12px;
    /* span:nth-child(1) {
    }
    span:nth-child(2) {
    }
    span:nth-child(3) {
    } */
  }
`;
export const CallMeBlockServ = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${device.tablet600} {
    width: 100%;
    gap: 24px;
  }
`;
export const CallMeBlockTitleServ = styled(TemplateTitle)`
  /* span {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #ffffff;
  } */
`;
export const CallMeBlockContentServ = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.tablet600} {
    flex-direction: column;
    gap: 24px;
  }
`;
export const CallMeBlockItemServ = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  span:nth-child(1) {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  span:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  span:nth-child(3) {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  span:nth-child(4) {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #595a68;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 120%;
      letter-spacing: 0.1px;
      color: #fff;
    }
  }

  a {
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #0679ff;
  }
  @media ${device.tablet600} {

  }
  @media ${device.tablet600} {
    width: 100%;
    gap: 12px;
    a {
      font-size: 24px;
    }
  }
`;

export const FeedBackBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${device.tablet600} {
    gap: 24px;
  }
`;
export const FeedBackTitle = styled(TemplateTitle)``;
export const FeedBackSubTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${device.tablet600} {
    flex-direction: column;
    gap: 24px;
  }
`;
export const FeedBackSubTitleDescription = styled.div`
  width: 45%;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  @media ${device.tablet600} {
    width: 100%;
  }
`;
export const FeedBackSubTitleEmail = styled.div`
  width: 50%;
  align-self: center;
  a {
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #0679ff;
  }
  @media ${device.tablet600} {
    width: 100%;
    a {
      font-size: 24px;
    }
  }
`;

export const NotificationModal = styled.div`
  position: fixed;
  /* position:relative; */
  width: 351px;
  /* max-width:500px; */
  /* height: 100px; */
  background-color: #171725;
  z-index: 10000;
  bottom: 3%;
  left: 20px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 25px;
  box-sizing: border-box;
  border: 2px solid #ff8a00;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */
    color: #ffffff;
  }
  .close-icon-notification-modal {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  @media ${device.tablet600} {
    min-width: calc(100vw - 40px);
    width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
  }
`;

export const FormContacts = styled.form`
  margin-top: 32px;
  .button-contacts {
    width: 20%;
    margin-top: 24px;
    button {
      font-size: 16px !important;
    }
  }
  .phone-contacts {
    width: 40%;
    margin-bottom: 16px;
  }
  .email-contacts {
    width: 60%;
    margin-bottom: 16px;
  }
  .name-contacts {
    width: 60%;
    margin-bottom: 16px;
  }
  .area-contacts {
    margin-bottom: 32px;
  }
  @media ${device.tablet600} {
    margin-top: 40px;
    .phone-contacts {
      width: 90%;
      margin-bottom: 16px;
    }
    .email-contacts {
      width: 100%;
      margin-bottom: 16px;
    }
    .name-contacts {
      width: 100%;
      margin-bottom: 16px;
    }
    .area-contacts {
      margin-bottom: 32px;
    }

    .button-contacts {
      margin-top: 24px;
      button {
        width: auto;
        font-size: 16px !important;
      }
    }
  }
`;
