import { AuthenticateResponse } from 'store/dto/dtos';
import IUserState from 'store/interfaces/User.Interface';
import { IAction } from 'store/utils';

export const initialState: IUserState = {
	item: new AuthenticateResponse(),
};

const reducer = (state = initialState, action: IAction<any>): IUserState => {
	const { payload } = action;

	switch (action.type) {
	case 'SET_USER':
		return { ...state, item: payload };
	default:
		return state;
	}
};

export default reducer;
