/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-alert */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { useDispatch } from 'react-redux';
import 'dayjs/locale/ru';
import actions from 'store/actions';

import { AcquiringInfo } from 'store/dto/dtos';
import {
	ContentWidget, TitleWidget, WidgetBox,
} from './styledComponent';

// const DataWidget = React.forwardRef((props:IDataWidget, ref) => {
const AcquiringWidget: React.FC = () => {
	const dispatch = useDispatch();
	const [acquiringInfoData, setAcquiringInfoData] = React.useState<AcquiringInfo>(new AcquiringInfo());

	const updateData = () => {
		const callbackAcquiring = (value:AcquiringInfo) => {
			setAcquiringInfoData(value);
		};
		dispatch(actions.data.saga.asyncGetAcquiring({
			callbackAcquiring,
		}));
	};

	React.useEffect(() => {
		updateData();
	}, []);

	return (
		<WidgetBox>
			<TitleWidget><span>Эквайринг</span></TitleWidget>
			<ContentWidget>
				{/* <div className="div1">Сумма:</div>
				<div className="div2">
					{statisticsData.totalPaymentsAmount?.toLocaleString()}
					{' '}
					₽
				</div>
				<div className="div3">Количество аккаунтов:</div>
				<div className="div4">
					{statisticsData.registrationCount?.toLocaleString()}
					{' '}
					шт
				</div>
				<div className="div5">Количество заказов:</div>
				<div className="div6">
					{statisticsData.orderCount?.toLocaleString()}
					{' '}
					шт
				</div> */}

				{acquiringInfoData?.values
					&& Object.entries(acquiringInfoData?.values).map(([key, item], i) => (
						<div className="blockAcquiring" key={i}>
							<div className="nameAcquiring">
								{key}
								:
							</div>
							<div className="valueAcquiring">
								{item}
							</div>
						</div>
					))}

			</ContentWidget>
		</WidgetBox>

	);
};

export default AcquiringWidget;
