import { TDevice } from 'common/devices';
import useWindowDimensions from 'common/useWindowDimensions';
import FooterBar from 'components/layout/FooterBar';
import HeaderBar from 'components/layout/HeaderBar';
import LoadingComponent from 'components/layout/Loading';
import React from 'react';
import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import tokenService from 'service/token.service';
import { MyAccountInfo } from 'store/dto/dtos';
import selectors from 'store/selectors';
// import actions from 'store/actions';
import ResetPasswordFirstPage from './FirstStep';
import ResultResetPasswordPage from './ResultResetPassword';
import ResetPasswordSecondPage from './SecondStep';
import {
	Body, FooterElement, HeaderElement, Main, Title,
} from './styledComponent';

const ResetPasswordPage: React.FC = () => {
	// const dispatch = useDispatch();
	const { pathname } = useLocation();
	const accountData : MyAccountInfo = useSelector(selectors.data.getMyAccountInfo) || [];
	const [phoneNumber, setPhoneNumber] = React.useState(accountData ? accountData.phone : '');
	const { width } = useWindowDimensions();

	const getDeviceType = (): TDevice => {
		if (width <= 768) return 'mobile';
		return 'laptop';
	};

	return (
		<>
			<LoadingComponent loadingElement="resetPasswordLoading" />
			<Main isLogin={tokenService.isLoggedIn}>

				{getDeviceType() === 'mobile'
&& <HeaderElement><HeaderBar /></HeaderElement>}

				<Body style={{
					justifyContent: `${
						(pathname.split('/')[2] === 'success' || pathname.split('/')[2] === 'error')
							? 'center' : 'start'}`,
				}}
				>
					{(pathname.split('/')[2] === 'second-step' || pathname.split('/')[2] === 'first-step')
		&& <Title><span>{tokenService.isLoggedIn ? 'Изменить пароль' : 'Сбросить пароль '}</span></Title>}
					{pathname.split('/')[2] === 'first-step' && (
						<ResetPasswordFirstPage
							setPhone={(phone:string) => setPhoneNumber(phone)}
							phoneStart={accountData ? accountData.phone : ''}
						/>
					)}
					{pathname.split('/')[2] === 'second-step' && <ResetPasswordSecondPage phone={phoneNumber} />}
					{pathname.split('/')[2] === 'success' && <ResultResetPasswordPage success />}
					{pathname.split('/')[2] === 'error' && <ResultResetPasswordPage success={false} />}

				</Body>
				<FooterElement>
					<FooterBar />
				</FooterElement>

			</Main>
		</>

	);
};

export default ResetPasswordPage;
