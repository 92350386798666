import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import selectors from 'store/selectors';
import InputAdornment from '@mui/material/InputAdornment';
// import Moment from 'react-moment';
// import moment from 'moment';
import Button from '@mui/material/Button';
import actions from 'store/actions';
import { Tariff } from 'store/dto/dtos';
import {
	ButtonElement, ContentForm, EditForm, TimeElements, TitleForm,
} from './styledComponent';

const TariffEditForm: React.FC = () => {
	const show: boolean = useSelector(selectors.app.getShowTariffEditForm);
	const selectTariff: Tariff = useSelector(selectors.data.getTariff);
	const dispatch = useDispatch();
	const [valueTo, setValueTo] = React.useState<any>();
	const [valueFrom, setValueFrom] = React.useState<any>();
	const [valueNumber, setValueNumber] = React.useState<any>();
	const handleChangeTo = (newValue:any) => {
		setValueTo(newValue);
	};
	const handleChangeFrom = (newValue:any) => {
		setValueFrom(newValue);
	};
	const handleChangeNumber = (event:any) => {
		setValueNumber(event.target.value);
	};

	const cancelForm = () => {
		dispatch(actions.data.setTariff({} as Tariff));
		dispatch(actions.app.setShowTariffEditForm(false));
	};

	const updateTariff = () => {
		if (selectTariff.id) {
			dispatch(actions.data.saga.asyncUpdateTariff({
				id: selectTariff.id,
				item: {
					id: selectTariff.id, from: valueFrom, to: valueTo, hourlyRate: valueNumber,
				} as Tariff,
			}));
		} else {
			dispatch(actions.data.saga.asyncCreateTariff(
				{
					from: valueFrom,
					to: valueTo,
					hourlyRate: valueNumber,
				} as Tariff,
			));
		}
	};
	React.useEffect(() => {
		setValueTo(selectTariff.to);
		setValueFrom(selectTariff.from);
		setValueNumber(selectTariff.hourlyRate);
	}, [selectTariff]);
	return (
		// <Body className={show ? 'show' : 'out'}>
		<EditForm className={show ? 'show' : 'out'}>
			<TitleForm>
				{selectTariff.id ? 'Редактировать' : 'Добавить' }
			</TitleForm>
			<ContentForm>
				<TimeElements>
					<div style={{ maxWidth: '150px' }}>
						<TimePicker
							label="Начало"
							value={valueFrom}
							onChange={handleChangeFrom}
							ampm={false}
							renderInput={(params) => (
								<TextField
									{...params}
								/>
							)}
						/>
					</div>
					<div style={{ maxWidth: '150px' }}>
						<TimePicker
							label="Конец"
							value={valueTo}
							onChange={handleChangeTo}
							ampm={false}
							renderInput={(params) => <TextField {...params} />}
						/>
					</div>

					<FormControl style={{ maxWidth: '100px' }}>
						<InputLabel htmlFor="outlined-adornment-amount">Стоимость</InputLabel>
						<OutlinedInput
							id="outlined-adornment-amount"
							value={valueNumber}
							onChange={handleChangeNumber}
							startAdornment={<InputAdornment position="end">₽</InputAdornment>}
							label="Стоимость"
						/>
					</FormControl>
				</TimeElements>
			</ContentForm>
			<ButtonElement>
				<Button onClick={() => cancelForm()}>Отмена</Button>
				<Button onClick={updateTariff} autoFocus>
					{selectTariff.id ? 'Обновить' : 'Создать' }
				</Button>
			</ButtonElement>
		</EditForm>
		// </Body>
	);
};

export default TariffEditForm;
