/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import {
	Form as FormPhone, Input as InputPhone, Label as LabelPhone, Prefix as PrefixPhone,
} from 'components/forms/PhoneInput/styledComponent';
import { useForm } from 'react-hook-form';
import { Button } from 'components/forms/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { MyAccountInfo } from 'store/dto/dtos';
import tokenService from 'service/token.service';
import {
	ButtonSection, Main, ProfileInput, Title,
} from './styledComponent';

interface IResetPasswordFirstPage {
	phoneStart: string;
	setPhone: (str: string) => void;
}
type FormData = {
	phone: string;
  };

const ResetPasswordFirstPage: React.FC<IResetPasswordFirstPage> = (props) => {
	const { phoneStart, setPhone } = props;
	const {
		register, handleSubmit, formState: { errors }, setValue,
	} = useForm<FormData>();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const accountData : MyAccountInfo = useSelector(selectors.data.getMyAccountInfo) || [];
	const onSubmit = handleSubmit((data) => {
		const { phone } = data;
		dispatch(actions.authorization.saga.asyncVerification({ number: `+7${phone}`, reset: true }));
		setPhone(phone);
		navigate('/reset-password/second-step');
	});

	phoneStart ? setValue('phone', phoneStart.slice(2, phoneStart.length))
		: accountData ? setValue('phone', accountData.phone?.slice(2, phoneStart.length))
			: setValue('phone', '');

	useEffect(() => {
		(!phoneStart && tokenService.isLoggedIn) && dispatch(actions.data.saga.asyncGetAcountInfo(''));
	}, []);
	return (
		<Main>
			<Title><span>Укажите ваш номер телефона, чтобы мы отправили вам код подтверждения.</span></Title>

			<ProfileInput onSubmit={onSubmit}>

				<FormPhone>
					<LabelPhone htmlFor="input-field">Номер телефона</LabelPhone>
					<PrefixPhone>
						<InputPhone
							placeholder="Ваш номер телефона"
							autoComplete="off"
							{...register('phone', {
								required: true,
								maxLength: 10,
								pattern: /\(?([0-9]{3})\)?([-]?)([0-9]{3})\2([0-9]{4})/,
							})}
						/>

					</PrefixPhone>
					{errors.phone && <p>Некорректный номер телефона</p>}

				</FormPhone>

				<ButtonSection>
					<Button onClick={() => navigate('/profile')}> Назад</Button>
					<Button type="submit"> Далее</Button>
				</ButtonSection>
			</ProfileInput>
		</Main>
	);
};

export default ResetPasswordFirstPage;
