/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { call, put } from 'redux-saga/effects';
import { SendSupportMessageRequest } from 'store/dto/dtos';
import baseApiService from 'service/base-api.service';
import { IAction } from 'store/utils';
import actions from 'store/actions';

// import { useNavigate } from 'react-router-dom';

// interface IJWTParse {
//    email: string;
//    roles: string;
//    Id: string;
// }

export interface IAsyncCall {
    phone:string;
    message:string;
    name:string;
	email:string;
}
// eslint-disable-next-line no-unused-vars
const sendMessageSupport = (opts: IAsyncCall): Promise<string> => {
	const {
		phone, message, name, email,
	} = opts;
	const request = new SendSupportMessageRequest();
	request.message = message;
	request.phone = phone;
	request.name = name;
	request.email = email;
	return baseApiService.post(request);
};

export function* sendMessages(action: IAction<IAsyncCall>) {
	try {
		yield put(actions.app.saga.asyncAddLoading('buttonSendMessages'));
		const {
			payload: {
				phone, message, name, email,
			},
		} = action;
		const result: string = yield call(sendMessageSupport, {
			phone, message, name, email,
		});

		yield put(actions.app.saga.asyncInitNotification({
			messages:
			`Сообщение успешно отправлено. Номер заявки ${result}`,
			type: 'warm',
		}));
		yield put(actions.app.setSuccessfulApi(['SendSupportMessageRequest']));
		yield put(actions.app.saga.asyncRemoveLoading('buttonSendMessages'));
	} catch (error:any) {
		console.log('error', error?.responseStatus?.message);
		yield put(actions.app.saga.asyncRemoveLoading('buttonSendMessages'));
	}
}
