export interface IPageOpt {
    page: number;
    items: number;
    sort: string;
    filter: string;
}

export interface IAction<TPayload> {
    type: string;
    payload: TPayload;
}

export interface IActionCreator<TPayload> {
    type: string;
    (payload: TPayload): IAction<TPayload>;
}
export const actionCreator = <TPayload>(type: string): IActionCreator<TPayload> => Object.assign(
	(payload: TPayload) => ({ type, payload }),
	{ type },
);
