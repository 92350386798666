/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { TDevice } from 'common/devices';
import useWindowDimensions from 'common/useWindowDimensions';
import { Button } from 'components/forms/Button';
import LoadingComponent from 'components/layout/Loading';
// import LoadingComponent from 'components/layout/Loading';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import actions from 'store/actions';
import {
	GetActiveOrderResponse,
	GetParkingSpacesResponse,
	Parking, ParkingSpace, ParkingSpaceStatus, ParkingSpaceStatusType, SystemStatus,
} from 'store/dto/dtos';
import selectors from 'store/selectors';
import { Modal } from 'antd';
// import { dataTest } from 'common/data/dataTest';
import { refreshAuth } from 'store/saga/authorization/tokenWorkers';
import {
	SectionContent,
	ParkingPlace,
	TitleSection,
	BackArrow,
	BackArrowIcon,
	ControlElement,
	MainBlock,
	TopElement,
} from './styledComponent';

const SelectMapElement: React.FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const systemStatus: SystemStatus = useSelector(selectors.data.getCurrentSystemStatus);
	const parkingSpaceData : GetParkingSpacesResponse = useSelector(selectors.data.getParkingSpace) || [];
	const parkingData : Parking = useSelector(selectors.data.getCurrentParking) || undefined;
	const [showBookingControl, setShowBookingControl] = React.useState(false);
	const [activeParking, setActiveParking] = React.useState<ParkingSpace>(new ParkingSpace());
	const activeOrder: GetActiveOrderResponse = useSelector(selectors.data.getActiveOrder) || null;
	const { width } = useWindowDimensions();
	const getDeviceType = (): TDevice => {
		if (width <= 768) return 'mobile';
		return 'laptop';
	};
	const [isModalOpen, setIsModalOpen] = React.useState(false);

	const clickBack = () => {
		navigate('/map/search');
		dispatch(actions.data.setCurrentParking(new Parking()));
	};

	const refresh = () => {
		dispatch(actions.data.saga.asyncGetParkingSpaces(parkingData.id));
	};

	const setParking = (item:ParkingSpace) => {
		dispatch(actions.data.saga.asyncGetParkingSpaces(parkingData.id));
		if (item.status.status === ParkingSpaceStatusType.Free) {
			setShowBookingControl(true);
			setActiveParking(item);
		} else {
			setShowBookingControl(false);
			setActiveParking(new ParkingSpace());
		}
	};
	const setBookingParking = () => {
		dispatch(actions.data.setCurrentParkingSpace(activeParking));
		dispatch(actions.parkingManipulation.saga.asyncActiveAndBookingParkingSpace({ id: activeParking.id }));
		setShowBookingControl(false);
		// dispatch(actions.app.setShowBooking(true));
	};
	const getColor = (status:ParkingSpaceStatus) => {
		switch (status.status) {
		case (ParkingSpaceStatusType.Free):
			return '#33CC33';
		case (ParkingSpaceStatusType.Busy):
			return '#E74A4F';
		case (ParkingSpaceStatusType.Booked):
			return '#E74A4F';
		case (ParkingSpaceStatusType.OfflineClosed):
			return '#E74A4F';
		case (ParkingSpaceStatusType.OfflineOpen):
			return '#E74A4F';
		default:
			return '#E74A4F';
		}
	};

	const showModal = () => {
		const callback = (items:SystemStatus) => {
			if (items.orderDisabled) {
				dispatch(actions.app.setShowWarningPopup(true));
			} else {
				setIsModalOpen(true);
			}
		};

		dispatch(actions.data.saga.asyncGetCurrentSystemStatus({ callback }));
	};

	const handleOk = () => {
		setBookingParking();
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<MainBlock>
			{getDeviceType() === 'mobile' && <LoadingComponent loadingElement="loadingParkingSpaces" />}
			<TopElement>
				<div className="header-select">
					<BackArrow onClick={() => clickBack()}>
						<BackArrowIcon />
						<span>Назад</span>

					</BackArrow>

					<div className="refresh-select" onClick={() => refresh()}>
						<span>Обновить</span>
					</div>
				</div>
				<TitleSection><span>{parkingData.number }</span></TitleSection>
			</TopElement>
			<SectionContent className={showBookingControl ? 'active' : 'out'}>

				{parkingSpaceData?.items?.map((item:ParkingSpace, index) => (
					<ParkingPlace
						key={item.id}
						borderTop={index === 0 ? '2px dashed #333C56' : 'none'}
						colorBackground={getColor(item.status)}
						onClick={() => setParking(item)}
						className={activeParking.number === item.number && activeParking.status.status ? 'actived' : ''}
					>
						<span>{item.number}</span>
					</ParkingPlace>
				))}

			</SectionContent>
			{/* <ControlElement className={showBookingControl ? 'active' : 'out'}>

				<Button onClick={() => { showModal(); }}>Открыть замок</Button>
			</ControlElement>
			<ControlElement className={showBookingControl ? 'active' : 'out'}>

				<Button onClick={() => { showModal(); }}>Открыть замок</Button>
			</ControlElement>
			*/}

			{!activeOrder.hasActiveOrder
				? (
					<ControlElement className={showBookingControl ? 'active' : 'out'}>

						<Button
							className={(systemStatus.orderDisabled) ? 'disabled-button' : ''}
							onClick={() => { showModal(); }}
						>
							Открыть замок

						</Button>
					</ControlElement>
				)
				: (
					<ControlElement className={showBookingControl ? 'active' : 'out'}>

						<div className="ControlElementText">
							Оплатите активную парковку, прежде чем начинать новую.
						</div>
					</ControlElement>
				)}

			<Modal
				okText="Подтвердить"
				title="Внимание"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<p>После нажатия на кнопку &quot;Подтвердить&quot; замок сразу откроется и начнется оплата.</p>

			</Modal>
		</MainBlock>
	);
};

export default SelectMapElement;
