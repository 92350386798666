import tokenService from './token.service';

const errorApiService = {

	catchRequestError(e:any) {
		console.log('catchRequestError', e);
		if (e?.responseStatus) {
			console.log('catchRequestError', e.responseStatus);
			switch (e.responseStatus.errorCode) {
			case 'TokenException':
				tokenService.logout();
				return 'Токен истек';
			case 'RefreshTokenException':
				tokenService.logout();
				return 'Токен истек';
			case 'Unauthorized':
				tokenService.logout();
				return 'Неправильное имя или пароль';
			case 'RequestTimeout':
				return 'Превышено время запроса';
			case 'NotFound':
				return 'Не найдено';
			default:
				return e.responseStatus.message;
			}
		}
		return 'Неизвестная ошибка';
	},
	catchRequestErrorTest(error:any) {
		switch (error) {
		case 'TokenException':
			tokenService.logout();
			return 'Токен истек';
		case 'RefreshTokenException':
			tokenService.logout();
			return 'Токен истек';
		case 'Unauthorized':
			tokenService.logout();
			return 'Неправильное имя или пароль';
		case 'RequestTimeout':
			return 'Превышено время запроса';
		case 'NotFound':
			return 'Не найдено';
		default:
			return error;
		}
	},
};
export default errorApiService;
