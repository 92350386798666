import { call, put } from 'redux-saga/effects';

import baseApiService from 'service/base-api.service';
import actions from 'store/actions';
import {
	GetScheduleRequest, Tariff, UpdateScheduleRequest,
} from 'store/dto/dtos';
import { IAction } from 'store/utils';

const getTariffsCall = (): Promise<Tariff[]> => {
	const request = new GetScheduleRequest();
	return baseApiService.get(request);
};

const updateTariffCall = (opts: {schedule:Tariff[]}): Promise<boolean> => {
	const request = new UpdateScheduleRequest();
	request.schedule = opts.schedule;
	return baseApiService.put(request);
};

export function* getTariffsRequest(action: IAction<{callbackGetSchedule: (item:Tariff[]) => void}>) {
	try {
		const {
			payload: {
				callbackGetSchedule,
			},
		} = action;
		yield put(actions.app.saga.asyncAddLoading('loadingTariffs'));
		const result: Tariff[] = yield call(getTariffsCall);
		yield callbackGetSchedule?.(result);
		yield put(actions.app.saga.asyncRemoveLoading('loadingTariffs'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingTariffs'));

		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}

export function* updateTariffRequest(action: IAction<{
	schedule:Tariff[];
	callbackUpdateSchedule: (item:boolean) => void;
	}>) {
	try {
		const {
			payload: { schedule, callbackUpdateSchedule },
		} = action;
		yield put(actions.app.saga.asyncAddLoading('updateTariff'));
		const result: boolean = yield call(updateTariffCall, {
			schedule,
		});
		yield callbackUpdateSchedule?.(result);
		yield put(actions.app.saga.asyncInitNotification({
			messages: 'Расписание изменено!',
			type: 'warm',
		}));
		yield put(actions.app.saga.asyncRemoveLoading('updateTariff'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('updateTariff'));
		// yield put(actions.data.setTariff({} as Tariff));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
