import styled from 'styled-components';
import HeaderSVG from 'assets/icons/header_icon.svg';
import MobileMenuSVG from 'assets/icons/Header/Menu.svg';
import { device } from 'common/devices';

export const HeaderIcon = styled(HeaderSVG)`
  z-index: 1310;
  cursor: pointer;
  width: 15%;

  @media ${device.tablet600} {
    width: 60%;
  }
`;
export const MobileMenuIcon = styled(MobileMenuSVG)`
  width: 10%;
  z-index: 1310;
  .Menu_svg__menu_top {
    transition: all 250ms;
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
  .Menu_svg__menu_bottom {
    transition: all 250ms;
    transform-origin: 50% 50%;
    transform: rotate(0deg);
  }
  &.show {
    .Menu_svg__menu_top {
      /* transform-origin: 50% 50%; */
      transition: all 250ms;
      transform: rotate(45deg);
    }
    .Menu_svg__menu_bottom {
      /* transform-origin: 50% 50%; */
      transition: all 250ms;
      transform: rotate(-45deg);
    }
  }
`;
export const Header = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px clamp(60px, 2vw, 100px);
  z-index: 1320;
  .headerElement {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    a {
      text-decoration: none;
      /* font-family: "Roboto"; */
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */
      text-align: center;
      letter-spacing: 0.1px;
      color: #8f9bbc;
      cursor: pointer;
      &.active {
        color: #ffffff;
      }
      &:hover {
        color: #a2afd4;
      }
    }
  }

  @media ${device.tablet600} {
    padding: 16px 16px;
    justify-content: space-between;
  }
  @media ${device.laptopHD} {
    .headerElement {
      a {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
`;
export const Phone = styled.div`
  align-items: flex-start !important;
  display: flex;
  flex-direction: column !important;
  gap: 10px !important;
  span:nth-child(1) {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.1px;

    color: #777c8e;
  }
  span:nth-child(2) {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.1px;
    span {
      font-size: 16px;
      line-height: 22px;
      color: #fff;
    }
    color: #595a68;
  }
`;

export const BodyList = styled.div`
  position: fixed;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  padding: 20px 16px;
  box-sizing: border-box;

  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 1305;
  background-color: #0a0a15;
  transform: translateY(-200%);
  transition: all 500ms ease-out;
  span {
    text-align: start;
    color: #fff;
  }
  .headerElement {
    box-sizing: border-box;
    padding: 25% 32px 0px 32px;
    /* margin-top:25%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0px;
    width: 100%;
    height: 60%;
  }
  .footerElement {
    height: 40%;
    margin-top: 10%;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: flex-end; */
  }

  &.show {
    transition: all 500ms;
    transform: translateY(0%);
  }
  &.show .${HeaderIcon} {
    position: relative;
  }
  @media ${device.tablet600} {
    button {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;
