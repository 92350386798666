import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
body{
    width:100%;
    height:100%;
    background:#0A0A15;
    margin: 0;

    .ant-select-selection-search{
        input{
            color:#fff;
        }
    }
}
`;
