import styled from 'styled-components';
import PlusIcon from 'assets/icons/plusFAQ.svg';
import JSBIcon from 'assets/icons/bank/jcb_logo.svg';
import MasterCardIcon from 'assets/icons/bank/mastercard_logo.svg';
import MirIcon from 'assets/icons/bank/mir_logo.svg';
import VisaIcon from 'assets/icons/bank/visa_logo.svg';
import { device } from 'common/devices';

export const VisaSVG = styled(VisaIcon)``;
export const MirSVG = styled(MirIcon)``;
export const MasterCardSVG = styled(MasterCardIcon)``;
export const JSBSVG = styled(JSBIcon)``;
export const PlusSVG = styled(PlusIcon)`
  .plusFAQ_svg__st1 {
    transform-origin: 50% 50%;
    transition: all 250ms;
    transform: scale(1);
  }
  .plusFAQ_svg__st0 {
    transition: all 250ms;
    fill: rgb(255, 138, 0);
  }
  position: absolute;
  height: 28px;
  right: 0;
  @media ${device.tablet600} {
    height: 7vw;
  }
`;

export const TitleElement = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  transition: all 250ms ease-out;
  span {
    width: 90%;
  }
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */
  color: #ffffff;
  @media ${device.tablet600} {
    font-size: 20px;
  }
`;

export const DescriptionElement = styled.div`
  /* height: 120px; */
  max-height: 0vh;
  transition: max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */
  color: #5e6880;
  .DescriptionTitle {
    color: #ff8a00;
  }
  .whiteText {
    color: #fff;
  }
  .iconGroup {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 30px;
  }
  a {
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    white-space: nowrap;
    color: #5e6880;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      /* or 22px */
      color: #ffffff;
    }
  }
  @media ${device.tablet600} {
    font-size: 14px;
    .iconGroup {
      margin: 10px 0px;
      gap:13px;
    }
  }
`;

export const ElementList = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 24px;
  background: #1d2335;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;

  &.show ${TitleElement} {
    margin-bottom: 3%;
  }
  &.show ${DescriptionElement} {
    /* margin-top: 50px; */
    max-height: 1000vh;
  }
  &.show ${PlusSVG} {
    .plusFAQ_svg__st0 {
      /* transform-origin: 50% 50%; */
      /* transition: all 250ms; */
      /* transform: rotate(45deg); */
      /* transform-origin: 50% 50%; */
      fill: rgb(6, 121, 255);
      transition: all 250ms;
    }
    .plusFAQ_svg__st1 {
      transform-origin: 50% 50%;
      transition: all 250ms;
      transform: scale(0);
    }
  }

  @media ${device.tablet600} {
    padding: 20px;
  }
`;
