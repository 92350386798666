import { takeLatest } from 'redux-saga/effects';
import actions from 'store/actions';
import * as parkingManipulationWorkers from './parkingManipulationWorkers';

export const {
	// bookingPArkingSpaceRequest, completePArkingSpaceRequest, activatePArkingSpaceRequest,
	activateAndBookingParkingSpaceRequest,
} = {
	...parkingManipulationWorkers,
};

export default function* dataWatcher() {
	const { saga } = actions.parkingManipulation;
	// yield takeLatest(saga.asyncBookingParkingSpace.type, bookingPArkingSpaceRequest);
	// yield takeLatest(saga.asyncActivateParkingSpace.type, activatePArkingSpaceRequest);
	// yield takeLatest(saga.asyncCompleteParkingSpace.type, completePArkingSpaceRequest);
	yield takeLatest(saga.asyncActiveAndBookingParkingSpace.type, activateAndBookingParkingSpaceRequest);
}
