/* eslint-disable no-unused-expressions */
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';
import { ReloadOutlined } from '@ant-design/icons';
import {
	Tooltip, DatePicker, Button,
} from 'antd';

import useCheckPermission from 'common/usePermission';
import {
	Body, ControlElement, MainSection, NameTitle, Title,
} from './styledComponent';
import DataWidget, { DataWidgetHandle } from './Widgets/DataWidget';
import StatusSystemWidget, { SystemDataWidgetHandle } from './Widgets/StatusSystemWidget';
import AcquiringWidget from './Widgets/AcquiringWidget';
import AppVersionWidget, { AppDataWidgetHandle } from './Widgets/AppVersionWidget';

const { RangePicker } = DatePicker;

const StatisticsPage: React.FC = () => {
	const [paramsControlTable, setParamsControlTable] = React.useState<{
		from:Dayjs|null; to:Dayjs|null;
	}>({
		from: dayjs().set('hour', 0).set('minute', 0).set('second', 0),
		to: dayjs().subtract(1, 'month').set('hour', 0).set('minute', 0)
			.set('second', 0),
	});

	const dataWidgetRef = React.useRef<DataWidgetHandle>(null);
	const systemStatusWidgetRef = React.useRef<SystemDataWidgetHandle>(null);
	const appVersionWidgetRef = React.useRef<AppDataWidgetHandle>(null);

	const updateData = () => {
		dataWidgetRef.current?.updateHandle();
		systemStatusWidgetRef.current?.updateHandle();
	};

	return (
		<Body>
			<Title>
				<NameTitle>Дашборд</NameTitle>
				<ControlElement>

					<RangePicker
						format="DD.MM.YYYY"
						onChange={(value) => {
							value ? setParamsControlTable({
								from: dayjs(value?.[0]).set('hour', 0).set('minute', 0).set('second', 0),
								to: dayjs(value?.[1]).set('hour', 0).set('minute', 0).set('second', 0),
							}) : setParamsControlTable({
								from: null,
								to: null,
							});
						}}
						value={[paramsControlTable.from, paramsControlTable.to]}
						style={{ minWidth: '300px' }}
					/>

					<Tooltip title="search">
						<Button type="primary" onClick={updateData} shape="circle" icon={<ReloadOutlined />} />
					</Tooltip>
				</ControlElement>
			</Title>
			<MainSection>
				{useCheckPermission('systemStatus.manage') && <StatusSystemWidget ref={systemStatusWidgetRef} />}

				<DataWidget
					ref={dataWidgetRef}
					toRange={paramsControlTable.to && dayjs(paramsControlTable.to).toISOString()}
					fromRange={paramsControlTable.from && dayjs(paramsControlTable.from).toISOString()}
				/>
				{useCheckPermission('systemStatus.manage') && <AcquiringWidget />}
				{useCheckPermission('systemStatus.manage') && <AppVersionWidget ref={appVersionWidgetRef} />}

			</MainSection>
		</Body>
	);
};

export default StatisticsPage;
