import styled from 'styled-components';
import HistorySVG from 'assets/icons/Header/History.svg';
import MapSVG from 'assets/icons/Header/Map.svg';
import SettingSVG from 'assets/icons/Header/Setting.svg';
import HeaderSVG from 'assets/icons/header_icon.svg';

export const HeaderIcon = styled(HeaderSVG)`
  position: relative;
  /* width: 60%; */
`;
export const SettingIcon = styled(SettingSVG)`
  position: relative;
  /* width: 20%; */
`;
export const MapIcon = styled(MapSVG)`
  position: relative;
  /* width: 20%; */
`;
export const HistoryIcon = styled(HistorySVG)`
  position: relative;
  /* width: 20%; */
`;
// export const Header = styled.div`
//   position: relative;
//   width: 100%;
//   height: 10vh;
//   top: 0;
//   box-sizing: border-box;
//   display: grid;
//   align-items: center;
//   grid-template-columns: 25% 20% 30% repeat(2, auto);
//   grid-template-rows: 1fr;
//   grid-column-gap: 0px;
//   grid-row-gap: 0px;
//   /* padding: 0px 40px; */

//   z-index: 10;
//   background: #171725;
//   box-shadow: 0px 4px 10px rgba(16, 16, 32, 0.5);

//   a {
//     text-decoration: none;
//     /* font-family: "Roboto"; */
//     font-style: normal;
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 19px;
//     /* identical to box height */
//     text-align: center;
//     letter-spacing: 0.1px;
//     color: #8f9bbc;
//     cursor: pointer;
//     &:hover {
//       color: #a2afd4;
//     }
//   }
// `;

// export const IconBlock = styled.div`
//   padding-left: 40px;
//   grid-area: 1 / 1 / 2 / 2;
// `;
// export const PlaceBlock = styled.div`
//   grid-area: 1 / 2 / 2 / 3;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   gap: 20%;
//   div {
//     display: flex;
//     flex-direction: column;
//     span:nth-child(1) {
//       font-style: normal;
//       font-weight: 400;
//       font-size: 14px;
//       line-height: 19px;
//       /* identical to box height */
//       text-align: center;
//       letter-spacing: 0.1px;
//       color: #8f9bbc;
//     }
//     span:nth-child(2) {
//       font-style: normal;
//       font-weight: 400;
//       font-size: 16px;
//       line-height: 22px;
//       /* identical to box height */
//       letter-spacing: 0.1px;
//       color: #ffffff;
//     }
//   }
// `;
// export const MenuBlock = styled.div`
//   grid-area: 1 / 3 / 2 / 4;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   /* gap:10%; */
//   .active {
//     span {
//       color: #ff8a00;
//     }
//     svg {
//       fill: #ff8a00;
//     }
//   }

//   div {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//     gap: 10px;
//     cursor: pointer;
//     svg {
//       transition: all 500ms;
//     }
//     span {
//       transition: all 500ms;
//       font-style: normal;
//       font-weight: 400;
//       font-size: 14px;
//       line-height: 19px;
//       /* identical to box height */
//       text-align: center;
//       letter-spacing: 0.1px;
//       color: #8f9bbc;
//     }

//     &:hover {
//       span {
//         color: #ff8a00;
//       }
//       svg {
//         fill: #ff8a00;
//       }
//     }
//   }
// `;
// export const SystemBlock = styled.div`
//   grid-area: 1 / 4 / 2 / 5;
//   justify-content: center;
//   display: flex;
//   cursor: pointer;
//   &:hover {
//     span {
//       color: #ff8a00;
//     }
//   }
//   span {
//     transition: all 500ms;
//     font-style: normal;
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 19px;
//     /* identical to box height */

//     text-align: center;
//     letter-spacing: 0.1px;

//     color: #8f9bbc;
//   }
// `;
// export const LogoutBlock = styled.div`
//   grid-area: 1 / 5 / 2 / 6;
//   justify-content: center;
//   display: flex;
//   cursor: pointer;
//   &:hover {
//     span {
//       color: #ff8a00;
//     }
//   }
//   span {
//     transition: all 500ms;
//     font-style: normal;
//     font-weight: 700;
//     font-size: 16px;
//     line-height: 22px;
//     /* identical to box height */

//     text-align: center;
//     letter-spacing: 0.1px;

//     /* Orange */

//     color: #ff8a00;
//   }
// `;

export const Header = styled.div`
  position: relative;
  width: 100%;
  height: 81px;
  top: 0;
  display: flex;
  padding: 0px 40px;
  flex-direction: row;
    align-items: center;
    justify-content: space-between;
  z-index: 1200;
  background: #171725;
  box-shadow: 0px 4px 10px rgba(16, 16, 32, 0.5);
 box-sizing:border-box;
  a {
    text-decoration: none;
    /* font-family: "Roboto"; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.1px;
    color: #8f9bbc;
    cursor: pointer;
    &:hover {
      color: #a2afd4;
    }
  }
`;

export const IconBlock = styled.div`

`;
export const PlaceBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
  div {
    display: flex;
    flex-direction: column;
    span:nth-child(1) {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      text-align: center;
      letter-spacing: 0.1px;
      color: #8f9bbc;
    }
    span:nth-child(2) {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */
      letter-spacing: 0.1px;
      color: #ffffff;
    }
  }
`;
export const MenuBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap:43px;
  .active {
    span {
      color: #ff8a00;
    }
    svg {
      fill: #ff8a00;
    }
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    svg {
      transition: all 500ms;
    }
    span {
      transition: all 500ms;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      text-align: center;
      letter-spacing: 0.1px;
      color: #8f9bbc;
    }

    &:hover {
      span {
        color: #ff8a00;
      }
      svg {
        fill: #ff8a00;
      }
    }
  }
`;
export const SystemBlock = styled.div`
  justify-content: center;
  display: flex;
  cursor: pointer;
  &:hover {
    span {
      color: #ff8a00;
    }
  }
  span {
    transition: all 500ms;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.1px;

    color: #8f9bbc;
  }
`;
export const LogoutBlock = styled.div`
  justify-content: center;
  display: flex;
  cursor: pointer;
  &:hover {
    span {
      color: #ff8a00;
    }
  }
  span {
    transition: all 500ms;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.1px;

    /* Orange */

    color: #ff8a00;
  }
`;
