/* eslint-disable no-unused-expressions */
import { Button } from 'components/forms/Button';
// import PasswordInput from 'components/forms/PasswordInput';
// import CheckboxInput from 'components/forms/CheckboxInput';
// import PhoneInput from 'components/forms/PhoneInput';
// import TextInput from 'components/forms/TextInput';
import {
	AShowPassword, Form as FormPassword, Input as InputPassword, Label as LabelPassword, ShowPasswordIcon,
} from 'components/forms/PasswordInput/styledComponent';
import {
	Form as FormPhone, Input as InputPhone, Label as LabelPhone,
} from 'components/forms/TextInput/styledComponent';
import FooterBar from 'components/layout/FooterBar';
import LoadingComponent from 'components/layout/Loading';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import actions from 'store/actions';
import selectors from 'store/selectors';
// import selectors from 'store/selectors';
import {
	ParkomatIcon,
	SectionTitle,
	TitleMainSpan,
	TitleSubSpan,
	TitleText,
	MainSection,
	FormLogin,
	FooterLogin,
} from './styledComponent';

type FormData = {
	adminLogin: string;
	adminPassword:string;
  };

const AdminLoginPage: React.FC = () => {
	// const navigate = useNavigate();
	const dispatch = useDispatch();
	const loading : string[] = useSelector(selectors.app.getCurrentLoading) || [];
	const [passwordShown, setPasswordShown] = React.useState(false);
	const {
		register, handleSubmit, formState: { errors },
	} = useForm<FormData>();

	const onSubmit = handleSubmit((data) => {
		dispatch(actions.authorization.saga.asyncLogInAdmin({ login: data.adminLogin, password: data.adminPassword }));
		// navigate('/map/search');
	});

	const togglePassword = () => {
		// When the handler is invoked
		// inverse the boolean state of passwordShown
		setPasswordShown(!passwordShown);
	};

	return (
		<MainSection>
			{loading.find((item) => item === 'loadingAdminLogin') && (
				<div style={{
					position: 'fixed', height: '100%', width: '100%', zIndex: '10000',
				}}
				>
					<LoadingComponent loadingElement="loadingAdminLogin" />
				</div>
			)}
			<SectionTitle>
				<TitleText>
					<TitleMainSpan>
						Приветствуем
					</TitleMainSpan>
					<TitleSubSpan>
						Укажите данные вашего аккаунта ИТС для входа
					</TitleSubSpan>
					<FormLogin style={{ width: '100%' }} onSubmit={onSubmit}>
						<FormPhone className="phone-login">
							<LabelPhone htmlFor="input-field">Имя пользователя</LabelPhone>
							<InputPhone
								placeholder="Имя пользователя в ИТС"
								autoComplete="off"
								{...register('adminLogin', {
									required: true,
								})}
							/>

							{errors.adminLogin && <p>Некорректный логин</p>}

						</FormPhone>

						<FormPassword className="password-login">
							<LabelPassword htmlFor="input-field">Укажите пароль</LabelPassword>
							<div style={{ position: 'relative', display: 'flex' }}>
								<InputPassword
									// className={invalid ? '.text-field__input_invalid' : ' '}
									type={passwordShown ? 'text' : 'password'}
									autoComplete="off"
									placeholder="Придумайте пароль"
									{...register('adminPassword', {
										required: true,
										maxLength: 50,
										minLength: 3,
									})}
								/>
								<AShowPassword>
									<ShowPasswordIcon onClick={togglePassword} />
								</AShowPassword>
							</div>
							{errors.adminPassword && <p>Некорректный пароль</p>}
						</FormPassword>
					</FormLogin>

					<div className="footer-from">
						<Button onClick={() => onSubmit()} className="button-login" type="submit">
							Войти
						</Button>
					</div>
				</TitleText>
				<ParkomatIcon />
			</SectionTitle>
			<FooterLogin>
				<FooterBar />
			</FooterLogin>
		</MainSection>
	);
};

export default AdminLoginPage;
