import styled from 'styled-components';
import closeSVG from 'assets/icons/Profile/close.svg';
import iconCardSVG from 'assets/icons/Profile/iconCard.svg';
import LockSVG from 'assets/icons/forms/lock-icon.svg';

export const LockIcon = styled(LockSVG)`
  height: 55%;
`;
export const CloseSVG = styled(closeSVG)``;
export const CardIcon = styled(iconCardSVG)``;
export const BodyProfile = styled.div`
  position: relative;
  display: flex;
  min-height: 90vh;
  flex-direction: column;
  justify-content: space-between;
`;

export const Main = styled.div`
  position: relative;
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 100px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 120px;
  gap: 32px;
`;
export const Title = styled.div`
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
`;
export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 126px;
`;
export const ProfileInput = styled.form`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 24px;
`;
export const ButtonLists = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const ElementButtonList = styled.div.attrs(
	(props: { color: string }) => props,
)`
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.1px;
  color: ${(props) => props.color};
`;
export const CardBlock = styled.div``;
export const CardBlockElement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const TitleCardBlock = styled.div`
  font-style: normal;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #92929d;
`;

export const Card = styled.div`
  display: grid;
  background: #1d2335;
  border-radius: 8px;
  grid-template-columns: 0.2fr 1fr 0.4fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 24px;
  box-sizing: border-box;
`;
export const TitleCard = styled.div`
  grid-area: 1 / 1 / 2 / 3;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #92929d;
  }
`;
export const IconCard = styled.div`
  grid-area: 2 / 1 / 3 / 2;
`;
export const NumberCard = styled.div`
  min-width: 200px;
  grid-area: 2 / 2 / 3 / 3;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
`;
export const DeleteCard = styled.div`
  cursor: pointer;
  align-self: center;
  text-align: end;
  grid-area: 1 / 3 / 3 / 4;
`;

export const FeedBackTitle = styled.div`
  margin-top: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #0679ff;
`;

export const Locked = styled.div`
  position: absolute;

  align-items: center;
  top: 0;
  bottom: 0;
  right: 0.875rem;
  cursor: pointer;
  &.show {
    display: flex;
  }
  &.out {
    display: none;
  }
`;

export const LicenseNumberBox = styled.div`
  background: #000;
  border: 1px solid #000;
  color: #000;
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  width: 99px;
  text-decoration: none;

  border-radius: 8px;
  border-width: 2px;
  width: 265px;
`;
export const InputTextLicenseNumber = styled.input`
  display: block;
  width: 100%;
  height: 48px;
  padding: 0.375rem 0.75rem;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  /* color: #92929d; */
  /* background: #1d2335; */
  border: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;


  background: #fff;
    border: 0;
    border-radius: 0;
    height: 100%;
    box-sizing: content-box;
    line-height: 22px;
    font-size: 14px;
    font-family: inherit;
    text-transform: uppercase;

  &.error {
    /* border-color: #198754; */
    border: 1px solid red;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  &:focus {
    color: none;
    border: none;
    outline: 0;
  }
`;
