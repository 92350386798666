/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-tabs */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import actions from 'store/actions';
import LoadingComponent from 'components/layout/Loading';
import selectors from 'store/selectors';
import { useSelector } from 'react-redux';
import {
	ControlOutlined, DollarOutlined, DashboardOutlined, FieldBinaryOutlined, BarChartOutlined, UserOutlined,
} from '@ant-design/icons';
import {
	Layout, Menu,
} from 'antd';
import { Header, Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import tokenService from 'service/token.service';
import useCheckPermission from 'common/usePermission';

import {
	BlurEffect,
	HeaderElement,
	HeaderIcon,
	IconBlock,
	LogoutBlock,

} from './styledComponent';
import OrdersAdminPage from '../OrdersPage';
import TariffAdminPage from '../TariffPage';
import TariffEditForm from '../TariffPage/TariffEditForm';
import StatisticsPage from '../StatisticsPage';
import UsersPage from '../UsersPage';
import ParkingSpacesPage from '../ParkingSpacesPage';
import LockReportPage from '../LockReportPage';

const AdminPage: React.FC = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const showEditForm: boolean = useSelector(selectors.app.getShowTariffEditForm);
	const showOrderEditForm: boolean = useSelector(selectors.app.getShowAdminOrderEditForm);
	const showOrderHistoryForm: boolean = useSelector(selectors.app.getShowAdminOrderHistoryForm);

	const setNavigate = (url: string, hash = '') => {
		navigate(`${url}${`${hash}`}`);
	};

	const [collapsed, setCollapsed] = React.useState(true);

	const logout = () => {
		navigate('/');
		tokenService.logout();
	};

	return (

		<>
			<BlurEffect className={showEditForm ? 'show' : 'out'} />
			<BlurEffect className={showOrderEditForm ? 'show' : 'out'} />
			<BlurEffect className={showOrderHistoryForm ? 'show' : 'out'} />
			<LoadingComponent loadingElement="loadingAdminOrders" />
			<LoadingComponent loadingElement="loadingTariffs" />
			<LoadingComponent loadingElement="deleteTariff" />
			<LoadingComponent loadingElement="createTariff" />
			<LoadingComponent loadingElement="updateTariff" />
			<LoadingComponent loadingElement="loadingUpdateAdminAccountCalls" />
			<LoadingComponent loadingElement="getAdminAccountsLoading" />
			<LoadingComponent loadingElement="getParkingSpacesRequest" />
			<LoadingComponent loadingElement="getParkingSpaceStatusRequest" />
			<LoadingComponent loadingElement="setParkingSpaceEngineerMode" />
			<LoadingComponent loadingElement="moveStepParkingSpaceRequest" />
			<LoadingComponent loadingElement="openParkingSpaceRequest" />
			<LoadingComponent loadingElement="getAcquiringRequest" />
			<LoadingComponent loadingElement="getExportAdjustmentStatRequest" />
			<LoadingComponent loadingElement="getOrderStatusesRequest" />
			<Layout style={{ minHeight: '100vh' }}>
				<Header style={{ padding: 0, background: 'red' }}>
					<HeaderElement>
						<IconBlock>
							<HeaderIcon />
						</IconBlock>
						<LogoutBlock onClick={() => logout()}>
							<span>Выход</span>
						</LogoutBlock>
					</HeaderElement>
				</Header>

				<Layout className="site-layout">
					<Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
						<Menu
							theme="dark"
							color="#171725"
							defaultSelectedKeys={['1']}
							mode="inline"
						>

							{	useCheckPermission('order.read') && (
								<Menu.Item icon={<DollarOutlined />} onClick={() => setNavigate('/admin', '/orders')}>
									Заказы
								</Menu.Item>
							)}
							{ useCheckPermission('tariff.read') && (
								<Menu.Item icon={<FieldBinaryOutlined />} onClick={() => setNavigate('/admin', '/tariff')}>
									Тарифы
								</Menu.Item>
							)}
							{useCheckPermission('systemStatus.read') &&	(
								<Menu.Item icon={<DashboardOutlined />} onClick={() => setNavigate('/admin', '/statistics')}>
									Дашбоард
								</Menu.Item>
							)}
							{useCheckPermission('parkingSpace.control') &&	(
								<Menu.Item icon={<ControlOutlined />} onClick={() => setNavigate('/admin', '/parking-space')}>
									Замки
								</Menu.Item>
							)}
							{useCheckPermission('parkingSpaceStatistic.read') &&	(
								<Menu.Item icon={<BarChartOutlined />} onClick={() => setNavigate('/admin', '/lock-report')}>
									Отчет по замкам
								</Menu.Item>
							)}
							{useCheckPermission('account.read') &&	(
								<Menu.Item icon={<UserOutlined />} onClick={() => setNavigate('/admin', '/users')}>
									Пользователи
								</Menu.Item>
							)}

						</Menu>
					</Sider>
					<Content style={{ margin: '0 16px' }}>

						{showEditForm && <TariffEditForm />}
						{(pathname.split('/')[2] === 'orders' && useCheckPermission('order.read')) && <OrdersAdminPage />}
						{(pathname.split('/')[2] === 'tariff' && useCheckPermission('tariff.read')) && <TariffAdminPage />}
						{(pathname.split('/')[2] === 'statistics' && useCheckPermission('systemStatus.read')) && <StatisticsPage />}
						{(pathname.split('/')[2] === 'users' && useCheckPermission('account.read')) && <UsersPage />}
						{(pathname.split('/')[2] === 'parking-space' && useCheckPermission('parkingSpace.control')) && <ParkingSpacesPage />}
						{(pathname.split('/')[2] === 'lock-report' && useCheckPermission('parkingSpaceStatistic.read')) && <LockReportPage />}
					</Content>
					{/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2023 Created by Ant UED</Footer> */}
				</Layout>
			</Layout>
		</>
	);
};

export default AdminPage;
