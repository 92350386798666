import styled from 'styled-components';
import HeaderSVG from 'assets/icons/HomePage/ParkomatBox.svg';
import { device } from 'common/devices';

export const ParkomatIcon = styled(HeaderSVG)`
  right: 0;
  top: 0;
  position: absolute;
  width: 100%;

  @media ${device.tablet600} {
    display: none;
  }
  /* height: 100%; */
`;

export const TitleSpan = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 110%;
  /* or 55px */
  letter-spacing: 0.1px;

  color: #ffffff;
  @media ${device.tablet600} {
    font-size: 32px;
  }
`;
export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.tablet600} {
    padding: 0px var(--padding);
    box-sizing: border-box;
  }
`;
// First section
export const SectionTitle = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  @media ${device.tablet600} {
    height: auto;
    min-height: auto;
    margin-bottom: 75px;
  }

  .footer-from {
    /* margin-top: 24px; */
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const TitleText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 10%;
  top: 20%;
  z-index: 1;
  gap: 30px;
  width: 20%;
  @media ${device.tablet600} {
    position: relative;
    width: 100%;
    left: auto;
    top: auto;
    margin-top: 100px;
    gap: 0px;
  }
`;
export const TitleMainSpan = styled(TitleSpan)``;
export const TitleSubSpan = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1px;

  color: #92929d;

  @media ${device.tablet600} {
    font-size: 14px;
    line-height: 19px;
    margin-top: 32px;
    margin-bottom: 24px;
  }
`;
export const TitleLogin = styled.span`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #0679ff;
`;

export const FormRegestration = styled.form`
  width: 100%;

  .name-registration {
    margin-bottom: 32px;
  }

  .phone-registration {
    margin-bottom: 32px;
  }

  .password-registration {
    margin-bottom: 24px;
  }

  .button-registration {
    font-size: 16px;
  }
`;

export const FooterRegistration = styled.div`
  z-index: 10;
  position: relative;
`;
