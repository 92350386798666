/* eslint-disable no-restricted-globals */
/* eslint-disable no-return-assign */
import { TDevice } from 'common/devices';
import useWindowDimensions from 'common/useWindowDimensions';
import React from 'react';
import {
	AndroidSVG, AppleSVG, Apps, Footer, Name, Phone,
} from './styledComponent';

const FooterBar:React.FC = () => {
	const { width } = useWindowDimensions();
	const getDeviceType = (): TDevice => {
		if (width <= 768) return 'mobile';
		return 'laptop';
	};
	return (
		<Footer>
			{getDeviceType() === 'mobile' && (
				<Phone>
					<span>Единый справочный центр</span>
					<span>
						+7 (391)
						{' '}
						<span>206 14 14</span>
					</span>
				</Phone>
			)}
			{getDeviceType() === 'mobile' && (
				<Apps>
					<span>Мобильные приложения</span>
					<div>
						<AppleSVG onClick={() => location.href = 'https://apps.apple.com/ru/app/%D0%BA%D1%80%D0%B0%D1%81%D0%BF%D0%B0%D1%80%D0%BA%D0%B8%D0%BD%D0%B3/id6444159218'} />
						<AndroidSVG onClick={() => location.href = 'https://play.google.com/store/apps/details?id=ru.msu24.parking'} />
					</div>
				</Apps>
			)}

			<Name>
				<span>© «КрасПаркинг», 2022</span>
				<span>Все права сохранены за своими правообладателями</span>
			</Name>
			{getDeviceType() === 'laptop' && (
				<Phone>
					<span>Единый справочный центр</span>
					<span>
						+7 (391)
						{' '}
						<span>206 14 14</span>
					</span>
				</Phone>
			)}
			{getDeviceType() === 'laptop' && (
				<Apps>
					<span>Мобильные приложения</span>
					<div>
						<AppleSVG onClick={() => location.href = 'https://apps.apple.com/ru/app/%D0%BA%D1%80%D0%B0%D1%81%D0%BF%D0%B0%D1%80%D0%BA%D0%B8%D0%BD%D0%B3/id6444159218'} />
						<AndroidSVG onClick={() => location.href = 'https://play.google.com/store/apps/details?id=ru.msu24.parking'} />
					</div>
				</Apps>
			)}
		</Footer>
	);
};

export default FooterBar;
