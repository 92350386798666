/* eslint-disable max-len */
import { AuthenticateResponse, Customer, User } from 'store/dto/hub.dtos';
import { IRegistrationData, IResetPassword } from 'store/interfaces/Authorization.Interface';
// import { IRegistrationCall } from 'store/saga/authorization/registrationWorkers';
import types from 'store/types';
import { actionCreator } from 'store/utils';

export default {
	setToken: actionCreator<string>(types.authorization.SET_TOKEN),
	setRefreshToken: actionCreator<string>(types.authorization.SET_REFRESH_TOKEN),
	setAuth: actionCreator<boolean>(types.authorization.SET_AUTH),
	setRegistrationData: actionCreator<IRegistrationData>(types.authorization.SET_REGISTRATION_DATA),
	setAdminData: actionCreator<AuthenticateResponse>(types.authorization.SET_ADMIN_DATA),
	setCustomer: actionCreator<Customer>(types.authorization.SET_CUSTOMER),
	setPermissions: actionCreator<string[]>(types.authorization.SET_PERMISSIONS),
	setUser: actionCreator<User>(types.authorization.SET_USER),
	saga: {
		asyncLogIn: actionCreator<{login: string, password: string}>(types.authorization.saga.ASYNC_LOG_IN),
		asyncLoginSussec: actionCreator<string>(types.authorization.saga.ASYNC_LOGIN_SUSSEC),
		asyncLoginError: actionCreator<string>(types.authorization.saga.ASYNC_LOGIN_ERROR),
		asyncVerification: actionCreator<{number:string, reset:boolean}>(types.authorization.saga.ASYNC_VERIFICATION),
		asyncAccessToken: actionCreator<any>(types.authorization.saga.ASYNC_ACCESS_TOKEN),
		asyncResetPassword: actionCreator<IResetPassword>(types.authorization.saga.ASYNC_RESET_PASSWORD),
		asyncRegistration: actionCreator<{phone:string;
			password:string;
			verifyCode:number;
			displayName:string; }>(types.authorization.saga.ASYNC_REGISTRATION),
		asyncLogInAdmin: actionCreator<{login: string, password: string}>(types.authorization.saga.ASYNC_LOGIN_ADMIN),
		asyncGetCustomer: actionCreator<{id?:string}>(types.authorization.saga.ASYNC_GET_CUSTOMER),
		asyncGetPermissionsCallBack: actionCreator<{callbackPermissions?:(item:string[]) => void}>(types.authorization.saga.ASYNC_GET_PERMISSIONS_CALLBACK),
		asyncGetCurrentUserCallBack: actionCreator<{callbackCurrentUser?:(item:User) => void}>(types.authorization.saga.ASYNC_GET_CURRENT_USER_CALLBACK),
	},
};
