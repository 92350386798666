import styled from 'styled-components';
import closeSVG from 'assets/icons/HomePage/closeIcon.svg';
import { device } from 'common/devices';

export const CloseIcon = styled(closeSVG)`
  position: absolute;
  cursor:pointer;
  right: 24px;
  top: 24px;
  transition: all 100ms;
  :hover{
    transform: scale(1.2);
  }
`;

export const EditForm = styled.div`
  position: fixed;
  transition: all 100ms;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background: #1d2335;
  border-radius: 30px;

  transform: translate(-50%, -50%);
  left: 50%;

  padding: 56px 40px 40px 40px;
  gap: 32px;
  z-index: 5001;

  max-width: 440px;


    box-sizing: border-box;

  &.show {
    top: 50%;
  }
  &.out {
    top: -50%;
  }

  @media ${device.tablet600} {
    width: 85vw;
    padding: 28px 25px 20px 25px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  span {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */
    letter-spacing: 0.1px;

    /* Orange */

    color: #ff8a00;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */

    text-align: center;
    letter-spacing: 0.1px;

    color: #ffffff;
  }

  /* Inside auto layout */
`;
export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;


  @media ${device.tablet600} {

    button{
    font-size: 16px;
    width: fit-content;
  }
  }
`;
