import styled from 'styled-components';

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const Title = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
export const NameTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 110%;
  letter-spacing: 0.1px;

  color: #ff8a00;
`;
export const ControlElement = styled.div``;
export const ContentSection = styled.div`
  width: 100%;
  background-color: blue;
`;

export const MainSection = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  /* max-height:calc(100vh - 81px - 48px - 36px - 24px); */
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

export const SVGElement = styled.div`
  width: 30%;
  height: 100%;
  svg {
    #face {
      stroke-width: 2px;
      stroke: #fff;
    }
    #hour,
    #min,
    #sec {
      stroke-width: 1px;
      fill: #333;
      stroke: #555;
    }
    #sec {
      stroke: #f55;
    }
  }
`;
export const ListElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
  gap: 24px;
  .header {
    color: #fff;
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    color: #fff;
    gap: 18px;

    .item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;

      .ant-input-affix-wrapper {
        border: none;
        border-bottom: 1px solid #d9d9d9;
        border-radius: 0px;
      }
      .error-block{
        display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    gap: 6px;
      }
    }
  }

  .footer {
    width: 587px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
  }
`;

export const DateTimePickerClass = styled.input`
  display: inline-flex;
  align-items: center;
  background-color: #fff0;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  /* border-radius: 6px; */
  height: 31px;
  width: 150px;
  max-width: 87px;
  &:focus-within {
    border-color: #ff8a00;
  }

  /* input { */
  font: inherit;
  color: inherit;
  appearance: none;
  outline: none;
  /* border: 0; */
  /* background-color: transparent; */
  /* height: 30px; */
  &[type="time"] {
    padding: 0 0.5rem 0 0.5rem;
    /* border-right-width: 0; */
  }
  &[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  width: 100%;
  box-sizing: border-box;

  span {
    height: 1rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    border-right: 1px solid #fff;
  }
`;
