import authorizationAction from './authorization';
import appAction from './app';
import userAction from './user';
import dataAction from './data';
import parkingManipulationAction from './parkingManipulation';
import parkingSpaceManipulationAction from './parkingSpaceManipulation';

export default {
	app: appAction,
	authorization: authorizationAction,
	user: userAction,
	data: dataAction,
	parkingManipulation: parkingManipulationAction,
	parkingSpaceManipulation: parkingSpaceManipulationAction,
};
