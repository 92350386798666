import styled from 'styled-components';
import PlusIcon from 'assets/icons/Footer/Android.svg';
import AppleIcon from 'assets/icons/Footer/Apple.svg';
import { device } from 'common/devices';

const Template = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  span:nth-child(1) {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.1px;
    color: #777c8e;
  }
  @media ${device.tablet600} {
    gap: 8px;
    span:nth-child(1) {
      font-size: 14px;
    }
  }
`;

export const AndroidSVG = styled(PlusIcon)`
  cursor: pointer;
`;
export const AppleSVG = styled(AppleIcon)`
  cursor: pointer;
`;

export const Footer = styled.div`
z-index:100;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 88px;
  margin-bottom: 79px;
  @media ${device.tablet600} {
    flex-direction: column;
    margin-top: 0px;
    box-sizing: border-box;
    gap: 26px;
    margin-bottom: 24px;
  }
`;
export const Name = styled(Template)`
  span:nth-child(2) {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;

    color: #777c8e;
  }
  @media ${device.tablet600} {
    span:nth-child(1) {
      font-size: 12px;
      line-height: 16px;
    }
    span:nth-child(2) {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
export const Phone = styled(Template)`
  align-items: center;
  span:nth-child(2) {
    span {
      font-weight: 400;
      font-size: 27px;
      line-height: 37px;
      /* identical to box height */

      letter-spacing: 0.1px;

      color: #fff;
    }
    font-weight: 400;
    font-size: 27px;
    line-height: 37px;
    /* identical to box height */

    letter-spacing: 0.1px;

    color: #595a68;
  }
  @media ${device.tablet600} {
    align-items: flex-start;
    span:nth-child(2) {
      font-size: 24px;
      line-height: 33px;
      span {
        font-size: 24px;
        line-height: 33px;
      }
    }
  }
`;
export const Apps = styled(Template)`
  div {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  @media ${device.tablet600} {
    div {
      gap: 8px;
    }
  }
`;
