import { call, put } from 'redux-saga/effects';
import {
	ChequeInfo,
	GetOrdersRequest,
	GetOrdersResponse,
	PaymentRequest,
	GetChequesRequest,
} from 'store/dto/dtos';
import baseApiService from 'service/base-api.service';
import actions from 'store/actions';
import { IAction, IPageOpt } from 'store/utils';
import errorApiService from 'service/error.service';

interface IPaidOrder {
	orderId:string; returlUrl:string;
}
const getOrdersCall = (opts: IPageOpt): Promise<GetOrdersResponse> => {
	const request = new GetOrdersRequest();
	request.filter = opts.filter;
	request.items = opts.items;
	request.page = opts.page;
	request.sort = opts.sort;
	return baseApiService.get(request);
};
const setPaidOrdersCall = (opts:IPaidOrder): Promise<string> => {
	const request = new PaymentRequest();
	// console.log();
	request.returnUrl = opts.returlUrl;
	return baseApiService.get(request);
};
const getCheques = (opts: { orderId: string }): Promise<ChequeInfo[]> => {
	const request = new GetChequesRequest();
	request.orderId = opts.orderId;
	return baseApiService.get(request);
};

export function* getChequesRequest(
	action: IAction<{
		orderId:string;
		callbackCheques: (item: ChequeInfo[]) => void;
	}>,
) {
	try {
		yield put(actions.app.saga.asyncAddLoading('getChequesRequest'));
		const {
			payload: { callbackCheques, orderId },
		} = action;
		const result: ChequeInfo[] = yield call(getCheques, { orderId });
		yield callbackCheques?.(result);
		yield put(
			actions.app.saga.asyncRemoveLoading('getChequesRequest'),
		);
	} catch (error) {
		yield put(actions.app.saga.asyncRemoveLoading('getChequesRequest'));
		yield put(
			actions.app.saga.asyncInitNotification({
				messages: errorApiService.catchRequestError(error),
				type: 'warm',
			}),
		);
	}
}

export function* getOrdersRequest(action: IAction<IPageOpt>) {
	try {
		const {
			payload: {
				page, items, sort, filter,
			},
		} = action;
		yield put(actions.app.saga.asyncAddLoading('paidLoading'));
		const result: GetOrdersResponse = yield call(getOrdersCall, {
			page, items, sort, filter,
		});
		yield put(actions.data.setOrders(result.items));
		yield put(actions.app.saga.asyncRemoveLoading('paidLoading'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('paidLoading'));
		yield put(actions.data.setOrders([]));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
export function* setPaidOrderRequest(action: IAction<{orderId:string, returlUrl:string}>) {
	try {
		const {
			payload: { orderId, returlUrl },
		} = action;
		yield put(actions.app.saga.asyncAddLoading('paidLoading'));
		const result: string = yield call(
			setPaidOrdersCall,
			{ orderId, returlUrl },
		);
		yield put(actions.data.setPaidLink(result));
		yield put(actions.app.saga.asyncRemoveLoading('paidLoading'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('paidLoading'));
		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}
