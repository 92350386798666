/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { TDevice } from 'common/devices';
import useWindowDimensions from 'common/useWindowDimensions';
import SearchInput from 'components/forms/SearchInput';
import LoadingComponent from 'components/layout/Loading';
// import LoadingComponent from 'components/layout/Loading';
import React from 'react';
// { useEffect }

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import actions from 'store/actions';
import { GetParkingsResponse, Parking } from 'store/dto/dtos';
import selectors from 'store/selectors';
import {

	SectionContent,
	ParkingStreetPlace,
	NamePlace,
	DescriptionPlace,
	ContentSearch,
	HeaderSearch,
	// SectionContentMobile,
} from './styledComponent';

interface IMapSearchElement {
	setParkingData:(item:Parking[]) => void;
    selectedParking:(item:Parking) => void;
	activeSearch:(flag:boolean) => void;

}

const SearchMapElement: React.FC<IMapSearchElement> = (props) => {
	const {
		selectedParking, setParkingData, activeSearch,
	} = props;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const parkingData: GetParkingsResponse = useSelector(selectors.data.getParking) || [];

	const [query, setQuery] = React.useState('');
	const [showContent, setShowContent] = React.useState<boolean>(false);
	const [displayMessage, setDisplayMessage] = React.useState('');
	const { width } = useWindowDimensions();
	const getDeviceType = (): TDevice => {
		if (width <= 768) return 'mobile';
		return 'laptop';
	};

	React.useEffect(() => {
		const timeOutId = setTimeout(() => {
			setDisplayMessage(query);
		}, 500);
		return () => clearTimeout(timeOutId);
	}, [query]);

	React.useEffect(() => {
		dispatch(
			actions.data.saga.asyncGetParkings({
				items: 0,
				filter: displayMessage,
				page: 0,
				sort: '',
			}),
		);
	}, [displayMessage]);
	React.useEffect(() => {
		dispatch(actions.data.saga.asyncGetActiveOrder(''));
	}, []);

	const setNavigate = (url: string, hash = '', item :Parking) => {
		dispatch(actions.data.saga.asyncGetParkingSpaces(item.id));
		dispatch(actions.data.setCurrentParking(item));
		navigate(`/map/${hash}`);
		selectedParking(item);
		setShowContent(false);
	};

	const onChangeSearchInput = (input: string) => {
		setQuery(input);
	};

	const clickSearch = (showPlace: boolean) => {
		// setShowContent(showPlace);
		// activeSearch(showPlace);

		// console.log('click search');
		navigate('/search-active');
	};
	React.useEffect(() => {
		setParkingData(parkingData.items);
	}, [parkingData]);
	React.useEffect(() => {
		dispatch(
			actions.data.saga.asyncGetParkings({
				items: 0,
				filter: '',
				page: 0,
				sort: '',
			}),
		);
	}, []);

	return (
		<>

			{getDeviceType() === 'mobile' && (
				<HeaderSearch className={showContent ? 'show' : 'out'}>
					<SearchInput
						onChange={onChangeSearchInput}
						onclick={clickSearch}
						// name="field_search"
						placeholder="Поиск"
						value={query}
					/>
				</HeaderSearch>
			)}

			{/* <SectionContent className="show"> */}
			<SectionContent className={showContent ? 'show' : 'out'}>
				{getDeviceType() === 'mobile' && <LoadingComponent loadingElement="loadingParkings" />}
				{getDeviceType() === 'laptop' && (
					<HeaderSearch>
						<SearchInput
							onChange={onChangeSearchInput}
							name="field_search"
							placeholder="Поиск"
							value={query}
						/>
					</HeaderSearch>
				)}

				<ContentSearch>
					{parkingData.items?.map((item) => (
						<ParkingStreetPlace onClick={() => setNavigate('map', 'select', item)} key={item?.id}>
							<NamePlace>{item.number}</NamePlace>
							<DescriptionPlace>
								<span>{item.number}</span>
								<span>
									{item.count}
									{' '}
									мест
								</span>
								<span style={{ color: `${item.available === 0 && 'red'}` }}>
									свободно
									{' '}
									{item.available}
								</span>
							</DescriptionPlace>
						</ParkingStreetPlace>
					))}
				</ContentSearch>
			</SectionContent>

		</>
	);
};

export default SearchMapElement;
