import { call, put } from 'redux-saga/effects';
import {
	GetActiveOrderRequest,
	GetActiveOrderResponse,
	GetFindParkingSpacesRequest,
	GetParkingSpacesRequest,
	GetParkingSpacesResponse,

} from 'store/dto/dtos';
import baseApiService from 'service/base-api.service';
import { IAction } from 'store/utils';
import actions from 'store/actions';
import errorApiService from 'service/error.service';

const parkingSpacesCall = (opts: string): Promise<GetParkingSpacesResponse> => {
	const request = new GetParkingSpacesRequest();
	request.id = opts;
	return baseApiService.get(request);
};
const activeParkingCall = (): Promise<GetActiveOrderResponse> => {
	const request = new GetActiveOrderRequest();
	return baseApiService.get(request);
};
const findParkingSpacesRequestCall = (opts: {
	filter:string; page:number; items:number; sort:string; }): Promise<GetParkingSpacesResponse> => {
	const request = new GetFindParkingSpacesRequest();
	request.filter = opts.filter;
	request.items = opts.items;
	request.sort = opts.sort;
	request.page = opts.page;
	return baseApiService.get(request);
};

export function* getFindParkingSpacesRequest(action: IAction<{ page: number;
    items: number;
    sort: string;
    filter: string;
    callbackFindParkingSpaces?:(item:GetParkingSpacesResponse) => void;}>) {
	try {
		const {
			payload: {
				page, items, sort, filter, callbackFindParkingSpaces,
			},
		} = action;
		yield put(actions.app.saga.asyncAddLoading('GetParkingSpacesResponse'));
		const result: GetParkingSpacesResponse = yield call(findParkingSpacesRequestCall, {
			page, items, sort, filter,
		});
		yield callbackFindParkingSpaces?.(result);
		yield put(actions.app.saga.asyncRemoveLoading('GetParkingSpacesResponse'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('GetParkingSpacesResponse'));

		if (error?.responseStatus) {
			yield put(actions.app.saga.asyncInitNotification({
				messages:
		`Ошибка! ${error.responseStatus.message}`,
				type: 'warm',
			}));
		}
	}
}

export function* getParkingSpacesRequest(action: IAction<string>) {
	try {
		yield put(actions.app.saga.asyncAddLoading('loadingParkingSpaces'));
		const { payload } = action;
		const result: GetParkingSpacesResponse = yield call(parkingSpacesCall, payload);
		yield put(actions.data.setParkingSpaces(result));
		yield put(actions.app.saga.asyncRemoveLoading('loadingParkingSpaces'));
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('loadingParkingSpaces'));
		yield put(actions.app.saga.asyncInitNotification({
			messages:
			errorApiService.catchRequestError(error),
			type: 'warm',
		}));
	}
}

export function* getActiveOrderRequest(action: IAction<any>) {
	try {
		const { payload } = action;
		console.log(payload);
		const result: GetActiveOrderResponse = yield call(activeParkingCall);
		yield put(actions.data.setActiveOrder(result));
	} catch (error:any) {
		yield put(actions.app.saga.asyncInitNotification({
			messages:
			errorApiService.catchRequestError(error),
			type: 'warm',
		}));
	}
}
