import { put, select } from 'redux-saga/effects';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { IAction } from 'store/utils';

export function* addLoadingWorker(action: IAction<string>) {
	const { payload } = action;
	const currentLoadings: string[] = yield select(selectors.app.getCurrentLoading);
	yield put(actions.app.setCurrentLoadings([...currentLoadings, payload]));
}

export function* removeLoadingWorker(action: IAction<string>) {
	const { payload } = action;
	const currentLoadings: string[] = yield select(selectors.app.getCurrentLoading);
	yield put(actions.app.setCurrentLoadings(currentLoadings?.filter((value) => value !== payload)));
}
