import { all } from 'redux-saga/effects';
import appWatcher from './app';
import authorizeWatcher from './authorization';
import dataWatcher from './data';
import parkingManipulationWatcher from './parkingManipulation';
import parkingSpaceWatcher from './parkingSpaceManipulation';

export default function* rootSaga() {
	yield all([appWatcher(), authorizeWatcher(), dataWatcher(), parkingManipulationWatcher(), parkingSpaceWatcher()]);
}
