import styled from 'styled-components';

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 100%;
  box-sizing: border-box;
  /* background-color: #3d3d3d30; */
`;
export const MainSection = styled.div`
  position: relative;
  /* width: 100%; */
  height: 100%;

  /* max-height:calc(100vh - 81px - 48px - 36px - 24px); */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 6px;
  box-sizing: border-box;
  background-color: #3d3d3d30;
  border-radius: 10px;
  gap: 24px;

  align-content: flex-start;
`;

export const Title = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
export const NameTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 110%;
  letter-spacing: 0.1px;

  color: #ff8a00;
`;
export const ControlElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  & .anticon {
    color: white;
  }

  &.ant-picker-clear {
    margin-left: 20px !important;
  }
`;
export const ContentSection = styled.div`
  width: 100%;
  background-color: blue;
`;
