import { IDataFAQ } from 'common/Interface';

export const dataFaq: IDataFAQ[] = [
	{
		id: 0,
		title: 'Где скачать Приложение «Краспаркинг»?',
		description: `
		<span>Скачайте на телефон мобильное Приложение "КрасПаркинг" в <a href="https://apps.apple.com/ru/app/%D0%BA%D1%80%D0%B0%D1%81%D0%BF%D0%B0%D1%80%D0%BA%D0%B8%D0%BD%D0%B3/id6444159218"><span>AppStore</span></a>, <a href="https://play.google.com/store/apps/details?id=ru.msu24.parking"><span>GooglePlay</span></a>, на <a href="https://krasparking.admkrsk.ru/"><span>сайте</span></a> 
		и так же QR код на ссылки с приложениями находятся на паркоматах.</span>`,
	},
	{
		id: 1,
		title: 'Как воспользоваться Парковкой?',
		description: `
		<span>Скачайте приложение, зарегистрируйтесь, в приложении выберете парковку и номер парковочного места, 
		подъедьте к замку с выбранным номером и нажмите "Открыть замок".
		</br> Припаркуйтесь.
		</br><span class="attentionText">ВАЖНО</span> чтоб Автомобиль <span class="attentionText">ОБЯЗАТЕЛЬНО</span>
		 находился над замком Парковочного места. 
		 </br>Оплата будет начислена после того как вы уедете с парковки.</span>`,
	},
	{
		id: 2,
		title: 'Как оплатить Парковку?',
		description: `
		<span>Оплатить услуги парковки можно через мобильное Приложение, сайт, паркомат с помощью банковской карты после того как 
		Ваш автомобиль покинул Парковочное место и сессия в приложении окончилась, на это может потребоваться до 2 минут. 
		</br> 
		В Приложении автоматически формируется чек-лист с информацией: № Парковочного места, время начала парковочной сессии, 
		сумма к оплате. </br>
		<span class="attentionText">ВАЖНО</span> 
		Оплата начисляется с первой минуты аренды Парковочного места.</span>`,
	},
	{
		id: 3,
		title: 'Как привязать банковскую карту в мобильном Приложении?',
		description: `
		<span>Операции с банковскими картами в мобильном Приложении будут доступны  
		 после Первой оплаты услуги Парковки в разделе профиль, управление картами.</span>`,
	},
	{
		id: 4,
		title: 'Что делать если парковочный замок не активен в приложении/не открывается?',
		description: `
		<span>Если парковочный замок неактивен в приложении, выберите другой замок на парковке.</span>
		<span>Если выбранный вами замок активен, но при нажатии кнопки «Открыть замок» никакой реакции не
		происходит, свяжитесь с оператором парковки, позвонив по номеру телефона <a href="tel:+73912061414"> 
		+7 (391) <span>206 14 14<span> </a> или через паркомат, и следуйте его инструкциям.</span>`,
	},
	{
		id: 5,
		title: 'Что делать если сел мобильный телефон и нет возможности завершить парковку?',
		description: `
		Если ваш мобильный телефон вышел из строя (сломался, села батарея, закончился
		интернет и т.п.), то вам необходимо связаться с оператором через паркомат
		и следовать его инструкциям.`,
	},
	{
		id: 6,
		title: 'Что делать если машина заглохла на парковке?',
		description: `
		<span>Если ваша машина заглохла на парковке «Краспаркинг», то вам необходимо 
		связаться с оператором парковки, позвонив по номеру телефона <a href="tel:+73912061414"> 
		+7 (391) <span>206 14 14<span> </a> или через паркомат, и следовать его инструкциям.</span>`,
	},

];
