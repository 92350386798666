export default {
	GLOBAL_LOADING: 'SET_LOADING',
	SET_CURRENT_LOADINGS: 'SET_CURRENT_LOADINGS',
	SET_NOTIFICATION: 'SET_NOTIFICATION',
	SET_SUCCESSFUL_API: 'SET_SUCCESSFUL_API',
	SET_SHOW_BOOKING: 'SET_SHOW_BOOKING',
	SET_SHOW_TARIFF_EDIT_FORM: 'SET_SHOW_TARIFF_EDIT_FORM',
	SET_SHOW_ADMIN_ORDER_EDIT_FORM: 'SET_SHOW_ADMIN_ORDER_EDIT_FORM',
	SET_SHOW_ADMIN_ORDER_HISTORY_FORM: 'SET_SHOW_ADMIN_ORDER_HISTORY_FORM',
	SET_SHOW_WARNING_POPUP: 'SET_SHOW_WARNING_POPUP',
	saga: {
		ASYNC_ADD_LOADING: 'ASYNC_ADD_LOADING',
		ASYNC_REMOVE_LOADING: 'ASYNC_REMOVE_LOADING',
		ASYNC_ADD_NOTIFICATION: 'ASYNC_ADD_NOTIFICATION',
		ASYNC_REMOVE_NOTIFICATION: 'ASYNC_REMOVE_NOTIFICATION',
		ASYNC_INIT_NOTIFICATION: 'ASYNC_INIT_NOTIFICATION',
		ASYNC_SEND_SUPPORT: 'ASYNC_SEND_SUPPORT',
	},
};
