import { AuthenticateResponse, Customer, User } from 'store/dto/hub.dtos';
import { IAuthorizationState } from 'store/interfaces/Authorization.Interface';
import { IAction } from 'store/utils';

const initialState: IAuthorizationState = {
	token: '',
	refreshToken: '',
	auth: false,
	registrationData: {
		phone: '',
		name: '',
		password: '',
	},
	adminData: new AuthenticateResponse(),
	customer: new Customer(),
	permissions: [],
	user: new User(),
};

const reducer = (state = initialState, action: IAction<any>) => {
	const { payload } = action;
	switch (action.type) {
	case 'SET_TOKEN':
		return { ...state, token: payload };
	case 'SET_REFRESH_TOKEN':
		return { ...state, refreshToken: payload };
	case 'SET_REGISTRATION_DATA':
		return { ...state, registrationData: payload };
	case 'SET_ADMIN_DATA':
		return { ...state, adminData: payload };
	case 'SET_CUSTOMER':
		return { ...state, customer: payload };
	case 'SET_PERMISSIONS':
		return { ...state, permissions: payload };
	case 'SET_USER':
		return { ...state, user: payload };
	default:
		return state;
	}
};
export default reducer;
