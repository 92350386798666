/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { Loading, NotificationMobile } from './styledComponent';

interface ILoading {

    loadingElement: string;

}
const LoadingComponent: React.FC <ILoading> = (props) => {
	const {
		loadingElement,
	} = props;
	const loading : string[] = useSelector(selectors.app.getCurrentLoading) || [];

	return (
		<>
			{loading.find((item) => item === loadingElement) && (
				<Loading>
					{loadingElement === 'activateAndBookingParking' && (
						<NotificationMobile>
							<span>Открытие замка.</span>
							{/* <br /> */}
							<span>Пожалуйста, подождите...</span>
						</NotificationMobile>
					)}
					<div className="dots-bars-6" />
				</Loading>
			)}
		</>
	);
};

export default LoadingComponent;
