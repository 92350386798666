/* eslint-disable no-alert */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import actions from 'store/actions';

import { Statistics } from 'store/dto/dtos';
import selectors from 'store/selectors';
import { Spin } from 'antd';
import {
	ContentWidget, TitleWidget, WidgetBox,
} from './styledComponent';

type DataWidgetProps = {
    fromRange:any;
    toRange:any;
};

export type DataWidgetHandle = {
  updateHandle: () => void;
};

// const DataWidget = React.forwardRef((props:IDataWidget, ref) => {
const DataWidget = React.forwardRef<DataWidgetHandle, DataWidgetProps>((props, ref) => {
	const { fromRange, toRange } = props;
	const dispatch = useDispatch();
	const statisticsData: Statistics = useSelector(selectors.data.getStatistics);
	const [dataParams, setDataParams] = React.useState<{ from:any, to:any}>({ from: fromRange, to: toRange });
	const loading : string[] = useSelector(selectors.app.getCurrentLoading) || [];
	const updateData = () => {
		dispatch(actions.data.saga.asyncGetStatistics({
			to: dataParams.to && dayjs(dataParams.to).add(24, 'hour').toISOString(),
			from: dataParams.from && dayjs(dataParams.from).toISOString(),
		}));
	};

	React.useImperativeHandle(ref, () => ({
		updateHandle() {
			updateData();
		},

	}));

	React.useEffect(() => {
		setDataParams({ from: fromRange, to: toRange });
	}, [fromRange, toRange]);

	React.useEffect(() => {
		updateData();
	}, [dataParams]);

	return (
		<WidgetBox>
			{loading.find((item) => item === 'loadingStatistics') && (
				<div style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
				}}
				>
					<Spin size="large" />

				</div>
			)}

			<TitleWidget><span>Основное</span></TitleWidget>
			<ContentWidget>
				{/* <div className="div1">Сумма:</div>
				<div className="div2">
					{statisticsData.totalPaymentsAmount?.toLocaleString()}
					{' '}
					₽
				</div>
				<div className="div3">Количество аккаунтов:</div>
				<div className="div4">
					{statisticsData.registrationCount?.toLocaleString()}
					{' '}
					шт
				</div>
				<div className="div5">Количество заказов:</div>
				<div className="div6">
					{statisticsData.orderCount?.toLocaleString()}
					{' '}
					шт
				</div> */}

				<div className="blockAcquiring">
					<div className="nameAcquiring">
						Количество аккаунтов:
					</div>
					<div className="valueAcquiring">
						{statisticsData.registrationCount?.toLocaleString()}
						{' '}
						шт
					</div>
				</div>
				<div className="blockAcquiring">
					<div className="nameAcquiring">
						Сумма через паркомат:
					</div>
					<div className="valueAcquiring">
						{statisticsData.posPaymentsAmount?.toLocaleString()}
						{' '}
						₽
					</div>
				</div>
				<div className="blockAcquiring">
					<div className="nameAcquiring">
						Количество транзакций через паркомат:
					</div>
					<div className="valueAcquiring">
						{statisticsData.posPaymentsCount?.toLocaleString()}
						{' '}
						шт
					</div>
				</div>
				<div className="blockAcquiring">
					<div className="nameAcquiring">
						Сумма эквайринг:
					</div>
					<div className="valueAcquiring">
						{statisticsData.acquiringPaymentsAmount?.toLocaleString()}
						{' '}
						₽
					</div>
				</div>
				<div className="blockAcquiring">
					<div className="nameAcquiring">
						Количество транзакций через эквайринг:
					</div>
					<div className="valueAcquiring">
						{statisticsData.acquiringPaymentsCount?.toLocaleString()}
						{' '}
						шт
					</div>
				</div>
				<div className="blockAcquiring">
					<div className="nameAcquiring">
						Сумма общая:
					</div>
					<div className="valueAcquiring">
						{statisticsData.totalPaymentsAmount?.toLocaleString()}
						{' '}
						₽
					</div>
				</div>
				<div className="blockAcquiring">
					<div className="nameAcquiring">
						Количество транзакций всего:
					</div>
					<div className="valueAcquiring">
						{statisticsData.totalPaymentsCount?.toLocaleString()}
						{' '}
						шт
					</div>
				</div>

			</ContentWidget>
		</WidgetBox>

	);
});

export default DataWidget;
