import { device } from 'common/devices';
import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  gap: 32px;
  @media ${device.tablet600} {
    width: 100%;
  }
`;
export const Title = styled.div`
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
`;

export const ProfileInput = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${device.tablet600} {
    gap: 16px;
  }
`;

export const VerifyPhoneReturn = styled.div`
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #0679ff;
  }
  @media ${device.tablet600} {
    span {
      font-size: 16px;
      line-height: 22px;
    }
    margin: 8px 0px 24px 0px;
  }
`;

export const ButtonSection = styled.div`
  button {
    width: 45%;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 57px;
  button:nth-child(1) {
    background-color: #fff0;
    border: 2px solid #ff8a00;
    border-radius: 8px;
    color: #ff8a00;
  }
  @media ${device.tablet600} {
    button {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
    }
    flex-direction: column;
    gap: 24px;
  }
`;
