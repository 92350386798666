/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tokenService from 'service/token.service';
import actions from 'store/actions';
import { GetParkingStatResponse } from 'store/dto/dtos';
import selectors from 'store/selectors';
import {
	Header, HeaderIcon, HistoryIcon, IconBlock, LogoutBlock, MapIcon, MenuBlock, PlaceBlock, SettingIcon,
	// SystemBlock,
} from './styledComponent';

const HeaderBarCabinet: React.FC = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const parkingStats : GetParkingStatResponse = useSelector(selectors.data.getParkingStats) || [];
	const setNavigate = (url: string, hash = '') => {
		navigate(`${url}${`${hash}`}`);
		dispatch(actions.app.setShowBooking(false));
	};
	const logout = () => {
		navigate('/');
		tokenService.logout();
	};
	useEffect(() => {
		dispatch(actions.data.saga.asyncGetParkingStats(''));
		dispatch(actions.data.saga.asyncGetActiveOrder(''));
		const timer1 = setInterval(() => {
			dispatch(actions.data.saga.asyncGetParkingStats(''));
			dispatch(actions.data.saga.asyncGetActiveOrder(''));
		}, 5000);
		return () => {
			clearInterval(timer1);
		};
	}, []);
	return (
		<Header>
			<IconBlock style={{ cursor: 'pointer' }}>
				<HeaderIcon onClick={() => setNavigate('/map', '/search')} />
			</IconBlock>
			<PlaceBlock>
				<div>
					<span>Всего парковок</span>
					<span>{parkingStats.totalParkingPlaces ? parkingStats.totalParkingPlaces : 0}</span>
				</div>
				<div>
					<span>Свободных мест</span>
					<span>{parkingStats.availableParkingPlaces ? parkingStats.availableParkingPlaces : 0}</span>
				</div>
			</PlaceBlock>
			<MenuBlock>
				<div
					className={pathname.split('/')[2]
				=== ('search' || 'select') ? 'active' : ''}
					onClick={() => setNavigate('/map', '/search')}
				>
					<MapIcon />
					<span>Карта</span>
				</div>
				<div className={pathname.split('/')[2] === 'history' ? 'active' : ''} onClick={() => setNavigate('/map', '/history')}>
					<HistoryIcon />
					<span>История</span>
				</div>
				<div className={pathname.split('/')[1] === 'profile' ? 'active' : ''} onClick={() => setNavigate('/profile')}>
					<SettingIcon />
					<span>Профиль</span>
				</div>
			</MenuBlock>
			{/* <SystemBlock>
				<span>О системе</span>
			</SystemBlock> */}
			<LogoutBlock onClick={() => logout()}>
				<span>Выход</span>
			</LogoutBlock>

		</Header>
	);
};

export default HeaderBarCabinet;
