import styled from 'styled-components';
import MarkerSVG from 'assets/icons/Map/iconMap.svg';
import MarkerActiveSVG from 'assets/icons/Map/iconMapActive.svg';

export const MarkerIcon = styled(MarkerSVG)``;
export const MarkerActiveIcon = styled(MarkerActiveSVG)``;
export const BackgroundHeaderMobile = styled.div`
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 73px;
  z-index: 1;
  /* background-color: #0a0a15; */
`;
export const MainSection = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
`;

// Map section
export const SectionMap = styled.div`
  margin-top: 73px;
  /* height:max-content; */
  width: 100%;
  height: calc(100%- 73px);
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 50px;
  .leaflet-container {
    width: 100%;
    height: 100%;
    .leaflet-control-attribution {
      display: none;
    }
    .leaflet-popup-close-button {
      display: none;
    }
  }

  &.activeMobile {
    width: 100%;
    height: calc(100vh - 73px);
    .leaflet-container {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ActiveParkingElementList = styled.div`
  position: absolute;
  top: 97px;
  left: 16px;
  z-index: 1000;
`;
export const MapElement = styled.div`
  height: calc(100vh - 73px);
  &.outActiveMobile {
    height: calc(100vh - 73px);
    .leaflet-top {
      top: 50%;
    }
    .leaflet-left {
      left: 95%;
      /* right: 0 !important; */
    }
    .marker-popup .leaflet-popup-tip {
      background: #171725;
    }

    .marker-popup .leaflet-popup-content-wrapper {
      background: #171725;
      border-radius: 8px;
      display: flex;
      span:nth-child(1) {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.1px;

        color: #ffffff;
      }
      span:nth-child(2) {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.1px;

        /* App/Gray */

        color: #92929d;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .leaflet-popup-content {
        margin: 8px 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &.activeMobile {
    height: calc(55vh - 121px);
  }
  &.activeSelectMobile {
    height: calc(50vh - 121px);
  }
  &.activeHistoryMobile {
    height: calc(80vh - 121px);
  }
`;

export const NotificationMobile = styled.div`
  z-index: 11000;
  position: absolute;
  width: calc(100vw - 40px);
  /* max-width: 500px; */
  /* height: calc(100vh - 40px); */
  background-color: #171725;
  z-index: 1000;
  top: 100px;
  left: 20px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction:column;
  padding: 16px 25px;
  box-sizing: border-box;
  border: 2px solid #ff8a00;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */
    color: #ffffff;
  }
`;
