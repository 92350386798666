import { device } from 'common/devices';
import styled from 'styled-components';

export const Loading = styled.div`
  z-index: 10000;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff57;

  .dots-bars-6 {
    width: 40px;
    height: 20px;
    --c: radial-gradient(farthest-side, currentColor 93%, #0000);
    background: var(--c) 0 0, var(--c) 50% 0;
    background-size: 8px 8px;
    background-repeat: no-repeat;
    position: relative;
    clip-path: inset(-200% -100% 0 0);
    animation: db6-0 1.5s linear infinite;
    transform: scale(2);
  }
  .dots-bars-6:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 12px;
    background: currentColor;
    left: -16px;
    top: 0;
    animation: db6-1 1.5s linear infinite,
      db6-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
  }
  .dots-bars-6:after {
    content: "";
    position: absolute;
    inset: 0 0 auto auto;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ff8a00;
    animation: db6-3 1.5s linear infinite;
  }

  @keyframes db6-0 {
    0%,
    30% {
      background-position: 0 0, 50% 0;
    }
    33% {
      background-position: 0 100%, 50% 0;
    }
    41%,
    63% {
      background-position: 0 0, 50% 0;
    }
    66% {
      background-position: 0 0, 50% 100%;
    }
    74%,
    100% {
      background-position: 0 0, 50% 0;
    }
  }

  @keyframes db6-1 {
    90% {
      transform: translateY(0);
    }
    95% {
      transform: translateY(15px);
    }
    100% {
      transform: translateY(15px);
      left: calc(100% - 8px);
    }
  }

  @keyframes db6-2 {
    100% {
      top: -0.1px;
    }
  }

  @keyframes db6-3 {
    0%,
    80%,
    100% {
      transform: translate(0);
    }
    90% {
      transform: translate(26px);
    }
  }
`;

export const NotificationMobile = styled.div`
  transform-origin: 50% 50%;
  z-index: 11000;
  position: absolute;
  width:auto;
  /* max-width: 500px; */
  /* height: calc(100vh - 40px); */
  background-color: #171725;
  z-index: 1000;
 top: 100px;
   /* left: 50%; */
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 16px 25px;
  box-sizing: border-box;
  border: 2px solid #ff8a00;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */
    color: #ffffff;
  }

  @media ${device.tablet600} {
    width: calc(100vw - 40px);
    top: 100px;
    left: 20px;
  }
`;
