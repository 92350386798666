/* eslint-disable no-unused-expressions */
import { Button } from 'components/forms/Button';
import { Form as FormText, Input as InputText, Label as LabelText } from 'components/forms/TextInput/styledComponent';
import FooterBar from 'components/layout/FooterBar';
import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { IRegistrationData } from 'store/interfaces/Authorization.Interface';
import actions from 'store/actions';
import LoadingComponent from 'components/layout/Loading';
import {
	ParkomatIcon,
	SectionTitle,
	TitleMainSpan,
	TitleSubSpan,
	TitleText,
	MainSection,
	TitleLogin,
} from './styledComponent';

type FormData = {
	verification: number;
  };
const VerificationPhonePage: React.FC = () => {
	const {
		register, handleSubmit, formState: { errors },
	} = useForm<FormData>();
	const dispatch = useDispatch();
	// const navigate = useNavigate();
	const [timer, setTimer] = React.useState(30);
	const id = React.useRef<number>();

	const registrationData : IRegistrationData = useSelector(selectors.authorization.getRegistrationData);

	const onSubmit = handleSubmit((data) => {
		console.log(data);
		dispatch(actions.authorization.saga.asyncRegistration({
			displayName: registrationData.name,
			phone: `7${registrationData.phone}`,
			password: registrationData.password,
			verifyCode: data.verification,
		}));
	});

	const clear = () => {
		window.clearInterval(id.current || undefined);
	};
	const initTimer = () => {
		id.current = window.setInterval(() => {
			setTimer((time) => time - 1);
		}, 1000);
		return () => clear();
	};

	React.useEffect(() => {
		initTimer();
	}, []);

	React.useEffect(() => {
		if (timer === 0) {
			clear();
		}
	}, [timer]);

	const startTimer = () => {
		setTimer(30);
		initTimer();
	};

	const resetVerificationCode = () => {
		startTimer();
		dispatch(actions.authorization.saga.asyncVerification({ number: `7${registrationData.phone}`, reset: false }));
	};

	return (
		<MainSection>
			<LoadingComponent loadingElement="registrationProcess" />
			<SectionTitle>
				<TitleText>
					<TitleMainSpan>
						Подтверждение
						телефона
					</TitleMainSpan>
					<TitleSubSpan>
						На ваш номер телефона отправлен код для подтверждения регистрации.
					</TitleSubSpan>
					<form onSubmit={onSubmit} style={{ width: '100%' }}>
						<FormText>
							<LabelText htmlFor="input-field">Проверочный код</LabelText>
							<InputText
								type="number"
								autoComplete="off"
								placeholder="Укажите код из смс"
								{...register('verification', {
									required: true,
									valueAsNumber: true,
								})}
							/>
							{errors.verification && <p>Обязательно поле</p>}
						</FormText>
						<div className="footer-from">
							<TitleLogin
								style={{
									color: timer === 0 ? '#0679ff' : '#4d4d4d',
									cursor: timer === 0 ? 'pointer' : 'default',
								}}
								onClick={() => (timer === 0 ? resetVerificationCode() : null)}
							>
								Выслать повторно через
								{' '}
								{timer}
								c
							</TitleLogin>

							<Button type="submit">Подтвердить</Button>
						</div>
					</form>
				</TitleText>
				<ParkomatIcon />
			</SectionTitle>
			<FooterBar />
		</MainSection>
	);
};

export default VerificationPhonePage;
