import { device } from 'common/devices';
import styled from 'styled-components';
import documentSVG from 'assets/icons/Document/document.svg';

export const DocumentSVG = styled(documentSVG)``;
export const Main = styled.div`
  @media ${device.tablet600} {
    padding: 0px var(--padding);
    box-sizing: border-box;
  }
`;

export const BodyPage = styled.div`
  position: relative;

  margin-top: 14%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vw;
  margin-bottom: 5%;
  @media ${device.tablet600} {
    padding-bottom: 32px;
  }
`;

export const TitleMain = styled.div`
  position: absolute;
  left: 120px;
  top: 105px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #ff8a00;
    }
  }
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
  @media ${device.tablet600} {
    padding-top: 0px;
    align-self: start;
    left: auto;
    top: auto;
    position: relative;
    span {
      font-size: 32px;
    }
  }
`;
export const BodyDocumentPage = styled.div`
  position: relative;
  display: flex;
  min-height: 90vh;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 64px;
`;

export const Content = styled.div`
  /* position: absolute; */
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 3vw;
  @media ${device.tablet600} {
    gap: 45px;
    width: 100%;
  }
`;
export const DescriptionContent = styled.div`
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #ffffff;
  }
`;
export const DocumentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vw;
  @media ${device.tablet600} {
    gap: 16px;
  }
`;
export const DocumentElement = styled.div`
  display: grid;
  grid-template-columns: 8% 92%;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  border: 1px solid #1d2335;
  border-radius: 8px;
  padding: 16px 16px 16px 0px;
  box-sizing: border-box;

  @media ${device.tablet600} {
    grid-template-columns: 20% 80%;
  }
`;
export const IconElementDocument = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: center;
`;
export const ContentElementDocument = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  display: flex;
  flex-direction: column;
`;
export const TitleDocument = styled.div`
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #ffffff;
  }
`;
export const SizeDocument = styled.div`
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #595a68;
  }
`;

export const DownloadDocumentLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  margin-top: 6px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #ff8a00;
`;
export const FAQSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vw;
  @media ${device.tablet600} {
    gap: 16px;
  }
`;

export const FooterDocument = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  bottom: 0;
  padding: 0px 16px;
`;
