/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import actions from 'store/actions';
import {
	Header, HistoryIcon, MapIcon, MenuBlock, SettingIcon,
} from './styledComponent';

const FooterCabinetMobile: React.FC = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const setNavigate = (url: string, hash = '') => {
		navigate(`${url}${`${hash}`}`);
		dispatch(actions.app.setShowBooking(false));
	};
	useEffect(() => {
		dispatch(actions.data.saga.asyncGetActiveOrder(''));
		const timer1 = setInterval(() => {
			dispatch(actions.data.saga.asyncGetActiveOrder(''));
		}, 10000);
		return () => {
			clearInterval(timer1);
		};
	}, []);
	return (
		<Header>
			<MenuBlock>
				<div
					className={pathname.split('/')[2]
				=== ('search' || 'select') ? 'active' : ''}
					onClick={() => setNavigate('/map', '/search')}
				>
					<MapIcon />
					<span>Карта</span>
				</div>
				<div className={pathname.split('/')[2] === 'history' ? 'active' : ''} onClick={() => setNavigate('/map', '/history')}>
					<HistoryIcon />
					<span>История</span>
				</div>
				<div className={pathname.split('/')[1] === 'profile' ? 'active' : ''} onClick={() => setNavigate('/profile')}>
					<SettingIcon />
					<span>Профиль</span>
				</div>
			</MenuBlock>

		</Header>
	);
};

export default FooterCabinetMobile;
