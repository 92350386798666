/* eslint-disable dot-notation */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable array-callback-return */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

import moment from 'moment';
import { OrderStatusType } from 'store/dto/dtos';
import { v4 as uuidv4 } from 'uuid';
import EnhancedTableHead from './SortedHeaderElement';
import {
	ITableComponent, ITypeMenuAction, Order,
} from './interface';
import TablePaginationActions from './PaddingElement';
import IsolatedMenu from './IsolatedMenuRow';
import { ActiveParkingCell, CustomFieldCell } from './styledComponent';

const TableElement: React.FC<ITableComponent> = (props) => {
	const {
		tableData, pagination, tableProps, onDoubleClickRow, rowMenuEvent, changeParamsPage,
	} = props;

	const [order, setOrder] = React.useState<Order>(tableProps.defaultOrder || 'desc');
	const [orderBy, setOrderBy] = React.useState<keyof any>(tableProps.defaultSorted);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);
	const [totalRows, setTotalRows] = React.useState(0);

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof any,
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleActionMenu = (itemAction:ITypeMenuAction, id:string) => {
		rowMenuEvent(itemAction, id);
	};

	const getStatus = (status: OrderStatusType) => {
		switch (status) {
		case OrderStatusType.New:
			return 'Новая';
		case OrderStatusType.Booked:
			return 'Бронь';
		case OrderStatusType.UserCancelled:
			return 'Отменена пользователем';
		case OrderStatusType.ReadyToDriveIn:
			return 'Готова к въезду';
		case OrderStatusType.Active:
			return 'Активна';
		case OrderStatusType.NotPaid:
			return 'Завершено, не оплачено';
		case OrderStatusType.PaymentProcessing:
			return 'В процессе оплаты';
		case OrderStatusType.Paid:
			return 'Оплачено';
		case OrderStatusType.SystemCancelled:
			return 'Отменена системой';
		case OrderStatusType.OperatorCancelled:
			return 'Отменена оператором';
		default:
			return ('Неизвестно');
		}
	};
	const getBoolean = (item: boolean) => (item ? 'Да' : 'Нет');

	const getDuration = (second:number) => {
		let hours = second / 3600;
		let mins = (second % 3600) / 60;
		// let secs = (mins * 60) % 60;

		hours = Math.trunc(hours);
		mins = Math.trunc(mins);
		// secs = Math.trunc(secs);
		return `
		${hours ? hours < 10 ? `0${hours} ч` : `${hours} ч` : ''} 
		${mins ? mins < 10 ? `0${mins} м` : `${mins} м` : '0м'} 
`;
	};
	React.useEffect(() => {
		const temp : string = orderBy as string;
		changeParamsPage({
			order, orderBy: temp, page, rowsPerPage,
		});
	}, [page, rowsPerPage, orderBy, order]);

	React.useEffect(() => {
		setTotalRows(pagination.total || 0);
		// setRows(pagination.row);
	}, [tableData]);

	return (
		<Paper sx={{ width: '100%', overflow: 'auto' }}>
			<TableContainer
				component={Paper}
				sx={{ minWidth: 500, maxHeight: 'calc(100vh - 226px)', minHeight: 'calc(100vh - 226px)' }}
			>
				<Table
					stickyHeader
					sx={{ minWidth: 500 }}
					aria-label="sticky table"
				>
					{/* <TableHead>
						<TableRow>
							{tableData?.header?.map((headerName) => (
								<TableCell align={headerName.propsStyle.align}>{headerName.translate}</TableCell>
							))}
						</TableRow>
					</TableHead> */}

					<EnhancedTableHead
						order={order}
						orderBy={orderBy as string}
						onRequestSort={handleRequestSort}
						headerData={tableData?.header}
						showAction={!!tableProps.rowMenuAction.findIndex((m) => m.type === 'none')}
					/>

					<TableBody>
						{tableData?.items?.map((item) => (
							<TableRow
								key={item['id' as keyof typeof item] as string}
								// sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								onDoubleClick={() => onDoubleClickRow(item['id' as keyof typeof item])}
							>
								{tableData?.header?.map((headerKey) => (
									<TableCell align="left" key={uuidv4()}>
										{headerKey.format === 'date' && moment(item[headerKey.key as keyof typeof item])
											.format(headerKey.formatOpts ? headerKey.formatOpts : 'DD.MM.YYYY HH:mm')}
										{headerKey.format === 'number' && `${item[headerKey.key as keyof typeof item]} ₽`}
										{headerKey.format === 'numberNew' && `${item[headerKey.key as keyof typeof item]}`}
										{headerKey.format === 'string' && item[headerKey.key as keyof typeof item]}
										{headerKey.format === 'duration' && `${moment.duration(item[headerKey.key as keyof typeof item]).hours()}ч ${moment.duration(item[headerKey.key as keyof typeof item]).minutes()}мин`}
										{headerKey.format === 'durationSecond' && getDuration(item[headerKey.key as keyof typeof item])}
										{headerKey.format === 'custom' && getStatus(item[headerKey.key as keyof typeof item])}
										{headerKey.format === 'boolean' && getBoolean(item[headerKey.key as keyof typeof item])}
										{(headerKey.format === 'custom_two' && item[headerKey.key as keyof typeof item]) && (
											<CustomFieldCell>
												<span>
													{item[headerKey.key as keyof typeof item]}
												</span>
												<span>
													{/* {item[headerKey.custom_key as keyof typeof item]} */}
													{moment(item[headerKey.custom_key as keyof typeof item])
														.format('DD.MM.YYYY HH:mm')}
												</span>
											</CustomFieldCell>
										)}
										{headerKey.format === 'value' && headerKey.value}
										{headerKey.format === 'custom_amount' && (item['status' as keyof typeof item] === OrderStatusType.Active ? <ActiveParkingCell>Расчет будет после завершения</ActiveParkingCell> : `${item[headerKey.key as keyof typeof item]} ₽`) }
										{headerKey.format === 'custom_duration' && (item['status' as keyof typeof item] === OrderStatusType.Active ? <ActiveParkingCell>Парковка еще активна</ActiveParkingCell> : getDuration(item[headerKey.key as keyof typeof item])) }

									</TableCell>
								))}
								{tableProps.rowMenuAction.findIndex((m) => m.type === 'none') ? (
									<TableCell>
										<IsolatedMenu
											item={item}
											rowActionArray={tableProps.rowMenuAction}
											onActionMenu={(itemAction, id) => handleActionMenu(itemAction, id)}
										/>

									</TableCell>

								) : ''}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TableFooter>
				<TableRow>
					<TablePagination
						rowsPerPageOptions={[25, 50, 100, 500, 1000]}
						component="div"
						colSpan={3}
						count={totalRows}
						rowsPerPage={rowsPerPage}
						page={page}
						labelRowsPerPage="Элементов на странице"
						SelectProps={{
							inputProps: {
								'aria-label': 'Элементов на странице',
							},
							native: true,
						}}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						ActionsComponent={TablePaginationActions}
					/>
				</TableRow>
			</TableFooter>
		</Paper>
	);
};
export default TableElement;
