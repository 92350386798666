import { takeEvery } from 'redux-saga/effects';
import actions from 'store/actions';
import * as tokenWorkers from './tokenWorkers';
import * as registrationWorkers from './registrationWorkers';
import * as adminWorkers from './adminAuthWorkers';

export const {
	logIn, loginSussec, loginError, verificationPhoneRequest, getCurrentUserRequest,
	registrationRequest, refreshAuth, resetAccountPasswordRequest, loginAdmin, getPermissionsRequest,
} = { ...tokenWorkers, ...registrationWorkers, ...adminWorkers };

export default function* authorizeWatcher() {
	const { saga } = actions.authorization;
	yield takeEvery(saga.asyncLogIn.type, logIn);
	yield takeEvery(saga.asyncLoginError.type, loginError);
	yield takeEvery(saga.asyncLoginSussec.type, loginSussec);
	yield takeEvery(saga.asyncVerification.type, verificationPhoneRequest);
	yield takeEvery(saga.asyncRegistration.type, registrationRequest);
	yield takeEvery(saga.asyncAccessToken.type, refreshAuth);
	yield takeEvery(saga.asyncResetPassword.type, resetAccountPasswordRequest);
	yield takeEvery(saga.asyncLogInAdmin.type, loginAdmin);
	yield takeEvery(saga.asyncGetPermissionsCallBack.type, getPermissionsRequest);
	yield takeEvery(saga.asyncGetCurrentUserCallBack.type, getCurrentUserRequest);
}
