export default {
	saga: {
		ASYNC_GET_PARKING_SPACE_STATUS: 'ASYNC_GET_PARKING_SPACE_STATUS',
		ASYNC_GET_PARKING_SPACES_MANIPULATION: 'ASYNC_GET_PARKING_SPACES_MANIPULATION',
		ASYNC_SET_PARKING_SPACE_ENGINEER_MODE: 'ASYNC_SET_PARKING_SPACE_ENGINEER_MODE',
		ASYNC_MOVE_STEP_PARKING_SPACE: 'ASYNC_MOVE_STEP_PARKING_SPACE',
		ASYNC_OPEN_PARKING_SPACE: 'ASYNC_OPEN_PARKING_SPACE',
		ASYNC_GET_PARKINGS_ITS: 'ASYNC_GET_PARKINGS_ITS',
	},
};
