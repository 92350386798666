import {
	AdminOrder,
	GetActiveOrderResponse,
	GetAdminOrdersResponse,
	GetBankCardsResponse,
	GetParkingSpacesResponse, GetParkingsResponse, GetParkingStatResponse,
	GetSystemStatusesResponse,
	MyAccountInfo, Parking, ParkingSpace, Statistics, SystemStatus, Tariff,
} from 'store/dto/dtos';
import IDataState from 'store/interfaces/Data.Interface';
import { IAction } from 'store/utils';

const initialState: IDataState = {
	parking: new GetParkingsResponse(),
	parkingSpace: new GetParkingSpacesResponse(),
	currentParking: new Parking(),
	currentParkingSpace: new ParkingSpace(),
	acountInfo: new MyAccountInfo(),
	orders: [],
	paidLink: '',
	activeOrder: new GetActiveOrderResponse(),
	parkingStats: new GetParkingStatResponse(),
	adminOrders: new GetAdminOrdersResponse(),
	adminOrder: new AdminOrder(),
	tariff: new Tariff(),
	bankCards: new GetBankCardsResponse(),
	statistics: new Statistics(),
	systemStatuses: new GetSystemStatusesResponse(),
	adminCurrentSystemStatus: new SystemStatus(),
	currentSystemStatus: new SystemStatus(),
};

const reducer = (state = initialState, action: IAction<any>) => {
	const { payload } = action;
	switch (action.type) {
	case 'SET_PARKING_SPACES':
		return { ...state, parkingSpace: payload };
	case 'SET_CURRENT_PARKING_SPACE':
		return { ...state, currentParkingSpace: payload };
	case 'SET_PARKINGS':
		return { ...state, parking: payload };
	case 'SET_CURRENT_PARKING':
		return { ...state, currentParking: payload };
	case 'SET_ACCOUNT_INFO':
		return { ...state, acountInfo: payload };
	case 'SET_ORDERS':
		return { ...state, orders: payload };
	case 'SET_PAID_LINK':
		return { ...state, paidLink: payload };
	case 'SET_ACTIVE_ORDER':
		return { ...state, activeOrder: payload };
	case 'SET_PARKING_STATS':
		return { ...state, parkingStats: payload };
	case 'SET_ADMIN_ORDER':
		return { ...state, adminOrder: payload };
	case 'SET_ADMIN_ORDERS':
		return { ...state, adminOrders: payload };
	case 'SET_TARIFF':
		return { ...state, tariff: payload };
	case 'SET_TARIFFS':
		return { ...state, tariffs: payload };
	case 'SET_BANK_CARDS':
		return { ...state, bankCards: payload };
	case 'SET_STATISTICS':
		return { ...state, statistics: payload };
	case 'SET_SYSTEM_STATUSES':
		return { ...state, systemStatuses: payload };
	case 'SET_ADMIN_CURRENT_SYSTEM_STATUS':
		return { ...state, adminCurrentSystemStatus: payload };
	case 'SET_CURRENT_SYSTEM_STATUS':
		return { ...state, currentSystemStatus: payload };
	default:
		return state;
	}
};
export default reducer;
