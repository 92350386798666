import { call, put } from 'redux-saga/effects';
import {
	Authenticate, AuthenticateResponse, GetAccessToken, GetAccessTokenResponse,
	// GetAccessToken, GetAccessTokenResponse,
} from 'store/dto/dtos';
import baseApiService from 'service/base-api.service';
import { IAction } from 'store/utils';
import actions from 'store/actions';
import { history } from 'common/history';
import tokenService from 'service/token.service';
import errorApiService from 'service/error.service';
// import { useNavigate } from 'react-router-dom';

// interface IJWTParse {
//    email: string;
//    roles: string;
//    Id: string;
// }

export interface IAsyncCall {
login:string;
password:string;
}

const accessTokenCall = () : Promise<GetAccessTokenResponse> => {
	const request = new GetAccessToken();
	request.refreshToken = tokenService.refreshToken as string;
	return baseApiService.post(request);
};

const loginCall = (opts: IAsyncCall): Promise<AuthenticateResponse> => {
	const { login, password } = opts;
	const request = new Authenticate();
	request.provider = 'credentials';
	request.userName = login;
	request.password = password;
	return baseApiService.post(request);
};

export function* logIn(action: IAction<{login: string, password: string}>) {
	try {
		yield put(actions.app.saga.asyncAddLoading('buttonLogIn'));
		const { payload: { login, password } } = action;
		const result: AuthenticateResponse = yield call(loginCall, { login: `+7${login}`, password });
		yield put(actions.user.setUser(result));
		yield tokenService.setToken(result.bearerToken, result.refreshToken);
		yield put(actions.app.saga.asyncRemoveLoading('buttonLogIn'));
		yield call(history.push, '/map/search');
	} catch (error:any) {
		yield put(actions.app.saga.asyncRemoveLoading('buttonLogIn'));
		yield put(actions.authorization.saga.asyncLoginError(''));
		yield put(actions.app.saga.asyncInitNotification({
			messages:
			errorApiService.catchRequestError(error),
			type: 'warm',
		}));
	}
}

export function* refreshAuth(action: IAction<any>) {
	try {
		// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
		const { payload } = action;
		const response : GetAccessTokenResponse = yield call(accessTokenCall);
		yield tokenService.setToken(response.accessToken, response.refreshToken);
	} catch (error:any) {
		yield put(actions.app.saga.asyncInitNotification({
			messages:
			`Ошибка! ${error.responseStatus.message}`,
			type: 'warm',
		}));
	}
}

export function* loginSussec(action: IAction<string>) {
	const { payload } = action;
	console.log(payload);
	yield put(actions.authorization.setAuth(true));
	yield put(actions.app.saga.asyncRemoveLoading('buttonLogIn'));
}
export function* loginError(action: IAction<string>) {
	const { payload } = action;
	console.log(payload);
	yield put(actions.authorization.setAuth(false));
	yield put(actions.app.saga.asyncRemoveLoading('buttonLogIn'));
}
