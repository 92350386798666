import { device } from 'common/devices';
import styled from 'styled-components';

const TextWhiteHistoryBlock = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ffffff;
`;
const TextGrayistoryBlock = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1px;

  /* App/Text gray */

  color: #92929d;
`;

export const MainBlock = styled.div`
  width: 100%;
  transition: all 250ms;
  overflow: hidden;
  display: flex;
    flex-direction: column;
  @media ${device.tablet600} {
    height: calc(100% - 137px);
    bottom: 64px;
    z-index: 1100;
    position: absolute;
    background-color: #1d2335;
    overflow: auto;
    &.show {
      transform: translateY(0%);
    }
    &.out {
      transform: translateY(300%);
      /* transform: translateY(0%); */
    }
  }
`;

export const SectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 40px 40px 40px;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
  @media ${device.tablet600} {
    gap: 16px;
    padding: 0px 16px 40px 16px;
  }
`;

export const TitleContent = styled.div`
  padding: 20px 40px 24px 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  gap:14px;
  span:nth-child(1) {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
  span:nth-child(2) {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ff8a00;
  }
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;

  @media ${device.tablet600} {
    padding: 20px 40px 16px 40px;
  }

`;
export const BodyContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HistoryElement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  background: #283046;
  border-radius: 8px;
  border: 1px solid #283046;
  box-sizing: border-box;
  &.active {
    border: 1px solid #ff8a00;
  }
`;

export const TimeElement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
`;
export const DateElement = styled(TextWhiteHistoryBlock)`
  width: 65%;
`;
export const TimerElement = styled(TextWhiteHistoryBlock)`
  width: 35%;
`;

export const DescriptionElement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
`;

export const FirstNameElement = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;
export const SecondElement = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;
export const NameElement = styled(TextGrayistoryBlock)``;
export const PriceElement = styled(TextWhiteHistoryBlock)`
  span {
    color: #92929d;
  }
`;

export const Subtitle = styled(TextWhiteHistoryBlock)``;
export const LinkElement = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #0679ff;
`;
export const PaidElement = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #ff0606;
  &.disabled-button{
    color: #595959;
  }
`;

export const ActiveHistoryElement = styled.div`
  display: none;
  @media ${device.tablet600} {
    position: absolute;
    /* height: 40vh; */
    bottom: 64px;
    width: 100%;
    background-color: #1d2335;
    /* transition:all 250ms; */
    display: block;
    &.show {
      transition-delay: 200ms;
      transform: translateY(0%);
      z-index: 1102;
    }
    &.out {
      z-index: 1002;
      transform: translateY(200%);
    }
  }
`;

export const ContentMobile = styled.div`
  display: flex;
  padding: 25px 16px 24px 16px;
  flex-direction: column;
  align-content: flex-start;
  gap: 25px;
`;
export const TitleMobile = styled.div`
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #ff8a00;
    }
  }
`;
