/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-tabs */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import {
	AdminOrder, AdminOrderCalculation, ChequeInfo, GetAdminOrdersResponse, OrderStatusDetails, OrderStatusType, Transaction, TransactionSource, TransactionStatus,
} from 'store/dto/dtos';
import TableElement from 'components/elements/TableElement';
import { ITypeMenuAction, Order } from 'components/elements/TableElement/interface';
import {
	ReloadOutlined, CopyOutlined, SearchOutlined,
} from '@ant-design/icons';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import dayjs from 'common/utils/dayjs';
import 'dayjs/locale/ru';
// import Input from 'antd/es/input/Input';
import {
	Select, Input, DatePicker, Button as ButtonAntd, Tooltip, Modal, Form, Skeleton, Spin, notification, Collapse,
} from 'antd';
import useCheckSupportUser from 'common/useTechUser';
import useVolkovUser from 'common/useVolkovUser';
import useCheckPermission from 'common/usePermission';
import {
	Body,
	Title,
	MainSection,
	NameTitle,
	ControlElement,
	ModalEditContent,
	EditInputForModal,
	EditInputForModalTitle,
	DateTimePickerClass,
	DateTimePickerClassRefund,
	StatusAndDateTimePicker,
	CustomInput,
	ContentModalHistory,
	ModalEditContentSpin,
	ChequeElement,
} from './styledComponent';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const { Panel } = Collapse;

const OrdersAdminPage: React.FC = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [formRefund] = Form.useForm();

	const [api, contextHolder] = notification.useNotification();
	const [query, setQuery] = React.useState('');
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [isModalHistoryOpen, setIsModalHistoryOpen] = React.useState(false);
	const [isModalChequeOpen, setIsModalChequeOpen] = React.useState(false);
	const [isModalRefundOpen, setIsModalRefundOpen] = React.useState(false);
	const [keyTest, setKeyTest] = React.useState<number>(1);
	const isSupportUser = useCheckSupportUser();
	const isVolkovUser = useVolkovUser();

	const loading : string[] = useSelector(selectors.app.getCurrentLoading) || [];
	const ordersData: GetAdminOrdersResponse = useSelector(selectors.data.getAdminOrders);
	const [paramsControlTable, setParamsControlTable] = React.useState<{filter:string, statusType:any, from:any, to:any, transactionSource:any}>(
		{
			filter: '', statusType: null, from: null, to: null, transactionSource: null,
		},
	);
	const [paramsPage, setParamsPage] = React.useState<{order: Order;
		orderBy: string;
		page:number;
		rowsPerPage:number; }>(
			{
				order: 'desc', orderBy: '', page: 0, rowsPerPage: 25,
			},
		);

	const [activeAdminOrder, setActiveAdminOrder] = React.useState<AdminOrder>(new AdminOrder());
	const [activeAdminOrderStatusDetails, setActiveAdminOrderStatusDetails] = React.useState<OrderStatusDetails[]>([]);
	const [calculateActiveAdminOrder, setCalculateActiveAdminOrder] = React.useState<AdminOrderCalculation>(new AdminOrderCalculation());
	const [disabledSelect, setDisabledSelect] = React.useState<boolean>(false);
	const [chequeInfoData, setChequeInfoData] = React.useState<ChequeInfo[]>([]);
	const [transactionsData, setTransactionsData] = React.useState<Transaction[]>([]);
	const [chequeInfoEmpty, setChequeInfoEmpty] = React.useState<string>('');

	React.useEffect(() => {
		if (!isSupportUser) {
			const timeOutId = setTimeout(() => setParamsControlTable({ ...paramsControlTable, filter: query }), 500);
			return () => clearTimeout(timeOutId);
		}
		query.replace(/\s/g, '').length !== 0 && setParamsControlTable({ ...paramsControlTable, filter: query.replace(/\s/g, '') });
		setQuery(query.replace(/\s/g, ''));
	}, [query]);
	React.useEffect(() => {
		if (keyTest >= (isSupportUser ? 3 : 2)) {
			updateData();
		} else {
			setKeyTest(keyTest + 1);
		}
	}, [paramsPage]);
	React.useEffect(() => {
		!isSupportUser && updateData();
	}, [paramsControlTable]);

	const searchUpdateData = () => {
		if (isSupportUser) {
			if (query.replace(/\s/g, '').length !== 0) {
				updateData();
			}
		} else {
			updateData();
		}
	};
	const updateData = () => {
		dispatch(actions.data.saga.asyncGetAdminOrders({
			items: paramsPage.rowsPerPage,
			filter: paramsControlTable.filter,
			page: paramsPage.page,
			sort: `${paramsPage.orderBy} ${paramsPage.order}`,
			to: paramsControlTable.to && dayjs(paramsControlTable.to).add(24, 'hour').add(-1, 'second').toISOString(),
			from: paramsControlTable.from && dayjs(paramsControlTable.from).toISOString(),
			status: paramsControlTable.statusType,
			transactionSource: paramsControlTable.transactionSource,
		}));
	};

	const showEditForm = (id: string) => {
		const temp = ordersData.items.find((item) => item.id === id);
		if (temp) {
			setActiveAdminOrder(temp);
			form.setFields(
				[
					{ name: 'description', value: '' },
					{ name: 'date', value: '' },
					// { name: 'status', value: '' },
				],
			);

			const callbackOrderStatuses = (value: OrderStatusDetails[]) => {
				setActiveAdminOrderStatusDetails(value);
				showModal();
			};
			dispatch(actions.data.saga.asyncGetOrderStatuses({
				orderId: id as string,
				callbackOrderStatuses,
			}));
		}
	};
	const showRefundForm = (id: string) => {
		const temp = ordersData.items.find((item) => item.id === id);
		if (temp) {
			setActiveAdminOrder(temp);
			formRefund.setFields(
				[
					{ name: 'descriptionRefund', value: '' },
					{ name: 'dateRefund', value: '' },
				],
			);
			const callbackOrderStatuses = (value: OrderStatusDetails[]) => {
				setActiveAdminOrderStatusDetails(value);
				setIsModalRefundOpen(true);
			};
			dispatch(actions.data.saga.asyncGetOrderStatuses({
				orderId: id as string,
				callbackOrderStatuses,
			}));
		}
	};

	const showHistoryForm = (id: string) => {
		const temp = ordersData.items.find((item) => item.id === id);
		if (temp) {
			setActiveAdminOrder(temp);

			const callbackOrderStatuses = (value: OrderStatusDetails[]) => {
				setActiveAdminOrderStatusDetails(value);
				setIsModalHistoryOpen(true);
			};
			dispatch(actions.data.saga.asyncGetOrderStatuses({
				orderId: id as string,
				callbackOrderStatuses,
			}));
		}
	};

	const showChequeForm = (id: string) => {
		const temp = ordersData.items.find((item) => item.id === id);
		if (temp) {
			// dispatch(actions.data.setAdminOrder(temp));
			// dispatch(actions.app.setShowAdminOrderHistoryForm(true));
			setActiveAdminOrder(temp);
			setIsModalChequeOpen(true);

			const callbackGetTransactions = (value: Transaction[]) => {
				setTransactionsData(value);
			};
			dispatch(actions.data.saga.asyncGetTransactions({
				orderId: temp.id as string,
				callbackGetTransactions,
			}));
		}
	};

	const onDoubleClickRow = (id:string) => {
		console.log(id);
	};

	const rowMenuEvent = (itemAction:ITypeMenuAction, id:string) => {
		switch (itemAction.type) {
		case 'history':
			showHistoryForm(id);
			break;
		case 'edit':
			showEditForm(id);
			break;
		case 'paid':
			showChequeForm(id);
			break;
		case 'refund':
			showRefundForm(id);
			break;
		default:
			console.log('alert');
		}
	};

	const getStatus = (status: OrderStatusType) => {
		switch (status) {
		case OrderStatusType.New:
			return 'Новая';
		case OrderStatusType.Booked:
			return 'Бронь';
		case OrderStatusType.UserCancelled:
			return 'Отменена пользователем';
		case OrderStatusType.ReadyToDriveIn:
			return 'Готова к въезду';
		case OrderStatusType.Active:
			return 'Активна';
		case OrderStatusType.NotPaid:
			return 'Завершено, не оплачено';
		case OrderStatusType.PaymentProcessing:
			return 'В процессе оплаты';
		case OrderStatusType.Paid:
			return 'Оплачено';
		case OrderStatusType.SystemCancelled:
			return 'Отменена системой';
		case OrderStatusType.OperatorCancelled:
			return 'Отменена оператором';
		default:
			return ('Неизвестно');
		}
	};

	const onFinish = (values: any) => {
		form.getFieldValue('date');

		const callback = () => {
			handleCancel();
			updateData();
		};
		dispatch(actions.data.saga.asyncUpdateAdminOrders({
			id: activeAdminOrder.id as string,
			refund: false,
			description: form.getFieldValue('description'),
			newAmount: undefined,
			newDurationTill: form.getFieldValue('date') && new Date(form.getFieldValue('date')).toISOString(),
			newStatusType: form.getFieldValue('status'),
			callback,
		}));
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	const onFinishRefund = (values: any) => {
		const callback = () => {
			handleCancel();
			updateData();
		};
		dispatch(actions.data.saga.asyncUpdateAdminOrders({
			id: activeAdminOrder.id as string,
			refund: true,
			description: formRefund.getFieldValue('descriptionRefund'),
			newAmount: undefined,
			newDurationTill: formRefund.getFieldValue('dateRefund') && new Date(formRefund.getFieldValue('dateRefund')).toISOString(),
			callback,
		}));
	};

	const onFinishRefundFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	const calculateValue = () => {
		form.validateFields(['date']).then((values) => {
			const callbackCalculateAdminOrder = (value: AdminOrderCalculation) => setCalculateActiveAdminOrder(value);
			dispatch(actions.data.saga.asyncCalculateAdminOrder({
				id: activeAdminOrder.id as string,
				durationTill: new Date(form.getFieldValue('date')).toISOString(),
				callbackCalculateAdminOrder,
			}));
		}).catch(((errorInfo) => console.log('errorInfo', errorInfo)));
	};

	const calculateRefundValue = () => {
		formRefund.validateFields(['dateRefund']).then((values) => {
			const callbackCalculateAdminOrder = (value: AdminOrderCalculation) => setCalculateActiveAdminOrder(value);
			dispatch(actions.data.saga.asyncCalculateAdminOrder({
				id: activeAdminOrder.id as string,
				durationTill: new Date(formRefund.getFieldValue('dateRefund')).toISOString(),
				callbackCalculateAdminOrder,
			}));
		}).catch(((errorInfo) => console.log('errorInfo', errorInfo)));
	};

	const getChequeValue = () => {
		const callbackCheques = (value: ChequeInfo[]) => {
			if (value.length === 0) {
				setChequeInfoEmpty('пусто');
			} else {
				setChequeInfoEmpty('не пусто');

				setChequeInfoData(value);
			}
		};
		dispatch(actions.data.saga.asyncGetCheques({
			orderId: activeAdminOrder.id as string,
			callbackCheques,
		}));
	};

	const secondsToString = (d:number) => {
		d = Number(d);
		let h = Math.floor(d / 3600);
		const m = (d - (h * 3600)) / 60;
		if (m > 3) { h += 1; }
		return h;
	};

	const handleOkHistory = () => {
		setActiveAdminOrder(new AdminOrder());
		setActiveAdminOrderStatusDetails([]);
		setIsModalHistoryOpen(false);
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		form.setFieldsValue({});
		form.resetFields();
		formRefund.setFieldsValue({});
		formRefund.resetFields();
		setActiveAdminOrder(new AdminOrder());
		setActiveAdminOrderStatusDetails([]);
		setCalculateActiveAdminOrder(new AdminOrderCalculation());
		setIsModalOpen(false);
		setIsModalRefundOpen(false);
		setDisabledSelect(false);
	};

	const changeSelect = (value:any) => {
		if (value === OrderStatusType.OperatorCancelled) {
			setDisabledSelect(true);
			form.setFields([{ name: 'date', value: '' }]);
			setCalculateActiveAdminOrder(new AdminOrderCalculation());
		} else if (value === OrderStatusType.NotPaid) {
			setDisabledSelect(false);
		} else { setDisabledSelect(false); }
	};

	const getMaxHours = (item:string) => {
		const temp = new Date(item);
		temp.setSeconds(0);
		temp.setMilliseconds(0);
		temp.setHours(temp.getHours() + 55);
		return temp.toISOString().replace('Z', '');
	};
	const getMaxHoursRefund = (item:string) => {
		const temp = new Date(item);
		temp.setMilliseconds(0);
		temp.setSeconds(temp.getSeconds() + (activeAdminOrder.duration || 0));
		temp.setHours(temp.getHours() + 7);
		return temp.toISOString().replace('Z', '');
	};
	const getMinHours = (item:string) => {
		const temp = new Date(item);
		temp.setSeconds(0);
		temp.setMilliseconds(0);
		temp.setHours(temp.getHours() + 7);
		return temp.toISOString().replace('Z', '');
	};

	const clickChequeElement = (item: ChequeInfo) => {
		navigator.clipboard.writeText(item.url);
		api.info({
			message: 'Ссылка на чек скопирована',
			// description: item.url,
			placement: 'bottom',
			style: { width: '400px' },
		});
	};

	return (
		<Body>
			{contextHolder}
			<Title>
				<NameTitle>Заказы</NameTitle>
				<ControlElement>

					{useCheckPermission('order.filter') && (
						<RangePicker
							format="DD.MM.YYYY"
							onChange={(value) => setParamsControlTable({
								...paramsControlTable,
								from: value ? dayjs(value?.[0]).set('hour', 0).set('minute', 0).set('second', 0) : '',
								to: value ? dayjs(value?.[1]).set('hour', 0).set('minute', 0).set('second', 0) : '',
							})}
							style={{ minWidth: '300px' }}
						/>
					)}
					{useCheckPermission('order.filter') && (
						<Select
							showSearch
							placeholder="Выберите статус"
							optionFilterProp="children"
							value={paramsControlTable.statusType}
							style={{ minWidth: '200px' }}
							// onChange={(value:any) => console.log(value)}
							onChange={(value:any) => setParamsControlTable({
								...paramsControlTable,
								statusType: value,
								transactionSource: value === OrderStatusType.Paid ? paramsControlTable.transactionSource : null,
							})}
							filterOption={(input:any, option:any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
							options={[
								{
									value: '',
									label: 'Не выбрано', // убрать
								},
								{
									value: OrderStatusType.SystemCancelled,
									label: 'Отменена системой',
								},
								{
									value: OrderStatusType.Paid,
									label: 'Оплачено',
								},
								{
									value: OrderStatusType.PaymentProcessing,
									label: 'В процессе оплаты',
								},
								{
									value: OrderStatusType.NotPaid,
									label: 'Завершено, не оплачено',
								},
								{
									value: OrderStatusType.Active,
									label: 'Активна',
								},
								{
									value: OrderStatusType.ReadyToDriveIn,
									label: 'Готова к въезду',
								},
								{
									value: OrderStatusType.UserCancelled,
									label: 'Отменена пользователем',
								},
								{
									value: OrderStatusType.OperatorCancelled,
									label: 'Отменена оператором',
								},
								{
									value: OrderStatusType.Booked,
									label: 'Бронь', // убрать
								},
							// {
							// 	value: OrderStatusType.New,
							// 	label: 'Новая', // убрать
							// },
							]}
						/>
					)}
					{useCheckPermission('order.filter') && (
						<Select
							placeholder="Выберите тип транзакции"
							optionFilterProp="children"
							value={paramsControlTable.transactionSource}
							style={{ minWidth: '200px' }}
							// onChange={(value:any) => console.log(value)}
							onChange={(value:any) => setParamsControlTable({
								...paramsControlTable,
								transactionSource: value,
								statusType: OrderStatusType.Paid,
							})}
							filterOption={(input:any, option:any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
							options={[
								{
									value: '',
									label: 'Не выбрано', // убрать
								},
								{
									value: TransactionSource.Acquiring,
									label: 'Эквайринг',
								},
								{
									value: TransactionSource.POS,
									label: 'Паркомат',
								},
							]}
						/>
					)}

					<Input
						value={query}
						onChange={(event) => setQuery(event.target.value)}
						id="outlined-search"
						placeholder="поиск..."
					/>

					<Tooltip title="search">
						<ButtonAntd type="primary" onClick={searchUpdateData} shape="circle" icon={!isSupportUser ? <ReloadOutlined /> : <SearchOutlined />} />
					</Tooltip>
				</ControlElement>
			</Title>
			<MainSection>
				<TableElement
					onDoubleClickRow={onDoubleClickRow}
					changeParamsPage={setParamsPage}
					pagination={{ itemsPerPage: ordersData.itemsPerPage, page: ordersData.page, total: ordersData.total }}
					tableProps={{
						defaultSorted: 'created',
						rowMenuAction: [
							{ type: 'edit', name: 'Изменить', permission: 'order.update' },
							{ type: 'history', name: 'История', permission: 'order.manage' },
							{ type: 'paid', name: 'Оплата', permission: 'order.manage' },
							{ type: 'refund', name: 'Пересчитать', permission: 'order.manage' },
						],
					}}
					rowMenuEvent={rowMenuEvent}
					tableData={{
						items: ordersData.items,
						header: [
							{
								key: 'created',
								translate: 'Дата создания',
								format: 'date',
								formatOpts: 'DD.MM.YY HH:mm',
								propsStyle: { align: 'left' },

							},
							{
								key: 'accountPhone',
								translate: 'Аккаунт',
								format: 'string',
								propsStyle: { align: 'left' },

							},
							{
								key: 'orderNumber',
								translate: 'Номер заказа',
								format: 'string',
								propsStyle: { align: 'left' },

							},
							{
								key: 'parkingSpaceName',
								translate: 'Имя парк. пространства',
								format: 'string',
								propsStyle: { align: 'left' },

							},
							{
								key: 'duration',
								translate: 'Продолжительность',
								format: 'custom_duration',
								propsStyle: { align: 'left' },

							},
							{
								key: 'amount',
								translate: 'Стоимость',
								format: 'custom_amount',
								propsStyle: { align: 'left' },

							},
							{
								key: 'statusTime',
								translate: 'Дата статуса',
								format: 'date',
								formatOpts: 'DD.MM.YY HH:mm',
								propsStyle: { align: 'left' },

							},
							{
								key: 'status',
								translate: 'Статус',
								format: 'custom',
								propsStyle: { align: 'left' },
							},
						],
					}}
				/>

			</MainSection>

			<Modal
				title={activeAdminOrder.status === OrderStatusType.Active ? `Редактировать статус и время заказа
				 ${activeAdminOrder?.orderNumber}` : `Редактировать время заказа ${activeAdminOrder?.orderNumber}`}
				centered
				open={isModalOpen}
				onCancel={handleCancel}
				width={600}
				footer={[
					<ButtonAntd key="back" onClick={handleCancel}>
						Отмена
					</ButtonAntd>,
					<ButtonAntd disabled={!(activeAdminOrder.status === OrderStatusType.Active || activeAdminOrder.status === OrderStatusType.NotPaid) || disabledSelect} loading={!!loading.find((item) => item === 'calculateAdminOrder')} onClick={calculateValue}>
						Рассчитать
					</ButtonAntd>,
					<ButtonAntd disabled={!(activeAdminOrder.status === OrderStatusType.Active || activeAdminOrder.status === OrderStatusType.NotPaid)} key="submit" htmlType="submit" type="primary" form="myForm">
						Применить
					</ButtonAntd>,
				]}
			>
				<ModalEditContent>
					<div className="ModalEditContentRow">
						<span>Дата создания заказа</span>
						<span>{dayjs(activeAdminOrder.created).format('DD.MM.YYYY HH:mm')}</span>
					</div>
					{activeAdminOrder.id && activeAdminOrderStatusDetails[activeAdminOrderStatusDetails?.length - 1]?.status === OrderStatusType.NotPaid && (

						<div className="ModalEditContentRow">
							<span>Дата завершения парковки</span>
							<span>{dayjs(activeAdminOrder.created).add(activeAdminOrder.duration || 0, 'seconds').format('DD.MM.YYYY HH:mm')}</span>
						</div>

					)}

					<div className="ModalEditContentRow">
						<span>Номер телефона</span>
						<span>{activeAdminOrder.accountPhone}</span>
					</div>
					<div className="ModalEditContentRow">
						<span>Имя парковочного пространства</span>
						<span>{activeAdminOrder.parkingSpaceName}</span>
					</div>
					<div className="ModalEditContentRow">
						<span>Номер заказа</span>
						<span>{activeAdminOrder.orderNumber}</span>
					</div>
					<div className="ModalEditContentRow">
						<span>Текущий статус</span>
						<span>{activeAdminOrder.id && getStatus(activeAdminOrderStatusDetails[activeAdminOrderStatusDetails?.length - 1]?.status)}</span>
					</div>
					{activeAdminOrder.id && activeAdminOrderStatusDetails[activeAdminOrderStatusDetails?.length - 1]?.status === OrderStatusType.NotPaid && (

						<div className="ModalEditContentRow">
							<span>Дата изменения</span>
							<span>{activeAdminOrder.id && dayjs(activeAdminOrderStatusDetails[activeAdminOrderStatusDetails?.length - 1]?.statusTime).format('DD.MM.YYYY HH:mm')}</span>
						</div>

					)}

				</ModalEditContent>
				{(activeAdminOrder.status === OrderStatusType.Active || activeAdminOrder.status === OrderStatusType.NotPaid) && (
					<EditInputForModal>

						<EditInputForModalTitle>Изменить статус и время парковки</EditInputForModalTitle>
						<Form
							id="myForm"
							form={form}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
						>
							<StatusAndDateTimePicker>
								{(activeAdminOrder.status === OrderStatusType.Active || activeAdminOrder.status === OrderStatusType.NotPaid)
								&& (
									<CustomInput style={{ flex: '1' }}>
										<span>Новый статус парковки</span>

										<Form.Item name="status" rules={[{ required: true, message: 'Выберите статус!' }]}>
											<Select
												// showSearch
												placeholder="Выберите статус"
												optionFilterProp="children"
												filterOption={(input:any, option:any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
												onChange={(value) => changeSelect(value)}
												options={[
													{
														value: OrderStatusType.OperatorCancelled,
														label: 'Отменена оператором',
													},
													{
														value: OrderStatusType.NotPaid,
														label: 'Завершена, не оплачено',
													},
												]}
											/>
										</Form.Item>

									</CustomInput>
								)}
								<CustomInput>
									{/* <span>{activeAdminOrder.status === OrderStatusType.NotPaid ? 'Новое время завершения парковки' : 'Время нового статуса парковки'}</span> */}
									<span style={{ color: disabledSelect ? 'gray' : '#fff' }}>Время завершения парковки</span>

									<Form.Item
										name="date"
										style={{ marginBottom: '0px', width: '250px' }}
										rules={[
											{ required: !disabledSelect, message: 'Укажите время!' },
											{
												validator: async (rule, value) => {
													if ((new Date(form.getFieldValue('date')).getTime() - new Date(activeAdminOrder.created).getTime()) > 144000000) {
														throw new Error('Продолжительность парковки не должна превышать 40 часов');
													}
													if ((new Date(activeAdminOrder.created).getTime() > new Date(form.getFieldValue('date')).getTime())) {
														throw new Error(`Некорректная дата. Дата завершения меньше даты создания заказа (${dayjs(activeAdminOrder.created).format('DD.MM.YYYY HH:mm')})`);
													}
												},
											},
										]}
									>
										<DateTimePickerClass
											disabled={disabledSelect}
											style={{ color: disabledSelect ? 'gray' : '#fff' }}
											type="datetime-local"
											id="meeting-time"
											name="meeting-time"
											max={getMaxHours(activeAdminOrder.created)}
											min={getMinHours(activeAdminOrder.created)}
										/>
									</Form.Item>

								</CustomInput>

							</StatusAndDateTimePicker>

							<CustomInput>
								<span>Причина</span>
								<Form.Item name="description" style={{ width: '100%' }} rules={[{ required: true, message: 'Укажите причину!' }]}>
									<TextArea
										showCount
										maxLength={100}
										placeholder="Укажите причину"
									/>
								</Form.Item>
							</CustomInput>

						</Form>

						<EditInputForModalTitle>Итого, после редактирования</EditInputForModalTitle>

						<ModalEditContent>
							<div className="ModalEditContentRow">
								<span>Количество часов</span>
								{loading.find((item) => item === 'calculateAdminOrder') ? <Skeleton.Button active style={{ height: '22px' }} size="small" /> : calculateActiveAdminOrder?.duration && <span>{secondsToString(calculateActiveAdminOrder?.duration)}</span>}
							</div>
							<div className="ModalEditContentRow">
								<span>Сумма</span>
								{loading.find((item) => item === 'calculateAdminOrder') ? <Skeleton.Button active style={{ height: '22px' }} size="small" /> : <span>{calculateActiveAdminOrder?.amount}</span>}
							</div>
							<div className="ModalEditContentRow">
								<span>Дата начала заказа</span>
								{loading.find((item) => item === 'calculateAdminOrder') ? <Skeleton.Button active style={{ height: '22px', width: '100px' }} size="small" /> : calculateActiveAdminOrder?.duration && <span>{dayjs(calculateActiveAdminOrder.orderStart).format('DD.MM.YYYY HH:mm')}</span>}

							</div>
							<div className="ModalEditContentRow">
								<span>Дата окончания заказа</span>
								{loading.find((item) => item === 'calculateAdminOrder') ? <Skeleton.Button active style={{ height: '22px', width: '100px' }} size="small" /> : calculateActiveAdminOrder?.duration && <span>{dayjs(calculateActiveAdminOrder.orderEnd).format('DD.MM.YYYY HH:mm')}</span>}

							</div>
							{loading.find((item) => item === 'calculateAdminOrder') && <ModalEditContentSpin><Spin /></ModalEditContentSpin> }
							{/* <ModalEditContentSpin><Spin /></ModalEditContentSpin> */}
						</ModalEditContent>
					</EditInputForModal>
				)}

			</Modal>

			<Modal
				title={`История заказа ${activeAdminOrder?.orderNumber}`}
				open={isModalHistoryOpen}
				onOk={handleOkHistory}
				width={1000}
				onCancel={handleOkHistory}
				centered
				footer={[
					<ButtonAntd key="submit" type="primary" onClick={handleOkHistory}>
						Закрыть
					</ButtonAntd>,
				]}
			>
				<ContentModalHistory>
					<table>
						<tr>
							<th>Время</th>
							<th>Статус</th>
							<th>Описание</th>
							<th>Имя пользователя</th>
						</tr>

						{loading.find((item) => item === 'getOrderStatusesRequest') && <Skeleton active />}
						{activeAdminOrderStatusDetails?.map((item) => (

							<tr>
								<td>{dayjs(item?.statusTime).format('DD.MM.YYYY HH:mm')}</td>
								<td>{getStatus(item?.status)}</td>
								<td>
									{item?.description?.split('\n')?.map((itemString) => (
										<>
											<span>
												{itemString}
											</span>
											<br />

										</>

									))}

								</td>
								<td>{item.userDisplayName}</td>
							</tr>
						))}
					</table>
				</ContentModalHistory>
			</Modal>

			<Modal
				title={`Информация по оплате заказа ${activeAdminOrder?.orderNumber}`}
				centered
				open={isModalChequeOpen}
				onCancel={() => { setChequeInfoData([]); setIsModalChequeOpen(false); setChequeInfoEmpty(''); setTransactionsData([]); }}
				width={600}
				footer={[
					<ButtonAntd key="back" onClick={() => { setChequeInfoData([]); setIsModalChequeOpen(false); setChequeInfoEmpty(''); setTransactionsData([]); }}>
						Закрыть
					</ButtonAntd>,
					<ButtonAntd
						disabled={activeAdminOrder.status !== OrderStatusType.Paid}
						loading={!!loading.find((item) => item === 'getChequesRequest')}
						type="primary"
						onClick={getChequeValue}
					>
						Получить чеки
					</ButtonAntd>,
				]}
			>
				<div style={{ overflow: 'hidden' }}>
					{(activeAdminOrder.status === OrderStatusType.Paid || activeAdminOrder.status === OrderStatusType.NotPaid || activeAdminOrder.status === OrderStatusType.PaymentProcessing) ? (
						<>
							{/* {	activeAdminOrder.status === OrderStatusType.Paid && (
								<ModalEditContent>
									<div className="ModalEditContentRow">
										<span>Номер транзакции</span>
										<span>{activeAdminOrder.transactionNumber}</span>
									</div>

									<div className="ModalEditContentRow">
										<span>Дата транзакции</span>
										<span>{dayjs(activeAdminOrder.transactionDate).format('DD.MM.YYYY HH:mm')}</span>
									</div>

									<div className="ModalEditContentRow">
										<span>Способ оплаты</span>
										<span>{activeAdminOrder.transactionSource === TransactionSource.Acquiring ? 'Эквайринг' : 'Паркомат'}</span>
									</div>

								</ModalEditContent>
							)} */}
							{	transactionsData.find((item) => item.status === TransactionStatus.Completed) && (
								<ModalEditContent>
									<div className="ModalEditContentRow">
										<span>Номер транзакции</span>
										<span>{transactionsData.find((item) => item.status === TransactionStatus.Completed)?.id?.replaceAll('-', '')}</span>
									</div>

									<div className="ModalEditContentRow">
										<span>Дата транзакции</span>
										<span>{dayjs(transactionsData.find((item) => item.status === TransactionStatus.Completed)?.modified).format('DD.MM.YYYY HH:mm')}</span>
									</div>

									<div className="ModalEditContentRow">
										<span>Способ оплаты</span>
										<span>{transactionsData.find((item) => item.status === TransactionStatus.Completed)?.source === TransactionSource.Acquiring ? 'Эквайринг' : 'Паркомат'}</span>
									</div>

								</ModalEditContent>
							)}

							{loading.find((item) => item === 'getTransactionsRequest') && <Skeleton active />}
							{ transactionsData.length !== 0 && <div style={{ margin: '17px 0px 4px 0px' }}><span>Транзакции:</span></div>}
							<div style={{
								maxHeight: '50vh',
								overflow: 'auto',
								padding: '12px 0px 12px 0px',
							}}
							>
								{	transactionsData.length !== 0
									? (
										<Collapse style={{ background: '#313851b8' }}>
											{transactionsData?.map((trItem) => (
												<Panel
													key={trItem.id}
													header={`Оплачено через "${trItem.source === TransactionSource.Acquiring ? 'Эквайринг' : 'Паркомат'}", в статусе "${trItem.status === TransactionStatus.Completed ? 'Завершено' : trItem.status === TransactionStatus.New ? 'Новое' : 'Отменено'}"`}
												>
													<ModalEditContent>
														{ (trItem.status === TransactionStatus.Completed && trItem?.paymentInfo) ? (
															Object.keys(JSON.parse(trItem?.paymentInfo)).map((keyName, i) => (
																<div className="ModalEditContentRow" key={i}>
																	<span>{keyName}</span>
																	<span>{(JSON.parse(trItem?.paymentInfo))[keyName]}</span>
																</div>
															))
														) : (
															<>
																<div className="ModalEditContentRow">
																	<span>Дата создания</span>
																	<span>{dayjs(activeAdminOrder?.created).format('DD.MM.YYYY HH:mm')}</span>
																</div>
																<div className="ModalEditContentRow">
																	<span>Дата последнего изменения</span>
																	<span>{dayjs(activeAdminOrder?.modified).format('DD.MM.YYYY HH:mm')}</span>
																</div>
															</>
														)}
														{/* {
														trItem.status !== TransactionStatus.Completed && (
															<>
																<div className="ModalEditContentRow">
																	<span>Дата создания</span>
																	<span>{dayjs(activeAdminOrder?.created).format('DD.MM.YYYY HH:mm')}</span>
																</div>
																<div className="ModalEditContentRow">
																	<span>Дата последнего изменения</span>
																	<span>{dayjs(activeAdminOrder?.modified).format('DD.MM.YYYY HH:mm')}</span>
																</div>
															</>
														)
													} */}

													</ModalEditContent>
												</Panel>

											))}

										</Collapse>
									)
									: <span>Транзакций нет</span>}
							</div>
							{(chequeInfoEmpty === 'пусто' || chequeInfoEmpty === 'не пусто')
						&& (
							<EditInputForModal>

								{chequeInfoEmpty === 'пусто' ? <span>Чеки еще не загружены, повторите попытку позже</span>

									: chequeInfoData.map((item) => (
										<ChequeElement onClick={() => clickChequeElement(item)}>
											<div>
												<span>{dayjs(item?.date).format('DD.MM.YYYY HH:mm')}</span>

												<span>{item?.url}</span>
											</div>
											<CopyOutlined />

										</ChequeElement>
									))}
							</EditInputForModal>
						)}
						</>
					) : <>Форма доступна только для оплаченных заказов</>}
				</div>
			</Modal>

			<Modal
				title={`Пересчитать заказ ${activeAdminOrder?.orderNumber}`}
				centered
				open={isModalRefundOpen}
				onCancel={handleCancel}
				width={600}
				footer={[
					<ButtonAntd key="back" onClick={handleCancel}>
						Отмена
					</ButtonAntd>,
					<ButtonAntd disabled={activeAdminOrder.status !== OrderStatusType.Paid || 	activeAdminOrder.transactionSource === TransactionSource.POS} loading={!!loading.find((item) => item === 'calculateAdminOrder')} onClick={calculateRefundValue}>
						Рассчитать
					</ButtonAntd>,
					<ButtonAntd disabled={activeAdminOrder.status !== OrderStatusType.Paid || 	activeAdminOrder.transactionSource === TransactionSource.POS} key="submit" htmlType="submit" type="primary" form="myFormRefund">
						Применить
					</ButtonAntd>,
				]}
			>
				<ModalEditContent>
					<div className="ModalEditContentRow">
						<span>Дата создания заказа</span>
						<span>{dayjs(activeAdminOrder.created).format('DD.MM.YYYY HH:mm')}</span>
					</div>

					<div className="ModalEditContentRow">
						<span>Номер телефона</span>
						<span>{activeAdminOrder.accountPhone}</span>
					</div>
					<div className="ModalEditContentRow">
						<span>Имя парковочного пространства</span>
						<span>{activeAdminOrder.parkingSpaceName}</span>
					</div>
					<div className="ModalEditContentRow">
						<span>Номер заказа</span>
						<span>{activeAdminOrder.orderNumber}</span>
					</div>
					<div className="ModalEditContentRow">
						<span>Текущий статус</span>
						<span>{activeAdminOrder.id && getStatus(activeAdminOrderStatusDetails[activeAdminOrderStatusDetails?.length - 1]?.status)}</span>
					</div>
					<div className="ModalEditContentRow">
						<span>Текущая стоимость</span>
						<span>{activeAdminOrder.amount}</span>
					</div>
					<div className="ModalEditContentRow">
						<span>Текущая стоимость</span>
						<span>{activeAdminOrder.transactionSource === TransactionSource.Acquiring ? 'Эквайринг' : 'Паркомат'}</span>
					</div>
					<div className="ModalEditContentRow">
						<span>Дата завершения парковки</span>
						<span>{dayjs(activeAdminOrder.created).add(activeAdminOrder.duration || 0, 'seconds').format('DD.MM.YYYY HH:mm')}</span>
					</div>
				</ModalEditContent>
				{(activeAdminOrder.status === OrderStatusType.Paid) ? (
					activeAdminOrder.transactionSource === TransactionSource.Acquiring
						? (
							<EditInputForModal>

								<EditInputForModalTitle>Изменить время парковки</EditInputForModalTitle>
								<Form
									id="myFormRefund"
									form={formRefund}
									onFinish={onFinishRefund}
									onFinishFailed={onFinishRefundFailed}
								>
									<StatusAndDateTimePicker style={{ width: '100%' }}>
										<CustomInput style={{ width: '100%' }}>
											<span style={{ color: disabledSelect ? 'gray' : '#fff' }}>Новое время завершения парковки</span>

											<Form.Item
												name="dateRefund"
												style={{ marginBottom: '0px', width: '100%' }}
												rules={[
													{ required: !disabledSelect, message: 'Укажите время!' },
													{
														validator: async (rule, value) => {
															if ((new Date(activeAdminOrder.created).getTime() > new Date(formRefund.getFieldValue('dateRefund')).getTime())) {
																throw new Error(`Некорректная дата. Дата завершения меньше даты создания заказа (${dayjs(activeAdminOrder.created).format('DD.MM.YYYY HH:mm')})`);
															}
															if ((new Date(formRefund.getFieldValue('dateRefund')).getTime() - new Date(activeAdminOrder.created).getTime()) > ((activeAdminOrder.duration || 1) * 1000)) {
																throw new Error(`Некорректная дата. Дата должна быть меньше ${dayjs(activeAdminOrder.created).add(activeAdminOrder.duration || 0, 'seconds').format('DD.MM.YYYY HH:mm')}`);
															}
														},
													},
												]}
											>
												<DateTimePickerClassRefund
													style={{ color: '#fff', maxWidth: '100% !important' }}
													type="datetime-local"
													id="meeting-time1"
													name="meeting-time1"
													max={getMaxHoursRefund(activeAdminOrder.created)}
													min={getMinHours(activeAdminOrder.created)}
												/>
											</Form.Item>

										</CustomInput>

									</StatusAndDateTimePicker>

									<CustomInput>
										<span>Причина</span>
										<Form.Item name="descriptionRefund" style={{ width: '100%' }} rules={[{ required: true, message: 'Укажите причину!' }]}>
											<TextArea
												showCount
												maxLength={100}
												placeholder="Укажите причину"
											/>
										</Form.Item>
									</CustomInput>

								</Form>

								<EditInputForModalTitle>Итого, после редактирования</EditInputForModalTitle>

								<ModalEditContent>
									<div className="ModalEditContentRow">
										<span>Количество часов</span>
										{loading.find((item) => item === 'calculateAdminOrder') ? <Skeleton.Button active style={{ height: '22px' }} size="small" /> : calculateActiveAdminOrder?.duration && <span>{secondsToString(calculateActiveAdminOrder?.duration)}</span>}
									</div>
									<div className="ModalEditContentRow">
										<span>Сумма</span>
										{loading.find((item) => item === 'calculateAdminOrder') ? <Skeleton.Button active style={{ height: '22px' }} size="small" /> : <span>{calculateActiveAdminOrder?.amount}</span>}
									</div>
									<div className="ModalEditContentRow">
										<span>Дата начала заказа</span>
										{loading.find((item) => item === 'calculateAdminOrder') ? <Skeleton.Button active style={{ height: '22px', width: '100px' }} size="small" /> : calculateActiveAdminOrder?.duration && <span>{dayjs(calculateActiveAdminOrder.orderStart).format('DD.MM.YYYY HH:mm')}</span>}

									</div>
									<div className="ModalEditContentRow">
										<span>Дата окончания заказа</span>
										{loading.find((item) => item === 'calculateAdminOrder') ? <Skeleton.Button active style={{ height: '22px', width: '100px' }} size="small" /> : calculateActiveAdminOrder?.duration && <span>{dayjs(calculateActiveAdminOrder.orderEnd).format('DD.MM.YYYY HH:mm')}</span>}

									</div>
									{loading.find((item) => item === 'calculateAdminOrder') && <ModalEditContentSpin><Spin /></ModalEditContentSpin> }
									{/* <ModalEditContentSpin><Spin /></ModalEditContentSpin> */}
								</ModalEditContent>
							</EditInputForModal>
						)
						: (
							<EditInputForModal>
								<span>Редактирование платежа доступно только для заказов, оплаченных через эквайринг.</span>
								<span>
									Редактирование платежа, оплаченного через Паркомат
									{' '}
									<span style={{ color: '#ff8a00' }}>НЕДОСТУПНО.</span>
								</span>
							</EditInputForModal>
						)
				) : (
					<EditInputForModal>
						<span>Редактирование платежа доступно только для оплаченных заказов!</span>
					</EditInputForModal>
				)}

			</Modal>

		</Body>
	);
};

export default OrdersAdminPage;
