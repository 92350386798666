import IState from 'store/interfaces/Store.Interface';

const getRoot = (state: IState) => state.app;

export default {
	get: (state: IState) => getRoot(state),
	getGlobalLoading: (state: IState) => getRoot(state).globalLoading,
	getCurrentLoading: (state: IState) => getRoot(state).currentLoadings,
	getNotification: (state:IState) => getRoot(state).notification,
	getSuccessfulApi: (state:IState) => getRoot(state).successfulApi,
	getShowBooking: (state:IState) => getRoot(state).showBooking,
	getShowTariffEditForm: (state:IState) => getRoot(state).showTariffEditForm,
	getShowAdminOrderEditForm: (state:IState) => getRoot(state).showAdminOrderEditForm,
	getShowAdminOrderHistoryForm: (state:IState) => getRoot(state).showAdminOrderHistoryForm,
	getShowWarning: (state:IState) => getRoot(state).showWarningPopup,
};
