/* Options:
Date: 2023-07-17 08:25:29
Version: 6.70
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://hub.dev.its.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

// @DataContract
export class PageRequest
{
    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<PageRequest>) { (Object as any).assign(this, init); }
}

export interface ICustomerItemRequest extends ICustomerEntity
{
}

export interface ICustomerEntity
{
    // @DataMember
    customerId: string;
}

export interface ICustomerIntegrationIdRequest
{
    customerIntegrationId: string;
}

export interface IBaseEntityItemRequest
{
    id: string;
}

export interface IDescription
{
    // @DataMember
    description: string;
}

export interface IRequestWithEntity
{
}

// @DataContract
export class GetEntityGroupsRequestBase implements ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetEntityGroupsRequestBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuditItem
{
    // @DataMember
    public activityTime: string;

    // @DataMember
    public userId: string;

    // @DataMember
    public entityId: string;

    // @DataMember
    public entityType: string;

    // @DataMember
    public entitySubType: string;

    // @DataMember
    public activity: string;

    // @DataMember
    public description: string;

    // @DataMember
    public entityName: string;

    // @DataMember
    public moduleId: string;

    public constructor(init?: Partial<AuditItem>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuditItemDetails
{
    // @DataMember
    public activityTime: string;

    // @DataMember
    public userId: string;

    // @DataMember
    public entityId: string;

    // @DataMember
    public userName: string;

    // @DataMember
    public entityName: string;

    // @DataMember
    public entityType: string;

    // @DataMember
    public entitySubType: string;

    // @DataMember
    public activity: string;

    // @DataMember
    public description: string;

    // @DataMember
    public moduleId: string;

    public constructor(init?: Partial<AuditItemDetails>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeleteItemsRequest implements IDescription
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteItemsRequest>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ExistItemRequestBase
{

    public constructor(init?: Partial<ExistItemRequestBase>) { (Object as any).assign(this, init); }
}

export class CustomerTimeZoneInfo implements INamed
{
    // @DataMember
    public id: string;

    // @DataMember
    public id2: string;

    // @DataMember
    public id3: string;

    // @DataMember
    public entityType: string;

    // @DataMember
    public name: string;

    // @DataMember
    public offset: number;

    public constructor(init?: Partial<CustomerTimeZoneInfo>) { (Object as any).assign(this, init); }
}

export class DeleteCustomerItemsRequest implements ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteCustomerItemsRequest>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventItemDetails
{
    // @DataMember
    public eventTime: string;

    // @DataMember
    public entityId: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public entityType: string;

    // @DataMember
    public entityName: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public eventType: string;

    // @DataMember
    public description: string;

    // @DataMember
    public moduleId: string;

    // @DataMember
    public parameters: { [index: string]: string; };

    public constructor(init?: Partial<EventItemDetails>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ApplicationInfo implements INamed, IDescription
{
    // @DataMember
    public id: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public instance: string;

    // @DataMember
    public version: string;

    // @DataMember
    public buildTime: string;

    public constructor(init?: Partial<ApplicationInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CustomerRoleInfo implements INamedEntity, IEntity
{
    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomerRoleInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ExistCustomerItemRequestBase implements ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    public constructor(init?: Partial<ExistCustomerItemRequestBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Permission implements IPermission
{
    // @DataMember
    public id: string;

    // @DataMember
    public category: string;

    // @DataMember
    public entityType: string;

    // @DataMember
    public moduleId: string;

    // @DataMember
    public featureId: string;

    public constructor(init?: Partial<Permission>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserInfo implements ITimeZoneId, ICustomerEntityViewModel, IEntity
{
    // @DataMember
    public userName: string;

    // @DataMember
    public displayName: string;

    // @DataMember
    public email: string;

    // @DataMember
    public phone: string;

    // @DataMember
    public title: string;

    // @DataMember
    public roles: string[];

    // @DataMember
    public timeZoneId: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<UserInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserModel implements ISecurityUser, IBaseEntity, ICustomerEntityViewModel, ITimeZoneId, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public userLiveTime: string;

    // @DataMember
    public userName: string;

    // @DataMember
    public displayName: string;

    // @DataMember
    public email: string;

    // @DataMember
    public phone: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public title: string;

    // @DataMember
    public deleted: boolean;

    // @DataMember
    public timeZoneId: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<UserModel>) { (Object as any).assign(this, init); }
}

export interface IChangeKey extends IReadonlyChangeKey
{
    // @DataMember
    changeKey: string;
}

export interface IReadonlyChangeKey
{
    // @DataMember
    changeKey: string;
}

// @DataContract
export class BaseEntityViewModelBase implements IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<BaseEntityViewModelBase>) { (Object as any).assign(this, init); }
}

export interface IEntity
{
}

export interface IEntityTyped
{
    // @DataMember
    entityType: string;
}

export interface IModified
{
    // @DataMember
    modified: string;

    // @DataMember
    modifiedBy: string;
}

// @DataContract
export class EntityGroupInfo implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public itemsCount: number;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<EntityGroupInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NamedEntityViewModelBase implements INamedEntity, IEntity
{
    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<NamedEntityViewModelBase>) { (Object as any).assign(this, init); }
}

export interface INamedEntity extends IEntity, INamed
{
}

export interface INamed
{
    // @DataMember
    name: string;
}

export interface ITimeZoneId
{
    // @DataMember
    timeZoneId: string;
}

export class CustomerEntityViewModelBase implements ICustomerEntityViewModel, IEntity
{
    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomerEntityViewModelBase>) { (Object as any).assign(this, init); }
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity
{
}

export interface ICustomerName
{
    // @DataMember
    customerName: string;
}

// @DataContract
export class NamedBaseEntityViewModelBase implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<NamedBaseEntityViewModelBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ApplicationInstanceInfo implements INamed, IDescription
{
    // @DataMember
    public id: string;

    // @DataMember
    public applicationId: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public instance: string;

    // @DataMember
    public version: string;

    // @DataMember
    public buildTime: string;

    // @DataMember
    public lastConnectionTime: string;

    public constructor(init?: Partial<ApplicationInstanceInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EntityViewModelBase implements IEntity
{
    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<EntityViewModelBase>) { (Object as any).assign(this, init); }
}

export interface ISecurityUser extends IEntity
{
    displayName: string;
    userName: string;
}

// @DataContract
export class SharedDbItem
{
    // @DataMember
    public type: string;

    // @DataMember
    public id: string;

    // @DataMember
    public object: Object;

    // @DataMember
    public modified: string;

    public constructor(init?: Partial<SharedDbItem>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export interface IPermission extends IModuleId, IFeatureId
{
}

export interface IModuleId
{
    moduleId: string;
}

export interface IFeatureId
{
    featureId: string;
}

export interface IBaseEntity extends IEntity, IModified, IDescription, IChangeKey
{
    deleted: boolean;
}

export class CustomerNamedBaseEntityViewModelBase implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PageRequestBase
{
    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<PageRequestBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public entityId: string;

    // @DataMember
    public customerIntegrationId: string;

    // @DataMember
    public externalId: string;

    // @DataMember
    public settings: string;

    // @DataMember
    public integrationId: string;

    // @DataMember
    public integrationName: string;

    // @DataMember
    public integrationType: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<EntityIntegration>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntityIntegrationResponse implements IChangeKey
{
    // @DataMember
    public items: EntityIntegration[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetEntityIntegrationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntityGroupsResponse implements IChangeKey
{
    // @DataMember
    public items: EntityGroupInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetEntityGroupsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntitiesResponse implements IChangeKey
{
    // @DataMember
    public items: Object[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetEntitiesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntityAuditResponse implements IChangeKey
{
    // @DataMember
    public items: AuditItemDetails[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetEntityAuditResponse>) { (Object as any).assign(this, init); }
}

export class Customer implements ITimeZoneId, INamedEntity, IEntity
{
    // @DataMember
    public timeZoneId: string;

    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<Customer>) { (Object as any).assign(this, init); }
}

export class CustomerInfo implements ITimeZoneId, INamedEntity, IEntity
{
    // @DataMember
    public timeZoneId: string;

    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomerInfo>) { (Object as any).assign(this, init); }
}

export class GetCustomersResponse implements IChangeKey
{
    // @DataMember
    public items: CustomerInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetCustomersResponse>) { (Object as any).assign(this, init); }
}

export class GetTimezonesResponse implements IChangeKey
{
    // @DataMember
    public languageCode: string;

    // @DataMember
    public zoneSet: string;

    // @DataMember
    public items: CustomerTimeZoneInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetTimezonesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CustomerIntegration implements ICustomerEntityViewModel, IEntity
{
    // @DataMember
    public integrationId: string;

    // @DataMember
    public integrationName: string;

    // @DataMember
    public integrationType: string;

    // @DataMember
    public settings: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomerIntegration>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetCustomerIntegrationsResponse implements IChangeKey
{
    // @DataMember
    public items: CustomerIntegration[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetCustomerIntegrationsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Integration implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public integrationType: string;

    // @DataMember
    public settings: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<Integration>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetIntegrationsResponse implements IChangeKey
{
    // @DataMember
    public items: Integration[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetIntegrationsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEventsResponse implements IChangeKey
{
    // @DataMember
    public items: EventItemDetails[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetEventsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FileItem implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public entityId: string;

    // @DataMember
    public size: number;

    // @DataMember
    public contentType: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<FileItem>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntityFilesResponse implements IChangeKey
{
    // @DataMember
    public items: FileItem[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetEntityFilesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ModuleSettings
{
    // @DataMember
    public moduleId: string;

    // @DataMember
    public settings: string;

    // @DataMember
    public modified: string;

    public constructor(init?: Partial<ModuleSettings>) { (Object as any).assign(this, init); }
}

export class GetCustomerRolesResponse implements IChangeKey
{
    // @DataMember
    public items: CustomerRoleInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetCustomerRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CustomerRole implements INamedEntity, IEntity
{
    // @DataMember
    public permissions: string[];

    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomerRole>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetCustomerPermissionsResponse implements IChangeKey
{
    // @DataMember
    public items: Permission[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetCustomerPermissionsResponse>) { (Object as any).assign(this, init); }
}

export class GetCustomerRoleUsersResponse implements IChangeKey
{
    // @DataMember
    public items: UserInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetCustomerRoleUsersResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetUsersFullCacheResponse implements IChangeKey
{
    // @DataMember
    public changeKey: string;

    // @DataMember
    public items: UserModel[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<GetUsersFullCacheResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetAllUsersResponse implements IChangeKey
{
    // @DataMember
    public items: UserInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetAllUsersResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetUsersResponse implements IChangeKey
{
    // @DataMember
    public items: UserInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetUsersResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class User implements ISecurityUser, ITimeZoneId, IEntity
{
    // @DataMember
    public userName: string;

    // @DataMember
    public displayName: string;

    // @DataMember
    public email: string;

    // @DataMember
    public phone: string;

    // @DataMember
    public customer: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public title: string;

    // @DataMember
    public timeZoneId: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetSharedDbItemsResponse
{
    // @DataMember
    public id: string;

    // @DataMember
    public items: SharedDbItem[];

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetSharedDbItemsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetAccessTokenResponse
{
    // @DataMember(Order=1)
    public accessToken: string;

    // @DataMember(Order=2)
    public refreshToken: string;

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAccessTokenResponse>) { (Object as any).assign(this, init); }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest implements IReturn<GetEntityIntegrationResponse>, ICustomerIntegrationIdRequest, ICustomerItemRequest
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetEntityIntegrationsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityIntegrationResponse(); }
    public getTypeName() { return 'GetEntityIntegrationsRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest implements IReturn<EntityIntegration>, ICustomerIntegrationIdRequest, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetEntityIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityIntegration(); }
    public getTypeName() { return 'GetEntityIntegrationRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest implements IReturn<EntityIntegration>, ICustomerIntegrationIdRequest, ICustomerItemRequest, IDescription
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: EntityIntegration;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateEntityIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityIntegration(); }
    public getTypeName() { return 'CreateEntityIntegrationRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest implements IReturn<EntityIntegration>, ICustomerIntegrationIdRequest, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: EntityIntegration;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateEntityIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityIntegration(); }
    public getTypeName() { return 'UpdateEntityIntegrationRequest'; }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetEntityPropertiesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return {}; }
    public getTypeName() { return 'GetEntityPropertiesRequest'; }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any>
{
    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember
    public description: string;

    // @DataMember
    public entity: { [index: string]: string; };

    public constructor(init?: Partial<UpdateEntityPropertiesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return {}; }
    public getTypeName() { return 'UpdateEntityPropertiesRequest'; }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string>
{
    // @DataMember
    public property: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetEntityPropertyRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'GetEntityPropertyRequest'; }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string>
{
    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember
    public description: string;

    // @DataMember
    public property: string;

    // @DataMember
    public value: string;

    public constructor(init?: Partial<UpdateEntityPropertyRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'UpdateEntityPropertyRequest'; }
}

// @Route("/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any>
{
    // @DataMember
    public property: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetEntitiesPropertyRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return {}; }
    public getTypeName() { return 'GetEntitiesPropertyRequest'; }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
    // @DataMember
    public id: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetItemEntityGroupsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityGroupsResponse(); }
    public getTypeName() { return 'GetItemEntityGroupsRequest'; }
}

// @Route("/entities/remoteRepository/{repositoryId}/data", "GET")
// @DataContract
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse>
{
    // @DataMember
    public repositoryId: string;

    // @DataMember
    public modified?: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetEntitiesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntitiesResponse(); }
    public getTypeName() { return 'GetEntitiesRequest'; }
}

// @Route("/audit", "POST")
// @DataContract
export class AddAuditRequest implements IReturnVoid
{
    // @DataMember
    public item: AuditItem;

    public constructor(init?: Partial<AddAuditRequest>) { (Object as any).assign(this, init); }
    public createResponse() {}
    public getTypeName() { return 'AddAuditRequest'; }
}

// @Route("/audit/entity/{Id}", "GET")
// @DataContract
export class GetEntityAuditRequest implements IReturn<GetEntityAuditResponse>
{
    // @DataMember
    public id: string;

    // @DataMember
    public from: string;

    // @DataMember
    public to: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetEntityAuditRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityAuditResponse(); }
    public getTypeName() { return 'GetEntityAuditRequest'; }
}

// @Route("/audit/user/{Id}", "GET")
// @DataContract
export class GetUserAuditRequest implements IReturn<GetEntityAuditResponse>
{
    // @DataMember
    public id: string;

    // @DataMember
    public from: string;

    // @DataMember
    public to: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetUserAuditRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityAuditResponse(); }
    public getTypeName() { return 'GetUserAuditRequest'; }
}

// @Route("/customers", "DELETE")
// @DataContract
export class DeleteCustomersRequest implements IReturn<number>, IDescription
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteCustomersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteCustomersRequest'; }
}

// @Route("/customers/current", "GET")
// @DataContract
export class GetCurrentCustomerRequest implements IReturn<Customer>, ICustomerEntity
{
    // @DataMember
    public customerId: string;

    public constructor(init?: Partial<GetCurrentCustomerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Customer(); }
    public getTypeName() { return 'GetCurrentCustomerRequest'; }
}

// @Route("/customers", "GET")
// @DataContract
export class GetCustomersRequest implements IReturn<GetCustomersResponse>
{
    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetCustomersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCustomersResponse(); }
    public getTypeName() { return 'GetCustomersRequest'; }
}

// @Route("/customer/{id}/availableCustomers", "GET")
// @DataContract
export class GetAvailableCustomersRequest implements IReturn<GetCustomersResponse>, ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetAvailableCustomersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCustomersResponse(); }
    public getTypeName() { return 'GetAvailableCustomersRequest'; }
}

// @Route("/customers/byIds", "GET")
// @DataContract
export class GetCustomersByIdsRequest implements IReturn<CustomerInfo>
{
    // @DataMember
    public ids: string[];

    public constructor(init?: Partial<GetCustomersByIdsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomerInfo(); }
    public getTypeName() { return 'GetCustomersByIdsRequest'; }
}

// @Route("/customers/exist", "GET")
// @DataContract
export class ExistCustomerNameRequest implements IReturn<boolean>
{
    // @DataMember
    public name: string;

    public constructor(init?: Partial<ExistCustomerNameRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'ExistCustomerNameRequest'; }
}

// @Route("/customer/{id}", "GET")
// @DataContract
export class GetCustomerRequest implements IReturn<Customer>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetCustomerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Customer(); }
    public getTypeName() { return 'GetCustomerRequest'; }
}

// @Route("/customer", "POST")
// @DataContract
export class CreateCustomerRequest implements IReturn<Customer>, IDescription
{
    // @DataMember(IsRequired=true)
    public entity: Customer;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateCustomerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Customer(); }
    public getTypeName() { return 'CreateCustomerRequest'; }
}

// @Route("/customer/{id}", "PUT,POST")
// @DataContract
export class UpdateCustomerRequest implements IReturn<Customer>, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: Customer;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateCustomerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Customer(); }
    public getTypeName() { return 'UpdateCustomerRequest'; }
}

// @Route("/timezones", "GET")
// @DataContract
export class GetTimezonesRequest implements IReturn<GetTimezonesResponse>
{
    // @DataMember
    public languageCode: string;

    // @DataMember
    public zoneSet: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetTimezonesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTimezonesResponse(); }
    public getTypeName() { return 'GetTimezonesRequest'; }
}

// @Route("/customer/{customerId}/integrations", "GET")
// @DataContract
export class GetCustomerIntegrationsRequest implements IReturn<GetCustomerIntegrationsResponse>, ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetCustomerIntegrationsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCustomerIntegrationsResponse(); }
    public getTypeName() { return 'GetCustomerIntegrationsRequest'; }
}

// @Route("/customer/{customerId}/integration/{id}", "GET")
// @DataContract
export class GetCustomerIntegrationRequest implements IReturn<CustomerIntegration>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetCustomerIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomerIntegration(); }
    public getTypeName() { return 'GetCustomerIntegrationRequest'; }
}

// @Route("/customer/{customerId}/integration", "POST")
// @DataContract
export class CreateCustomerIntegrationRequest implements IReturn<CustomerIntegration>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: CustomerIntegration;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateCustomerIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomerIntegration(); }
    public getTypeName() { return 'CreateCustomerIntegrationRequest'; }
}

// @Route("/customer/{customerId}/integration/{id}", "PUT")
// @DataContract
export class UpdateCustomerIntegrationRequest implements IReturn<CustomerIntegration>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: CustomerIntegration;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateCustomerIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomerIntegration(); }
    public getTypeName() { return 'UpdateCustomerIntegrationRequest'; }
}

// @Route("/customer/{customerId}/integrations", "DELETE")
// @DataContract
export class DeleteCustomerIntegrationsRequest implements IReturn<number>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteCustomerIntegrationsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteCustomerIntegrationsRequest'; }
}

// @Route("/integrations", "GET")
// @DataContract
export class GetIntegrationsRequest implements IReturn<GetIntegrationsResponse>
{
    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetIntegrationsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetIntegrationsResponse(); }
    public getTypeName() { return 'GetIntegrationsRequest'; }
}

// @Route("/integration/{id}", "GET")
// @DataContract
export class GetIntegrationRequest implements IReturn<Integration>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Integration(); }
    public getTypeName() { return 'GetIntegrationRequest'; }
}

// @Route("/integration", "POST")
// @DataContract
export class CreateIntegrationRequest implements IReturn<Integration>, IDescription
{
    // @DataMember(IsRequired=true)
    public entity: Integration;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Integration(); }
    public getTypeName() { return 'CreateIntegrationRequest'; }
}

// @Route("/integration/{id}", "PUT")
// @DataContract
export class UpdateIntegrationRequest implements IReturn<Integration>, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: Integration;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Integration(); }
    public getTypeName() { return 'UpdateIntegrationRequest'; }
}

// @Route("/events/entity/{Id}", "GET")
// @DataContract
export class GetEventsRequest implements IReturn<GetEventsResponse>
{
    // @DataMember
    public id: string;

    // @DataMember
    public from: string;

    // @DataMember
    public to: string;

    // @DataMember
    public eventTypes: string[];

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetEventsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEventsResponse(); }
    public getTypeName() { return 'GetEventsRequest'; }
}

// @Route("/customer/{customerId}/events", "GET")
// @DataContract
export class GetCustomerEventsRequest implements IReturn<GetEventsResponse>, ICustomerItemRequest
{
    // @DataMember
    public customerId: string;

    // @DataMember
    public from: string;

    // @DataMember
    public to: string;

    // @DataMember
    public eventTypes: string[];

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetCustomerEventsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEventsResponse(); }
    public getTypeName() { return 'GetCustomerEventsRequest'; }
}

// @Route("/events/types", "GET")
// @DataContract
export class GetEventTypesRequest implements IReturn<string[]>
{

    public constructor(init?: Partial<GetEventTypesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<string>(); }
    public getTypeName() { return 'GetEventTypesRequest'; }
}

// @Route("/files/download/{link}", "GET")
// @DataContract
export class GetEntityFileRequest implements IReturn<Object>
{
    // @DataMember
    public link: string;

    public constructor(init?: Partial<GetEntityFileRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Object(); }
    public getTypeName() { return 'GetEntityFileRequest'; }
}

// @Route("/file/{id}/getLink", "GET")
// @DataContract
export class GetEntityFileDownloadLinkRequest implements IReturn<string>
{
    // @DataMember
    public id: string;

    // @DataMember
    public expiredIn: number;

    public constructor(init?: Partial<GetEntityFileDownloadLinkRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'GetEntityFileDownloadLinkRequest'; }
}

// @Route("/files/{entityId}", "GET")
// @DataContract
export class GetEntityFilesRequest implements IReturn<GetEntityFilesResponse>
{
    // @DataMember
    public entityId: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetEntityFilesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityFilesResponse(); }
    public getTypeName() { return 'GetEntityFilesRequest'; }
}

// @Route("/files/upload", "POST")
// @DataContract
export class CreateEntityFileRequest implements IReturn<FileItem>, IDescription
{
    // @DataMember
    public entityId: string;

    // @DataMember
    public name: string;

    // @DataMember(IsRequired=true)
    public entity: FileItem;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateEntityFileRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new FileItem(); }
    public getTypeName() { return 'CreateEntityFileRequest'; }
}

// @Route("/files/{entityId}", "DELETE")
// @DataContract
export class DeleteEntityFileRequest implements IReturn<number>, IDescription
{
    // @DataMember
    public entityId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteEntityFileRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteEntityFileRequest'; }
}

// @Route("/modules/{ModuleId}/settings", "GET")
// @DataContract
export class GetModuleSettingsRequest implements IReturn<ModuleSettings>
{
    // @DataMember
    public moduleId: string;

    // @DataMember
    public camelCase: boolean;

    public constructor(init?: Partial<GetModuleSettingsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ModuleSettings(); }
    public getTypeName() { return 'GetModuleSettingsRequest'; }
}

// @Route("/modules/{ModuleId}/settings", "PUT")
// @DataContract
export class UpdateModuleSettingsRequest implements IReturn<ModuleSettings>
{
    // @DataMember
    public moduleId: string;

    // @DataMember
    public settings: string;

    // @DataMember
    public camelCase: boolean;

    public constructor(init?: Partial<UpdateModuleSettingsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ModuleSettings(); }
    public getTypeName() { return 'UpdateModuleSettingsRequest'; }
}

// @Route("/customer/{customerId}/modules/{ModuleId}/settings", "GET")
// @DataContract
export class GetModuleCustomerSettingsRequest implements IReturn<ModuleSettings>, ICustomerItemRequest
{
    // @DataMember
    public moduleId: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public camelCase: boolean;

    public constructor(init?: Partial<GetModuleCustomerSettingsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ModuleSettings(); }
    public getTypeName() { return 'GetModuleCustomerSettingsRequest'; }
}

// @Route("/customer/{customerId}/modules/{ModuleId}/settings", "PUT")
// @DataContract
export class UpdateModuleCustomerSettingsRequest implements IReturn<ModuleSettings>, ICustomerItemRequest
{
    // @DataMember
    public moduleId: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public settings: string;

    // @DataMember
    public camelCase: boolean;

    public constructor(init?: Partial<UpdateModuleCustomerSettingsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ModuleSettings(); }
    public getTypeName() { return 'UpdateModuleCustomerSettingsRequest'; }
}

// @Route("/modules/{ModuleId}/registerDefaultCustomerSettings", "POST")
// @DataContract
export class RegisterDefaultModuleCustomerSettingsRequest implements IReturn<ModuleSettings>
{
    // @DataMember
    public moduleId: string;

    // @DataMember
    public settings: string;

    // @DataMember
    public application: ApplicationInfo;

    public constructor(init?: Partial<RegisterDefaultModuleCustomerSettingsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ModuleSettings(); }
    public getTypeName() { return 'RegisterDefaultModuleCustomerSettingsRequest'; }
}

// @Route("/modules/{ModuleId}/registerDefaultSettings", "POST")
// @DataContract
export class RegisterDefaultModuleSettingsRequest implements IReturn<ModuleSettings>
{
    // @DataMember
    public moduleId: string;

    // @DataMember
    public settings: string;

    // @DataMember
    public application: ApplicationInfo;

    public constructor(init?: Partial<RegisterDefaultModuleSettingsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ModuleSettings(); }
    public getTypeName() { return 'RegisterDefaultModuleSettingsRequest'; }
}

// @Route("/customer/{customerId}/modules/settings", "GET")
// @DataContract
export class GetModulesCustomerSettingsRequest implements IReturn<ModuleSettings[]>, ICustomerItemRequest
{
    // @DataMember
    public customerId: string;

    // @DataMember
    public camelCase: boolean;

    public constructor(init?: Partial<GetModulesCustomerSettingsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<ModuleSettings>(); }
    public getTypeName() { return 'GetModulesCustomerSettingsRequest'; }
}

// @Route("/permissions", "GET")
// @DataContract
export class GetPermissionsRequest implements IReturn<Permission[]>
{

    public constructor(init?: Partial<GetPermissionsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<Permission>(); }
    public getTypeName() { return 'GetPermissionsRequest'; }
}

// @Route("/permissions/declare", "POST")
// @DataContract
export class DeclarePermissionsRequest implements IReturnVoid
{
    // @DataMember
    public permissions: Permission[];

    // @DataMember
    public application: ApplicationInfo;

    public constructor(init?: Partial<DeclarePermissionsRequest>) { (Object as any).assign(this, init); }
    public createResponse() {}
    public getTypeName() { return 'DeclarePermissionsRequest'; }
}

// @Route("/permissions/currentUser", "GET")
// @DataContract
export class GetCurrentUserPermissionsRequest implements IReturn<string[]>
{

    public constructor(init?: Partial<GetCurrentUserPermissionsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<string>(); }
    public getTypeName() { return 'GetCurrentUserPermissionsRequest'; }
}

// @Route("/customer/{customerId}/roles", "GET")
// @DataContract
export class GetCustomerRolesRequest implements IReturn<GetCustomerRolesResponse>, ICustomerItemRequest
{
    // @DataMember
    public permissionsFilter: string[];

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetCustomerRolesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCustomerRolesResponse(); }
    public getTypeName() { return 'GetCustomerRolesRequest'; }
}

// @Route("/customer/{customerId}/roles/exist", "GET")
// @DataContract
export class ExistCustomerRoleNameRequest implements IReturn<boolean>, ICustomerItemRequest
{
    // @DataMember
    public name: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    public constructor(init?: Partial<ExistCustomerRoleNameRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'ExistCustomerRoleNameRequest'; }
}

// @Route("/customer/{customerId}/role/{id}", "GET")
// @DataContract
export class GetCustomerRoleRequest implements IReturn<CustomerRole>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetCustomerRoleRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomerRole(); }
    public getTypeName() { return 'GetCustomerRoleRequest'; }
}

// @Route("/customer/{customerId}/role", "POST")
// @DataContract
export class CreateCustomerRoleRequest implements IReturn<CustomerRole>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: CustomerRole;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateCustomerRoleRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomerRole(); }
    public getTypeName() { return 'CreateCustomerRoleRequest'; }
}

// @Route("/customer/{customerId}/role/{id}", "PUT")
// @DataContract
export class UpdateCustomerRoleRequest implements IReturn<CustomerRole>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: CustomerRole;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateCustomerRoleRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomerRole(); }
    public getTypeName() { return 'UpdateCustomerRoleRequest'; }
}

// @Route("/customer/{customerId}/roles", "GET")
// @DataContract
export class DeleteCustomerRolesRequest implements IReturn<number>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteCustomerRolesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteCustomerRolesRequest'; }
}

// @Route("/customer/{customerId}/permissions", "GET")
// @DataContract
export class GetCustomerPermissionsRequest implements IReturn<GetCustomerPermissionsResponse>, ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetCustomerPermissionsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCustomerPermissionsResponse(); }
    public getTypeName() { return 'GetCustomerPermissionsRequest'; }
}

// @Route("/customer/{customerId}/permissions", "PUT")
// @DataContract
export class UpdateCustomerPermissionsRequest implements IReturn<boolean>, ICustomerItemRequest
{
    // @DataMember
    public entity: string[];

    // @DataMember
    public customerId: string;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateCustomerPermissionsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'UpdateCustomerPermissionsRequest'; }
}

// @Route("/customer/{customerId}/role/{id}/users", "GET")
// @DataContract
export class GetCustomerRoleUsersRequest implements IReturn<GetCustomerRoleUsersResponse>, ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetCustomerRoleUsersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCustomerRoleUsersResponse(); }
    public getTypeName() { return 'GetCustomerRoleUsersRequest'; }
}

// @Route("/customer/{customerId}/role/{roleId}/users", "POST")
// @DataContract
export class AssignCustomerRoleToUserRequest implements IReturn<boolean>, ICustomerItemRequest
{
    // @DataMember
    public roleId: string;

    // @DataMember
    public userId: string;

    // @DataMember
    public customerId: string;

    public constructor(init?: Partial<AssignCustomerRoleToUserRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'AssignCustomerRoleToUserRequest'; }
}

// @Route("/customer/{customerId}/user/{id}/roles", "GET")
// @DataContract
export class GetUserRolesRequest implements IReturn<CustomerRoleInfo[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetUserRolesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<CustomerRoleInfo>(); }
    public getTypeName() { return 'GetUserRolesRequest'; }
}

// @Route("/customer/{customerId}/user/{id}/roles", "PUT")
// @DataContract
export class UpdateUserRolesRequest implements IReturn<CustomerRoleInfo[]>, ICustomerItemRequest
{
    // @DataMember
    public id: string;

    // @DataMember
    public entity: string[];

    // @DataMember
    public customerId: string;

    public constructor(init?: Partial<UpdateUserRolesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<CustomerRoleInfo>(); }
    public getTypeName() { return 'UpdateUserRolesRequest'; }
}

// @Route("/customer/{customerId}/role/{roleId}/users", "DELETE")
// @DataContract
export class DeleteCustomerRoleFromUserRequest implements IReturn<boolean>, ICustomerItemRequest
{
    // @DataMember
    public roleId: string;

    // @DataMember
    public userId: string;

    // @DataMember
    public customerId: string;

    public constructor(init?: Partial<DeleteCustomerRoleFromUserRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'DeleteCustomerRoleFromUserRequest'; }
}

// @Route("/validateToken", "GET")
// @DataContract
export class ValidateItsTokenRequest implements IReturn<boolean>
{
    // @DataMember
    public application: ApplicationInfo;

    public constructor(init?: Partial<ValidateItsTokenRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'ValidateItsTokenRequest'; }
}

// @Route("/applicationInstanceShutdown", "POST")
// @DataContract
export class ApplicationInstanceShutdownRequest implements IReturn<boolean>
{
    // @DataMember
    public application: ApplicationInfo;

    public constructor(init?: Partial<ApplicationInstanceShutdownRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'ApplicationInstanceShutdownRequest'; }
}

// @Route("/applicationInfoInstances", "GET")
// @DataContract
export class GetApplicationInfoInstancesRequest implements IReturn<ApplicationInstanceInfo[]>
{

    public constructor(init?: Partial<GetApplicationInfoInstancesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<ApplicationInstanceInfo>(); }
    public getTypeName() { return 'GetApplicationInfoInstancesRequest'; }
}

// @Route("/applicationInstances", "GET")
// @DataContract
export class GetApplicationInstancesRequest implements IReturn<ApplicationInfo[]>
{

    public constructor(init?: Partial<GetApplicationInstancesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<ApplicationInfo>(); }
    public getTypeName() { return 'GetApplicationInstancesRequest'; }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string>
{

    public constructor(init?: Partial<GetHealthStatusRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'GetHealthStatusRequest'; }
}

// @Route("/users/changeKey", "GET")
// @DataContract
export class GetUsersChangeKeyRequest implements IReturn<string>
{

    public constructor(init?: Partial<GetUsersChangeKeyRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'GetUsersChangeKeyRequest'; }
}

// @Route("/users/fullCache", "GET")
// @DataContract
export class GetUsersFullCacheRequest implements IReturn<GetUsersFullCacheResponse>
{
    // @DataMember
    public lastModified?: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetUsersFullCacheRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUsersFullCacheResponse(); }
    public getTypeName() { return 'GetUsersFullCacheRequest'; }
}

// @Route("/allUsers", "GET")
// @DataContract
export class GetAllUsersRequest implements IReturn<GetAllUsersResponse>
{
    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetAllUsersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAllUsersResponse(); }
    public getTypeName() { return 'GetAllUsersRequest'; }
}

// @Route("/customer/{customerId}/users", "GET")
// @DataContract
export class GetUsersRequest implements IReturn<GetUsersResponse>, ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public propertiesTest: { [index: string]: number; };

    public constructor(init?: Partial<GetUsersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUsersResponse(); }
    public getTypeName() { return 'GetUsersRequest'; }
}

// @Route("/users/current", "GET")
// @DataContract
export class GetCurrentUserRequest implements IReturn<User>
{

    public constructor(init?: Partial<GetCurrentUserRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new User(); }
    public getTypeName() { return 'GetCurrentUserRequest'; }
}

// @Route("/customer/{customerId}/user/{id}", "GET")
// @DataContract
export class GetUserRequest implements IReturn<User>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetUserRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new User(); }
    public getTypeName() { return 'GetUserRequest'; }
}

// @Route("/customer/{customerId}/user", "POST")
// @DataContract
export class CreateUserRequest implements IReturn<User>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public password: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: User;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateUserRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new User(); }
    public getTypeName() { return 'CreateUserRequest'; }
}

// @Route("/user/{id}/resetPassword", "PUT")
// @DataContract
export class ResetPasswordRequest implements IReturn<boolean>
{
    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public password: string;

    public constructor(init?: Partial<ResetPasswordRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'ResetPasswordRequest'; }
}

// @Route("/customer/{customerId}/user/logoutEverywhere", "POST")
// @DataContract
export class LogoutCurrentUserEverywhereRequest implements IReturn<boolean>
{

    public constructor(init?: Partial<LogoutCurrentUserEverywhereRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'LogoutCurrentUserEverywhereRequest'; }
}

// @Route("/customer/{customerId}/user/{id}/logoutEverywhere", "POST")
// @DataContract
export class LogoutEverywhereRequest implements IReturn<boolean>
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<LogoutEverywhereRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'LogoutEverywhereRequest'; }
}

// @Route("/user/changePassword", "PUT")
// @DataContract
export class ChangePasswordRequest implements IReturn<boolean>
{
    // @DataMember(IsRequired=true)
    public oldPassword: string;

    // @DataMember(IsRequired=true)
    public newPassword: string;

    public constructor(init?: Partial<ChangePasswordRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'ChangePasswordRequest'; }
}

// @Route("/customer/{customerId}/user/{id}", "PUT")
// @DataContract
export class UpdateUserRequest implements IReturn<User>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: User;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateUserRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new User(); }
    public getTypeName() { return 'UpdateUserRequest'; }
}

// @Route("/user/exist", "GET")
// @DataContract
export class ExistUserNameRequest implements IReturn<boolean>
{
    // @DataMember(IsRequired=true)
    public userName: string;

    public constructor(init?: Partial<ExistUserNameRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'ExistUserNameRequest'; }
}

// @Route("/customer/{customerId}/users", "DELETE")
// @DataContract
export class DeleteUsersRequest implements IReturn<number>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteUsersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteUsersRequest'; }
}

// @DataContract
export class GetSharedDbItemsRequest implements IReturn<GetSharedDbItemsResponse>
{
    // @DataMember
    public id: string;

    // @DataMember
    public lastModified: string;

    // @DataMember
    public items: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetSharedDbItemsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSharedDbItemsResponse(); }
    public getTypeName() { return 'GetSharedDbItemsRequest'; }
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/access-token")
// @DataContract
export class GetAccessToken implements IReturn<GetAccessTokenResponse>, IPost
{
    // @DataMember(Order=1)
    public refreshToken: string;

    public constructor(init?: Partial<GetAccessToken>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAccessTokenResponse(); }
    public getTypeName() { return 'GetAccessToken'; }
}

