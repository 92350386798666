/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import actions from 'store/actions';

import { GetSystemStatusesResponse, SystemStatus } from 'store/dto/dtos';
import selectors from 'store/selectors';
import {
	CheckOutlined,
	CloseOutlined,
	HistoryOutlined,
} from '@ant-design/icons';
import {
	Switch, Tooltip, Button, Modal, Input, Form, Table, DatePicker,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Permission } from 'store/dto/hub.dtos';
import tokenService from 'service/token.service';
import {
	ContentWidget,
	ControlElement,
	FooterWidget,
	TableTest,
	TitleWidget,
	WidgetBox,
} from './styledComponent';

const { TextArea } = Input;

interface IDataType {
	id: React.Key;
	from: string;
	paymentDisabled: string;
	orderDisabled: string;
	description: string;
  }

const { RangePicker } = DatePicker;

const columns: ColumnsType<IDataType> = [
	{
		title: 'Время',
		dataIndex: 'from',

	},
	{
		title: 'Оплата заказов',
		dataIndex: 'paymentDisabled',

	},
	{
		title: 'Создание заказов',
		dataIndex: 'orderDisabled',
	},
	{
		title: 'Описание',
		dataIndex: 'description',
	},
];
export type SystemDataWidgetHandle = {
  updateHandle: () => void;
};

const StatusSystemWidget = React.forwardRef<SystemDataWidgetHandle>((props, ref) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const statisticsData: GetSystemStatusesResponse = useSelector(
		selectors.data.getSystemStatuses,
	);
	const currentData: SystemStatus = useSelector(
		selectors.data.getAdminCurrentSystemStatus,
	);
	const permissionsData: string[] = useSelector(
		selectors.authorization.getPermissions,
	);
	const currentLoading : string[] = useSelector(selectors.app.getCurrentLoading);

	const [paramsControlTable, setParamsControlTable] = React.useState<{ from:any, to:any}>({ from: null, to: null });
	const [dataHistoryTable, setDataHistoryTable] = React.useState<IDataType[]>([]);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [isHistoryModalOpen, setIsHistoryModalOpen] = React.useState(false);
	const [requestParams, setRequestParams] = React.useState<{paymentDisabled:boolean, orderDisabled:boolean, description:string}>(
		{ paymentDisabled: currentData.paymentDisabled, orderDisabled: currentData.orderDisabled, description: '' },
	);
	const [defaultSystemStatus, setDefaultSystemStatus] = React.useState<SystemStatus>(currentData);
	const [checkAdministratorState, setCheckAdministratorState] = React.useState<boolean>(false);

	const updateData = () => {
		dispatch(actions.data.saga.asyncGetAdminCurrentSystemStatus(''));
	};

	React.useImperativeHandle(ref, () => ({
		updateHandle() {
			updateData();
		},
	}));

	const onChangeDescription = (value:any) => {
		setRequestParams(
			{ ...requestParams, description: value.target.value },
		);
	};

	const showModal = () => {
		setIsModalOpen(true);
		form.resetFields();
	};

	const checkAdministrator = () => {
		if (tokenService.permissions === null) {
			console.log('в localstorage нету permissions');
			if (permissionsData.length === 0) {
				console.log('в store нету permissions');

				const callbackPermissions = (value:string[]) => {
					setCheckAdministratorState(!!value.find((item:string) => item === 'system.manage'));
				};
				dispatch(actions.authorization.saga.asyncGetPermissionsCallBack({ callbackPermissions }));
			} else {
				setCheckAdministratorState(!!permissionsData.find(
					(item) => item === 'system.manage',
				));
			}
		} else {
			setCheckAdministratorState(!!JSON.parse(tokenService.permissions || '').find(
				(item:string) => item === 'system.manage',
			));
		}
		return false;
	};

	const sussesCloseModalEdit = () => {
		setIsModalOpen(false);
		dispatch(actions.data.saga.asyncGetAdminCurrentSystemStatus(''));
	};

	const handleEditOk = () => {
		const callback = () => sussesCloseModalEdit();
		dispatch(actions.data.saga.asyncCreateSystemStatus({
			item: {
				orderDisabled: requestParams.orderDisabled,
				paymentDisabled: requestParams.paymentDisabled,
				from: '',
				description: requestParams.description,
			} as SystemStatus,
			callback,
		}));
	};
	const loadDataHistiry = () => {
		dispatch(actions.data.saga.asyncGetSystemStatuses({
			items: 0,
			page: 0,
			filter: '',
			sort: 'from desc',
			to: paramsControlTable.to && dayjs(paramsControlTable.to).add(24, 'hour').toISOString(),
			from: paramsControlTable.from && dayjs(paramsControlTable.from).toISOString(),
		}));
	};
	const openHistoryModal = () => {
		setIsHistoryModalOpen(true);
		loadDataHistiry();
	};

	React.useEffect(() => {
		dispatch(actions.data.saga.asyncGetAdminCurrentSystemStatus(''));
		checkAdministrator();
	}, []);
	React.useEffect(() => {
		setDefaultSystemStatus(currentData);
		setRequestParams({
			...requestParams,
			orderDisabled: currentData.orderDisabled,
			paymentDisabled: currentData.paymentDisabled,
		});
	}, [currentData]);

	React.useEffect(() => {
		const tempData: IDataType [] = [];
		statisticsData.items?.forEach((item) => {
			tempData.push({
				from: dayjs(item.from).format('DD.MM.YYYY HH:mm'),
				id: item.id,
				orderDisabled: item.orderDisabled ? 'Отключено' : 'Включено',
				paymentDisabled: item.paymentDisabled ? 'Отключено' : 'Включено',
				description: item.description,
			});
		});
		setDataHistoryTable(tempData);
	}, [statisticsData]);

	React.useEffect(() => {
		loadDataHistiry();
	}, [paramsControlTable]);
	return (
		<WidgetBox>
			<TitleWidget>
				<span>Статус системы</span>

				<ControlElement>
					<Tooltip title="История">
						<Button onClick={openHistoryModal} className="button-antd" ghost icon={<HistoryOutlined />}>
							{/* История */}
						</Button>
					</Tooltip>
				</ControlElement>
			</TitleWidget>
			<ContentWidget>
				<div className="div1">Оформление заказов:</div>
				<div
					className="div2"
				>
					<Switch
						disabled={!checkAdministratorState}
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						onChange={(checked:boolean) => setRequestParams({ ...requestParams, orderDisabled: !checked })}
						defaultChecked
						checked={!requestParams.orderDisabled}
						loading={!currentLoading.findIndex((item) => item === 'loadingAdminCurrentSystemStatus')}
					/>
				</div>
				<div className="div3">Оплата заказов: </div>
				<div className="div4">
					<Switch
						disabled={!checkAdministratorState}
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						onChange={(checked:boolean) => setRequestParams({ ...requestParams, paymentDisabled: !checked })}
						defaultChecked
						loading={!currentLoading.findIndex((item) => item === 'loadingAdminCurrentSystemStatus')}
						checked={!requestParams.paymentDisabled}
					/>
				</div>
			</ContentWidget>
			<FooterWidget>
				<Button
					style={{ fontSize: '12px' }}
					size="small"
					type="primary"
					onClick={showModal}
					disabled={defaultSystemStatus.orderDisabled === requestParams.orderDisabled
						&& defaultSystemStatus.paymentDisabled === requestParams.paymentDisabled}
				>
					Сохранить

				</Button>
			</FooterWidget>

			<Modal
				title="Укажите причину"
				open={isModalOpen}
				footer={[

					<Button
						onClick={() => setIsModalOpen(false)}
						loading={!currentLoading.findIndex((item) => item === 'createSystemStatus')}
					>
						Отмена
					</Button>,
					<Button
						form="myForm"
						type="primary"
						key="submit"
						htmlType="submit"
						loading={!currentLoading.findIndex((item) => item === 'createSystemStatus')}
					>
						Подтвердить
					</Button>,

				]}
			>
				<Form
					id="myForm"
					form={form}
					onFinish={handleEditOk}
				>
					<Form.Item name="description" rules={[{ required: true, message: 'Укажите причину!' }]}>
						<TextArea
							rows={4}
							placeholder="Укажите причину здесь"
							maxLength={500}
							value={requestParams.description}
							onChange={onChangeDescription}
						/>
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				title={[
					<div style={{
						display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px',
					}}
					>
						<span>история</span>
						<RangePicker
							format="DD.MM.YYYY"
							onChange={(value) => {
								value ? setParamsControlTable({
									from: dayjs(value?.[0]).set('hour', 0).set('minute', 0).set('second', 0),
									to: dayjs(value?.[1]).set('hour', 0).set('minute', 0).set('second', 0),
								}) : setParamsControlTable({ from: null, to: null });
							}}
							style={{ minWidth: '300px' }}
						/>
					</div>,
				]}
				open={isHistoryModalOpen}
				width="70vw"
				footer={[
					<Button type="primary" onClick={() => setIsHistoryModalOpen(false)}>
						Закрыть
					</Button>,
				]}
			>
				<TableTest>
					<Table
						bordered
						columns={columns}
						dataSource={dataHistoryTable}
						pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50'] }}
						scroll={{ y: 'calc(50vh - 4em)' }}
					/>
				</TableTest>
			</Modal>
		</WidgetBox>
	);
});

export default StatusSystemWidget;
